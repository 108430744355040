import {AfterViewInit, Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {TableColumn} from "./TableColumn";
import {MatSort, Sort, SortDirection} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import { ActivatedRoute, Router} from "@angular/router";
import { merge, Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import decode from 'jwt-decode';
import { FormsService } from '../../../formio-core/forms-and-formsets.service';

@Component({
  selector: 'custom-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss', '../../styles/data-table-style.css']
})
export class TableComponent implements OnInit, AfterViewInit, OnDestroy {

  @HostListener('document:click', ['$event'])
  public documentClick(event: Event): void { 
    // let count=0;
    // if(event) {
    // count++;
    // console.log("mouse event",count );
    // }
    // this.filter='';
  }
  
  public tableDataSource = new MatTableDataSource([]);
  public displayedColumns: string[];
  public active: string = '';
  public filter: string = '';
  public direction: SortDirection;
  public pageIndex: number = 0;
  public queryParams: Subscription;
  public isDashBoardPage:boolean = false;
  public usr;
  sharedData: string;

  // @Input() childMessage;
 
  @ViewChild(MatPaginator) matPaginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) matSort: MatSort;

  @Input() isPageable = false;
  @Input() isSortable = false;
  @Input() isFilterable = false;
  @Input() tableColumns: TableColumn[];
  @Input() rowActionIcon: string;
  @Input() rowActions: {label: string; key: string; icon: string}[];
  @Input() paginationSizes: number[] = [5, 10, 15];
  @Input() routerLinkStr: string;
  @Input() routerLinkParam: string;
  @Input() AfterrouterLinkParam: string;
  @Input() routerLinkURLParams: string[];
  @Input() defaultPageSize = 10;
  @Output() sort: EventEmitter<Sort> = new EventEmitter();
  @Output() rowAction: EventEmitter<any> = new EventEmitter<any>();
  // this property needs to have a setter, to dynamically get changes from parent component
  @Input() set tableData(data: any[]) {
    this.setTableDataSource(data);
  }

  constructor(private router: Router, private route: ActivatedRoute,private formservice: FormsService,) {}

  ngOnInit(): void {
    this.formservice.sharedData$.subscribe(res=>{
      if(res){
      this.filter= '';
      }
    }) 
    // console.log(this.routerLinkStr, this.routerLinkURLParams)
    this.usr = decode(localStorage.getItem('token'));
    // console.log("usr = " + JSON.stringify(this.usr));

    this.queryParams = this.route.queryParams.subscribe(params => {
      if(!(Object.keys(params).length === 0 && params.constructor === Object)) {
      this.pageIndex = params.pageIndex;
      this.defaultPageSize = params.pageSize;
      this.active = params.active,
      this.direction = params.direction,
      this.filter = params.filter
     }
    });
    const columnNames = this.tableColumns.map((tableColumn: TableColumn) => tableColumn.name);
    if (this.rowActionIcon) {
      this.displayedColumns = [...columnNames, this.rowActionIcon]
    } else {
      this.displayedColumns = columnNames;
    }
   this.sortTable({active: this.active, direction: this.direction});
  
  }

  // we need this, in order to make pagination work with *ngIf
  ngAfterViewInit(): void {
    this.tableDataSource.paginator = this.matPaginator;
    merge(this.matSort.sortChange, this.matPaginator.page)
            .pipe(
                tap(() => {
                  this.router.navigate(
                    [],
                    {
                      relativeTo: this.route,
                      queryParams: { direction: this.matSort.direction, active: this.matSort.active, pageSize: this.matPaginator.pageSize, pageIndex: this.matPaginator.pageIndex },
                      queryParamsHandling: 'merge'
                    });
                })
            )
            .subscribe();
  }
  navigateToMasterData(event: any, workflow: any, formId: string) {
   event.stopPropagation();
   this.router.navigate(['/workflows/workflow-editor',{wfTitle:workflow.wfTitle,wfId:workflow.wfId, formType:'masterdata', formId:formId}]);

  }
  navigateToNext(workflow: any) {
    let routeobj = {};
    if(this.routerLinkURLParams && this.routerLinkURLParams.length > 0) {
      this.routerLinkURLParams.forEach(param => {
        routeobj[param] = workflow[param];
      })
    }
    // console.log(workflow,routeobj);
    if(!this.routerLinkParam) {
      if(this.routerLinkStr){
       this.router.navigate([this.routerLinkStr, routeobj]);
      }
   } else {
     if(!this.AfterrouterLinkParam) {
    this.router.navigate([this.routerLinkStr, workflow[this.routerLinkParam], routeobj]);
    } else {
      this.router.navigate([this.routerLinkStr, workflow[this.routerLinkParam], this.AfterrouterLinkParam]);
    }
   }
  }

  setTableDataSource(data: any) {
    this.tableDataSource = new MatTableDataSource<any>(data);
    this.tableDataSource.filter = this.filter;
    this.tableDataSource.paginator = this.matPaginator;
    this.tableDataSource.sort = this.matSort;
    this.isDashBoardPage = (this.router.url === '/dashboard-list') ? true : false;
  }

  applyFilter(event: Event) {
    
    const filterValue = (event.target as HTMLInputElement).value;
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: { filter: filterValue.trim().toLowerCase() },
        queryParamsHandling: 'merge'
      });
    this.tableDataSource.filter = filterValue.trim().toLowerCase();
  }

  sortTable(sortParameters: Sort) {
    // defining name of data property, to sort by, instead of column name
    if(sortParameters.active) {
    sortParameters.active = this.tableColumns.find(column => column.name === sortParameters.active).dataKey;
  }
    this.sort.emit(sortParameters);
  }

  emitRowAction(actionType: string, row: any, event?: any) {
    this.rowAction.emit({actionType, row, event});
  }
  ngOnDestroy() {
    this.queryParams.unsubscribe();
  }
}
