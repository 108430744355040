import ComponentEditConditional from 'formiojs/components/_classes/component/editForm/Component.edit.conditional';
import ComponentEditData from 'formiojs/components/_classes/component/editForm/Component.edit.data';
import ComponentEditAPI from 'formiojs/components/_classes/component/editForm/Component.edit.api';
import ComponentEditDisplay from 'formiojs/components/_classes/component/editForm/Component.edit.display';

import ComponentEditLogic from 'formiojs/components/_classes/component/editForm/Component.edit.logic';
import ComponentEditValidation from 'formiojs/components/_classes/component/editForm/Component.edit.validation';
import ComponentEditLayout from 'formiojs/components/_classes/component/editForm/Component.edit.layout';
import _ from 'lodash';
import EditFormUtils from 'formiojs/components/_classes/component/editForm/utils';
export function BarcodeEditForm() {
  return {
    components: [
      { key: 'type', type: 'hidden' },
      {
        type: 'tabs',
        key: 'tabs',
        components: [
          {
            label: 'Display',
            key: 'display',
            weight: 0,
            components:
            [
              {
                weight: 0,
                type: 'textfield',
                input: true,
                key: 'label',
                label: 'Label',
                placeholder: 'Field Label',
                tooltip: 'The label for this field that will appear next to it.',
                validate: {
                  required: true
                }
              },
              {
                type: 'select',
                input: true,
                key: 'labelPosition',
                label: 'Label Position',
                tooltip: 'Position for the label for this field.',
                weight: 20,
                defaultValue: 'top',
                dataSrc: 'values',
                data: {
                  values: [
                    { label: 'Top', value: 'top' },
                    { label: 'Left (Left-aligned)', value: 'left-left' },
                    { label: 'Left (Right-aligned)', value: 'left-right' },
                    { label: 'Right (Left-aligned)', value: 'right-left' },
                    { label: 'Right (Right-aligned)', value: 'right-right' },
                    { label: 'Bottom', value: 'bottom' }
                  ]
                }
              },
              {
                type: 'number',
                input: true,
                key: 'labelWidth',
                label: 'Label Width',
                tooltip: 'The width of label on line in percentages.',
                clearOnHide: false,
                weight: 30,
                placeholder: '30',
                suffix: '%',
                validate: {
                  min: 0,
                  max: 100
                },
                conditional: {
                  json: {
                    and: [
                      { '!==': [{ var: 'data.labelPosition' }, 'top'] },
                      { '!==': [{ var: 'data.labelPosition' }, 'bottom'] },
                    ]
                  }
                }
              },
              {
                type: 'number',
                input: true,
                key: 'labelMargin',
                label: 'Label Margin',
                tooltip: 'The width of label margin on line in percentages.',
                clearOnHide: false,
                weight: 40,
                placeholder: '3',
                suffix: '%',
                validate: {
                  min: 0,
                  max: 100
                },
                conditional: {
                  json: {
                    and: [
                      { '!==': [{ var: 'data.labelPosition' }, 'top'] },
                      { '!==': [{ var: 'data.labelPosition' }, 'bottom'] },
                    ]
                  }
                }
              },
              {
                weight: 100,
                type: 'textfield',
                input: true,
                key: 'placeholder',
                label: 'Placeholder',
                placeholder: 'Placeholder',
                tooltip: 'The placeholder text that will appear when this field is empty.'
              },
              {
                weight: 200,
                type: 'textarea',
                input: true,
                key: 'description',
                label: 'Description',
                placeholder: 'Description for this field.',
                tooltip: 'The description is text that will appear below the input field.',
                editor: 'ace',
                as: 'html',
                wysiwyg: {
                  minLines: 3,
                },
              },
              {
                weight: 300,
                type: 'textarea',
                input: true,
                key: 'tooltip',
                label: 'Tooltip',
                placeholder: 'To add a tooltip to this field, enter text here.',
                tooltip: 'Adds a tooltip to the side of this field.',
                editor: 'ace',
                as: 'html',
                wysiwyg: {
                  minLines: 3,
                },
              },
              {
                weight: 500,
                type: 'textfield',
                input: true,
                key: 'customClass',
                label: 'Custom CSS Class',
                placeholder: 'Custom CSS Class',
                tooltip: 'Custom CSS class to add to this component.'
              },
              {
                weight: 600,
                type: 'textfield',
                input: true,
                key: 'tabindex',
                label: 'Tab Index',
                placeholder: '0',
                tooltip: 'Sets the tabindex attribute of this component to override the tab order of the form. See the <a href=\'https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/tabindex\'>MDN documentation</a> on tabindex for more information.'
              },
              {
                weight: 1100,
                type: 'checkbox',
                label: 'Hidden',
                tooltip: 'A hidden field is still a part of the form, but is hidden from view.',
                key: 'hidden',
                input: true
              },
              {
                weight: 1200,
                type: 'checkbox',
                label: 'Hide Label',
                tooltip: 'Hide the label of this component. This allows you to show the label in the form builder, but not when it is rendered.',
                key: 'hideLabel',
                input: true
              },
              {
                weight: 1350,
                type: 'checkbox',
                label: 'Initial Focus',
                tooltip: 'Make this field the initially focused element on this form.',
                key: 'autofocus',
                input: true
              },
              {
                weight: 1370,
                type: 'checkbox',
                label: 'Show Label in DataGrid',
                tooltip: 'Show the label when in a Datagrid.',
                key: 'dataGridLabel',
                input: true,
                customConditional(context) {
                  return context.instance.options.editComponent.inDataGrid;
                }
              },
              {
                weight: 1400,
                type: 'checkbox',
                label: 'Disabled',
                tooltip: 'Disable the form input.',
                key: 'disabled',
                input: true
              },
              {
                weight: 1500,
                type: 'checkbox',
                label: 'Table View',
                tooltip: 'Shows this value within the table view of the submissions.',
                key: 'tableView',
                input: false
              },
              {
                weight: 1600,
                type: 'checkbox',
                label: 'Modal Edit',
                tooltip: 'Opens up a modal to edit the value of this component.',
                key: 'modalEdit',
                input: true
              },

            ]
          },
          {
            label: 'Data',
            key: 'data',
            weight: 0,
            components: [

              {
                weight: 0,
                type: 'checkbox',
                label: 'Multiple Values',
                tooltip: 'Allows multiple values to be entered for this field.',
                key: 'multiple',
                input: true
              },
              {
                type: 'textfield',
                label: 'Default Value',
                key: 'defaultValue',
                weight: 5,
                placeholder: 'Default Value',
                tooltip: 'The will be the value for this field, before user interaction. Having a default value will override the placeholder text.',
                input: true
              },
              {
                weight: 30,
                type: 'radio',
                label: 'Persistent',
                tooltip: 'A persistent field will be stored in database when the form is submitted.',
                key: 'persistent',
                input: true,
                inline: true,
                defaultValue: true,
                values: [
                  { label: 'None', value: false },
                  { label: 'Server', value: true },
                  { label: 'Client', value: 'client-only' },
                ]
              },
              {
                weight: 150,
                type: 'checkbox',
                label: 'Protected',
                tooltip: 'A protected field will not be returned when queried via API.',
                key: 'protected',
                input: true
              },
              {
                type: 'checkbox',
                input: true,
                weight: 200,
                key: 'dbIndex',
                label: 'Database Index',
                tooltip: 'Set this field as an index within the database. Increases performance for submission queries.'
              },
              {
                weight: 400,
                type: 'checkbox',
                label: 'Encrypted (Enterprise Only)',
                tooltip: 'Encrypt this field on the server. This is two way encryption which is not suitable for passwords.',
                key: 'encrypted',
                input: true
              },
              {
                weight: 1700,
                type: 'checkbox',
                label: 'Update Value To Scanned Barcode',
                tooltip: 'Once the barcode is scanned successfully, the form field will be set to the scanned data.',
                key: 'barcodeDataField',
                input: true,
              },
              {
                weight: 300,
                type: 'checkbox',
                input: true,
                label: 'Trigger Smart Data Component',
                tooltip: 'Enable if you require a barcode scan to trigger a Smart Data Component to fetch data',
                key: 'publishEvents'
              },
              {
                type: 'select',
                input: true,
                label: 'Select Smart Data Component',
                key: 'eventName',
                dataSrc: 'custom',
                placeholder: 'Event Name',
                valueProperty: 'value',
                validate: {
                  required: true
                },
                conditional: {
                  json: {
                    '===': [{
                      var: 'data.publishEvents'
                    }, true]
                  }
                },
                data: {
                  custom(context) {
                    const values = [];
                    context.utils.eachComponent(context.instance.options.editForm.components, function (component, path) {
                      if (component.key !== context.data.key && component.type === 'smartdata') {
                        values.push({
                          label: ''.concat(component.label || component.key, ' (').concat(path, ')'),
                          value: path
                        });
                      }
                    });
                    return values;
                  }
                }
              },
              {
                type: 'select',
                input: true,
                key: 'redrawOn',
                label: 'Redraw On',
                weight: 600,
                tooltip: 'Redraw this component if another component changes. This is useful if interpolating parts of the component like the label.',
                dataSrc: 'custom',
                valueProperty: 'value',
                data: {
                  custom(context) {
                    const values = [];
                    values.push({ label: 'Any Change', value: 'data' });
                    context.utils.eachComponent(context.instance.options.editForm.components, function (component, path) {
                      if (component.key !== context.data.key) {
                        values.push({
                          label: component.label || component.key,
                          value: path
                        });
                      }
                    });
                    return values;
                  }
                },
                conditional: {
                  json: { '!': [{ var: 'data.dataSrc' }] },
                },
              },
              {
                weight: 700,
                type: 'checkbox',
                label: 'Clear Value When Hidden',
                key: 'clearOnHide',
                defaultValue: true,
                tooltip: 'When a field is hidden, clear the value.',
                input: true
              },
              EditFormUtils.javaScriptValue('Custom Default Value', 'customDefaultValue', 'customDefaultValue', 1000,
                '<p><h4>Example:</h4><pre>value = data.firstName + " " + data.lastName;</pre></p>',
                '<p><h4>Example:</h4><pre>{"cat": [{"var": "data.firstName"}, " ", {"var": "data.lastName"}]}</pre>'
              ),
              EditFormUtils.javaScriptValue('Calculated Value', 'calculateValue', 'calculateValue', 1100,
                '<p><h4>Example:</h4><pre>value = data.a + data.b + data.c;</pre></p>',
                '<p><h4>Example:</h4><pre>{"+": [{"var": "data.a"}, {"var": "data.b"}, {"var": "data.c"}]}</pre><p><a target="_blank" href="http://formio.github.io/formio.js/app/examples/calculated.html">Click here for an example</a></p>',
                '<tr><th>token</th><td>The decoded JWT token for the authenticated user.</td></tr>'
              ),
              {
                type: 'checkbox',
                input: true,
                weight: 1100,
                key: 'calculateServer',
                label: 'Calculate Value on server',
                tooltip: 'Checking this will run the calculation on the server. This is useful if you wish to override the values submitted with the calculations performed on the server.'
              },
              {
                type: 'checkbox',
                input: true,
                weight: 1200,
                key: 'allowCalculateOverride',
                label: 'Allow Manual Override of Calculated Value',
                tooltip: 'When checked, this will allow the user to manually override the calculated value.'
              },

            ]
          },

          {
            label: 'Validation',
            key: 'validation',
            weight: 20,
            components: ComponentEditValidation

          },
          {
            label: 'API',
            key: 'api',
            weight: 30,
            components: [
              {
                weight: 0,
                type: 'textfield',
                input: true,
                key: 'key',
                label: 'Property Name',
                tooltip: 'The name of this field in the API endpoint.',
                validate: {
                  pattern: '(\\w|\\w[\\w-.]*\\w)',
                  patternMessage: 'The property name must only contain alphanumeric characters, underscores, dots and dashes and should not be ended by dash or dot.'
                }
              }, {
                weight: 100,
                type: 'tags',
                input: true,
                label: 'Field Tags',
                storeas: 'array',
                tooltip: 'Tag the field for use in custom logic.',
                key: 'tags'
              }, {
                weight: 200,
                type: 'datamap',
                label: 'Custom Properties',
                tooltip: 'This allows you to configure any custom properties for this component.',
                key: 'properties',
                valueComponent: {
                  type: 'textfield',
                  key: 'value',
                  label: 'Value',
                  placeholder: 'Value',
                  input: true
                }
              },
            ]
          },
          {
            label: 'Conditional',
            key: 'conditional',
            weight: 40,
            components: ComponentEditConditional
          },
          {
            label: 'Logic',
            key: 'logic',
            weight: 50,
            components: ComponentEditLogic
          },
          {
            label: 'Layout',
            key: 'layout',
            weight: 60,
            components: ComponentEditLayout
          }
        ]
      }
    ]
  };
}
