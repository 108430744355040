import { Component, OnInit } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';

import { Router, Event, NavigationStart, NavigationEnd, NavigationError, NavigationCancel } from '@angular/router';
import { ImagePreviewComponent } from './forms/image-preview/image-preview.component';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  public loading: boolean;
    
    constructor(private router: Router, public overlayContainer: OverlayContainer, 
      public dialog: MatDialog) {
    this.router.events.subscribe((routerEvent: Event) => {
      this.checkRouterEvent(routerEvent);
    });
  }
  ngOnInit () {
    this.loading = true;
  
  document.removeEventListener('openImagePreview', this.openImagePreviewHandler.bind(this), true)
    document.addEventListener('openImagePreview', this.openImagePreviewHandler.bind(this), true)
  }
    openImagePreviewHandler(event){
    this.openImagePreview(event.detail.data)
  }
  async openImagePreview(imageData){
  const dialogRef = this.dialog.open(ImagePreviewComponent, {
    minWidth: '70%',
    height:'90%',
    panelClass: 'custom-dialog',
    data: {
      imageData: imageData
    },
  });
  dialogRef.afterClosed().subscribe((response) => {
    if (response) {
      document.removeEventListener('openImagePreview', this.openImagePreviewHandler.bind(this), true)
    }
  });
}
  checkRouterEvent(routerEvent: Event): void {
    if (routerEvent instanceof NavigationStart) {
      this.loading = true;
    }
    if (
      routerEvent instanceof NavigationEnd ||
      routerEvent instanceof NavigationCancel ||
      routerEvent instanceof NavigationError) {
      this.loading = false;
    }
  }
}
