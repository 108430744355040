import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sso-login',
  template: ``,
  styles: []
})
export class SSOLoginComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
  }

}
