import { Component, OnInit, Inject, ViewChild, HostListener } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FormsService } from '../../../formio-core/forms-and-formsets.service';
import { MatSelectionList } from '@angular/material/list';
import { HotToastService } from '@ngneat/hot-toast';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { whiteSpaceValidator } from '../../directives/whitespacevalidator.directive';
@Component({
  selector: 'app-import-forms',
  templateUrl: './import-forms.component.html',
  styleUrls: [
    './import-forms.component.css'
  ]
})
export class ImportFormsComponent implements OnInit {
  @HostListener('window:keyup.esc') onKeyUp() {
    this.closeoverlay();
  }
  public formsselectlist = [];
  errmsg: string;
  selectedForms: any;
  autopublish = false;
  overwritedraft = false;
  @ViewChild('selectedformitems')
  private selectedformitems: MatSelectionList;
  importform: FormGroup;
  public file: File;
  public showpassword = false;
  FileJsonObj = null;
  constructor(
    public dialogRef: MatDialogRef<ImportFormsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private formservice: FormsService,
    private toastService: HotToastService
  ) {}

  ngOnInit(): void {
    this.importform = this.fb.group({
      url: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, whiteSpaceValidator()]]
    });
  }

  processFile(fileInput: any) {
    // var files = evt.target.files; // FileList object
    this.file = fileInput.files[0];
    const reader = new FileReader();
    // Closure to capture the file information.
    reader.addEventListener('load', (event: any) => {
      this.FileJsonObj = JSON.parse(event.target.result);
      // console.log(this.JsonObj, typeof this.JsonObj);
    });
    // Read in the file as a Text.
    reader.readAsText(this.file);
  }

  b64EncodeUnicode(str: any) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
    return String.fromCharCode(("0x" + p1) as any);
    }));
   }

  importfromfile() {
    this.formservice
    .importformsfromfile(
      this.data.importtype,
      this.data.formtype,
      this.autopublish,
      this.overwritedraft,
      this.b64EncodeUnicode(JSON.stringify(this.FileJsonObj))
    )
    .subscribe(res => {
      // console.log(res);
    const response = JSON.parse(res);
    // console.log(response);
    if (response.error === '') {
      if (response.status === 'Success') {
           this.toastService.success(`skipped:${response.skipped},imported ${response.imported} of all selected ${this.data.formtype}`, {
            style: {
              border: "1px solid var(--success-color)",
              padding: "16px",
              color: "var(--success-color)",
            },
            duration: 2000,
            position: "top-right",
          });
        this.closeoverlay();
      }
    } else {
      this.errmsg = response.error;
    }
    });
  }
  downloadforms() {
    this.formservice
      .downloadremoteforms(
        this.data.importtype,
        this.data.formtype,
        this.importform.value
      )
      .subscribe(res => {
        // console.log(res);
        const response = JSON.parse(res);
        if (response.error === '') {
          if (response.status === 'Success') {
            this.formsselectlist = response.data;
           // console.log('Downloaded forms successfully!!');
          }
        } else {
          this.errmsg = response.error;
        }
      });
  }
  isAllSelected() {
    const numSelected = this.selectedformitems.selectedOptions.selected.length;
    const numRows = this.formsselectlist.length;
    return numSelected === numRows;
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selectedformitems.selectedOptions.clear();
    } else {
      this.selectedformitems.selectAll();
    }
  }
  importselectedforms() {
    // console.log('Forms to export', this.selectedformitems.selectedOptions.selected.map(o => o.value));
    // console.log(this.autopublish, this.overwritedraft);
    this.formservice
      .importremoteforms(
        this.data.importtype,
        this.data.formtype,
        this.importform.value,
        this.selectedformitems.selectedOptions.selected.map(o => o.value),
        this.autopublish,
        this.overwritedraft
      )
      .subscribe(res => {
        const response = JSON.parse(res);
        // console.log(response);
        if (response.error === '') {
          if (response.status === 'Success') {
               this.toastService.success(`skipped:${response.skipped}, imported ${response.imported} of all selected ${this.data.formtype}`, {
                style: {
                  border: "1px solid var(--success-color)",
                  padding: "16px",
                  color: "var(--success-color)",
                },
                duration: 2000,
                position: "top-right",
              });
            this.closeoverlay();
          }
        } else {
          this.errmsg = response.error;
        }
      });
  }
  closeoverlay() {
    this.dialogRef.close();
  }
}
