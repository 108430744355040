import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IconpickerService } from '../../dialogs/iconpicker/iconpicker.service';
import { iconPickerOverlayRef } from '../../dialogs/iconpicker/iconpickerref';
@Component({
  selector: 'app-dashboardlist-dialog',
  templateUrl: './dashboardlist-dialog.component.html',
  styleUrls: ['./dashboardlist-dialog.component.css']
})
export class DashboardlistDialogComponent implements OnInit {
  update: FormGroup;
  picked_icon: string='';
  constructor(public dialogRef: MatDialogRef<DashboardlistDialogComponent>, 
    private fb: FormBuilder, 
    private iconpickerservice: IconpickerService,
    @Inject(MAT_DIALOG_DATA) public data: any,) { }

  ngOnInit(): void {
  
  this.update = this.fb.group({
    name: ['', [Validators.required, Validators.maxLength(100)]],
    userName: ['',[Validators.required]],
    description:['',[Validators.required]],
    slug:['',[Validators.required]],
    accessRole:['',Validators.required],
    icon: [{ value: '', disabled: true }, Validators.required]
  });
  if (this.data.isupdate) {
    if(this.data.update){
    this.update.patchValue({
      name: `${this.data.update.name}`,
      userName: this.data.update.userName,
      description: `${this.data.update.description}`,
      slug:this.data.update.slug,
      accessRole:this.data.update.accessRole
    });
    this.picked_icon = this.data.update.avatar;
  }
}
}
openiconpicker() {
  const dialogRef: iconPickerOverlayRef = this.iconpickerservice.open();
  dialogRef.afterClosed().subscribe((icondata: any) => {
    this.picked_icon = icondata;
  });
 }
  
  updateDashboardList(){
    console.log(this.update.value);
    this.closeoverlay(null);
  }

  closeoverlay(responseobj: any) {
    this.dialogRef.close(responseobj);
  }

}
