/** Import core Angular modules */
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { httpInterceptorProviders } from './http-interceptors/index';
import { OverlayModule } from '@angular/cdk/overlay';
import { FormioModule } from 'angular-formio';
import { APP_INITIALIZER } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppConfigService } from './app.config';

/**
 * Importing External Modules
 *
 */
import { QueryBuilderModule } from 'angular2-query-builder';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { MonacoEditorModule, NgxMonacoEditorConfig } from 'ngx-monaco-editor';
import jsonataMode from './rete/nodes/jsonata-editor/jsonataMode';
import { MY_DATE_FORMATS, MyDateAdapter } from './date-format';
import { HotToastModule } from '@ngneat/hot-toast';

/**
 * Importing all the components of the app
 *
 */
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { HomeLayoutComponent } from './layouts/home-layout/home-layout.component';
import { LoginLayoutComponent } from './layouts/login-layout/login-layout.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { SignupComponent } from './signup/signup.component';
import { SSOLoginComponent } from './sso-login/sso-login.component';
import { LoaderComponent } from './shared/components/loader/loader.component';
// import { DialogComponent } from './shared/components/dialog/dialog.component';
import { ConfirmDialogComponent } from './shared/components/confirm-dialog/confirm-dialog.component';
import { PublicShredFormsInfoComponent } from './public-shred-forms-info/public-shred-forms-info.component';
import { BarcodeDialogComponent } from './shared/components/barcode-dialog/barcode-dialog.component';
import { TestformrendererDialogComponent } from './shared/dialogs/testformrenderer-dialog/testformrenderer-dialog.component';

/**
 * Importing  Custom Formio Components
 */

import './custom-components/smart-data/NewSmartData';
import './custom-components/autocomplete/AutoCompleteField';
import './custom-components/barcode/barcode';
import './custom-components/smart-button/SmartButton';
import './custom-components/smart-file/smart-file';
import './custom-components/smart-table/smart-table';
import './custom-components/smart-select/smart-select';
import './custom-components/select-barcode/SelectBarcode';
import './custom-components/smart-id/smart-id';
import './custom-components/nested-component/nested-component';
import './custom-components/smart-signature/Signature';
import { SafePipe } from './report-builder/pipes/safe-url-filter';
import { DashboardListComponent } from './dashboard-list/dashboard-list.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const monacoConfig: NgxMonacoEditorConfig = {
  onMonacoLoad: jsonataMode
};
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    HomeLayoutComponent,
    LoginLayoutComponent,
    DashboardComponent,
    PagenotfoundComponent,
    ResetpasswordComponent,
    SignupComponent,
    SSOLoginComponent,
    LoaderComponent,
    // DialogComponent,
    ConfirmDialogComponent,
    PublicShredFormsInfoComponent,
    BarcodeDialogComponent,
    TestformrendererDialogComponent,
    SafePipe,
    DashboardListComponent
   ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormioModule,
    SharedModule,
    HttpClientModule,
    OverlayModule,
    QueryBuilderModule,
    HotToastModule.forRoot(),
    MonacoEditorModule.forRoot(monacoConfig),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  },
  ),
  AppRoutingModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [AppConfigService],
      useFactory: (appConfigService: AppConfigService) => {
        return async () => {
          return await appConfigService.loadAppConfig();
          };
      }
    },
    { provide: DateAdapter, useClass: MyDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    httpInterceptorProviders
  ],
  bootstrap: [AppComponent],
  entryComponents: []
})
export class AppModule {}



