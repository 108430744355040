import {
  Components
} from 'angular-formio';
import _ from 'lodash';
import NativePromise from 'native-promise-only';
import Component from 'formiojs/components/_classes/component/Component';
import ComponentModal from 'formiojs/components/_classes/componentModal/ComponentModal';
import EventEmitter from 'eventemitter3';
const Form = Formio.Form;

import {
  isMongoId,
  eachComponent,
  getStringFromComponentPath,
  getArrayFromComponentPath
} from 'formiojs/utils/utils';

import {
  nestedComponentEditForm
} from './nested-component.editForm';

export default class SmartNestedComponent extends Component {
  static schema(...extend) {
    return Component.schema({
      label: 'Form',
      type: 'form',
      key: 'form',
      src: '',
      reference: true,
      form: '',
      path: '',
      tableView: true,
    }, ...extend);
  }

  static get builderInfo() {
    return {
      title: 'Smart Nested Form',
      icon: 'wpforms',
      group: 'premium',
      documentation: '/userguide/#smartnestedform',
      weight: 110,
      schema: SmartNestedComponent.schema()
    };
  }

  init() {
    super.init();

    if (this.component.form) {
      let temp = []
      temp = window.formdesign.filter((item) => item.key === this.component.key);
      // if(temp.length == 0){
      let that = this;
      const formId = that.component.form;
      let selVersion = that.component.selNestedFormVersion;
      if((temp.length > 0 && !temp[0].data)|| temp.length == 0){
      
      Formio.makeStaticRequest(
          `${localStorage.getItem(
          'UMP_url'
        )}/UMP/API/v2/applications/DIGITAL_FORMS/execute/DIGITAL_FORMS_PA_GET_FORM_DATA?queuedExecute=false&messageFormat=custom&inputMessage=%7B%22formId%22%3A%22${formId}%22%2C%22type%22%3A%22form%22%2C%22version%22%3A%22${selVersion}%22%7D&sensitive=undefined&externalReference=undefined`,
          'POST',
          null, {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
              Accept: 'application/json',
              'Content-type': 'application/json',
            },
          }
        )
        .then(function (response) {
          if (response) {
            response = JSON.parse(response);
            let data = that.getMasterdDataresource(response.formData, 'dataSrc', 'masterdata');
            const obj = {
              key: that.component.key,
              formdesign: data,
              category:response.category,
              data:true
            };
// if(response.category === "Approval Forms" && that.component.tags){

// if(response.category === "Nested Forms" && that.component.tags){
//   // that.component.tags.push("ApprovalNode"); 
//   // that.component.defaultValue = "ApprovalNode";
// }
            window.formdesign.push(obj);
            that.createSubForm().then(() => {
              if (that.element) {
                that.attach(that.element);
              }
            });
          }
        })
        .catch((error) => {
          console.error('Error getting form data ', error);
        });
      }else{
        if (window.formdesign && this.component.form) {
          for (let i = 0; i < window.formdesign.length; i++) {
            if (window.formdesign[i].key == this.component.key) {
              let data = this.getMasterdDataresource(window.formdesign[i].formdesign, 'dataSrc', 'masterdata');
              window.formdesign[i].formdesign = data;
            }
          }
  
        }

      }
    }

    this.formObj = {
      display: this.component.display,
      settings: this.component.settings,
      components: this.component.components
    };
    this.valueChanged = false;
    this.subForm = null;
    this.formSrc = '';
    if (this.component.src) {
      this.formSrc = this.component.src;
    }

    if (
      !this.component.src &&
      !this.options.formio &&
      (this.component.form || this.component.path)
    ) {
      if (this.component.project) {
        this.formSrc = Formio.getBaseUrl();
        // Check to see if it is a MongoID.
        if (isMongoId(this.component.project)) {
          this.formSrc += '/project';
        }
        this.formSrc += `/${this.component.project}`;
        this.options.project = this.formSrc;
      } else {
        this.formSrc = Formio.getProjectUrl();
        this.options.project = this.formSrc;
      }
      if (this.component.form) {
        this.formSrc += `/form/${this.component.form}`;
      } else if (this.component.path) {
        this.formSrc += `/${this.component.path}`;
      }
    }

    // Build the source based on the root src path.
    if (!this.formSrc && this.options.formio) {
      const rootSrc = this.options.formio.formsUrl;
      if (this.component.path) {
        const parts = rootSrc.split('/');
        parts.pop();
        this.formSrc = `${parts.join('/')}/${this.component.path}`;
      }
      if (this.component.form) {
        this.formSrc = `${rootSrc}/${this.component.form}`;
      }
    }

    if (this.builderMode && this.component.hasOwnProperty('formRevision')) {
      this.component.revision = this.component.formRevision;
      delete this.component.formRevision;
    }

    // Add revision version if set.
    if (this.component.revision || this.component.revision === 0 ||
      this.component.formRevision || this.component.formRevision === 0
    ) {
      this.setFormRevision(this.component.revision || this.component.formRevision);
    }
    return this.createSubForm();
  }


  get dataReady() {
    return this.subFormReady || NativePromise.resolve();
  }

  get defaultValue() {
    // Not not provide a default value unless the subform is ready so that it will initialize correctly.
    return this.subForm ? super.defaultValue : null;
  }

  get defaultSchema() {
    return SmartNestedComponent.schema();
  }

  get emptyValue() {
    return {
      data: {}
    };
  }

  get ready() {
    return this.subFormReady || NativePromise.resolve();
  }

  get useOriginalRevision() {
    // return this.component?.useOriginalRevision && !!this.formObj?.revisions;
    if (this.component && this.component.useOriginalRevision && !!(this.formObj && this.formObj.revisions)) {
      return true;
    } else {
      return false;
    }
  }

  setFormRevision(rev) {
    // Remove current revisions from src if it is
    this.formSrc = this.formSrc.replace(/\/v\/\d*/, '');
    const revNumber = Number.parseInt(rev);

    if (!isNaN(revNumber)) {
      this.subFormRevision = rev;
      this.formSrc += `/v/${rev}`;
    } else {
      this.subFormRevision = undefined;
    }
  }

  getComponent(path, fn) {
    path = getArrayFromComponentPath(path);
    if (path[0] === 'data') {
      path.shift();
    }
    const originalPathStr = `${this.path}.data.${getStringFromComponentPath(path)}`;
    if (this.subForm) {
      return this.subForm.getComponent(path, fn, originalPathStr);
    }
  }

  getSubOptions(options = {}) {
    options.parentPath = `${this.path}.data.`;
    options.events = this.createEmitter();

    // Make sure to not show the submit button in wizards in the nested forms.
    _.set(options, 'buttonSettings.showSubmit', false);

    if (!this.options) {
      return options;
    }
    if (this.options.base) {
      options.base = this.options.base;
    }
    if (this.options.project) {
      options.project = this.options.project;
    }
    if (this.options.readOnly || this.component.disabled) {
      options.readOnly = this.options.readOnly || this.component.disabled;
    }
    if (this.options.breadcrumbSettings) {
      options.breadcrumbSettings = this.options.breadcrumbSettings;
    }
    if (this.options.buttonSettings) {
      options.buttonSettings = _.clone(this.options.buttonSettings);
    }
    if (this.options.viewAsHtml) {
      options.viewAsHtml = this.options.viewAsHtml;
    }
    if (this.options.language) {
      options.language = this.options.language;
    }
    if (this.options.template) {
      options.template = this.options.template;
    }
    if (this.options.templates) {
      options.templates = this.options.templates;
    }
    if (this.options.renderMode) {
      options.renderMode = this.options.renderMode;
    }
    if (this.options.attachMode) {
      options.attachMode = this.options.attachMode;
    }
    if (this.options.iconset) {
      options.iconset = this.options.iconset;
    }
    if (this.options.fileService) {
      options.fileService = this.options.fileService;
    }
    if (this.options.onChange) {
      options.onChange = this.options.onChange;
    }
    return options;
  }

  render() {
    if (this.builderMode) {
      return super.render(this.component.label || 'Nested form');
    }
    const subform = this.subForm ? this.subForm.render() : this.renderTemplate('loading');
    return super.render(subform);
  }
  getMasterdDataresource(obj, key, val) {
    let objects = [];

    for (let i in obj) {
      if (typeof obj[i] === 'object') {
        objects = objects.concat(this.getMasterdDataresource(obj[i], key, val));
      } else if (i === key && obj[key] === val) {
        // let val1 = obj['valueProperty'];
        let ress;
        if (obj.data && typeof obj.data === 'object' && obj.data !== undefined) {
          if (obj.data['masterdata']) {
            ress = obj.data['masterdata'];
          }
        }
        let obj1 = [];

        const resource = JSON.parse(localStorage.getItem('resourceData'));
        if (resource !== undefined && resource !== '' && ress !== undefined && ress !== '') {
          for (let x = 0; x < resource.length; x++) {
            const s = resource[x].resourceName;
            if (s === ress) {
              obj1.push(JSON.parse(resource[x].data).data);
            }
          }
        } else {
          console.log('resource is empty');
          obj1 = [];
        }
        const tmplt = obj['template'];
        let str2 = '';
        if (tmplt !== '') {
          const str1 = tmplt.substring(tmplt.lastIndexOf('.') + 1, tmplt.length);
          str2 = str1.substring(0, str1.indexOf('}'), str1.length);
          const str3 = '<span>{{' + 'item.' + str2.trim() + '}}</span>';
          obj['template'] = str3;
        }
        // tslint:disable-next-line:no-shadowed-variable
        const val = obj['valueProperty'];
        let valProp = '';
        if (val !== '') {
          valProp = val.substring(val.lastIndexOf('.') + 1, val.length);
          obj['valueProperty'] = valProp;
        }
        str2 = str2.trim();
        obj1 = obj1.sort(function (a, b) {
          return (a[str2] > b[str2]) ? 1 : ((a[str2] < b[str2]) ? -1 : 0);
        });
        obj.masterdata = obj1;
      }
    }
    return obj;
  }
  asString(value) {
    return this.getValueAsString(value);
  }

  /**
   * Prints out the value of form components as a datagrid value.
   */
  getValueAsString(value) {
    if (!value) {
      return 'No data provided';
    }
    if (!value.data && value._id) {
      return value._id;
    }
    if (!value.data || !Object.keys(value.data).length) {
      return 'No data provided';
    }
    return '[Complex Data]';
  }

  attach(element) {
    // Don't attach in builder.
    if (this.builderMode) {
      return super.attach(element);
    }
    return super.attach(element)
      .then(() => {
        if (this.isSubFormLazyLoad() && !this.hasLoadedForm && !this.subFormLoading) {
          this.createSubForm(true);
        }

        return this.subFormReady.then(() => {
          this.empty(element);
          if (this.options.builder) {
            this.setContent(element, this.ce('div', {
              class: 'text-muted text-center p-2'
            }, this.text(this.formObj.title)));
            return;
          }

          this.setContent(element, this.render());
          if (this.subForm) {
            if (this.isNestedWizard) {
              element = this.root.element;
            }
            this.subForm.attach(element);

            if (!this.valueChanged && this.dataValue.state !== 'submitted') {
              this.setDefaultValue();
            } else {
              this.restoreValue();
            }
          }
          if (!this.builderMode && this.component.modalEdit) {
            const modalShouldBeOpened = this.componentModal ? this.componentModal.isOpened : false;
            const currentValue = modalShouldBeOpened ? this.componentModal.currentValue : this.dataValue;
            this.componentModal = new ComponentModal(this, element, modalShouldBeOpened, currentValue);
            this.setOpenModalElement();
          }
        });
      });
  }


  detach() {
    if (this.subForm) {
      this.subForm.detach();
    }
    super.detach();
  }

  get currentForm() {
    return this._currentForm;
  }

  get hasLoadedForm() {
    return this.formObj &&
      this.formObj.components &&
      Array.isArray(this.formObj.components) &&
      this.formObj.components.length;
  }

  set currentForm(instance) {
    this._currentForm = instance;
    if (!this.subForm) {
      return;
    }
    this.subForm.getComponents().forEach(component => {
      component.currentForm = this;
    });
  }

  get isRevisionChanged() {
    return _.isNumber(this.subFormRevision) &&
      _.isNumber(this.formObj._vid) &&
      this.formObj._vid !== this.subFormRevision;
  }

  destroy() {
    if (this.subForm) {
      this.subForm.destroy();
      this.subForm = null;
      this.subFormReady = null;
    }
    super.destroy();
  }

  redraw() {
    if (this.subForm) {
      this.subForm.form = this.formObj;
    }
    return super.redraw();
  }

  /**
   * Pass everyComponent to subform.
   * @param args
   * @returns {*|void}
   */
  everyComponent(...args) {
    if (this.subForm) {
      this.subForm.everyComponent(...args);
    }
  }

  updateSubWizards(subForm) {
    // if (this.isNestedWizard && this.root?.subWizards && subForm?._form?.display === 'wizard') {
    if (this.isNestedWizard && this.root && this.root.subWizards && subForm && subForm._form && subForm._form.display === 'wizard') {

      const existedForm = this.root.subWizards.findIndex(form => form.component.form === this.component.form);
      if (existedForm !== -1) {
        this.root.subWizards[existedForm] = this;
      } else {
        this.root.subWizards.push(this);
      }
      this.emit('subWizardsUpdated', subForm);
    }
  }

  /**
   * Create a subform instance.
   *
   * @return {*}
   */
  createSubForm(fromAttach) {
    this.subFormReady = this.loadSubForm(fromAttach).then((form) => {
      if (window.formdesign && this.component.form) {
        for (let i = 0; i < window.formdesign.length; i++) {
          if (window.formdesign[i].key == this.component.key) {
            form = window.formdesign[i].formdesign;
          }
        }

      }
      if (!form || (Object.keys(form).length === 0 && form.constructor === Object)) {
        return;
      }
      // Iterate through every component and hide the submit button.
      if (form.components.length > 0) {
        eachComponent(form.components, (component) => {
          this.hideSubmitButton(component);
        });
      }
      // If the subform is already created then destroy the old one.
      if (this.subForm) {
        this.subForm.destroy();
      }

      // Render the form.
      return (new Form(form, this.getSubOptions())).ready.then((instance) => {
        this.subForm = instance;
        this.subForm.currentForm = this;
        this.subForm.parent = this;
        this.subForm.parentVisible = this.visible;
        this.subForm.on('change', () => {
          if (this.subForm) {
            this.dataValue = this.subForm.getValue();
            this.triggerChange({
              noEmit: true
            });
          }
        });
        this.subForm.url = this.formSrc;
        this.subForm.nosubmit = true;
        this.subForm.root = this.root;
        this.restoreValue();
        this.valueChanged = this.hasSetValue;
        return this.subForm;
      });
    }).then((subForm) => {
      this.updateSubWizards(subForm);
      return subForm;
    });
    return this.subFormReady;
  }


  hideSubmitButton(component) {
    const isSubmitButton = (component.type === 'button') &&
      ((component.action === 'submit') || !component.action);

    if (isSubmitButton) {
      component.hidden = true;
    }
  }

  /**
   * Load the subform.
   */
  loadSubForm(fromAttach) {
    
    if(this.component.form){
      if (window.formdesign && this.component.form) {
        for (let i = 0; i < window.formdesign.length; i++) {
          if (window.formdesign[i].key == this.component.key) {
            this.formObj = window.formdesign[i].formdesign;
          }
        }
      }
    }

    if (this.builderMode || this.isHidden() || (this.isSubFormLazyLoad() && !fromAttach)) {
      return NativePromise.resolve();
    }
    if (this.hasLoadedForm && !this.isRevisionChanged) {
      // Pass config down to sub forms.
      if (this.root && this.root.form && this.root.form.config && !this.formObj.config) {
        this.formObj.config = this.root.form.config;
      }
      return NativePromise.resolve(this.formObj);
    } else if (this.formSrc) {
      this.subFormLoading = true;
      // return (new Formio(this.formSrc)).loadForm({ params: { live: 1 } })
      //   .then((formObj) => {
      //     this.formObj = formObj;
      //     this.subFormLoading = false;
      //     return formObj;
      //   });

      if (window.formdesign && this.component.form) {
        for (let i = 0; i < window.formdesign.length; i++) {
          if (window.formdesign[i].key == this.component.key) {
            this.formObj = window.formdesign[i].formdesign;
          }
        }
      }
      this.subFormLoading = false;
    }
    return NativePromise.resolve();
  }

  checkComponentValidity(data, dirty, row, options) {
    options = options || {};
    const silentCheck = options.silentCheck || false;

    if (this.subForm) {
      return this.subForm.checkValidity(this.dataValue.data, dirty, null, silentCheck);
    }

    return super.checkComponentValidity(data, dirty, row, options);
  }

  checkComponentConditions(data, flags, row) {
    const visible = super.checkComponentConditions(data, flags, row);

    // Return if already hidden
    if (!visible) {
      return visible;
    }

    if (this.subForm) {
      return this.subForm.checkConditions(this.dataValue.data);
    }
    // There are few cases when subForm is not loaded when a change is triggered,
    // so we need to perform checkConditions after it is ready, or some conditional fields might be hidden in View mode
    else if (this.subFormReady) {
      this.subFormReady.then(() => {
        if (this.subForm) {
          return this.subForm.checkConditions(this.dataValue.data);
        }
      });
    }

    return visible;
  }

  calculateValue(data, flags, row) {
    if (this.subForm) {
      return this.subForm.calculateValue(this.dataValue.data, flags);
    }

    return super.calculateValue(data, flags, row);
  }

  setPristine(pristine) {
    super.setPristine(pristine);
    if (this.subForm) {
      this.subForm.setPristine(pristine);
    }
  }

  /**
   * Determine if the subform should be submitted.
   * @return {*|boolean}
   */
  get shouldSubmit() {
    return this.subFormReady && (!this.component.hasOwnProperty('reference') || this.component.reference) && !this.isHidden();
  }

  /**
   * Returns the data for the subform.
   *
   * @return {*}
   */
  getSubFormData() {
    if (_.get(this.subForm, 'form.display') === 'pdf') {
      return this.subForm.getSubmission();
    } else {
      return NativePromise.resolve(this.dataValue);
    }
  }

  /**
   * Submit the subform if configured to do so.
   *
   * @return {*}
   */
  submitSubForm(rejectOnError) {
    // If we wish to submit the form on next page, then do that here.
    if (this.shouldSubmit) {
      return this.subFormReady.then(() => {
        if (!this.subForm) {
          return this.dataValue;
        }
        this.subForm.nosubmit = false;
        return this.subForm.submitForm().then(result => {
          this.subForm.loading = false;
          this.subForm.showAllErrors = false;
          this.dataValue = result.submission;
          return this.dataValue;
        }).catch(err => {
          this.subForm.showAllErrors = true;
          if (rejectOnError) {
            this.subForm.onSubmissionError(err);
            return NativePromise.reject(err);
          } else {
            return {};
          }
        });
      });
    }
    return this.getSubFormData();
  }

  /**
   * Submit the form before the next page is triggered.
   */
  beforePage(next) {
    // Should not submit child forms if we are going to the previous page
    if (!next) {
      return super.beforePage(next);
    }
    return this.submitSubForm(true).then(() => super.beforePage(next));
  }

  /**
   * Submit the form before the whole form is triggered.
   */
  beforeSubmit() {
    const submission = this.dataValue;

    const isAlreadySubmitted = submission && submission._id && submission.form;

    // This submission has already been submitted, so just return the reference data.
    // if (isAlreadySubmitted && !this.subForm?.wizard) {
    if (isAlreadySubmitted && !(this.subForm && this.subForm.wizard)) {
      this.dataValue = submission;
      return NativePromise.resolve(this.dataValue);
    }
    return this.submitSubForm(false)
      .then(() => {
        return this.dataValue;
      })
      .then(() => super.beforeSubmit());
  }

  isSubFormLazyLoad() {
    // return  this.root?._form?.display === 'wizard' && this.component.lazyLoad;
    if ((this.root && this.root._form && this.root._form.display === 'wizard') && this.component.lazyLoad) {
      return true;
    } else {
      return false;
    }

  }

  isHidden() {
    if (!this.visible) {
      return true;
    }

    return !super.checkConditions(this.rootValue);
  }

  setValue(submission, flags = {}) {
    const changed = super.setValue(submission, flags);
    this.valueChanged = true;
    if (this.subForm) {
      const shouldLoadOriginalRevision = this.useOriginalRevision &&
        _.isNumber(submission._fvid)
        // && _.isNumber(this.subForm.form?._vid)
        &&
        _.isNumber(this.subForm.form._vid) &&
        submission._fvid !== this.subForm.form._vid;

      if (shouldLoadOriginalRevision) {
        this.setFormRevision(submission._fvid);
        this.createSubForm().then(() => {
          this.attach(this.element);
        });
      } else {
        this.setSubFormValue(submission, flags);
      }
    }
    return changed;
  }

  setSubFormValue(submission, flags) {
    const shouldLoadSubmissionById = submission &&
      submission._id &&
      this.subForm.formio &&
      _.isEmpty(submission.data);

    if (shouldLoadSubmissionById) {
      const submissionUrl = `${this.subForm.formio.formsUrl}/${submission.form}/submission/${submission._id}`;
      this.subForm.setUrl(submissionUrl, this.options);
      this.subForm.loadSubmission();
    } else {
      this.subForm.setValue(submission, flags);
    }
  }

  isEmpty(value = this.dataValue) {
    return value === null || _.isEqual(value, this.emptyValue) || (this.areAllComponentsEmpty(value.data) && !value._id);
  }

  areAllComponentsEmpty(data) {
    let res = true;
    if (this.subForm) {
      this.subForm.everyComponent((comp) => {
        const componentValue = _.get(data, comp.key);
        res &= comp.isEmpty(componentValue);
      });
    } else {
      res = false;
    }
    return res;
  }

  getValue() {
    if (this.subForm) {
      return this.subForm.getValue();
    }
    return this.dataValue;
  }

  get errors() {
    let errors = super.errors;
    if (this.subForm) {
      errors = errors.concat(this.subForm.errors);
    }
    return errors;
  }

  updateSubFormVisibility() {
    if (this.subForm) {
      this.subForm.parentVisible = this.visible;
    }
  }

  /**
   * Determines if this form is a Nested Wizard
   * which means it should be a Wizard itself and should be a direct child of a Wizard's page
   * @returns {boolean}
   */
  get isNestedWizard() {
    // return this.subForm?._form?.display === 'wizard' && this.parent?.parent?._form?.display === 'wizard';
    if (this.subForm && this.subForm._form && this.subForm._form.display === 'wizard' && this.parent && this.parent.parent && this.parent.parent._form && this.parent.parent._form.display === 'wizard') {
      return true
    } else {
      return false;
    }
  }

  get visible() {
    return super.visible;
  }

  set visible(value) {
    if (this._visible !== value) {
      this._visible = value;
      this.clearOnHide();
      // Form doesn't load if hidden. If it becomes visible, create the form.
      if (!this.subForm && value) {
        this.createSubForm();
        this.subFormReady.then(() => {
          this.updateSubFormVisibility();
        });
        this.redraw();
        return;
      }
      this.updateSubFormVisibility();
      this.redraw();
    }
    if (!value && this.isNestedWizard) {
      this.root.redraw();
    }
  }

  get parentVisible() {
    return super.parentVisible;
  }

  set parentVisible(value) {
    if (this._parentVisible !== value) {
      this._parentVisible = value;
      this.clearOnHide();
      // Form doesn't load if hidden. If it becomes visible, create the form.
      if (!this.subForm && value) {
        this.createSubForm();
        this.subFormReady.then(() => {
          this.updateSubFormVisibility();
        });
        this.redraw();
        return;
      }
      this.updateSubFormVisibility();
      this.redraw();
    }
  }

  isInternalEvent(event) {
    switch (event) {
      case 'focus':
      case 'blur':
      case 'componentChange':
      case 'componentError':
      case 'error':
      case 'formLoad':
      case 'languageChanged':
      case 'render':
      case 'checkValidity':
      case 'initialized':
      case 'submit':
      case 'submitButton':
      case 'nosubmit':
      case 'updateComponent':
      case 'submitDone':
      case 'submissionDeleted':
      case 'requestDone':
      case 'nextPage':
      case 'prevPage':
      case 'wizardNavigationClicked':
      case 'updateWizardNav':
      case 'restoreDraft':
      case 'saveDraft':
      case 'saveComponent':
      case 'pdfUploaded':
        return true;
      default:
        return false;
    }
  }

  createEmitter() {
    const emitter = new EventEmitter();
    const nativeEmit = emitter.emit;
    const that = this;
    emitter.emit = function (event, ...args) {
      const eventType = event.replace(`${that.options.namespace}.`, '');
      nativeEmit.call(this, event, ...args);
      if (!that.isInternalEvent(eventType)) {
        that.emit(eventType, ...args);
      }
    };

    return emitter;
  }

  deleteValue() {
    super.setValue(null, {
      noUpdateEvent: true,
      noDefault: true
    });
    this.unset();
  }
}
document.addEventListener('ApproveEvent', function (event) {
console.log("event listening");
},false);
SmartNestedComponent.editForm = nestedComponentEditForm;
Components.addComponent("form", SmartNestedComponent);
