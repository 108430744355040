import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit {

  title: string;
  message: string;
  docTodelete: any;
  formId: string;
  ServerStatusMessage: string;
  errorMessage = false;
  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {
      dialogRef.disableClose = true;
    // Update view with given values
    this.title = data.title;
    this.message = data.message;
  }

  ngOnInit() {
    // this.formId = this.data.formId;
    // this.errorMessage = false;
  }

  close() {
  this.dialogRef.close();

  }

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }
}
export class ConfirmDialogModel {

  constructor(public title: string, public message: string) {
  }
}
