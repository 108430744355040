<div *ngIf="dashboardList.length > 0">
    <mat-toolbar color="primary">
        <span>Select Dashboard</span>
        <span class="fill-remaining-space"></span>
        <button mat-button (click)="closeoverlay(null)">
            <mat-icon>clear</mat-icon>
        </button>
    </mat-toolbar>
    <div *ngIf="errmsg" class="error-msg">
        <span>{{errmsg}}</span>
    </div>
    <div class="dialog-container select-list-container">

        <mat-checkbox labelPosition="before" (change)="$event ? masterToggle() : null"
            [checked]="selectedformitems.selectedOptions.hasValue() && isAllSelected()"
            [indeterminate]="selectedformitems.selectedOptions.hasValue() && !isAllSelected()">
            Select All
        </mat-checkbox>
        <mat-selection-list #selectedformitems>
            <mat-list-option *ngFor="let item of dashboardList" [value]="item.id">
                {{item.name}}&nbsp;({{item.slug}})
            </mat-list-option>
        </mat-selection-list>
        <div class="aligned-center-btn">
            <button mat-flat-button (click)="importselectedDashboard()" color="primary">Import</button>
        </div>

    </div>
</div>

<div *ngIf="!isNoDashboard">
    <mat-toolbar color="primary">
        <span>Select Dashboard</span>
        <span class="fill-remaining-space"></span>
        <button mat-button (click)="closeoverlay(null)">
            <mat-icon>clear</mat-icon>
        </button>
    </mat-toolbar>
    <div class="dispaly-center">
        <span>All available dashboards have already been imported.</span>
    </div>
    <div class="dialog-container select-list-container">
    </div>

</div>