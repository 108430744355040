<div class="data-table-status-bar">
  <div class="status-bar-content">
    Dashboards &nbsp;
    <span class="fill-remaining-space"></span>
    <button class="import-export-btn" matTooltip="Import Dashboards" mat-mini-fab color="primary"
      (click)="openSelectlistOverlay()" *ngIf="usr.ROLE === 'Admin'">
      <mat-icon>import_export</mat-icon>
    </button>
  </div>
</div>
<div *ngIf="errmsg" class="error-msg">
  <span>{{errmsg}}</span>
</div>

<custom-table [tableData]="listOfDashboards" [tableColumns]="formsTableColumns" [isFilterable]="true"
  [isPageable]="true" [paginationSizes]="[5,10,25]" [defaultPageSize]="10" [rowActionIcon]="'more_vert'"
  [rowActions]="rowActions" (rowAction)="rowAction($event)">
</custom-table>
