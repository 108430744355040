import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Admin } from '../models/admin';
import { map, tap } from 'rxjs/operators';
// import { environment } from '../../environments/environment';
import { UtilsService } from '../utils.service';
import { AppConfigService } from '../app.config';
import { CustomEncoder } from '../shared/classes/customencoder';
import decode from 'jwt-decode';
@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {
  /**Using subjects for Multicasting observable so that each subscriber gets the same value **/
  private loggedIn = new BehaviorSubject<boolean>(false);
  public baseUrl: string;
  private getJWTtokenUrl: string;
  private forgotpasswordUrl: string;
  private resetpasswordUrl: string;
  get isLoggedIn() {
    const idToken = localStorage.getItem('token');
    if (idToken) {
      this.loggedIn.next(true);
    }
    return this.loggedIn.asObservable();
  }
  constructor(
     private router: Router,
     private http: HttpClient, 
     private appConfigService: AppConfigService, 
     private utilsservice: UtilsService) {
      this.baseUrl = this.utilsservice.getUMPUrl();
      this.getJWTtokenUrl = `${this.baseUrl}/UMP/API/v2/applications/DIGITAL_FORMS/session`;
      this.forgotpasswordUrl = `${this.baseUrl}/UMP/API/v2/users/:me/forgotpassword`;
      this.resetpasswordUrl = `${this.baseUrl}/UMP/API/v2/users/:me/changepassword`;
     }
  authenticateUser(user: any) {
    let headers_object = new HttpHeaders();
    let params_obj = new HttpParams({ encoder: new CustomEncoder() });
    const selectedlang = localStorage.getItem('language');
    params_obj = params_obj.append('jwtOptions', JSON.stringify({ app: 'admin', language: selectedlang }));
    /** For ADS Login if login type is other than ads in config.json comment the below line */
    // set request header for http request
    headers_object = headers_object.append('Content-Type', 'application/json');
    if(this.appConfigService.login.type === 'ads') {
      params_obj = params_obj.append('credentials', JSON.stringify([{ "port": `${this.appConfigService.login.port}`}]));
      headers_object = headers_object.append('Authorization', `Basic ${btoa(`${user.domain.trim()}\\${user.adsdomainname.trim()}\\${user.email}:${user.password}`)}`);
    } else {
    headers_object = headers_object.append('Authorization', `Basic ${btoa(`${user.domain.trim()}\\${user.email}:${user.password}`)}`);
  }
    const httpOptions = {
      headers: headers_object,
      params: params_obj
    };
    /**Get the accesstoken from UMP **/
    return this.http.post<Admin>(this.getJWTtokenUrl, {}, httpOptions).pipe(
      tap(res => this.storeTokenAndlogIn(res, user))
    );
  }

  storeTokenAndlogIn(jwtres, user) {
    localStorage.setItem('token', jwtres.token);
    localStorage.setItem('login_type', `${this.appConfigService.login.type}`);
    localStorage.setItem('domain', user.domain.trim());
    localStorage.setItem('email', user.email);
    localStorage.setItem('UMP_url', this.baseUrl);
    const usr = decode(localStorage.getItem('token'));
    localStorage.setItem('name', usr.FNAME);
    this.loggedIn.next(true);
  }
  removeTokenAndlogOut() {
    localStorage.removeItem('token');
    localStorage.removeItem('UMP_url');
    localStorage.removeItem('resourceData');
    this.loggedIn.next(false);
    this.router.navigate(['/login']);
  }
  decode() {
    return decode(localStorage.getItem('token'));
  }
  forgotpassword(user: Admin): Observable<HttpResponse<any>> {
    let headers_object = new HttpHeaders();
    let params_obj = new HttpParams({ encoder: new CustomEncoder() });
    const selectedlang = localStorage.getItem('language');
    params_obj = params_obj.append('jwtOptions', JSON.stringify({ app: 'admin', language: selectedlang }));
    params_obj = params_obj.append('application', 'DIGITAL_FORMS');
    params_obj = params_obj.append('mailTemplate', 'FORGOT_PASSWORD_MAIL');
    // set request header for http request
    headers_object = headers_object.append('Content-Type', 'application/json');
    headers_object = headers_object.append('Authorization', `Basic ${btoa(`${user.domain.trim()}\\${user.email}:${user.password}`)}`);
    return this.http.get<any>(this.forgotpasswordUrl, {
      headers: headers_object,
      observe: 'response',
      params: params_obj
    });
  }
  resetpassword(resetToken, user, domain, new_password): Observable<HttpResponse<any>> {
    let headers_object = new HttpHeaders();
    let params_obj = new HttpParams({ encoder: new CustomEncoder() });
    const selectedlang = localStorage.getItem('language');
    params_obj = params_obj.append('jwtOptions', JSON.stringify({ app: 'admin', language: selectedlang }));
    params_obj = params_obj.append('resetToken', resetToken);
    params_obj = params_obj.append('application', 'DIGITAL_FORMS');
    params_obj = params_obj.append('newPassword', new_password);

    // set request header for http request
    // headers_object = headers_object.append('Content-Type', 'application/json');
     headers_object = headers_object.append('Authorization', `Basic ${btoa(`${domain.trim()}\\${user}:${new_password}`)}`);
    return this.http.put<any>(this.resetpasswordUrl, {}, {
      headers: headers_object,
      observe: 'response',
      params: params_obj
    });
  }
  signup(user: Admin): Observable<HttpResponse<any>> {
    const signupurl = `${this.baseUrl}/UMP/API/v2/companies/${user.domain}/applications/DIGITAL_FORMS/users/${user.email}/signup`;
    let headers_object = new HttpHeaders();
    let params_obj = new HttpParams({ encoder: new CustomEncoder() });
    // params_obj = params_obj.append('jwtOptions', JSON.stringify({ app: 'admin' }));
    params_obj = params_obj.append('mailTemplate', 'SIGN_UP_MAIL');
    // set request header for http request
    headers_object = headers_object.append('Content-Type', 'application/json');
    return this.http.get<any>(signupurl, {
      headers: headers_object,
      observe: 'response',
      params: params_obj
    });
  }
  signupintoUMP(resetToken, email, domain, signupform) {
    const userid = email.replace(/[.@]/g, '-');
    // console.log(userid);
    const signuUMPpurl = `${this.baseUrl}/UMP/API/v2/users/${userid}/provision`;
     let headers_object = new HttpHeaders();
    headers_object = headers_object.append('Authorization', `Basic ${btoa(`${domain.trim()}\\token:${resetToken}`)}`);
     let params_obj = new HttpParams({ encoder: new CustomEncoder() });
    params_obj = params_obj.append('company', domain);
    params_obj = params_obj.append('application', 'DIGITAL_FORMS');
    params_obj = params_obj.append('firstName', signupform.firstname);
    params_obj = params_obj.append('lastName', signupform.lastname);
    params_obj = params_obj.append('mailId', email);
    params_obj = params_obj.append('password', signupform.password);
    params_obj = params_obj.append('userType', 'APPLICATION');
    params_obj = params_obj.append('createProcessor', 'true');
    params_obj = params_obj.append('frontendUsers', JSON.stringify(
    [{ 'frontend': 'iPhone', 'frontendUser': `${userid}iPhone` },
    { 'frontend': 'iPad', 'frontendUser': `${userid}iPad` },
    { 'frontend': 'ANDROID_PHONE', 'frontendUser': `${userid}APhone` },
    { 'frontend': 'Browser', 'frontendUser': `${userid}Browser` }]
    ));
    return this.http.post<any>(signuUMPpurl, {}, {
     headers: headers_object,
       observe: 'response',
       params: params_obj
     });
  }
}
