<!--
<div *ngFor="let icon of material_icons" >
    <mat-icon >{{icon}}</mat-icon>
</div>
-->
<div class="overlay-content" [@slideContent]="animationState" (@slideContent.start)="onAnimationStart($event)" (@slideContent.done)="onAnimationDone($event)">
    <div class="mat-elevation-z8">
        <div class="icon-picker">
            <mat-form-field>
                <input matInput autocomplete="off" #search type="search" placeholder="filter..">
            </mat-form-field>
            <button mat-icon-button *ngFor="let icon of material_icons | filter : search.value" (click)="pickedicon(icon)">
                <mat-icon>{{icon}}</mat-icon>
            </button>
        </div>
    </div>
</div>
