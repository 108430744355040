function registerJsonata() {
  // console.log((window as any).monaco);
  // Register a new language
  (window as any).monaco.languages.register({ id: 'jsonata' });

  // Register a tokens provider for the language
  (window as any).monaco.languages.setMonarchTokensProvider('jsonata', {
      tokenizer: {
          root: [
              [/\/\*.*\*\//, "jsonata-comment"],
              [/'.*'/, "jsonata-string"],
              [/".*"/, "jsonata-string"],
              [/\$[a-zA-Z0-9_]*/, "jsonata-variable"],
              [/[a-zA-Z0-9_]+/, "jsonata-names"],
          ]
      }
  });

  const brackets = [
      {open: '(', close: ')'},
      {open: '[', close: ']'},
      {open: '{', close: '}'},
      {open: '"', close: '"'},
      {open: '\'', close: '\''},
      {open: '`', close: '`'},
  ];
  (window as any).monaco.languages.setLanguageConfiguration('jsonata', {
      brackets: [['(', ')'], ['[', ']'], ['{', '}']],
      autoClosingPairs: brackets,
      surroundingPairs: brackets,
      indentationRules: {
          // ^(.*\*/)?\s*\}.*$
          decreaseIndentPattern: /^((?!.*?\/\*).*\*\/)?\s*[}\])].*$/,
          // ^.*\{[^}"']*$
          increaseIndentPattern: /^((?!\/\/).)*(\{[^}"'`]*|\([^)"'`]*|\[[^\]"'`]*)$/
      },
      insertSpaces: true,
      tabSize: 2
  });

  // Define a new theme that contains only rules that match this language
  (window as any).monaco.editor.defineTheme('jsonataTheme', {
      base: 'vs',
      inherit: true,
      rules: [
          { token: 'jsonata-string', foreground: 'a00000' },
          { token: 'jsonata-comment', foreground: '008000' },
          { token: 'jsonata-variable', foreground: 'ff4000' },
          { token: 'jsonata-names', foreground: '0000c0' },
      ],
      colors: {
          "editor.background": '#fffffb'
      }
  });
  (window as any).monaco.languages.registerCompletionItemProvider('jsonata', {
    provideCompletionItems: () => {
      const suggestions = [
        {
          label: 'simpleText',
          kind: (window as any).monaco.languages.CompletionItemKind.Text
        }, {
          label: 'testing',
          kind: (window as any).monaco.languages.CompletionItemKind.Keyword,
          insertText: {
            value: 'testing(${1:condition})'
          }
        },
        {
          label: 'ifelse',
          kind: (window as any).monaco.languages.CompletionItemKind.Snippet,
          insertText: {
            value: [
              'if (${1:condition}) {',
              '\t$0',
              '} else {',
              '\t',
              '}'
            ].join('\n')
          },
          documentation: 'If-Else Statement'
        }
      ];
      return { suggestions: suggestions };
    }
  });
}

export default registerJsonata;
