const SelectComponent = Formio.Components.components.select;
import { BarcodeSelectEditForm } from './select-barcode.editForm';

export default class BarcodeSelectComponent extends SelectComponent {

  static schema(...extend) {
    return SelectComponent.schema({
      type: 'barcodeselect',
      label: 'Select Barcode',
      key: 'select',
      idPath: 'id',
      data: {
        values: [],
        json: '',
        url: '',
        resource: '',
        custom: ''
      },
      clearOnRefresh: false,
      limit: 100,
      dataSrc: 'values',
      valueProperty: '',
      lazyLoad: true,
      filter: '',
      searchEnabled: true,
      searchField: '',
      minSearch: 0,
      readOnlyValue: false,
      authenticate: false,
      ignoreCache: false,
      template: '<span>{{ item.label }}</span>',
      selectFields: '',
      searchThreshold: 0.3,
      uniqueOptions: false,
      tableView: true,
      fuseOptions: {
        include: 'score',
        threshold: 0.3,
      },
      customOptions: {},
      useExactSearch: false,
    }, ...extend);
  }

  static get builderInfo() {
    return {
      title: 'Select Barcode',
      group: 'premium',
      icon: 'th-list',
      weight: 30,
      documentation: '/userguide/#selectbarcode',
      schema: BarcodeSelectComponent.schema()
    };
  }

  render() {
    return super.render();
  }

  attach(element) {
    let ret = super.attach(element);
    let id = this.id;
    let elemets = document.querySelectorAll("[id=" + id + "]");
    for (var i = 0; i < elemets.length; i++) {
      let div = elemets[i];
      if (div && this.type == 'barcodeselect' && this.path != 'defaultValue') {
        let childDiv = div.getElementsByTagName('div')[0];
        let a = childDiv.getElementsByTagName('div');
        let requiredDiv = childDiv.getElementsByTagName('div')[0];

        if (requiredDiv && requiredDiv.firstChild) {
          let theFirstChild = requiredDiv.firstChild
          requiredDiv.style.display = 'inline-flex';
          if (requiredDiv.firstChild.className != 'barcode-icon') {
            // Create a new element
            let newElement = document.createElement("div")
            newElement.setAttribute('class', 'barcode-icon');
            newElement.style.width = "fit-content";
            var icon = document.createElement('i');
            icon.setAttribute('class', 'fa fa-barcode');

            newElement.appendChild(icon);
            requiredDiv.insertBefore(newElement, theFirstChild)
          }
          var choiselistArray = document.getElementsByClassName('choices__list--single');
          for (let i = 0; i < choiselistArray.length; i++) {
            choiselistArray[i].style.marginLeft = "15px";
          }
        }

      }

    }

    var buttons = document.getElementsByClassName('barcode-icon');
    for (var i = 0; i < buttons.length; i++) {
      buttons[i].addEventListener('click', function (ev) {
        let componentObj = this;
        setTimeout(() => {
          var choicelistdown = document.getElementsByClassName('choices__list--dropdown')
          for (let j = 0; j < choicelistdown.length; j++) {
            choicelistdown[j].classList.remove("is-active");
          }
        })
        let id = ev.currentTarget.offsetParent.childNodes[1].id;
        if (id) {
          let eventCustom = new CustomEvent('openBarcode', {
            detail: {
              compObj: componentObj,
              controlId: id
            }
          });
          document.dispatchEvent(eventCustom);
        }

      });
    }
    return ret;
  }
}
BarcodeSelectComponent.editForm = BarcodeSelectEditForm;
Formio.registerComponent('barcodeselect', BarcodeSelectComponent);
