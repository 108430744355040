import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AuthenticationService } from '../auth/authentication.service';
import { whiteSpaceValidator } from '../shared/directives/whitespacevalidator.directive';
import { MustMatch } from '../shared/directives/mustmatchvalidator.directive';
import { HotToastService } from '@ngneat/hot-toast';
@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css', '../login/login.component.css']
})
export class ResetpasswordComponent implements OnInit {
  public resetpasswordform: FormGroup;
  public resetToken: string;
  public user: string;
  public usertype: string;
  public showpassword: boolean;
  public showdomainfield: boolean;
  public domain: string;
  public displaymsg: string;
  public errmsg: string;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authservice: AuthenticationService,
    private route: ActivatedRoute,
    private toastService: HotToastService
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.resetToken = params['resetToken'];
      this.user = params['user'];
      this.domain = params['domain'];
      this.usertype = params['usertype'];
    });
    this.resetpasswordform = this.fb.group({
      password: ['', [Validators.required, whiteSpaceValidator()]],
      confirmpassword: ['', Validators.required]
    }, {
        validator: MustMatch('password', 'confirmpassword')
      });
  }
  ressetpassword() {
    this.authservice.resetpassword(this.resetToken, this.user, this.domain, this.resetpasswordform.get('confirmpassword').value)
    .subscribe(
      (res: any) => {
         if (res.status === 204) {
          this.usertype = 'app'
           if (this.usertype === 'app') {
             this.displaymsg = 'Your TurboForms password has been reset. You can open the TurboForms app and login now.';
             let toast = this.toastService.success(`${this.displaymsg}`, {
              style: {
                border: "1px solid var(--success-color)",
                padding: "16px",
                color: "var(--success-color)",
              },
              position: "top-right",
              autoClose: false,
              dismissible: true,
              className:'pwd-reset',
              icon: '❎',
            });
             toast.afterClosed.subscribe((e) => {
              window.close();
            });

           } else {
            this.router.navigate(['login']);
          }
       }},
     (error) => {
         this.errmsg = error.error.error;
       }
    );
  }
}
