import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.css']
})
export class ImagePreviewComponent implements OnInit {
  @HostListener('window:keyup.esc') onKeyUp() {
    this.closeoverlay(null);
  }
  public imageData : any;

  constructor(
    public dialogRef: MatDialogRef<ImagePreviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.imageData = this.data.imageData
  }
  closeoverlay(responseobj: any) {
    this.dialogRef.close(responseobj);
  }
  downloadLog(){
    let downloadLink = document.createElement("a");
    downloadLink.href = this.imageData.url;
    downloadLink.setAttribute("download", this.imageData.name);
    downloadLink.click();
  }
}
