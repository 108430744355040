import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules, NoPreloading } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { RoleGuard } from './auth/role.guard';
import { AnonymousGuard } from './auth/anonymous.guard';
import { HomeLayoutComponent } from './layouts/home-layout/home-layout.component';
import { LoginLayoutComponent } from './layouts/login-layout/login-layout.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UsersComponent } from './users/users.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { SignupComponent } from './signup/signup.component';
import { SSOLoginComponent } from './sso-login/sso-login.component';
import { PublicShredFormsInfoComponent } from './public-shred-forms-info/public-shred-forms-info.component';
import { CanDeactivateGuard } from './auth/deactivate.guard';
import { DashboardListComponent } from './dashboard-list/dashboard-list.component';
import { FormUsersComponent } from './shared/dialogs/form-users/form-users.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: '',
    component: HomeLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
      },
      // {
      //   path: 'users',
      //   component: UsersComponent,
      //   data: { role: 'Admin' },
      //   canActivate: [RoleGuard]
      // },
      {
        path: 'public-shared-forms',
        component: PublicShredFormsInfoComponent,
        data: { role: 'Admin' },
        canActivate: [RoleGuard]
      },
      {
        path: 'users',
        loadChildren: () => import('./users/users.module').then(mod => mod.UsersModule)
      },
      {
        path: 'teams',
        loadChildren: () => import('./team/team.module').then(mod => mod.TeamModule)
      },
      {
        path: 'forms',
         loadChildren: () => import('./forms/forms.module').then(mod => mod.FormsModule),
      },
      {
        path: 'formsets',
         loadChildren: () => import('./formsets/formsets.module').then(mod => mod.FormsetsModule),
      },
      {
        path: 'masterdata',
         loadChildren: () => import('./masterdata/masterdata.module').then(mod => mod.MasterdataModule)
      },
      {
        path: 'formio',
         loadChildren: () => import('./formio-core/formio-core.module').then(mod => mod.FormioCoreModule)
      },
      {
        path: 'workflows',
        loadChildren: () => import('./rete/rete.module').then(mod => mod.ReteEditorModule)
      },
      {
        path: 'settings',
         loadChildren: () => import('./settings/settings.module').then(mod => mod.SettingsModule)
      },
      {
        path:'reports',
        // data: { role: 'Manager' },
        // canActivate: [RoleGuard],
        loadChildren: () => import('./report-builder/report-builder.module').then(mod => mod.ReportBuilderModule)
      },
       
      {
        path: 'dashboard-list',
        component: DashboardListComponent,
      },
      {
        path: 'form-users',
        component: FormUsersComponent,
      },

    ]
  },
  {
    path: '',
    component: LoginLayoutComponent,
    canActivate: [AnonymousGuard],
    children: [
      {
        path: 'login',
        component: LoginComponent
      },
      { path: 'ssologin', component: SSOLoginComponent },
      {
        path: 'resetpassword',
        component: ResetpasswordComponent
      },
      {
        path: 'signup',
        component: SignupComponent
      }
    ]
  },
  { path: '**', component: PagenotfoundComponent }
];
@NgModule({
  imports: [ RouterModule.forRoot(
              routes,
              {
    // enableTracing: true,
    // preloadingStrategy: PreloadAllModules,
    relativeLinkResolution: 'legacy'
}
              ),
           ],
  exports: [RouterModule],
  providers: [
    CanDeactivateGuard
  ]
})
export class AppRoutingModule { }
