import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { QueryBuilderModule } from "angular2-query-builder";
// Angular Material Components
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkTreeModule} from '@angular/cdk/tree';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatTreeModule } from "@angular/material/tree";
// Pipes
import { FilterPipe } from './pipes/filter.pipe';
import { UsersFilterPipe } from './pipes/usersfilter.pipe';
import { TeamsFilterPipe } from './pipes/teamsfilter.pipe';
import { FormsFilterPipe } from './pipes/formsfilter.pipe';
import { DataPropertyGetterPipe } from './components/table/data-property-getter-pipe/data-property-getter.pipe';
// Dialogs
import { IconpickerComponent } from './dialogs/iconpicker/iconpicker.component';
import { CreateFormComponent } from './dialogs/create-form/create-form.component';
import { SelectListComponent } from './dialogs/select-list/select-list.component';
import { ImportFormsComponent } from './dialogs/import-forms/import-forms.component';
import { AssignTeamsToFormComponent } from './dialogs/assign-teams-to-form/assign-teams-to-form.component';
import { GetFormsComponent } from './dialogs/get-forms/get-forms.component';
import { GetFormsetsComponent } from './dialogs/get-fomsets/get-fomsets.component';
import { CreateScheduleComponent } from './dialogs/create-schedule/create-schedule.component';
import { PublishFormComponent } from './dialogs/publish-form/publish-form.component';
// Components
import { TableComponent } from './components/table/table.component';


import { BlockCopyPasteDirective } from './directives/blockcopypaste.directive';
import { SelectDashboardComponent } from './dialogs/select-dashboard/select-dashboard.component';
import { FormUsersComponent } from './dialogs/form-users/form-users.component';
import { FormConfigComponent } from './dialogs/form-config/form-config.component';
import { JsonViewComponent } from './dialogs/json-view/json-view.component';
import { PrettyjsonPipe } from './pipes/prettyjson.pipe';
import { DashboardlistDialogComponent } from './components/dashboardlist-dialog/dashboardlist-dialog.component';
@NgModule({
  declarations: [
    FilterPipe,
    UsersFilterPipe,
    TeamsFilterPipe,
    FormsFilterPipe,
    TableComponent,
    IconpickerComponent,
    CreateFormComponent,
    SelectListComponent,
    ImportFormsComponent,
    GetFormsComponent,
    AssignTeamsToFormComponent,
    GetFormsetsComponent,
    CreateScheduleComponent,
    PublishFormComponent,
    BlockCopyPasteDirective,
    DataPropertyGetterPipe,
    SelectDashboardComponent,
    FormUsersComponent,
    FormConfigComponent,
    JsonViewComponent,
    PrettyjsonPipe,
    DashboardlistDialogComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatInputModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatPaginatorModule,
    MatSortModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatTableModule,
    MatTabsModule,
    MatDividerModule,
    MatBadgeModule,
    MatExpansionModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatStepperModule,
    MatSnackBarModule,
    MatSliderModule,
    MatDialogModule,
    DragDropModule,
    CdkTreeModule,
    ClipboardModule,
    MatTreeModule,
    MatRippleModule,
    QueryBuilderModule
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatTreeModule,
    QueryBuilderModule,
    MatButtonModule,
    MatCheckboxModule,
    MatInputModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatPaginatorModule,
    MatSortModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatTableModule,
    MatTabsModule,
    MatDividerModule,
    MatBadgeModule,
    MatExpansionModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatStepperModule,
    MatSnackBarModule,
    MatSliderModule,
    MatDialogModule,
    DragDropModule,
    CdkTreeModule,
    MatRippleModule,
    UsersFilterPipe,
    TeamsFilterPipe,
    FormsFilterPipe,
    ClipboardModule,
    TableComponent,
    IconpickerComponent,
    DataPropertyGetterPipe
  ],
  providers: [MatDatepickerModule],
})
export class SharedModule {}
