import { Components } from 'angular-formio';
import { fileEditForm } from './smart-file.editForm';
import NativePromise from 'native-promise-only';
import _ from 'lodash';
var Camera;
var webViewCamera = navigator.camera || Camera;
var uniqueName = FormioUtils.uniqueName;
var Field = Formio.Components.components.field;
const FieldComponent = Formio.Components.components.field;
const Templates = Formio.Templates;
let imagUrl = "";
let isfileBrowse = false
const imageTypes = ['image/gif', 'image/jpeg', 'image/png'];
let htmlCanvasElement;
if (typeof window !== 'undefined') {
  htmlCanvasElement = window.HTMLCanvasElement;
} else if (typeof global !== 'undefined') {
  htmlCanvasElement = global.HTMLCanvasElement;
}

if (htmlCanvasElement && !htmlCanvasElement.prototype.toBlob) {
  Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
    value: function (callback, type, quality) {
      var canvas = this;
      setTimeout(function () {
        var binStr = atob(canvas.toDataURL(type, quality).split(',')[1]),
          len = binStr.length,
          arr = new Uint8Array(len);

        for (var i = 0; i < len; i++) {
          arr[i] = binStr.charCodeAt(i);
        }

        callback(new Blob([arr], {
          type: type || 'image/png'
        }));
      });
    }
  });
}

class SmartFileComponent extends FieldComponent {
  static schema(...extend) {
    return FieldComponent.schema({
      type: 'file',
      label: 'Upload',
      key: 'file',
      image: false,
      privateDownload: false,
      imageSize: '200',
      filePattern: '*',
      fileMinSize: '0KB',
      fileMaxSize: '1GB',
      uploadOnly: false,
    }, ...extend);
  }

  static get builderInfo() {
    return {
      title: 'File',
      group: 'premium',
      icon: 'file',
      documentation: '/userguide/#file',
      weight: 100,
      schema: SmartFileComponent.schema(),
    };
  }


  init() {

    Templates.templates.bootstrap.filetemplate = {
      form: `
    {% if (!ctx.self.imageUpload) { %}
  <ul class="list-group list-group-striped">
    <li class="list-group-item list-group-header hidden-xs hidden-sm">
      <div class="row">
        {% if (!ctx.disabled) { %}
          <div class="col-md-1"></div>
        {% } %}
        <div class="col-md-{% if (ctx.self.hasTypes) { %}7{% } else { %}9{% } %}"><strong>{{ctx.t('File Name')}}</strong></div>
        <div class="col-md-2"><strong>{{ctx.t('Size')}}</strong></div>
        {% if (ctx.self.hasTypes) { %}
          <div class="col-md-2"><strong>{{ctx.t('Type')}}</strong></div>
        {% } %}
      </div>
    </li>
    {% ctx.files.forEach(function(file) { %}
      <li class="list-group-item">
        <div class="row">
          {% if (!ctx.disabled) { %}
            <div class="col-md-1">
            
            </div>
          {% } %}
          <div class="col-md-{% if (ctx.self.hasTypes) { %}7{% } else { %}9{% } %}">
            {% if (ctx.component.uploadOnly) { %}
              {{file.originalName || file.name}}
            {% } else { %}
              <a href="{{file.url || '#'}}" target="_blank" ref="fileLink">{{file.originalName || file.name}}</a>
            {% } %}
          </div>
          <div class="col-md-2">{{ctx.fileSize(file.size)}}</div>
          {% if (ctx.self.hasTypes && !ctx.disabled) { %}
            <div class="col-md-2">
              <select class="file-type" ref="fileType">
                {% ctx.component.fileTypes.map(function(type) { %}
                  <option class="test" value="{{ type.value }}" {% if (type.label === file.fileType) { %}selected="selected"{% } %}>{{ type.label }}</option>
                {% }); %}
              </select>
            </div>
          {% } %}
          {% if (ctx.self.hasTypes && ctx.disabled) { %}
          <div class="col-md-2">{{file.fileType}}</div>
          {% } %}
        </div>
      </li>
    {% }) %}
  </ul>
{% } else { %}
<div style ="display: grid; grid-template-columns: repeat(auto-fill, 290px); gap: 10px; grid-auto-rows: minmax(100px, auto);">
    {% ctx.files.forEach(function(file) { %}
    {% if (file.type.split('/')[0] === 'image') { %} 
    <div>
      <span style="display: inline-flex;">
        <img ref = "fileImage" class = "fileImageData" src = "" id = "{{file.compnentId}}" alt = "{{file.originalName || file.name}}" style = "width:{{ctx.component.imageSize}}px;pointer-events: all">
        {% if (!ctx.disabled) { %}
         <span style="display: inline-grid;padding: 10px;height: fit-content;">
          <i class="{{ctx.iconClass('trash')}}" ref="removeLink" style="font-size:22px !important;color:red;"></i>
          </span>
        {% } %}
      </span>
    </div>
{% } %}
{% if (file.type.split('/')[0] !== 'image') { %} 
      <div>
      <span style="display: inline-flex;">
      {% if (ctx.component.imageSize === '100%') { %}
      <img ref="fileImage" class="fileImageData" src="" id="{{file.compnentId}}" alt="{{file.originalName || file.name}}" style="width:{{ctx.component.imageSize}}">
      {% } else { %}
      <img ref="fileImage" class="fileImageData" src="" id="{{file.compnentId}}" alt="{{file.originalName || file.name}}" style="width:{{ctx.component.imageSize}}px">
      {% } %}
        {% if (!ctx.disabled) { %}
         <span style="display: inline-grid;padding: 10px;height: fit-content;">
        {% if (file.type.split('/')[1] === 'pdf') { %}
          <i class="fa fa-file-pdf-o" style="font-size:22px !important;color:black;margin-top: 15px;"></i>
          {% } %}
          {% if (file.type.split('/')[1] === 'plain') { %}
          <i class="fa fa-address-card-o" style="font-size:22px !important;color:black;margin-top: 15px;"></i>
          {% } %}
           {% if (file.type.split('/')[1] === 'zip') { %}
          <i class="fa fa-file-zip-o" style="font-size:22px !important;color:black;margin-top: 15px;"></i>
          {% } %}
           {% if (file.type.split('/')[1] === 'mp4') { %}
          <i class="fa fa-file-movie-o" style="font-size:22px !important;color:black;margin-top: 15px;"></i>
          {% } %}
            {% if (file.type.split('/')[1] === 'doc') { %}
          <i class="fa fa-file-word-o" style="font-size:22px !important;color:black;margin-top: 15px;"></i>
          {% } %}
          <i class="{{ctx.iconClass('trash')}}" ref="removeLink" style="font-size:22px !important;color:red;margin-top: 15px;"></i>
          </span>
        {% } %}
      </span>
      </div>
      {% } %}
   {% }) %}
 </div>
{% } %}
{% if (!ctx.disabled && (ctx.component.multiple || !ctx.files.length)) { %}
  {% if (ctx.self.useWebViewCamera) { %}
    <div class="fileSelector">
      <button class="btn btn-primary" ref="galleryButton"><i class="fa fa-book"></i> {{ctx.t('Gallery')}}</button>
      <button class="btn btn-primary" ref="cameraButton"><i class="fa fa-camera"></i> {{ctx.t('Camera')}}</button>
    </div>
  {% } else if (!ctx.self.cameraMode) { %}
    <div class="fileSelector" ref="fileDrop" {{ctx.fileDropHidden ? 'hidden' : ''}}>
      <i class="{{ctx.iconClass('cloud-upload')}}"></i> {{ctx.t('Drop files to attach,')}}
        {% if (ctx.self.imageUpload) { %}
          <a href="#" ref="toggleCameraMode"><i class="fa fa-camera"></i> {{ctx.t('Use Camera,')}}</a>
        {% } %}
        {{ctx.t('or')}} <a href="#" ref="fileBrowse" class="browse">{{ctx.t('browse')}}</a>
      <div ref="fileProcessingLoader" class="loader-wrapper" style="display:none;">
        <div class="loader text-center"></div>
      </div>
    </div>
  {% } else { %}
    <div class="video-container">
      <video class="video" autoplay="true" ref="videoPlayer"></video>
    </div>
    <button class="btn btn-primary" ref="takePictureButton"><i class="fa fa-camera"></i> {{ctx.t('Take Picture')}}</button>
    <button class="btn btn-primary" ref="toggleCameraMode">{{ctx.t('Switch to file upload')}}</button>
  {% } %}
{% } %}
{% ctx.statuses.forEach(function(status) { %}
  <div class="file {{ctx.statuses.status === 'error' ? ' has-error' : ''}}">
    <div class="row">
      <div class="fileName col-form-label col-sm-10">{{status.originalName}} <i class="{{ctx.iconClass('trash')}}" style="font-size:22px !important;color:red;" ref="fileStatusRemove"></i></div>
      <div class="fileSize col-form-label col-sm-2 text-right">{{ctx.fileSize(status.size)}}</div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        {% if (status.status === 'progress') { %}
          <div class="progress">
            <div class="progress-bar" role="progressbar" aria-valuenow="{{status.progress}}" aria-valuemin="0" aria-valuemax="100" style="width: {{status.progress}}%">
              <span class="sr-only">{{status.progress}}% {{ctx.t('Complete')}}</span>
            </div>
          </div>
        {% } else if (status.status === 'error') { %}
          <div class="alert alert-danger bg-{{status.status}}">{{ctx.t(status.message)}}</div>
        {% } else { %}
          <div class="bg-{{status.status}}">{{ctx.t(status.message)}}</div>
        {% } %}
      </div>
    </div>
  </div>
{% }) %}
{% if (!ctx.component.storage || ctx.support.hasWarning) { %}
  <div class="alert alert-warning">
    {% if (!ctx.component.storage) { %}
      <p>{{ctx.t('No storage has been set for this field. File uploads are disabled until storage is set up.')}}</p>
    {% } %}
    {% if (!ctx.support.filereader) { %}
      <p>{{ctx.t('File API & FileReader API not supported.')}}</p>
    {% } %}
    {% if (!ctx.support.formdata) { %}
      <p>{{ctx.t("XHR2's FormData is not supported.")}}</p>
    {% } %}
    {% if (!ctx.support.progress) { %}
      <p>{{ctx.t("XHR2's upload progress isn't supported.")}}</p>
    {% } %}
  </div>
{% } %}
`
    };

    super.init();
    webViewCamera = navigator.camera || Camera;
    const fileReaderSupported = (typeof FileReader !== 'undefined');
    const formDataSupported = typeof window !== 'undefined' ? Boolean(window.FormData) : false;
    const progressSupported = (typeof window !== 'undefined' && window.XMLHttpRequest) ? ('upload' in new XMLHttpRequest) : false;

    this.support = {
      filereader: fileReaderSupported,
      formdata: formDataSupported,
      hasWarning: !fileReaderSupported || !formDataSupported || !progressSupported,
      progress: progressSupported,
    };
    // Called when our files are ready.
    this.filesReady = new Promise((resolve, reject) => {
      this.filesReadyResolve = resolve;
      this.filesReadyReject = reject;
    });
    this.cameraMode = false;
    this.statuses = [];
    this.fileDropHidden = false;
  }

  get dataReady() {
    return this.filesReady;
  }

  get defaultSchema() {
    return SmartFileComponent.schema();
  }

  loadImage(fileInfo) {
    if (this.component.privateDownload) {
      fileInfo.private = true;
    }
    return this.fileService.downloadFile(fileInfo).then((result) => result.url);
  }

  get emptyValue() {
    return [];
  }

  getValueAsString(value) {
    if (_.isArray(value)) {
      return _.map(value, 'originalName').join(', ');
    }

    return _.get(value, 'originalName', '');
  }

  getValue() {
    return this.dataValue;
  }

  get defaultValue() {
    const value = super.defaultValue;
    return Array.isArray(value) ? value : [];
  }

  get hasTypes() {
    return this.component.fileTypes &&
      Array.isArray(this.component.fileTypes) &&
      this.component.fileTypes.length !== 0 &&
      (this.component.fileTypes[0].label !== '' || this.component.fileTypes[0].value !== '');
  }

  get fileDropHidden() {
    return this._fileBrowseHidden;
  }

  set fileDropHidden(value) {
    if (typeof value !== 'boolean' || this.component.multiple) {
      return;
    }
    this._fileBrowseHidden = value;
  }

  render() {
    let templateType = 'filetemplate';

    return super.render(this.renderTemplate(templateType, {
      fileSize: this.fileSize,
      files: this.dataValue || [],
      statuses: this.statuses,
      disabled: this.disabled,
      support: this.support,
      fileDropHidden: this.fileDropHidden
    }));
  }

  getVideoStream() {
    const options = {
      audio: false,
      video: {
        width: {
          min: 640,
          ideal: 1920
        },
        height: {
          min: 360,
          ideal: 1080
        },
        aspectRatio: {
          ideal: 16 / 9
        },
      },
    };
    return navigator.mediaDevices.getUserMedia(options);
  }

  stopVideoStream(videoStream) {
    videoStream.getVideoTracks().forEach((track) => track.stop());
  }

  getFrame(videoPlayer) {
    return new Promise((resolve) => {
      const canvas = document.createElement('canvas');
      canvas.height = videoPlayer.videoHeight;
      canvas.width = videoPlayer.videoWidth;
      const context = canvas.getContext('2d');
      context.drawImage(videoPlayer, 0, 0);
      canvas.toBlob(resolve);
    });
  }

  startVideo() {

    this.getVideoStream()
      .then((stream) => {
        console.log("inside getVideoStream")
        this.videoStream = stream;
        const {
          videoPlayer
        } = this.refs;
        console.log("after videoPlayer recieved")
        if (!videoPlayer) {
          alert('Video player not found in template.')
          console.warn('Video player not found in template.');
          this.cameraMode = false;
          this.redraw();
          return;
        }

        videoPlayer.srcObject = stream;
        console.log("videoPlayer.srcObject = " + videoPlayer.srcObject);
        const width = parseInt(this.component.webcamSize) || 320;
        videoPlayer.setAttribute('width', width);
        videoPlayer.play();

      })
      .catch((err) => {
        console.error(err);
        this.cameraMode = false;
        this.redraw();
      });
  }

  stopVideo() {
    if (this.videoStream) {
      this.stopVideoStream(this.videoStream);
      this.videoStream = null;
    }
  }

  takePicture() {
    const {
      videoPlayer
    } = this.refs;
    if (!videoPlayer) {
      console.warn('Video player not found in template.');
      this.cameraMode = false;
      this.redraw();
      return;
    }

    this.getFrame(videoPlayer)
      .then((frame) => {
        frame.name = `photo-${Date.now()}.png`;
        this.compressFile([frame])
        this.cameraMode = false;
        this.redraw();
      });
  }

  browseFiles(attrs = {}) {
    console.log("attrs = " + JSON.stringify(attrs))
    return new Promise((resolve) => {
      const obj = Object.assign({
        type: 'file',
        style: 'height: 0; width: 0; visibility: hidden;image-orientation: from-image;',
        tabindex: '-1',
      }, attrs);
      const fileInput = this.ce('input', obj);
      document.body.appendChild(fileInput);

      fileInput.addEventListener('change', () => {
        resolve(fileInput.files);
        document.body.removeChild(fileInput);
      }, true);

      // There is no direct way to trigger a file dialog. To work around this, create an input of type file and trigger
      // a click event on it.
      if (typeof fileInput.trigger === 'function') {
        fileInput.trigger('click');
      } else {
        fileInput.click();
      }
    });
  }

  set cameraMode(value) {
    this._cameraMode = value;
    if (value) {
      this.startVideo();
    } else {
      this.stopVideo();
    }
  }

  get cameraMode() {
    return this._cameraMode;
  }

  get useWebViewCamera() {
    return this.imageUpload && webViewCamera;
  }

  get imageUpload() {
    return Boolean(this.component.image);
  }

  get browseOptions() {
    const options = {};

    if (this.component.multiple) {
      options.multiple = true;
    }
    //use "accept" attribute only for desktop devices because of its limited support by mobile browsers
    if (!this.isMobile.any) {
      const filePattern = this.component.filePattern.trim() || '';
      const imagesPattern = 'image/*';

      if (this.imageUpload && (!filePattern || filePattern === '*')) {
        options.accept = imagesPattern;
      } else if (this.imageUpload && !filePattern.includes(imagesPattern)) {
        options.accept = `${imagesPattern},${filePattern}`;
      } else {
        options.accept = filePattern;
      }
    }

    return options;
  }

  deleteFile(fileInfo) {
    if (fileInfo && (this.component.storage === 'url')) {
      const fileService = this.fileService;
      if (fileService && typeof fileService.deleteFile === 'function') {
        fileService.deleteFile(fileInfo);
      } else {
        const formio = this.options.formio || (this.root && this.root.formio);

        if (formio) {
          formio.makeRequest('', fileInfo.url, 'delete');
        }
      }
    }
  }

  attach(element) {
    this.loadRefs(element, {
      fileDrop: 'single',
      fileBrowse: 'single',
      galleryButton: 'single',
      cameraButton: 'single',
      takePictureButton: 'single',
      toggleCameraMode: 'single',
      videoPlayer: 'single',
      fileLink: 'multiple',
      removeLink: 'multiple',
      fileStatusRemove: 'multiple',
      fileImage: 'multiple',
      fileType: 'multiple',
      fileProcessingLoader: 'single',
   
    });
    // Ensure we have an empty input refs. We need this for the setValue method to redraw the control when it is set.
    this.refs.input = [];
    const superAttach = super.attach(element);

    if (this.refs.fileDrop) {
      const element = this;
      this.addEventListener(this.refs.fileDrop, 'dragover', function (event) {
        this.className = 'fileSelector fileDragOver';
        event.preventDefault();
      });
      this.addEventListener(this.refs.fileDrop, 'dragleave', function (event) {
        this.className = 'fileSelector';
        event.preventDefault();
      });
      this.addEventListener(this.refs.fileDrop, 'drop', function (event) {
        this.className = 'fileSelector';
        event.preventDefault();
        element.upload(event.dataTransfer.files);
        return false;
      });
    }

    if (this.refs.fileBrowse) {
      this.addEventListener(this.refs.fileBrowse, 'click', (event) => {
        event.preventDefault();
        if (!this.component.multiple && this.statuses.some(fileUpload => fileUpload.status === 'progress')) {
          return;
        }
        this.statuses = [];
        this.browseFiles(this.browseOptions)
          .then((files) => {
            for(let i=0; i<files.length;i++){
            let file = files[i];
            const reader = new FileReader();
            reader.onloadend = (evt) => {
              console.log("onloadend = " + evt)
              const blob = new Blob([new Uint8Array(evt.target.result)], {
                type: file.type
              });
              blob.name = file.name;
              blob.lastModified = file.lastModified;
              blob.lastModifiedDate = file.lastModifiedDate;
              console.log("blob instanceOf Blob = " + blob)
              isfileBrowse = true;
              this.compressFile([blob])
            };
            reader.readAsArrayBuffer(file);
          }
          });
      });
    }

    this.refs.fileLink.forEach((fileLink, index) => {
      this.addEventListener(fileLink, 'click', (event) => {
        event.preventDefault();
        this.getFile(this.dataValue[index]);
      });
    });

    this.refs.removeLink.forEach((removeLink, index) => {
      this.addEventListener(removeLink, 'click', (event) => {
        const fileInfo = this.dataValue[index];
        this.deleteFile(fileInfo);
        event.preventDefault();
        this.splice(index);
        this.redraw();
      });
    });

    this.refs.fileImage.forEach((fileImage, index) => {
      this.addEventListener(fileImage, 'click', (event) => {
        const fileInfo = this.dataValue[index];
        //  console.log("fileInfo "+ JSON.stringify(fileInfo));
         let eventCustom = new CustomEvent('openImagePreview', {
          detail: {
            data: fileInfo
          }
        });
        console.log("dispatching openImagePreview event");
        document.dispatchEvent(eventCustom);

      });
    });

    this.refs.fileStatusRemove.forEach((fileStatusRemove, index) => {
      this.addEventListener(fileStatusRemove, 'click', (event) => {
        event.preventDefault();
        if (this.abortUpload) {
          this.abortUpload();
        }
        this.statuses.splice(index, 1);
        this.redraw();
      });
    });

    if (this.refs.galleryButton && webViewCamera) {
      this.addEventListener(this.refs.galleryButton, 'click', (event) => {
        event.preventDefault();
        webViewCamera.getPicture((success) => {
          window.resolveLocalFileSystemURL(success, (fileEntry) => {
            fileEntry.file((file) => {
              const reader = new FileReader();
              reader.onloadend = (evt) => {
                const blob = new Blob([new Uint8Array(evt.target.result)], {
                  type: file.type
                });
                blob.name = file.name;
                this.upload([blob]);
              };
              reader.readAsArrayBuffer(file);
            });
          });
        }, (err) => {
          console.error(err);
        }, {
          sourceType: webViewCamera.PictureSourceType.PHOTOLIBRARY,
        });
      });
    }

    if (this.refs.cameraButton && webViewCamera) {
      this.addEventListener(this.refs.cameraButton, 'click', (event) => {
        event.preventDefault();
        webViewCamera.getPicture((success) => {
          window.resolveLocalFileSystemURL(success, (fileEntry) => {
            fileEntry.file((file) => {
              const reader = new FileReader();
              reader.onloadend = (evt) => {
                const blob = new Blob([new Uint8Array(evt.target.result)], {
                  type: file.type
                });
                blob.name = file.name;
                this.upload([blob]);
              };
              reader.readAsArrayBuffer(file);
            });
          });
        }, (err) => {
          console.error(err);
        }, {
          sourceType: webViewCamera.PictureSourceType.CAMERA,
          encodingType: webViewCamera.EncodingType.PNG,
          mediaType: webViewCamera.MediaType.PICTURE,
          saveToPhotoAlbum: true,
          correctOrientation: false,
        });
      });
    }

    if (this.refs.takePictureButton) {
      this.addEventListener(this.refs.takePictureButton, 'click', (event) => {
        event.preventDefault();
        this.takePicture();
      });
    }
    let currentObj = this;
    if (this.refs.toggleCameraMode) {
        this.addEventListener(this.refs.toggleCameraMode, 'click', (event) => {
            event.preventDefault();
            let id = currentObj.id
            isfileBrowse = false;
            this.cameraMode = !this.cameraMode;
            this.redraw();
        });
    }

    this.refs.fileType.forEach((fileType, index) => {
      this.dataValue[index].fileType = this.dataValue[index].fileType || this.component.fileTypes[0].label;

      this.addEventListener(fileType, 'change', (event) => {
        event.preventDefault();

        const fileType = this.component.fileTypes.find((typeObj) => typeObj.value === event.target.value);

        this.dataValue[index].fileType = fileType.label;
      });
    });

    const fileService = this.fileService;
    if (fileService) {
      const loadingImages = [];
      this.refs.fileImage.forEach((image, index) => {
        loadingImages.push(this.loadImage(this.dataValue[index]).then((url) => (image.src = url)));
      });
      if (loadingImages.length) {
        Promise.all(loadingImages).then(() => {
          this.filesReadyResolve();
        }).catch(() => this.filesReadyReject());
      } else {
        this.filesReadyResolve();
      }
    }
    let that = this;
    document.addEventListener('capureImageData', function (event) {
      console.log("event.detail.controlId = " + event.detail.controlId)
      if (that.component.id == event.detail.controlId) {
        if (!window.isListenerSet) {
          window.resolveLocalFileSystemURL(event.detail.imageData, (fileEntry) => {
            fileEntry.file((file) => {
              console.log("fileEntry = " + file)
              const reader = new FileReader();
              reader.onloadend = (evt) => {
                console.log("onloadend = " + evt)
                const blob = new Blob([new Uint8Array(evt.target.result)], {
                  type: file.type
                });
                blob.name = file.name;
                console.log("file.name = "+ file.name);
                console.log("blob instanceOf Blob = " + blob)
                that.compressFile([blob])
              };
              reader.readAsArrayBuffer(file);
            });
          })
          window.isListenerSet = true;
        }
      }
    }, false);


    document.addEventListener('capureImageDataForAndroidBrowser', function (event) {
      console.log("event.detail.controlId capureImageDataForAndroidBrowser = " + event.detail.controlId)
      if (that.component.id == event.detail.controlId) {
        if (!window.isListenerSet) {
          that.compressFile([event.detail.imageData])
          // window.resolveLocalFileSystemURL(event.detail.imageData, (fileEntry) => {
          //   fileEntry.file((file) => {
          //     console.log("fileEntry = " + file)
          //     const reader = new FileReader();
          //     reader.onloadend = (evt) => {
          //       console.log("onloadend = " + evt)
          //       const blob = new Blob([new Uint8Array(evt.target.result)], {
          //         type: file.type
          //       });
          //       blob.name = file.name;
          //       console.log("blob instanceOf Blob = " + blob)
          //       that.compressFile([blob])
          //     };
          //     reader.readAsArrayBuffer(file);
          //   });
          // })
          window.isListenerSet = true;
        }
      }
    }, false);


    return superAttach;
  }

  /* eslint-disable max-len */
  fileSize(a, b, c, d, e) {
    return `${(b = Math, c = b.log, d = 1024, e = c(a) / c(d) | 0, a / b.pow(d, e)).toFixed(2)} ${e ? `${'kMGTPEZY'[--e]}B` : 'Bytes'}`;
  }

  /* eslint-enable max-len */

  /* eslint-disable max-depth */
  globStringToRegex(str) {
    let regexp = '',
      excludes = [];
    if (str.length > 2 && str[0] === '/' && str[str.length - 1] === '/') {
      regexp = str.substring(1, str.length - 1);
    } else {
      const split = str.split(',');
      if (split.length > 1) {
        for (let i = 0; i < split.length; i++) {
          const r = this.globStringToRegex(split[i]);
          if (r.regexp) {
            regexp += `(${r.regexp})`;
            if (i < split.length - 1) {
              regexp += '|';
            }
          } else {
            excludes = excludes.concat(r.excludes);
          }
        }
      } else {
        if (str.startsWith('!')) {
          excludes.push(`^((?!${this.globStringToRegex(str.substring(1)).regexp}).)*$`);
        } else {
          if (str.startsWith('.')) {
            str = `*${str}`;
          }
          regexp = `^${str.replace(new RegExp('[.\\\\+*?\\[\\^\\]$(){}=!<>|:\\-]', 'g'), '\\$&')}$`;
          regexp = regexp.replace(/\\\*/g, '.*').replace(/\\\?/g, '.');
        }
      }
    }
    return {
      regexp,
      excludes
    };
  }

  /* eslint-enable max-depth */

  translateScalars(str) {
    if (typeof str === 'string') {
      if (str.search(/kb/i) === str.length - 2) {
        return parseFloat(str.substring(0, str.length - 2) * 1024);
      }
      if (str.search(/mb/i) === str.length - 2) {
        return parseFloat(str.substring(0, str.length - 2) * 1024 * 1024);
      }
      if (str.search(/gb/i) === str.length - 2) {
        return parseFloat(str.substring(0, str.length - 2) * 1024 * 1024 * 1024);
      }
      if (str.search(/b/i) === str.length - 1) {
        return parseFloat(str.substring(0, str.length - 1));
      }
      if (str.search(/s/i) === str.length - 1) {
        return parseFloat(str.substring(0, str.length - 1));
      }
      if (str.search(/m/i) === str.length - 1) {
        return parseFloat(str.substring(0, str.length - 1) * 60);
      }
      if (str.search(/h/i) === str.length - 1) {
        return parseFloat(str.substring(0, str.length - 1) * 3600);
      }
    }
    return str;
  }

  validatePattern(file, val) {
    if (!val) {
      return true;
    }
    const pattern = this.globStringToRegex(val);
    let valid = true;
    if (pattern.regexp && pattern.regexp.length) {
      const regexp = new RegExp(pattern.regexp, 'i');
      valid = (!_.isNil(file.type) && regexp.test(file.type)) ||
        (!_.isNil(file.name) && regexp.test(file.name));
    }
    valid = pattern.excludes.reduce((result, excludePattern) => {
      const exclude = new RegExp(excludePattern, 'i');
      return result && (_.isNil(file.type) || !exclude.test(file.type)) &&
        (_.isNil(file.name) || !exclude.test(file.name));
    }, valid);
    return valid;
  }

  validateMinSize(file, val) {
    return file.size + 0.1 >= this.translateScalars(val);
  }

  validateMaxSize(file, val) {
    return file.size - 0.1 <= this.translateScalars(val);
  }
  compressFile(files) {
    let that = this;
    console.log("files[0] = " + JSON.stringify(files[0]));
    console.log('originalFile instanceof Blob', files[0] instanceof Blob); // true
    console.log(`originalFile size ${files[0].size / 1024 / 1024} MB`);

    //default file compression values
    let maxFileSizeMB = 2;
    let MaximumCompressionHeightOrWidth = 2048;

    // setting component compression values
    if (that.component.MaximumCompressionHeightOrWidth) {
      MaximumCompressionHeightOrWidth = that.component.MaximumCompressionHeightOrWidth
    }
    if (that.component.fileCompressionSize) {
      maxFileSizeMB = that.component.fileCompressionSize;
    }
    console.log("maxFileSizeMB = " + maxFileSizeMB)
    console.log("MaximumCompressionHeightOrWidth = " + MaximumCompressionHeightOrWidth)

    var options = {
      maxSizeMB: maxFileSizeMB,
      maxWidthOrHeight: MaximumCompressionHeightOrWidth,
      useWebWorker: true
    }

    const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
 
     if(files && files[0] && acceptedImageTypes.includes(files[0].type)){
      imageCompression(files[0], options)
      .then(function (compressedFile) {
        console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
        console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
        that.blobToBase64(compressedFile).then(res => {
          imagUrl = res;
          that.upload([compressedFile])
        });
      })
      .catch(function (error) {
        console.log(error.message);
        alert(error.message)
      });
    }else{
      that.upload(files)
    }
  }

  upload(files, replace) {
    // this.component.storage = 'SmartStorage';
    if (!this.component.multiple) {
      files = Array.prototype.slice.call(files, 0, 1);
    }
    if (this.component.storage && files && files.length) {
      // if (this.component.storage && files) {
      Array.prototype.forEach.call(files, async (file) => {
        const fileName = uniqueName(file.name, this.component.fileNameTemplate, this.evalContext());
        let fileUpload = {}
         fileUpload = {
          originalName: file.name,
          name: fileName,
          size: file.size,
          status: 'info',
          message: this.t('Processing file. Please wait...')
        };

        // Check if file with the same name is being uploaded
        const fileWithSameNameUploaded = isfileBrowse ? (this.dataValue.some(fileStatus => fileStatus.originalName === file.name)) : (this.dataValue.some(fileStatus => fileStatus.url === imagUrl));
        const fileWithSameNameUploadedWithError = this.statuses.findIndex(fileStatus =>
          fileStatus.url === imagUrl &&
          fileStatus.status === 'error'
        );

        if (fileWithSameNameUploaded && !replace) {
          fileUpload.status = 'error';
          fileUpload.message = this.t('File with the same name is already uploaded');
        }

        if (fileWithSameNameUploadedWithError !== -1) {
          this.statuses.splice(fileWithSameNameUploadedWithError, 1);
          this.redraw();
        }

        // Check file pattern
        if (this.component.filePattern && !this.validatePattern(file, this.component.filePattern)) {
          fileUpload.status = 'error';
          fileUpload.message = this.t('File is the wrong type; it must be {{ pattern }}', {
            pattern: this.component.filePattern,
          });
        }

        // Check file minimum size
        if (this.component.fileMinSize && !this.validateMinSize(file, this.component.fileMinSize)) {
          fileUpload.status = 'error';
          fileUpload.message = this.t('File is too small; it must be at least {{ size }}', {
            size: this.component.fileMinSize,
          });
        }

        // Check file maximum size0
        if (this.component.fileMaxSize && !this.validateMaxSize(file, this.component.fileMaxSize)) {
          fileUpload.status = 'error';
          fileUpload.message = this.t('File is too big; it must be at most {{ size }}', {
            size: this.component.fileMaxSize,
          });
        }

        // Get a unique name for this file to keep file collisions from occurring.
        const dir = this.interpolate(this.component.dir || '');
        const {
          fileService
        } = this;
        if (!fileService) {
          fileUpload.status = 'error';
          fileUpload.message = this.t('File Service not provided.');
        }
        this.statuses.push(fileUpload);

        this.redraw();

        if (fileUpload.status !== 'error') {
          if (this.component.privateDownload) {
            file.private = true;
          }
          const {
            storage,
            options = {}
          } = this.component;
          const url = this.interpolate(this.component.url, {
            file: fileUpload
          });
          let groupKey = null;
          let groupPermissions = null;

          //Iterate through form components to find group resource if one exists
          this.root.everyComponent((element) => {
            if (element.component ? element.component.submissionAccess : null || element.component ? element.component.defaultPermission : null) {
              groupPermissions = !element.component.submissionAccess ? [{
                type: element.component.defaultPermission,
                roles: [],
              }, ] : element.component.submissionAccess;

              groupPermissions.forEach((permission) => {
                groupKey = ['admin', 'write', 'create'].includes(permission.type) ? element.component.key : null;
              });
            }
          });

          const fileKey = this.component.fileKey || 'file';
          const groupResourceId = groupKey ? this.currentForm.submission.data[groupKey]._id : null;
          let processedFile = null;

          if (this.root.options.fileProcessor) {
            try {
              this.refs.fileProcessingLoader.style.display = 'block';
              const fileProcessorHandler = fileProcessor(this.fileService, this.root.options.fileProcessor);
              processedFile = await fileProcessorHandler(file, this.component.properties);
            } catch (err) {
              fileUpload.status = 'error';
              fileUpload.message = this.t('File processing has been failed.');
              this.fileDropHidden = false;
              this.redraw();
              this.refs.fileProcessingLoader.style.display = 'none';
              return;
            }
            this.refs.fileProcessingLoader.style.display = 'none';
          }

          fileUpload.message = this.t('Starting upload.');
          this.redraw();

          const filePromise = fileService.uploadFile(
              storage,
              processedFile || file,
              fileName,
              dir,
              // Progress callback
              (evt) => {
                fileUpload.status = 'progress';
                fileUpload.progress = parseInt(100.0 * evt.loaded / evt.total);
                delete fileUpload.message;
                this.redraw();
              },
              url,
              options,
              fileKey,
              groupPermissions,
              groupResourceId,
              // Upload start callback
              () => {
                this.fileDropHidden = true;
                this.emit('fileUploadingStart', filePromise);
              },
              // Abort upload callback
              (abort) => this.abortUpload = abort,
            ).then((fileInfo) => {
              const index = this.statuses.indexOf(fileUpload);
              if (index !== -1) {
                this.statuses.splice(index, 1);
              }
              fileInfo.originalName = file.name;
              fileInfo.compnentId = this.component.id
              if (!this.hasValue()) {
                this.dataValue = [];
              }
              if (replace) {
                let ind;
                for (let i = 0; i < this.dataValue.length; i++) {
                  if (this.dataValue[i].originalName == fileInfo.originalName) {
                    console.log("original name matches")
                    ind = i;
                  }
                }
                if (ind !== -1) {
                  let originalFileId = this.dataValue[ind].id;
                  console.log("originalFileId = " + originalFileId)
                  this.dataValue[ind] = fileInfo;
                  let eventCustom = new CustomEvent('deleteOriginalFileFromDB', {
                    detail: {
                      fileId: originalFileId,
                    }
                  });
                  document.dispatchEvent(eventCustom);
                }
              } else {
                this.dataValue.push(fileInfo)

              }
              this.fileDropHidden = false;
              this.redraw();
              this.triggerChange();
              this.emit('fileUploadingEnd', filePromise);
            })
            .catch((response) => {
              fileUpload.status = 'error';
              fileUpload.message = response;
              delete fileUpload.progress;
              this.fileDropHidden = false;
              this.redraw();
              this.emit('fileUploadingEnd', filePromise);
            });
        }
      });
    }
  }

  blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  getFile(fileInfo) {
    const {
      options = {}
    } = this.component;
    const {
      fileService
    } = this;
    if (!fileService) {
      return alert('File Service not provided');
    }
    if (this.component.privateDownload) {
      fileInfo.private = true;
    }
    fileService.downloadFile(fileInfo, options).then((file) => {
        if (file) {
          if (['base64', 'indexeddb'].includes(file.storage)) {
            download(file.url, file.originalName || file.name, file.type);
          } else {
            window.open(file.url, '_blank');
          }
        }
      })
      .catch((response) => {
        // Is alert the best way to do this?
        // User is expecting an immediate notification due to attempting to download a file.
        alert(response);
      });
  }

  focus() {
    if ('beforeFocus' in this.parent) {
      this.parent.beforeFocus(this);
    }

    if (this.refs.fileBrowse) {
      this.refs.fileBrowse.focus();
    }
  }

  destroy() {
    this.stopVideo();
    super.destroy();
  }

}
SmartFileComponent.editForm = fileEditForm;
Components.addComponent('file', SmartFileComponent);
