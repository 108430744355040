
<mat-toolbar color="primary">
    <mat-toolbar-row>
        <span> {{title}}</span>
        <span class="fill-remaining-space"></span>
        <button mat-button (click)="close()">
      <mat-icon>clear</mat-icon>
    </button>
    </mat-toolbar-row>
  </mat-toolbar>
<br>
<div>
  <div mat-dialog-content>

        <form >
          <div class="form-group">
            <label>Event data</label>
            <input type="text" name="eventdata" [(ngModel)]="wfdata.eventdata" class="form-control"  placeholder="Enter Eventdata (if any)"><br>
            <label>Custom Input</label>
            <input type="text" name="custominput" [(ngModel)]="wfdata.custominput" class="form-control"  placeholder="Enter custominput json">
          </div>
        </form>
  </div>
  
  <div mat-dialog-actions>
    <button mat-flat-button color="primary" (click)="close()">Save</button>
  </div>
  </div>