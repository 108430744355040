import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AnonymousGuard implements CanActivate {
  constructor(
    private authservice: AuthenticationService,
    private router: Router
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.authservice.isLoggedIn.pipe(
      take(1),
      map((isuserLoggedIn: boolean) => {
        // console.log(isuserLoggedIn);
        if (!isuserLoggedIn) {
          return true;
        }
        this.router.navigate(['/dashboard']);
        // console.log('loggedIn user tring to accss login page');
        return false;
      })
    );
  }
}
