<div class="data-table-status-bar">
  <div class="status-bar-content">
      <div>
      <button *ngIf="form" mat-button color="primary" [routerLink]="['/forms',formid,'sharing']"><mat-icon>{{form.avatar}}</mat-icon>
        {{form.formTitle}}<mat-icon>keyboard_arrow_right</mat-icon>
      </button>
      </div>
      <div *ngIf="shareDesription">{{shareDesription}}</div>
      <span class="fill-remaining-space"></span>
      <div>
      </div>
  </div>
</div>
<div class="search-container mat-app-background">
  <form [formGroup]="searchpublicforms" novalidate>
      <mat-form-field>
          <input matInput formControlName="startDate" [max]="maxDate" [matDatepicker]="startdatepicker" placeholder="Start Date">
          <mat-datepicker-toggle matSuffix [for]="startdatepicker"></mat-datepicker-toggle>
          <mat-datepicker #startdatepicker></mat-datepicker>
          <mat-error *ngIf="searchpublicforms.get('startDate').hasError('required')">
              Start date is required
          </mat-error>
          <mat-error *ngIf="searchpublicforms.get('startDate').hasError('matDatepickerMax')">
              Start date can't be greater than today's date
          </mat-error>
      </mat-form-field>

      <mat-form-field>
          <input matInput formControlName="endDate" [matDatepicker]="enddatepicker" placeholder="End Date">
          <mat-datepicker-toggle matSuffix [for]="enddatepicker"></mat-datepicker-toggle>
          <mat-datepicker #enddatepicker></mat-datepicker>
          <mat-error *ngIf="searchpublicforms.get('startDate').hasError('required')">
              End date is required
          </mat-error>
          <mat-error *ngIf="searchpublicforms.get('startDate').hasError('matDatepickerMax')">
              End date can't be greater than today's date
          </mat-error>
      </mat-form-field>
      <!-- <mat-form-field>
          <input matInput formControlName="formName" placeholder="Form Name">
      </mat-form-field> -->
      <button mat-flat-button color="primary" class="search-button" [disabled]="!searchpublicforms.valid" (click)="getpublicsharedformsinfo(formid, shareid, searchpublicforms.value, pageSize, pageIndex)">Search</button>
  </form>
</div>
<div *ngIf="errmsg" class="reports-msg">
  <span>{{errmsg}}</span>
</div>
<div class="table-container mat-app-background">
  <!-- <pre>{{ submitteddata | json }}</pre> -->
  <mat-paginator *ngIf="dataSourcepublicforms" [length]="length" [pageIndex]="pageIndex" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="pageevent($event)">
  </mat-paginator>
  <table mat-table [dataSource]="dataSourcepublicforms" multiTemplateDataRows class="mat-elevation-z1">
  <!-- <ng-container matColumnDef="formName">
      <th mat-header-cell *matHeaderCellDef> Form Name </th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
  </ng-container> -->
  <ng-container matColumnDef="submissionDate">
    <th mat-header-cell *matHeaderCellDef> Submission Date</th>
    <td mat-cell *matCellDef="let element"> {{element.submissionDate | date: "MMM d, y, h:mm:ss a" }} </td>
  </ng-container>
  <ng-container matColumnDef="submissionId">
      <th mat-header-cell *matHeaderCellDef> Submission ID</th>
      <td mat-cell *matCellDef="let element"> {{element.submissionId}} </td>
  </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row style="cursor: pointer" *matRowDef="let row; columns: displayedColumns;" [routerLink]="['/formio/sharedform-renderer',{name: row.name, submissionId: row.submissionId , shareId: row.shareID}]"></tr>
  </table>
</div>

