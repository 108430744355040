<div *ngIf="loadingCtrl">
    <div class="wrapper">
      <div class="inner">
        <mat-progress-spinner diameter='80' mode="indeterminate"></mat-progress-spinner><br><h4>Loading...</h4>
      </div>
    </div>
</div>

<div *ngIf='!dashboardUrlExist && !loadingCtrl' class="grid-container mat-app-background">
    <mat-grid-list cols="6" rowHeight="260px">
        <mat-grid-tile *ngFor="let card of cards | async; let ind = index; " [colspan]="card.cols" [rowspan]="card.rows">
            <mat-card class="dashboard-card" routerLink='/{{card.route}}'>
                <!--
                <mat-card-header>
                    <mat-card-title>
                      <button mat-icon-button class="more-button" [matMenuTriggerFor]="menu" aria-label="Toggle menu">
                        <mat-icon>more_vert</mat-icon>
                      </button>
                      <mat-menu #menu="matMenu" xPosition="before">
                        <button mat-menu-item>Expand</button>
                        <button mat-menu-item>Remove</button>
                      </mat-menu>
                    </mat-card-title>
                </mat-card-header>
              -->
                <mat-card-content class="dashboard-card-content">
                    <div class="icon">
                        <mat-icon [inline]="true" [style.color]="card.iconcolor">{{card.icon}}</mat-icon>
                    </div>
                    <!-- <div>{{ 'HOME.TITLE' | translate }}</div> -->
                    {{card.title | translate }}
                    <div>{{card.desc | translate}}</div>
                </mat-card-content>
            </mat-card>
        </mat-grid-tile>
        <mat-grid-tile *ngFor="let card of externalapps" [colspan]="card.cols" [rowspan]="card.rows">
          <mat-card class="dashboard-card" (click)="openapp(card.url, card.target)">
              <mat-card-content class="dashboard-card-content">
                  <div class="icon">
                      <mat-icon [inline]="true" [style.color]="card.iconcolor">{{card.icon}}</mat-icon>
                  </div>
                  {{card.title | translate }}
                  <div>{{card.desc | translate }}</div>
              </mat-card-content>
          </mat-card>
      </mat-grid-tile>
    </mat-grid-list>
</div>
<iframe *ngIf='dashboardUrlExist && !loadingCtrl' style="height: 100%;width:100%;" [src]="dashboardUrl | safe" [title]="dashboardTitle"></iframe>

<mat-menu #dashboardMenu="matMenu" yPosition="below" class="dashboard-list" style="margin-top: 10px;">
  <button mat-menu-item (click)="loadSelectedDashboard(list)" *ngFor="let list of listOfDashboards" >
  <mat-icon color='primary' *ngIf="list.selected === 'true'">check</mat-icon>
  <mat-icon color='primary' *ngIf="list.selected === 'false'"></mat-icon>
  {{list.name}}</button>
</mat-menu>

<div id="addiconbutton" *ngIf='dashboardUrlExist && !loadingCtrl && listOfDashboards.length > 1'>
  <button mat-mini-fab color="primary" [matMenuTriggerFor]="dashboardMenu" matTooltip="Dashboard Lists">
      <mat-icon aria-label="Example icon-button with a add icon">insights</mat-icon>
  </button>
</div>
