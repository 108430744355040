import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CustomEncoder } from './shared/classes/customencoder';
import { AppConfigService } from './app.config';
import { ReteService } from './rete/rete.service';
import { HotToastService } from '@ngneat/hot-toast';
import { BehaviorSubject, Observable } from 'rxjs';
import * as jsonLogic from 'json-logic-js/logic.js';
@Injectable({
  providedIn: "root",
})
/**
 *
 * Service class consisting of basic utility methods
 *
 */
export class UtilsService {
  public canDeploy = new BehaviorSubject<boolean>(false);
  private eventSource: EventSource;
  constructor(
    private appConfigService: AppConfigService,
    private reteservice: ReteService,
    private toastService: HotToastService,
    private _zone: NgZone
  ) {}

  /**
   * @returns {string} - Basic UMP Url which will be used in making http requests
   */
  getUMPUrl(): string {
    if (!location.hostname.includes("digitalform.io")) {
      return this.appConfigService.apiBaseUrl;
    } else {
      let hosturl = location.protocol + "//" + location.hostname;
      if (location.port) {
        hosturl = `${hosturl}:${location.port}`;
      }
      return hosturl;
    }
  }

  /**
   * @param {string} contentType - For json or x-www-form-urlencoded
   * @param {string} accept - Defaults to application/json type
   *
   * @returns {HttpHeaders} - Request content headers
   */

  setBasicHeaders(contentType: string): HttpHeaders {
    let Headers = new HttpHeaders();
    Headers = Headers.append("Content-Type", contentType);
    Headers = Headers.append("accept", "application/json");
    return Headers;
  }

  /**
     * @param {string} queuedExecute - Default parameter
     * @param {string} messageFormat - Default parameter
     * @param {string} inputMessage - Request data to send
     * @param {string} sensitive - Specifies the request data is sensitive or not
     * @param {string} externalReference - For making Async request calls
     *
     * @returns {HttpParams} - Request parameters
  */
  setParams(inputMessage: any, sensitive?: string, externalReference?: string, queuedExecute='false'): HttpParams {
    const inputParams = JSON.stringify(inputMessage);
    let params = new HttpParams({ encoder: new CustomEncoder() });
    params = params.append('queuedExecute', queuedExecute);
    params = params.append('messageFormat', 'custom');
    params = params.append('inputMessage', inputParams);
    params = params.append('sensitive', sensitive);
    params = params.append('externalReference', externalReference);
    return params;
  }

  /**
   *
   * @param inputMessage - Request data to send within the body
   *
   * @returns {string} - body for http request
   */
  setBody(inputMessage: any): string {
    const inputParams = JSON.stringify(inputMessage);
    const encodedInput = new CustomEncoder().encodeValue(inputParams);
    const body = `queuedExecute=false&messageFormat=custom&inputMessage=${encodedInput}`;
    return body;
  }
  /**
   *
   * @param {number} length - Length of the string to be generated
   *
   * @returns {string} - Random String
   */
  generateRandomString(length: number): string {
    let randomString = "";
    const characters =
      "0123ABCDEFGHIJPQRSTUVWXYZabcdefghijklmnopqrstuvwxyzKLMNO456789";
    for (let i = length; i > 0; i--) {
      randomString += characters[Math.floor(Math.random() * characters.length)];
    }
    return randomString;
  }
  /**
   *
   * @param workflowJsonData - The json data representing workflow node
   */
  getAllNodeNames(workflowJsonData: any) {
    const allnodes = workflowJsonData.nodes;
    const nodenamesarr = [];
    const values = Object.values(allnodes);
    // console.log(values);
    values.forEach((element: any) => {
      nodenamesarr.push(element.data.name);
    });
    return nodenamesarr;
  }
  /**
   * @param object - JavaScript object
   *
   * @returns {boolean} - whether Object is empty or not
   */
  isObjEmpty(object: any) {
    return Object.keys(object).length === 0 && object.constructor === Object;
  }
  /**
   * @param object - Object consisting checkbox values
   *
   * @returns {boolean} - whether object of checkbox has atleast one checked or not
   */
  public checkboxvalidity(checkboxgroup: any): boolean {
    let counter = 0;
    Object.values(checkboxgroup).forEach(checked => {
    if (checked) {
      counter++;
    }
    });
    if (counter > 0) {
      return true;
    } else {
      return false;
    }
  }

  /**
   *
   * @param jsonLogicRule - Rule to apply
   * @param selectedParams - User selected parameters
   *
   * @returns {Array} - Empty or array with missing parrameters
   */
  applyJsonLogic(jsonLogicRule: any, selectedParams: any) {
    const jsonLogicData = {};
    selectedParams.map( param => {
      jsonLogicData[param.inputName] = param.values;
    })
    return jsonLogic.apply(
      jsonLogicRule,   // Rule
      jsonLogicData    // Data
    );

  }
  /**
   *
   * @param {number} nodeid  - Nodeid assiged to node via rete editor
   * @param {string} nodename - Name of the node from previous nodes will be returned
   * @param {any} nodesfound - Used for recursion, to let function know previous nodes found after each iteration
   * @param {any} editorJson - Workflow Editor json of all nodes and connection
   *
   * @returns - All previous nodes found before given nodename
   */
  getpreviousnodes(
    nodeId: number,
    nodeName: string,
    nodesFound: any,
    editorJson: any
  ) {
    // console.log(nodeId, nodeName, nodesFound)
    const previousnodes = nodesFound;
    const preparednode = editorJson.nodes[nodeId];
    let noip=false;
    if (preparednode.inputs[nodeName].connections.length === 0) {
      // console.log(this.data.editor.toJSON().nodes[nodeid].inputs[this.data.node.name].connections[0].node);
      console.log("No input connections");
      noip=true;
      localStorage.setItem('noinput',noip.toString())
      return previousnodes;
    } else {
      noip=false;
      localStorage.setItem('noinput',noip.toString())
      if (preparednode.inputs[nodeName].connections[0].node === 1) {
        // console.log("Start node reached....\n\n");
        // console.log('Previous nodes found....\n', previousnodes);
        return previousnodes;
      } else {
        const incominginputnodeid =
          preparednode.inputs[nodeName].connections[0].node;
        const incominginputnodedata =
          editorJson.nodes[incominginputnodeid].name;
        const exprnodedata = editorJson.nodes[incominginputnodeid].data;
        // console.log(incominginputnodeid, incominginputnodedata);
        if (
          incominginputnodedata !== "Condition" &&
          incominginputnodedata !== "SendEmail" &&
          incominginputnodedata !== "FormAlert"
        ) {
          const node = editorJson.nodes[incominginputnodeid];
          previousnodes.push(node);
        }
        //   else {
        //   const node  = editorJson.nodes[incominginputnodeid];
        //   previousnodes.push(node);
        //   // exprnodedata.expressions.forEach(element => {
        //   //   previousnodes.push(`${element.key}`);
        //   // });
        // }
        return this.getpreviousnodes(
          incominginputnodeid,
          incominginputnodedata,
          previousnodes,
          editorJson
        );
      }
    }
  }
  /**
   *
   * @param {any} previousnodesfound - All previous nodes found before given nodename
   * @param {string} data - Node data
   * @returns - nodes Tree
   */
  async preparePreviousNodesTree(previousnodesfound: any, data: any) {
    let previousNodesTree = [];
    let currentNodesTree = [];
    const EnvironmentTree =  await this.getEnvironmentVariables(data.formId);
    // console.log(EnvironmentTree);
    previousnodesfound.forEach(element => {
     if(element.name !== 'Calculate') {
       if(element.name === 'ExecuteJavaScript' || element.name === 'ExecutePythonScript') {
        let items = [];
        // console.log(element.data);
        if(element.data.outputparams && element.data.outputparams.length > 0) {
        JSON.parse(element.data.outputparams).forEach(outputresult => {
          items.push({
            label: `${outputresult}`,
            value:'${' + `${element.data.name}_${outputresult}` + '}'
          });
        });
        const node = {
          label: element.data.name,
          items: items
        };
        previousNodesTree.push(node);
      }

       }
       else if(element.name === 'ForLoop') {
        const node = {
          label: element.data.name,
          items: [
            {
             label: `Success`,
             value: '${' + `${element.data.name}_result` + '}'
           }
          ]
        };
        previousNodesTree.push(node);
       } else {
        const node = {
          label: element.data.name,
          items: [
            {
              label: `Error`,
              value: '${' + `${element.data.name}_error` + '}'
            },
            {
             label: `Success`,
             value: '${' + `${element.data.name}_result` + '}'
           }
          ]
        };
        previousNodesTree.push(node);
       }


     } else {
       let value = [];
       if(element.data.expressions && element.data.expressions.length > 0){
       element.data.expressions.forEach(expression => {
         value.push({
          label: `${expression.key}`,
          value:'${' + `${element.data.name}_${expression.key}` + '}'
         });
       });
       const node = {
         label: element.data.name,
         items: value
       };
       previousNodesTree.push(node);
      }
   }
    });
    if(data.addedExpressions && data.addedExpressions.length > 0) {
      // let currentExprvals = [];
        data.addedExpressions.forEach((expressionvar, index) => {
          // console.log(expressionvar, index);
          if(expressionvar.key !== data.currentExpressionKey && data.index > index) {
          currentNodesTree.push({
          label: `${expressionvar.key}`,
          value:'${' + `${data.node.data.name}_${expressionvar.key}` + '}'
          });
        }
        });
    }
    if(previousNodesTree.length > 0 && currentNodesTree.length > 0) {
     return [
      {
        label: 'Custom',
        items: [{
          label: 'custominput',
          value:'${custominput}'
          }]
      },
      {
        label: 'Previous Node',
        items: previousNodesTree
      },
      {
          label: 'Current Node',
          items: currentNodesTree
      },
      {
          label: 'Environment',
          items: EnvironmentTree
      }
     ];
    } else if (previousNodesTree.length > 0 && currentNodesTree.length === 0) {
      return [
        {
          label: 'Custom',
          items: [{
            label: 'custominput',
            value:'${custominput}'
            }]
        },
        {
          label: 'Previous Node',
          items: previousNodesTree
        },
        {
          label: 'Environment',
          items: EnvironmentTree
        }
      ];
    } else if (previousNodesTree.length === 0 && currentNodesTree.length > 0  ) {
      return [
        {
          label: 'Custom',
          items: [{
            label: 'custominput',
            value:'${custominput}'
            }]
        },
        {
          label: 'Current Nodes',
          items: currentNodesTree
        },
        {
          label: 'Environment',
          items: EnvironmentTree
        }
      ];
    } else {
      return [
        {
          label: 'Custom',
          items: [{
            label: 'custominput',
            value:'${custominput}'
            }]
        },
        {
        label: 'Environment',
        items: EnvironmentTree
      }
  ];
    }

   }
   /**
    *
    * @param {string} formId - Id of the form to get attributes
    *
    * @returns - All Macro + Attributes variable for Environment Tree
    */
  getEnvironmentVariables(formId: string) {
     let macros = [];
     this.reteservice.getAttributesForExpressionBuilder(formId)
    .subscribe( async res => {
      const response = JSON.parse(res);
      if (response.error === '') {
        if (response.status === 'Success') {
          // console.log(response);
          const attributes = response.attributes;
          if(attributes.length > 0) {
           attributes.forEach(element => {
            let environmentobj = {
              label: element.key,
              value: '${env_'+ element.key + '}',
              icon: `add_to_drive`
            }
             response.macros.push(environmentobj);
          });
        }
        // console.log(macros);
        macros = response.macros;
        }
      }
        else {
          this.toastService.error(
            `${response.error}`,
            {
              autoClose: false,
              id: "wfsave-error",
              dismissible: true,
              position: "bottom-right",
              style: {
                border: "1px solid var(--error-color)",
                padding: "16px",
                color: "var(--error-color)",
              },
            }
          );
        }
    });
   // Added 2secs delay to wait until get response of DIGITAL_FORMS_PA_GET_FORM_ATTRIBUTES
   return new Promise(resolve => {
     setTimeout(() => {
       if (macros && macros.length > 0) {
         return resolve(macros);
       } else {
         setTimeout(() => {
           return resolve(macros);
         }, 1000);
       }
     }, 1000);
   });
  }
  /**
   *
   * @param {any} previousnodesfound - All previous nodes found before given nodename
   *
   * @returns - All nodes outputs results compatible with Angular QueryBuilder fields
   */
  prepareFromfieldsFromPreviousNodes(previousnodesfound: any, isDataMapper?:boolean ) {
    let formfields = [];
    previousnodesfound.forEach((element) => {
      if (element.name !== "Calculate") {

        if(element.name === 'ExecuteJavaScript' || element.name === 'ExecutePythonScript') {
          let items = [];
          let name;
          let key;
          let operators;
          let errorfield;
          let resultfield 
          // console.log(element.data);
          if (element.data.outputparams && element.data.outputparams.length > 0) {
            JSON.parse(element.data.outputparams).forEach(outputresult => {
              // items.push({
              //   label: `${outputresult}`,
              //   value: '${' + `${element.data.name}_${outputresult}` + '}'
              // });
              name = `${outputresult}`;
              key = '${' + `${element.data.name}_${outputresult}` + '}';
              operators = [
                  "contains",
                  "does not contain",
                  "begins with",
                  "does not being with",
                  "ends with",
                  "does not end with",
                  "equals",
                  "not equals",
                  "in",
                  "not in",
                  "is empty",
                  "is not empty",
                  "is null",
                  "is not null",
                  "is empty list",
                  "is not empty list"
                ];
         
              if (outputresult === 'error') {
                 errorfield = {
                  name: name,
                  type: 'string',
                  input: "text",
                  key: key,
                  operators: operators,
              }
              } else {
                //  resultfield = this.prepareField(
                //   `${outputresult}`,
                //   "result",
                //   "string"
                // );
                
                resultfield = {
                  name: name,
                  type: 'string',
                  input: "text",
                  key: key,
                  operators: operators,
              }
            }
           
            });
          }
          if(errorfield != undefined || resultfield != undefined){
            formfields.push(resultfield, errorfield);
          }

        }else{
          const errorfield = this.prepareField(
            `${element.data.name}`,
            "error",
            "string"
          );
          const resultfield = this.prepareField(
            `${element.data.name}`,
            "result",
            "string"
          );
          formfields.push(resultfield, errorfield);
        }
       
      } else {
        if (element.data.expressions && element.data.expressions.length > 0) {
          element.data.expressions.forEach((expression) => {
            formfields.push(
              this.prepareField(expression.key, element.data.name , expression.type, isDataMapper)
            );
          });
        }
      }
    });
    return formfields;
  }
  /**
   *
   * @param {string} fieldname - node name for preparing Label for field to display in QueryBuilder
   * @param {string} fieldresulttype - node output for  preparing key for field to use internally in QueryBuilder
   * @param {string} type - node output type for selecting operators in QueryBuilder for parlicular field type
   *
   * @returns - Angular QueryBuilder Compatible field object
   */
  prepareField(fieldname: string, fieldresulttype: string, type: string, isDataMapper?: boolean) {
    let name;
    let key;
    const operators = {
      string: [
        "contains",
        "does not contain",
        "begins with",
        "does not being with",
        "ends with",
        "does not end with",
        "equals",
        "not equals",
        "in",
        "not in",
        "is empty",
        "is not empty",
        "is null",
        "is not null",
        "is empty list",
        "is not empty list"
      ],
      number: [
        "equals",
        "not equals",
        "less than",
        "less than or equals",
        "greater than",
        "greater than or equals",
        "between",
        "not between",
      ],
      date: [
        "equals",
        "not equals",
        "less than",
        "less than or equals",
        "greater than",
        "greater than or equals",
        "between",
        "not between",
        "is empty",
        "is not empty",
        "is null",
        "is not null",
      ],
    };
    if (fieldresulttype === "error") {
      name = `${fieldname} Error`;
      key = "${" + `${fieldname}_${fieldresulttype}` + "}";
    } else if (fieldresulttype === "result") {
      name = `${fieldname} Result`;
      key = "${" + `${fieldname}_${fieldresulttype}` + "}";
    } else {
      name = `${fieldname}`;
      if(isDataMapper) {
       key  = '${' + `${fieldresulttype}_${fieldname}` + '}';
      } else {
        key = "${" + `${fieldname}` + "}";
      }
    }
    return {
      name: name,
      type: type,
      input: "text",
      key: key,
      operators: operators[type],
    };
  }
  /**
   *
   * @param {string} workflowId - Id of the workflow to get
   *
   * @returns {any} - Candeploy boolean obserable
   */
   isDeployable(workflowId: string, formType: string) {
    this.reteservice.getworkflow(workflowId, formType).subscribe((res) => {
      const response = JSON.parse(res);
      // console.log(response.enableDeploy);
      if (response) {
        this.canDeploy.next(response.enableDeploy);
      }
    });
    return this.canDeploy.asObservable();
  }
  /**
   *
   * @param {string} url - url to get event stream
   * @returns {EventSource} - EventSource object
   */
  private getEventSource(url: string): EventSource {
    if (this.eventSource) {
      // console.log('Connection to server closed.');
      this.eventSource.close();
    }
    this.eventSource = new EventSource(url);
    return this.eventSource;
  }
  /**
   *
   * @param url - url to get event stream
   * @returns {Observable} - Observable stream of event sent by server url
   */
  getServerSentEvent(url: string): Observable<any> {
    return new Observable(observer => {
      const eventSource = this.getEventSource(url);
      eventSource.onopen = (ev) => {
        // console.log('Connection to server opened.', ev);
      };
      eventSource.onmessage = event => {
        // console.log(JSON.parse(JSON.parse(event.data).NotificationMessage));
        const eventdata = JSON.parse(JSON.parse(event.data).NotificationMessage);
        if(eventdata.event === "END-OF-STREAM") {
          // console.log('Connection to server closed.');
          eventSource.close();
        } else {
        this._zone.run(() => {
          observer.next(event);
        });
      }
      };
      eventSource.onerror = error => {
        this._zone.run(() => {
          observer.error(error);
        });
      };
      // return () => eventSource.close();
    });
  }
  /**
   *
   * @param {string} workflowId - Id of the workflow to save
   * @param {any} editorJson - Workflow Editor json of all nodes and connection
   * @param {boolean} saveNodedata - Whether the call is to save node data or entire workflow
   */
  saveWfdata(workflowId: string, editorJson: any, saveNodedata: boolean, formType?: string, formId?: string, oldActionOnDeploy?: string, actionOnDeploy?: string, dialogref?: any, wfName?: string) {
    this.reteservice
      .saveworkflowdata(workflowId, editorJson, saveNodedata, formType, formId, oldActionOnDeploy, actionOnDeploy, wfName)
      .subscribe((res) => {
        // console.log(res);
        const response = JSON.parse(res);
        if (response.error === "") {
          if (response.status === "Success") {
            this.toastService.close("wfsave-error");
            let successmsg = 'Workflow saved and deployed.';
            if(saveNodedata) {
              successmsg = 'Workflow node saved.';
            }
            if(dialogref) {
            dialogref.close(true);
            }
            this.toastService.success(`${successmsg}`, {
              style: {
                border: "1px solid var(--success-color)",
                padding: "16px",
                color: "var(--success-color)"
              },
              duration: 2000,
              position: "top-right"
            });
            if (!saveNodedata) {
              // this.canDeploy.next(true);
              this.isDeployable(workflowId, formType);
              let nodecontainer = document.getElementsByClassName(
                `node-save-error`
              ) as HTMLCollectionOf<HTMLElement>;
              if (nodecontainer.length > 0) {
                nodecontainer[0].style.border = "";
                nodecontainer[0].style.boxShadow = "";
                nodecontainer[0].classList.remove("node-save-error");
              }
            }
          }
        } else {
          if(dialogref) {
            dialogref.close(true);
            }
          const errorobj = response.error;
          this.toastService.error(
            `${errorobj}`,
            {
              autoClose: false,
              id: "wfsave-error",
              dismissible: true,
              position: "bottom-right",
              style: {
                border: "1px solid var(--error-color)",
                padding: "16px",
                color: "var(--error-color)",
              },
            }
          );
          if (!saveNodedata) {
            this.canDeploy.next(false);
            let nodecontainer = document.getElementsByClassName(
              `${errorobj.node}`
            ) as HTMLCollectionOf<HTMLElement>;
            if (nodecontainer.length > 0) {
              nodecontainer[0].style.border = "2px solid var(--error-color)";
              nodecontainer[0].style.boxShadow = "0 0 6px 6px rgb(128, 12, 12)";
              nodecontainer[0].classList.add("node-save-error");
            }
          }
          // console.log(nodecontainer[0]);
        }
        // console.log(res);
      });
  }
}
