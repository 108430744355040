import { trigger } from '@angular/animations';
import ComponentEditConditional from 'formiojs/components/_classes/component/editForm/Component.edit.conditional';
import ComponentEditAPI from 'formiojs/components/_classes/component/editForm/Component.edit.api';
import ComponentEditLogic from 'formiojs/components/_classes/component/editForm/Component.edit.logic';
import ComponentEditLayout from 'formiojs/components/_classes/component/editForm/Component.edit.layout';
import EditFormUtils from 'formiojs/components/_classes/component/editForm/utils';
import { Formio } from 'angular-formio';

let formHeaderObj;
let selNestedFormRecentRevision;
declare const window: any;
window.formdesign = [];
export function nestedComponentEditForm() {
  return {
    components: [{
      key: 'type',
      type: 'hidden'
    },
    {
      type: 'tabs',
      key: 'tabs',
      components: [
        {
          label: 'Display',
          key: 'display',
          weight: 0,
          components:
            [
              {
                weight: 0,
                type: 'textfield',
                input: true,
                key: 'label',
                label: 'Label',
                placeholder: 'Field Label',
                tooltip: 'The label for this field that will appear next to it.',
                validate: {
                  required: true
                }
              },
              {
                weight: 500,
                type: 'textfield',
                input: true,
                key: 'customClass',
                label: 'Custom CSS Class',
                placeholder: 'Custom CSS Class',
                tooltip: 'Custom CSS class to add to this component.'
              },
              {
                weight: 600,
                type: 'textfield',
                input: true,
                key: 'tabindex',
                label: 'Tab Index',
                placeholder: '0',
                tooltip: 'Sets the tabindex attribute of this component to override the tab order of the form. See the <a href=\'https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/tabindex\'>MDN documentation</a> on tabindex for more information.'
              },
              {
                weight: 1100,
                type: 'checkbox',
                label: 'Hidden',
                tooltip: 'A hidden field is still a part of the form, but is hidden from view.',
                key: 'hidden',
                input: true
              },
              {
                weight: 1200,
                type: 'checkbox',
                label: 'Hide Label',
                tooltip: 'Hide the label (title, if no label) of this component. This allows you to show the label in the form builder, but not when it is rendered.',
                key: 'hideLabel',
                input: true
              },
              {
                weight: 1350,
                type: 'checkbox',
                label: 'Initial Focus',
                tooltip: 'Make this field the initially focused element on this form.',
                key: 'autofocus',
                input: true
              },
              {
                weight: 1400,
                type: 'checkbox',
                label: 'Disabled',
                tooltip: 'Disable the form input.',
                key: 'disabled',
                input: true
              },
              {
                type: 'checkbox',
                input: true,
                weight: 1500,
                key: 'tableView',
                label: 'Table View',
                tooltip: 'Shows this value within the table view of the submissions.',
                defaultValue: true
              },
              {
                weight: 1600,
                type: 'checkbox',
                label: 'Modal Edit',
                tooltip: 'Opens up a modal to edit the value of this component.',
                key: 'modalEdit',
                input: true
              }
            ]
        },
        {
          label: 'Form',
          key: 'form',
          weight: 0,
          components:
            [
              {
                type: 'select',
                input: true,
                lazyLoad: true,
                dataSrc: 'url',
                data: {
                  url: `${localStorage.getItem('UMP_url')}/UMP/API/v2/applications/DIGITAL_FORMS/execute/DIGITAL_FORMS_PA_GET_NESTED_FORMS`,
                  method: 'POST',
                  headers: [
                    { key: 'Authorization', value: 'Bearer ' + localStorage.getItem('token') },
                    { key: 'Content-Type', value: 'application/x-www-form-urlencoded' },
                    { key: 'accept', value: 'application/json' }
                  ]
                },
                selectValues: 'formHeaders',
                searchField: 'formName__regex',
                template: '<span>{{ item.formTitle }}</span>',
                valueProperty: 'formId',
                authenticate: true,
                label: 'Form',
                key: 'form',
                weight: 10,
                // customClass: 'is-flipped',
                tooltip: 'The form to load within this form component.',
                validate: {
                  required: true,
                },
              //   customOptions: {
              //     'renderChoiceLimit': 2
              // },
                onSetItems(component, form) {
                  formHeaderObj = form.formHeaders;
                  component.triggerChange();
                },
                onChange(context) {
                  if (context && context.flags && context.flags.modified) {
                    if (context.instance && context.instance.data && context.instance.data.form) {
                      context.instance.root.getComponent('nestedFormComponenttype').setValue('smartNestedForm');
                      const selNestedForm = context.instance.data.form;
                      for (let i = 0; i < formHeaderObj.length; i++) {
                        if (selNestedForm && selNestedForm === formHeaderObj[i].formId) {
                          context.instance.root.getComponent('selectedNestedFormId').setValue(formHeaderObj[i].formId);
                          selNestedFormRecentRevision = formHeaderObj[i].recentVersion;
                          context.instance.root.getComponent('selNestedFormVersion').setValue(formHeaderObj[i].recentVersion);
                          const data = 'Revision : ' + formHeaderObj[i].recentVersion + ' - ' + formHeaderObj[i].publishComments;
                          context.instance.root.getComponent('formVersionAndComments').setValue(data);
                        }
                      }
                    } else {
                      context.instance.root.getComponent('selectedNestedFormId').resetValue();
                      context.instance.root.getComponent('selNestedFormVersion').resetValue();
                      context.instance.root.getComponent('formVersionAndComments').resetValue();
                      context.instance.root.getComponent('formComments').resetValue();
                      context.instance.root.getComponent('UpdateVersionColumns').component.hidden = true;
                      selNestedFormRecentRevision = '';
                      if (window.formdesign) {
                        window.formdesign = window.formdesign.filter((item) => item.key !== context.instance.data.key);
                      }

                    }
                  } else {
                    if (context.instance && context.instance.data && context.instance.data.selNestedFormVersion) {
                      context.instance.root.getComponent('nestedFormComponenttype').setValue('smartNestedForm');
                      if (formHeaderObj && formHeaderObj.length > 0) {
                        for (let i = 0; i < formHeaderObj.length; i++) {
                          // tslint:disable-next-line:max-line-length
                          if (context.instance && context.instance.data && context.instance.data.selectedNestedFormId && context.instance.data.selectedNestedFormId === formHeaderObj[i].formId) {
                            if (context.instance.data.selNestedFormVersion !== formHeaderObj[i].recentVersion) {
                              context.instance.root.getComponent('UpdateVersionColumns').component.hidden = false;
                              const data = 'Revision : ' + formHeaderObj[i].recentVersion + ' - ' + formHeaderObj[i].publishComments;
                              context.instance.root.getComponent('formComments').setValue(data);
                            }
                          }
                        }
                      }
                    }
                  }
                  if (context.instance && context.instance.data && context.instance.data.form) {
                    const formId = context.instance.data.form;
                    let selVersion = context.instance.data.selNestedFormVersion;
                    if (!selVersion && formHeaderObj && formHeaderObj.length > 0) {
                      for (let i = 0; i < formHeaderObj.length; i++) {
                        if (formId === formHeaderObj[i].formId) {
                          selVersion = formHeaderObj[i].recentVersion;
                        }
                      }
                    }
                    Formio.makeStaticRequest(
                      `${localStorage.getItem(
                        'UMP_url'
                      )}/UMP/API/v2/applications/DIGITAL_FORMS/execute/DIGITAL_FORMS_PA_GET_FORM_DATA?queuedExecute=false&messageFormat=custom&inputMessage=%7B%22formId%22%3A%22${formId}%22%2C%22type%22%3A%22form%22%2C%22version%22%3A%22${selVersion}%22%7D&sensitive=undefined&externalReference=undefined`,
                      'POST',
                      null,
                      {
                        headers: {
                          Authorization: 'Bearer ' + localStorage.getItem('token'),
                          Accept: 'application/json',
                          'Content-type': 'application/json',
                        },
                      }
                    )
                      .then(function (response) {
                        if (response) {
                          response = JSON.parse(response);
                          const obj = { key: context.instance.data.key, formdesign: response.formData, data: true };
                          const data = [];
                          const tags = context.instance.root.getComponent('tags').getValue();
                          if (tags && tags !== 'undefined') {
                            data.push(context.instance.root.getComponent('tags').getValue());
                          }
                          let array = [];
                          if (data && data.length > 0) {
                            for (let i = 0; i < data.length; i++) {
                              array = data[i].split(',');

                            }
                            for (let j = 0; j < array.length; j++) {
                                if (array[j] === 'Approval') {
                                  array.splice(j, 1);
                                }
                            }
                          }
                          console.log('category' + response.category);
                          const category = response.category;
                          if (category === 'Approval Templates') {
                            array.push('Approval');
                          }
                          if (array && array.length > 0) {
                            context.instance.root.getComponent('tags').setValue(array);
                          } else {
                            context.instance.root.getComponent('tags').resetValue();
                          }
                          window.formdesign.push(obj);
                        }
                      })
                      .catch((error) => {
                        console.error('Error getting form data ', error);
                      });
                  }
                }
              },
              {
                label: 'Lazy Load',
                inputType: 'checkbox',
                defaultValue: true,
                clearOnHide: true,
                errorLabel: '',
                key: 'lazyLoad',
                type: 'checkbox',
                tooltip: 'if it is checked, the subform is loaded after navigation to the page with this component within the wizard.',
                input: true,
                customConditional({ instance, data }) {
                  // const formInfo = instance.root?.getComponent('form')?.defaultDownloadedResources.find(res => res._id === data.form);
                  // const displayMode = 'wizard';
                  // return instance.options?.editForm?.display === displayMode && formInfo && formInfo.display !== displayMode;
                },
              },
              {
                type: 'textfield',
                input: true,
                label: 'Nested Form Component type',
                tooltip: 'Nested Form Component type.',
                key: 'nestedFormComponenttype',
                clearOnHide: false,
                weight: 11,
                hidden: true
              },
              {
                type: 'textfield',
                input: true,
                label: 'Selected Nested Form Id',
                tooltip: 'Selected Nested Form Id.',
                key: 'selectedNestedFormId',
                clearOnHide: false,
                weight: 11,
                hidden: true
              },
              {
                type: 'textfield',
                input: true,
                label: 'Select Nested Form Revision',
                tooltip: 'Select Nested Form Revision',
                key: 'selNestedFormVersion',
                clearOnHide: false,
                weight: 11,
                hidden: true
              },
              {
                type: 'textarea',
                input: true,
                label: 'Form Revision',
                tooltip: 'Selected Nested Form Revision and Comments',
                key: 'formVersionAndComments',
                weight: 11,
                disabled: true,
              },
              {
                label: 'Columns',
                key: 'UpdateVersionColumns',
                type: 'columns',
                input: false,
                tableView: false,
                hidden: true,
                columns: [
                  {
                    components: [
                      {
                        label: '<span class= \"text-danger\">Updates Available</span>',
                        autoExpand: false,
                        tableView: true,
                        key: 'formComments',
                        type: 'textarea',
                        input: true,
                        disabled: true,
                        tooltip: 'The updated form Revision and comments.',

                      }
                    ],
                    offset: 0,
                    push: 0,
                    pull: 0,
                    size: 'md',
                    currentWidth: 9,
                    width: 9
                  },
                  {
                    components: [
                      {
                        label: 'Update',
                        action: 'custom',
                        showValidations: false,
                        tableView: false,
                        key: 'refresh',
                        type: 'button',
                        input: true,
                        custom: (context) => {
                          if (formHeaderObj && formHeaderObj.length > 0) {
                            for (let i = 0; i < formHeaderObj.length; i++) {
                              // tslint:disable-next-line:max-line-length
                              if (context.instance && context.instance.data && context.instance.data.selectedNestedFormId && context.instance.data.selectedNestedFormId === formHeaderObj[i].formId) {
                                if (context.instance.data.selNestedFormVersion !== formHeaderObj[i].recentVersion) {
                                  context.instance.root.getComponent('selNestedFormVersion').setValue(formHeaderObj[i].recentVersion);
                                  const data = 'Revision : ' + formHeaderObj[i].recentVersion + ' - ' + formHeaderObj[i].publishComments;
                                  context.instance.root.getComponent('formVersionAndComments').setValue(data);
                                  context.instance.root.getComponent('UpdateVersionColumns').component.hidden = true;
                                }
                              }
                            }
                          }
                          if (context.instance && context.instance.data && context.instance.data.form) {
                            const formId = context.instance.data.form;
                            const selVersion = context.instance.data.selNestedFormVersion;
                            Formio.makeStaticRequest(
                              `${localStorage.getItem(
                                'UMP_url'
                              )}/UMP/API/v2/applications/DIGITAL_FORMS/execute/DIGITAL_FORMS_PA_GET_FORM_DATA?queuedExecute=false&messageFormat=custom&inputMessage=%7B%22formId%22%3A%22${formId}%22%2C%22type%22%3A%22form%22%2C%22version%22%3A%22${selVersion}%22%7D&sensitive=undefined&externalReference=undefined`,
                              'POST',
                              null,
                              {
                                headers: {
                                  Authorization: 'Bearer ' + localStorage.getItem('token'),
                                  Accept: 'application/json',
                                  'Content-type': 'application/json',
                                },
                              }
                            )
                              .then(function (response) {
                                if (response) {
                                  response = JSON.parse(response);
                                  const obj = { key: context.instance.data.key, formdesign: response.formData, data: true};
                                  const data = [];
                          const tags = context.instance.root.getComponent('tags').getValue();
                          if (tags && tags !== 'undefined') {
                            data.push(context.instance.root.getComponent('tags').getValue());
                          }
                          let array = [];
                          if (data && data.length > 0) {
                            for (let i = 0; i < data.length; i++) {
                              array = data[i].split(',');

                            }
                            for (let j = 0; j < array.length; j++) {
                                if (array[j] === 'Approval') {
                                  array.splice(j, 1);
                                }
                            }
                          }
                          console.log('category' + response.category);
                          const category = response.category;
                          if (category === 'Approval Templates') {
                            array.push('Approval');
                          }
                          if (array && array.length > 0) {
                            context.instance.root.getComponent('tags').setValue(array);
                          } else {
                            context.instance.root.getComponent('tags').resetValue();
                          }
                                  window.formdesign.push(obj);
                                  context.instance.triggerChange();
                                }
                              })
                              .catch((error) => {
                                console.error('Error getting form data ', error);
                              });
                          }
                        },
                        customClass: 'mt-3 pb-1 pt-1 text-right',
                      }
                    ],
                    offset: 0,
                    push: 0,
                    pull: 0,
                    size: 'md',
                    currentWidth: 3,
                    width: 3
                  }
                ],
              },
              {
                type: 'checkbox',
                input: true,
                weight: 20,
                key: 'reference',
                label: 'Save as reference',
                tooltip: 'Using this option will save this field as a reference and link its value to the value of the origin record.',
                defaultValue: true,
                hidden: true
              }
            ]
        },
        {
          label: 'Data',
          key: 'data',
          weight: 10,
          components:
            [
              EditFormUtils.javaScriptValue('Custom Default Value', 'customDefaultValue', 'customDefaultValue', 1000,
                '<p><h4>Example:</h4><pre>value = data.firstName + " " + data.lastName;</pre></p>',
                '<p><h4>Example:</h4><pre>{"cat": [{"var": "data.firstName"}, " ", {"var": "data.lastName"}]}</pre>'
              ),
              EditFormUtils.javaScriptValue('Calculated Value', 'calculateValue', 'calculateValue', 1100,
                '<p><h4>Example:</h4><pre>value = data.a + data.b + data.c;</pre></p>',
                '<p><h4>Example:</h4><pre>{"+": [{"var": "data.a"}, {"var": "data.b"}, {"var": "data.c"}]}</pre><p><a target="_blank" href="http://formio.github.io/formio.js/app/examples/calculated.html">Click here for an example</a></p>',
                '<tr><th>token</th><td>The decoded JWT token for the authenticated user.</td></tr>'
              ),
              {
                weight: 700,
                type: 'checkbox',
                label: 'Clear Value When Hidden',
                key: 'clearOnHide',
                defaultValue: true,
                tooltip: 'When a field is hidden, clear the value.',
                input: true
              }
            ]
        },
        {
          label: 'API',
          key: 'api',
          weight: 30,
          refreshOn: 'data',
          // redrawOn: 'data.key',
          redrawOn: 'data',
          // components: ComponentEditAPI
          components: [
            {
              weight: 0,
              type: 'textfield',
              input: true,
              key: 'key',
              label: 'Property Name',
              tooltip: 'The name of this field in the API endpoint.',
              validate: {
                pattern: '(\\w|\\w[\\w-.]*\\w)',
                patternMessage: 'The property name must only contain alphanumeric characters, underscores, dots and dashes and should not be ended by dash or dot.',
                required: true
              }
            },
            {
              weight: 100,
              type: 'tags',
              input: true,
              label: 'Field Tags',
              storeas: 'array',
              tooltip: 'Tag the field for use in custom logic.',
              key: 'tags',
              refreshOn: 'data',
              redrawOn: 'data',
              // defaultValue: ['ApprovalNode'],
              // customDefaultValue: ['ApprovalNode']
              customDefaultValue: (context) => {

                console.log('In Input select data', window.formdesign);
                // const URLArray = window.location.pathname.split('/');
                // // console.log(pathArray);
                // const urlmatrixstrings = URLArray[3].split(';');
                // const formId = urlmatrixstrings[0];
                // console.log(formId);
                // return formId;
              },
            },
            {
              weight: 200,
              type: 'datamap',
              label: 'Custom Properties',
              tooltip: 'This allows you to configure any custom properties for this component.',
              key: 'properties',
              valueComponent: {
                type: 'textfield',
                key: 'value',
                label: 'Value',
                placeholder: 'Value',
                input: true
              }
            }
          ]
        },
        {
          label: 'Conditional',
          key: 'conditional',
          weight: 40,
          components: ComponentEditConditional
        },
        {
          label: 'Logic',
          key: 'logic',
          weight: 50,
          components: ComponentEditLogic
        },
        {
          label: 'Layout',
          key: 'layout',
          weight: 60,
          components: ComponentEditLayout
        }
      ]
    }
    ]
  };
}
