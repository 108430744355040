import { Component, OnInit} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../auth/authentication.service';
import { whiteSpaceValidator } from '../shared/directives/whitespacevalidator.directive';
import { MustMatch } from '../shared/directives/mustmatchvalidator.directive';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css', '../login/login.component.css']
})
export class SignupComponent implements OnInit {
  public email: string;
  public errmsg: string;
  public resetToken: string;
  public user: string;
  public showpassword: boolean;
  public usertype: string;
  public domain: string;
  public signupform: FormGroup;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private authservice: AuthenticationService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.resetToken = params['token'];
      this.email = params['email'];
      this.domain = params['domain'];
    });
    this.signupform = this.fb.group(
      {
        firstname: ['', [Validators.required, whiteSpaceValidator()]],
        lastname: ['', [Validators.required, whiteSpaceValidator()]],
        password: ['', [Validators.required, whiteSpaceValidator()]],
        confirmpassword: ['', Validators.required]
      },
      {
        validator: MustMatch('password', 'confirmpassword')
      }
    );
  }
  signup() {
     this.authservice.signupintoUMP(this.resetToken, this.email, this.domain, this.signupform.value)
      .subscribe(
       (res: any) => {
          if (res.status === 201) {
              this.router.navigate(['login']);
           }
       },
       (error) => {
          this.errmsg = error.error.error;
           // console.log(res);
         }
     );
  }
}
