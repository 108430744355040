/*
import { OverlayRef } from '@angular/cdk/overlay';

export class iconPickerOverlayRef {
  constructor(private overlayRef: OverlayRef) {}

  close(): void {
    this.overlayRef.dispose();
  }
}
*/
import { OverlayRef } from '@angular/cdk/overlay';
import { IconpickerComponent } from './iconpicker.component';
import { Subject, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
export class iconPickerOverlayRef {
         private _beforeClose = new Subject<any | undefined>();
         private _afterClosed = new Subject<any | undefined>();
         //Result to be passed to afterClosed.
         private _result: any | undefined;
         constructor(private overlayRef: OverlayRef) {}
         componentInstance: IconpickerComponent;

         close(dialogResult?: any): void { 
           this._result = dialogResult;
           this.componentInstance.animationStateChanged
             .pipe(
               filter(event => event.phaseName === 'start'),
               take(1)
             )
             .subscribe(() => {
               this._beforeClose.next(dialogResult);
               this._beforeClose.complete();
               this.overlayRef.detachBackdrop();
             });

           this.componentInstance.animationStateChanged
             .pipe(
               filter(
                 event =>
                   event.phaseName === 'done' &&
                   event.toState === 'leave'
               ),
               take(1)
             )
             .subscribe(() => {
               this._afterClosed.next(this._result);
               this._afterClosed.complete();
               this.componentInstance = null!;
               this.overlayRef.dispose();
             });

           this.componentInstance.startExitAnimation();
         }
         afterClosed(): Observable<void> {
           return this._afterClosed.asObservable();
         }

         beforeClose(): Observable<void> {
           return this._beforeClose.asObservable();
         }
       }
