import { Component, OnInit, Inject, ViewChild, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormsService } from '../../../formio-core/forms-and-formsets.service';
import { MatSelectionList } from '@angular/material/list';

@Component({
  selector: 'app-select-dashboard',
  templateUrl: './select-dashboard.component.html',
  styleUrls: ['./select-dashboard.component.css']
})
export class SelectDashboardComponent implements OnInit {

  @HostListener('window:keyup.esc') onKeyUp() {
    this.closeoverlay(null);
  }
  dashboardList = [];
  errmsg: string;
  selectedForms: any;
  isNoDashboard = true;
  @ViewChild('selectedformitems')
  private selectedformitems: MatSelectionList;
  constructor(
    public dialogRef: MatDialogRef<SelectDashboardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formservice: FormsService
  ) { }

  ngOnInit(): void {
    this.getlisttoexport();
  }
  getlisttoexport() {
    this.formservice
      .getDashboardsForImport()
      .subscribe((response: any) => {
        console.log("responseobj = " + response)
        let responseobj = JSON.parse(response);
        if (responseobj.status === 'Success') {
          this.dashboardList = responseobj.dashboards;
          this.isNoDashboard = (this.dashboardList.length > 0) ? true : false;

        }
        else {
          this.errmsg = response.error ? response.error : "";
        }
      });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selectedformitems.selectedOptions.selected.length;
    const numRows = this.dashboardList.length;
    return numSelected === numRows;
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selectedformitems.selectedOptions.clear();
    } else {
      this.selectedformitems.selectAll();
    }
  }
  importselectedDashboard() {

    let selectedItemArr = []
    let selIds = this.selectedformitems.selectedOptions.selected;
    for (let i = 0; i < selIds.length; i++) {
      var index = this.dashboardList.findIndex(p => p.id === selIds[i].value);
      if (index > -1) {
        selectedItemArr.push(this.dashboardList[index])
      }
    }

    this.formservice
      .importDashboard(selectedItemArr)
      .subscribe((response: any) => {
        console.log("responseobj = " + response)
        let responseobj = JSON.parse(response);
        if (responseobj.error === "") {
          this.closeoverlay(responseobj.status);
        }
      });

  }

  closeoverlay(responseobj: any) {
    this.dialogRef.close(responseobj);
  }
}
