import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { UtilsService } from '../utils.service';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ReportDataService {

  public baseUrl: string;
  private UMP_URL: string;
  private UMP_URL_ATTACHMENT: string;

  public testJson;

  constructor(
    private http: HttpClient,
    private utilsservice: UtilsService
  ) {
    this.baseUrl = this.utilsservice.getUMPUrl();
    this.UMP_URL = `${this.baseUrl}/UMP/API/v2/applications/DIGITAL_FORMS/execute`;
    this.UMP_URL_ATTACHMENT = `${this.baseUrl}/UMP/API/v2/applications/DIGITAL_FORMS/attachments/`;
   }








  // search matching form in reports
  searchforms(query): Observable<any> {
    const paginationparams = {
      query: query,
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(paginationparams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_FORMS`,
      {},
      httpOptions
    );
  }


  // methods for reports page
  getFormFields(formId) {

    const inputparams = {
      formId: formId,
    };

    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputparams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_ALL_FORM_FIELDS`,
      {},
      httpOptions
    );

  }

  createQuery(queryobject) {
    const inputparams = {
      // id: "",
      name: queryobject.name,
      desc: queryobject.desc,
      query: queryobject.query,
      public: queryobject.public,
      favourite: queryobject.favourite,
      edit: queryobject.edit,
      formId: queryobject.formId,
      reportId: queryobject.reportId,
      queryJson: queryobject.queryJson,
      updateFavourite:  queryobject.updateFavourite,
      selectedColumns: queryobject.columns
    };
    //
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputparams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_SAVE_FORM_QUERIES`,
      {},
      httpOptions
    );
  }
  getAllCategories() {
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const httpOptions = {
      headers: headers
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_REPORT_CATEGORY`,
      {},
      httpOptions
    );
  }
  getAllQueries(criteria) {
    // const inputparamsjson = JSON.stringify(criteria);
    //
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(criteria);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_FORM_QUERIES`,
      {},
      httpOptions
    );
  }
  markFavorite(reportId: string, favourite: boolean) {
    const inputparams = {
      reportId: reportId,
      favourite: favourite,
      updateFavourite: true,
      edit: true
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputparams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_SAVE_FORM_QUERIES`,
      {},
      httpOptions
    );
  }
  deleteQuery(deleteParam) {
    const inputparams = {
      reportId: deleteParam.id,
      delete: deleteParam.delete
    };

    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputparams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_SAVE_FORM_QUERIES`,
      {},
      httpOptions
    );
  }

  getQueryUsingId(queryId) {
    const inputparams = {
      reportId: queryId
    };
    const inputparamsjson = JSON.stringify(inputparams);
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputparams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_FORM_QUERY`,
      {},
      httpOptions
    );
  }

  createNewreportCopy(objForReport) {
    const inputparams = {
      copy: objForReport.copy,
      copySrc: objForReport.copySrc
    };


    const inputparamsjson = JSON.stringify(inputparams);
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputparams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_SAVE_FORM_QUERIES`,
      {},
      httpOptions
    );
  }

  saveQuerytemporrarilyintoService(Json) {
    this.testJson = Json;
    //
  }

  getTestJsonFromService() {
    return this.testJson;
  }


  shareForReport(QUeryToBeShared) {
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(QUeryToBeShared);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_SAVE_FORM_QUERIES`,
      {},
      httpOptions
    );
  }

  deleteShareForReport(ShareToDelete) {
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(ShareToDelete);
    const httpOptions = {
      headers: headers,
      params: params
    };


    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_REMOVE_FORM_REPORT_SHARE`,
      {},
      httpOptions
    );
  }

  getScheduleById(scheduleId){
    const inputparams = {
      schdeuleId: scheduleId
    };

    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputparams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_FORM_REPORT_SCHEDULE`,
      {},
      httpOptions
    );

  }

  deleteSchedule(objToDelete) {
    const inputparams = {
      delete: objToDelete.delete,
      id:objToDelete.scheduleId
    };

    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputparams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_UPDATE_FORM_REPORT_SCHEDULE`,
      {},
      httpOptions
    );


  }


  getCsvReports(paramsRecieved) {
    const inputparams = {
      reportId: paramsRecieved.reportId,
      type:paramsRecieved.type,
      query: paramsRecieved.query
    };

    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputparams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_REPORT_AS_CSV_PDF`,
      {},
      httpOptions
    );


  }


  DownloadCsv(AttachId) {
    const attachmentid = AttachId;
    return this.http.get(this.UMP_URL_ATTACHMENT + attachmentid, {
      responseType: 'blob',
    });
  }


  getusers(
    filter: string,
    sortOrder: string,
    pageNumber: number,
    pageSize: number,
    excludeusers: any,
    teamid: string,
    excludeteamusers: boolean,
    newuseremail: string
  ): Observable<any> {
    const excludeusrstr = excludeusers.toString();
    //
    const inputParams = {
      limit: pageSize,
      offset: pageNumber,
      query: filter,
      sort_order: sortOrder,
      exclude: excludeusrstr,
      filter: {
        teamId: teamid,
        // excludeUsers: excludeteamusers,
        newUserEmail: newuseremail
      }
    };

    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_USERS`,
      {},
      httpOptions
    );
  }


    getteams(
    query,
    teams,
    sortOrder: string,
    pageNumber: number,
    pageSize: number,
    formids: any,
    createdteamid: string
  ): Observable<any> {
    const teamsarr = teams.toString();
    const inputParams = {
      limit: pageSize,
      offset: pageNumber,
      teams: teamsarr,
      query: query,
      sort_order: sortOrder,
      filter: {
        // formId: formids.formId,
        // formsetId: formids.formsetId,
        // newTeamId: createdteamid
      }
    };



    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };

    if (null !== query || teams === null || teams === '') {
      //
      return this.http
        .post<any>(
          `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_TEAMS`,
          {},
          httpOptions
        );
    }
  }
  getAuditLogs(submissionId: string) {
    const inputparams = {
      submissionId: submissionId
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputparams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_AUDIT_LOG`,
      {},
      httpOptions
    );

  }
  getAuditLogDetails(AuditLogId: string) {
    const inputparams = {
      auditId: AuditLogId
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputparams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_AUDIT_LOG_DATA`,
      {},
      httpOptions
    );
  }
}
