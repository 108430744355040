import { Components } from "angular-formio";
import _ from "lodash";
import { NewSmartDataEditForm } from "./new-smart-data.editForm";
const HTMLComponent = Components.components.htmlelement;
export default class SmartDataComponent extends HTMLComponent {
  constructor(component, options, data,) {
    super(component, options, data);
    // console.log(component);
  }

  static schema(...extend) {
    return HTMLComponent.schema(
      {
        label: "Smart Data Component",
        key: "sdc",
        type: "smartdata",
        tag: "h5",
        attrs: [
          {
            attr: "style",
            value: "padding: 15px;text-align: center;border: 2px dashed #ddd;",
          },
        ],
        content: "Smart Data Component",
        input: false,
        persistent: false,
        hidden: true,
      },
      ...extend
    );
  }
  get key() {
    return _.get(this.component, "key", "");
  }
  static get builderInfo() {
    return {
      title: "Smart Data",
      icon: "cloud",
      group: "premium",
      documentation: "/userguide/#smartdata",
      weight: 50,
      schema: SmartDataComponent.schema(),
    };
  }
  get defaultSchema() {
    return SmartDataComponent.schema();
  }
  get content() {
    if (this.builderMode) {
      return this.component.content;
    }
    const submission = _.get(this.root, "submission", {});
    return this.component.content
      ? this.interpolate(this.component.content, {
          metadata: submission.metadata || {},
          submission: submission,
          data: this.rootValue,
          row: this.data,
        })
      : "";
  }

  get singleTags() {
    return ["br", "img", "hr"];
  }

  renderContent() {
    const submission = _.get(this.root, "submission", {});
    return this.renderTemplate("html", {
      component: this.component,
      tag: this.component.tag,
      attrs: (this.component.attrs || []).map((attr) => {
        return {
          attr: attr.attr,
          value: this.interpolate(attr.value, {
            metadata: submission.metadata || {},
            submission: submission,
            data: this.rootValue,
            row: this.data,
          }),
        };
      }),
      content: this.component.label,
      singleTags: this.singleTags,
    });
  }

  render() {
    return super.render(this.renderContent());
  }

  attach(element) {
    this.loadRefs(element, { html: "single" });
    // console.log(this.refs.html);
    let _self = this;

    switch (this.component.dataSrc) {
      case "url":
        Formio.makeStaticRequest(
          this.interpolate(this.component.fetchapi.url),
          (this.component.fetchapi.method || "get").toUpperCase(),
          null,
          {
            headers: this.requestHeaders,
            noToken: !(
              this.component.fetchapi && this.component.fetchapi.authenticate
            ),
          }
        ).then(function (urldata) {
          // console.log(_self);
          _self.assign(urldata);
        });
        break;
      case "workflow":
        if (this.component.trigger.prefetch.init) {
          // console.log('SDC called on init', this.key);
          if (!this.builderMode) {
            this.executeworkflow(this.component, this.data, null);
          }
        }
        if (this.component.trigger.prefetch.triggerbycomponent) {
          this.on(
            this.key,
            (data) => {
              // console.log(data.detail);
              if (!this.builderMode) {
                this.executeworkflow(this.component, this.data, data.detail);
              }
            },
            true
          );
        }
        break;
    }
    return super.attach(element);
  }
  assign(DataSourcedata) {
    // console.log(DataSourcedata);
    if (this.component.trigger.prefetch.init) {
      this.data = DataSourcedata;
      this.emitcustomformioevent(
        this.component.trigger.postfetch.event,
        this.component,
        DataSourcedata
      );
    }
    if (this.component.trigger.prefetch.triggerbycomponent) {
      // this.on(this.component.trigger.prefetch.event, (data) => {
      this.data = DataSourcedata;
      this.emitcustomformioevent(
        this.component.trigger.postfetch.event,
        this.component,
        DataSourcedata
      );
      // }, true);
    }
  }
  executeworkflow(component, formdata, eventdata) {
    let nodecontainer = document.getElementsByClassName(
      `sdc-spinner`
    );
    // console.log(nodecontainer)
    if (nodecontainer && nodecontainer.length > 0) {
      nodecontainer[0].style.display = 'flex';
      nodecontainer[0].style.justifyContent = 'center';
    }
    let _self = this;
    let formId;
    let wfname;
    // console.log(component);
    if (component.fetchapi) {
      if (component.fetchapi.formId) {
        formId = component.fetchapi.formId;
      }
    } else {
      const URLArray = window.location.pathname.split("/");
      const urlmatrixstrings = URLArray[3].split(";");
      formId = urlmatrixstrings[0];
    }
    if (component.fetchapi) {
      if (component.fetchapi.workflowname) {
        wfname = component.fetchapi.workflowname;
      }
    }
    let inputparams = `formdata=${encodeURIComponent(
      JSON.stringify(formdata)
    )}`;
    if (eventdata) {
      if (typeof eventdata === "object") {
        inputparams =
          inputparams +
          `&eventdata=${encodeURIComponent(JSON.stringify(eventdata))}`;
      } else {
        inputparams =
          inputparams + `&eventdata=${encodeURIComponent(eventdata)}`;
      }
    }
    Formio.makeStaticRequest(
      `${localStorage.getItem(
        "UMP_url"
      )}/UMP/API/v2/applications/DIGITAL_FORMS/workflow/${wfname}?${inputparams}`,
      "POST",
      null,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
          "Content-type": "application/json",
        },
      }
    )
      .then(function (workflowdata) {
        if (nodecontainer && nodecontainer.length > 0) {
          nodecontainer[0].style.display = "none";
          nodecontainer[0].style.justifyContent = '';
        }
        if (workflowdata) {
          try{
          // console.log('Object Assign',Object.assign(_self.data,JSON.parse(workflowdata.data)));
          if(workflowdata.data !== ""){
            _self.data = Object.assign(_self.data, JSON.parse(workflowdata.data));
          }else{
            _self.data = Object.assign(_self.data, workflowdata.data);
          }
        }catch(err){
          if(workflowdata.data !== ""){
          _self.data = Object.assign(_self.data, JSON.parse(JSON.stringify(workflowdata.data)));
          }
        }
          _self.emitcustomformioevent(
            _self.component.trigger.postfetch.event,
            _self.component,
            _self.data
          );
        }
      })
      .catch((error) => {
        if (nodecontainer && nodecontainer.length > 0) {
          nodecontainer[0].style.display = "none";
          nodecontainer[0].style.justifyContent = '';
        }
        console.error("Error occured while fetching workflow data:", error);
        this.emitcustomformioevent(
          "WorkflowExecuteError",
          this.component,
          error
        );
      });
  }
  emitcustomformioevent(eventtype, component, data) {
    this.emit("customEvent", {
      type: eventtype,
      component: component,
      data: data
    });
    window.eventtype = data;
     this.emit(eventtype, {
      component: component,
      data: data
    });
    this.evaluate(this.component.trigger.postfetch.panel.javascript)
    let eventCustom = new CustomEvent('sdcDoneEvent',
            {
                detail: {
                  type: eventtype,
                  component: component,
                  data: data
                }
              });
            document.dispatchEvent(eventCustom);
  }
}
SmartDataComponent.requestHeaders = {
  get: function () {
    let _self = this,
      DataSourceHeaders = new Formio.Headers();
    if (this.component.fetchapi && this.component.fetchapi.headers) {
      try {
        this.component.fetchapi.headers.forEach(function (header) {
          header.key &&
            DataSourceHeaders.set(n.key, _self.interpolate(header.value));
        });
      } catch (e) {
        console.warn(e.message);
      }
    }
    return DataSourceHeaders;
  },
  enumerable: true,
  configurable: true,
};
SmartDataComponent.editForm = NewSmartDataEditForm;
// Register the component to the Formio.Components registry.
Components.addComponent("smartdata", SmartDataComponent);
