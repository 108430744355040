<ng-container>

  <!-- Filter -->
  <div *ngIf="isFilterable" [matColumnDef]="this.rowActionIcon">
    <!-- <mat-form-field>
      <mat-label>Search</mat-label>
    </mat-form-field> -->
  <div class="searchBox mat-elevation-z8">
      <button class="searchButton" href="#">
        <mat-icon class="material-icons">
          search
        </mat-icon> </button>
      <input class="searchInput" 
       type="text" name="" value="{{filter}}" (keyup)="applyFilter($event)"
        placeholder="Filter rows"> 
    </div>  

  </div>
  <!-- Pagination -->
  <mat-paginator *ngIf="isPageable" [pageSizeOptions]="paginationSizes" [pageSize]="defaultPageSize"
    [pageIndex]="pageIndex">
  </mat-paginator>
  <!-- Table -->
  <table mat-table [dataSource]="tableDataSource" matSort [matSortActive]="active" [matSortDirection]="direction"
    (matSortChange)="sortTable($event)">
    <ng-container *ngFor="let tableColumn of tableColumns" [matColumnDef]="tableColumn.name">

      <!-- if sortable column header -->
      <ng-container *ngIf="tableColumn.isSortable; else notSortable">
        <th mat-header-cell *matHeaderCellDef [mat-sort-header]="tableColumn.name"
          [arrowPosition]="tableColumn.position === 'right' ? 'before' : 'after'">
          {{tableColumn.name}}
        </th>
      </ng-container>
      <!-- else not sortable -->
      <ng-template #notSortable>
        <th mat-header-cell *matHeaderCellDef>
          <span *ngIf="tableColumn.dataKey !== 'avatar'">{{tableColumn.name}}</span>
          <span style="padding-left:25px;" *ngIf="tableColumn.dataKey === 'avatar'">{{tableColumn.name}}</span>
        </th>
      </ng-template>
      <!-- column data -->
      <td mat-cell *matCellDef="let element">
        <div [ngSwitch]="tableColumn.type">
          <div *ngSwitchCase="'icon'">
            <button mat-button color="primary">
              <mat-icon class="icon-margin">{{element | dataPropertyGetter: tableColumn.dataKey}}</mat-icon>
            </button>
          </div>
          <div *ngSwitchCase="'boolean'">
            <button *ngIf="tableColumn.dataKey !== 'favourite'" mat-icon-button
              [ngClass]="{'btn-success-color': element[tableColumn.dataKey], 'btn-error-color': !element[tableColumn.dataKey]}">
              <mat-icon>radio_button_checked</mat-icon>
            </button>
            <span *ngIf="tableColumn.dataKey === 'favourite'">
              <span *ngIf="element[tableColumn.dataKey]">
                <button mat-icon-button color="primary" class="margin" matTooltip="Remove from favorites"
                  (click)="emitRowAction('favorite',element, $event)" matTooltipPosition="above">
                  <mat-icon>star</mat-icon>
                </button>
              </span>
              <span *ngIf="!element[tableColumn.dataKey]">
                <button mat-icon-button color="" class="margin" matTooltip="Mark as favorite"
                  (click)="emitRowAction('favorite',element, $event)" matTooltipPosition="above">
                  <mat-icon>star_border</mat-icon>
                </button>
              </span>
              <button mat-icon-button color="" class="btn-success-color" (click)="emitRowAction('run',element, $event)"
                matTooltip="Run" matTooltipPosition="above">
                <mat-icon>play_arrow</mat-icon>
              </button>
            </span>
          </div>
          <div *ngSwitchCase="'dashboard'">
            <span style="margin-left: 5px;">
              <span *ngIf="element.favorite">
                <button mat-icon-button color="primary" class="margin" matTooltip="Remove from favorites"
                  (click)="emitRowAction('favorite',element, $event)" matTooltipPosition="above"
                  [disabled]="element.accessRole === 'Manager' && usr.ROLE === 'Admin'">
                  <mat-icon>star</mat-icon>
                </button>
              </span>
              <span *ngIf="!element.favorite">
                <button mat-icon-button color="" class="margin" matTooltip="Mark as favorite"
                  (click)="emitRowAction('favorite',element, $event)" matTooltipPosition="above"
                  [disabled]="element.accessRole === 'Manager' && usr.ROLE === 'Admin'">
                  <mat-icon>star_border</mat-icon>
                </button>
              </span>
            </span>
            <span *ngIf="tableColumn.dataKey === 'default'">
              <button mat-icon-button matTooltip="Default" matTooltipPosition="above"
                [ngClass]="{'btn-primary-color': element[tableColumn.dataKey], 'btn-error-color': !element[tableColumn.dataKey]}"
                (click)="emitRowAction('default',element, $event)"
                [disabled]="element.accessRole === 'Manager' && usr.ROLE === 'Admin'">
                <mat-icon>radio_button_checked</mat-icon>
              </button>
            </span>
          </div>
          <div *ngSwitchCase="'number'">
            <button mat-mini-fab color="primary">
              {{element | dataPropertyGetter: tableColumn.dataKey}}
            </button>
          </div>
          <div *ngSwitchCase="'list'">
            <span class="row-badge {{element[tableColumn.dataKey] }}">{{element | dataPropertyGetter:
              tableColumn.dataKey}}</span>
          </div>
          <div *ngSwitchCase="'masterdatawf'">
            <span *ngIf="element[tableColumn.dataKey].length > 0">
              <span *ngFor="let workflow of element[tableColumn.dataKey]">
                <button *ngIf="workflow.readWF" class="master-data-btn" mat-mini-fab color="primary">
                  <mat-icon class="master-data-icons" (click)="navigateToMasterData($event, workflow, element.formId)"
                    [matTooltip]="workflow.wfTitle">
                    fact_check
                  </mat-icon>
                </button>
                <span *ngIf="!workflow.readWF">
                  <button class="master-data-btn" mat-mini-fab color="primary">
                    <mat-icon class="master-data-icons" (click)="navigateToMasterData($event, workflow, element.formId)"
                      [matTooltip]="workflow.wfTitle">
                      post_add
                    </mat-icon>
                  </button> |
                  <button class="master-data-btn" mat-mini-fab color="primary">
                    <mat-icon class="master-data-icons" (click)="emitRowAction('schedule', element, $event)"
                      matTooltip="Set Schedule of Workflow">
                      pending_actions
                    </mat-icon>
                  </button>
                </span>
              </span>
            </span>
          </div>
          <div *ngSwitchCase="'interactive_icons'">
            <span *ngIf="element[tableColumn.dataKey] === '1'">
              <button mat-mini-fab color="" class="interactive_icons btn-error-color" matTooltip="Pause"
                (click)="emitRowAction('interactive_icons',element, $event)" matTooltipPosition="above">
                <mat-icon>pause</mat-icon>
              </button>
            </span>
            <span *ngIf="element[tableColumn.dataKey] === '0'">
              <button mat-mini-fab color="" class="interactive_icons btn-success-color"
                (click)="emitRowAction('interactive_icons',element, $event)" matTooltip="Resume"
                matTooltipPosition="above">
                <mat-icon>play_arrow</mat-icon>
              </button>
            </span>
          </div>

          <div *ngSwitchCase="'dashboard_role_icons'">
            <!-- <span style="margin-left: 5px;" > -->
            <span *ngIf="element[tableColumn.dataKey].includes('Admin')" style="margin-left: 5px;">
              <button mat-icon-button color="primary" class="margin" matTooltip="Admin" matTooltipPosition="above">
                <mat-icon>supervisor_account</mat-icon>
              </button>
            </span>
            <span *ngIf="element[tableColumn.dataKey].includes('Manager')" style="margin-left: 5px;">
              <button mat-icon-button color="primary" class="margin" matTooltip="Manager" matTooltipPosition="above">
                <mat-icon>person</mat-icon>
              </button>
            </span>
            <!-- </span> -->
          </div>
          <div *ngSwitchCase="'form_type_icon'">
            <!-- <span style="margin-left: 5px;" > -->
            <span *ngIf="element[tableColumn.dataKey].includes('form')" style="margin-left: 5px;">
              <button mat-icon-button color="primary" class="margin" matTooltip="forms" matTooltipPosition="above">
                <mat-icon>description</mat-icon>
              </button>
            </span>
            <span *ngIf="element[tableColumn.dataKey].includes('masterdata')" style="margin-left: 5px;">
              <button mat-icon-button color="primary" class="margin" matTooltip="Master Data" matTooltipPosition="above">
                <mat-icon>widgets</mat-icon>
              </button>
            </span>
            <!-- </span> -->
          </div>
          <div *ngSwitchDefault>
            {{element | dataPropertyGetter: tableColumn.dataKey}}
          </div>
        </div>
      </td>
    </ng-container>
    <!-- action column -->
    <ng-container *ngIf="rowActionIcon?.length" [matColumnDef]="rowActionIcon">
      <th mat-header-cell *matHeaderCellDef>Option's</th>
      <td mat-cell *matCellDef="let element" [id]="rowActionIcon">
        <div *ngIf="!element.system && isDashBoardPage && usr.ROLE === 'Admin'">
          <button mat-mini-fab color="" [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
            <mat-icon>{{rowActionIcon}}</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <span *ngFor="let action of rowActions">
              <button mat-menu-item (click)="emitRowAction(action.key,element)">
                <mat-icon>{{action.icon}}</mat-icon>
                <span>{{action.label}}</span>
              </button>
            </span>
            <!-- <button mat-menu-item (click)="emitRowAction('update',element)">
            <mat-icon>edit</mat-icon>
            <span>Update</span>
          </button>
          <button mat-menu-item (click)="emitRowAction('delete',element)">
            <mat-icon>delete</mat-icon>
            <span>Delete</span>
          </button> -->
          </mat-menu>
        </div>
        <div *ngIf="!isDashBoardPage">
          <button mat-mini-fab color="" [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
            <mat-icon>{{rowActionIcon}}</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <span *ngFor="let action of rowActions">
              <button mat-menu-item (click)="emitRowAction(action.key,element)">
                <mat-icon>{{action.icon}}</mat-icon>
                <span>{{action.label}}</span>
              </button>
            </span>
            <!-- <button mat-menu-item (click)="emitRowAction('update',element)">
            <mat-icon>edit</mat-icon>
            <span>Update</span>
          </button>
          <button mat-menu-item (click)="emitRowAction('delete',element)">
            <mat-icon>delete</mat-icon>
            <span>Delete</span>
          </button> -->
          </mat-menu>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="navigateToNext(row)"></tr>
  </table>
</ng-container>