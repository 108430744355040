import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PopupService {
  public base_popupurl = 'https://umpdev.unvired.io/UMP/sso/saml';
  public company = 'UNVIRED';
  public application = 'DIGITAL_FORMS';
  public redirecturl = this.getRedirectUrlPath();
  public device = 'browser';
  public popupurl;
  public popupOptions = {
    width: 600,
    height: 600,
    top: window.screenY + ((window.outerHeight - 600) / 2.5),
    left: window.screenX + ((window.outerWidth - 600) / 2)
  };
  constructor() { }
  openPopup (popuptype: string) {
   if (popuptype === 'login') {
    this.popupurl = `${this.base_popupurl}/dologin?company=${this.company}&application=${this.application}&device=${this.device}&redirect=${this.redirecturl}`;
   } else if (popuptype === 'logout') {
    const token = localStorage.getItem('token');
    this.popupurl = `${this.base_popupurl}/dologout?token=${token}&redirect=${this.redirecturl}`;

   } else {
     console.log('popup type missing');
   }
    try {
      const popup = window.open(this.popupurl, '_blank', this._stringifyOptions());
      if (popup && popup.focus) {
        popup.focus();
      }
      if (this.popupurl === 'about:blank') {
        popup.document.body.innerHTML = 'Loading...';
      }
      this.pollPopup(popup, popuptype).then(
        (params: URLSearchParams) => {
            if (popuptype === 'login') {
              const token = params.get('token');
              localStorage.setItem('token', token);
              localStorage.setItem('login_type', 'SSO');
              window.location.reload();
            } else {
              localStorage.removeItem('token');
              localStorage.removeItem('login_type');
              window.location.reload();
            }
        }
        ).catch(
          error => {
            console.log(error);
          }
        );

    } catch (e) {
      console.log('Error opening popup:', e);
      // return throwError(new Error('OAuth popup error occurred'))
    }
  }
  pollPopup(window, popuptype) {
    return new Promise((resolve, reject) => {
        const polling = setInterval(() => {
        if (!window || window.closed || window.closed === undefined) {
          clearInterval(polling);
          reject(new Error('The popup window was closed'));
        }
        try {
          if (popuptype === 'logout'){
            localStorage.removeItem('token');
            localStorage.removeItem('login_type');
          }
          const popupUrlPath = window.location.protocol + '//' + window.location.host + window.location.pathname;
          if (popupUrlPath === this.redirecturl) {
            if (window.location.search) {
              const urlParams = new URLSearchParams(window.location.search);
              const error = urlParams.get('error');
              if (error) {
                window.document.write(error + '<br />' + 'Please quit browser or clear recent history and Try again.');
              } else {
                resolve(urlParams);
                clearInterval(polling);
                window.close();
              }
            } else {
              reject(new Error('OAuth redirect has occurred but no query parameters were found.'));
            }
          }
        } catch (error) {
            // Ignore DOMException: Blocked a frame with origin from accessing a cross-origin frame.
            // A hack to get around same-origin security policy errors in Internet Explorer.
        }
      }, 500);
    });
  }
  /*
  getDevice() {
    const userAgent = navigator.userAgent || navigator.vendor;
      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
          return 'windows';
      }
      if (/android/i.test(userAgent)) {
          return 'android';
      }
      if (/iPad/i.test(userAgent) && !window.MSStream) {
          return 'ipad';
      }
      if (/iPhone/.test(userAgent) && !window.MSStream) {
        return 'iphone';
      }
      return 'browser';
  }
  */
 
  getRedirectUrlPath() {
    return location.protocol + '//' + location.hostname +
      (location.port ? (':' + (location.port || '') + '/') : '/') +
      'ssologin';
  }
  _stringifyOptions() {
    const options = [];
    for (const optionKey in this.popupOptions) {
      if (!this.isUndefined(this.popupOptions[optionKey])) {
        options.push(`${optionKey}=${this.popupOptions[optionKey]}`);
      }
    }
    return options.join(',');
  }
  isUndefined(value) {
    return typeof value === 'undefined';
  }
}
