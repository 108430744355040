import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormsService } from '../formio-core/forms-and-formsets.service';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-public-shred-forms-info',
  templateUrl: './public-shred-forms-info.component.html',
  styleUrls: ['./public-shred-forms-info.component.css']
})
export class PublicShredFormsInfoComponent implements OnInit {

  public searchpublicforms: FormGroup;
  public formid: string;
  public shareid: string;
  public maxDate = new Date();
  public errmsg: string;
  form: any;
  shareDesription: string;
  public dataSourcepublicforms = [];
  public displayedColumns: string[] = [
    'submissionDate',
    'submissionId',
  ];
  // MatPaginator Inputs
  length: any;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  pageIndex = 0;
  // MatPaginator Output
  pageEvent: PageEvent;
  constructor(
    private fb: FormBuilder,
    private formservice: FormsService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.formid = this.route.snapshot.params.formId;
    this.shareid = this.route.snapshot.params.shareId;
    this.shareDesription = this.route.snapshot.params.shareDesription;
    this.searchpublicforms = this.fb.group({
      startDate: [new Date(), Validators.required],
      endDate: [new Date(), Validators.required]
    });
    this.formservice.getform(this.formid, '').subscribe((form: any) => {
      this.form = JSON.parse(form);
    });
    this.route.queryParams.subscribe(searchparams => {
      console.log(searchparams, typeof searchparams);
      if (Object.entries(searchparams).length !== 0 && searchparams.constructor === Object) {
        this.getpublicsharedformsinfo(this.formid, this.shareid, searchparams,  this.pageSize, this.pageIndex);
      }
    });
  }
  getpublicsharedformsinfo(formid: string, shareid: string, searchform: any, pageSize: number, pageIndex: number) {
    this.router.navigate([], { relativeTo: this.route, queryParams: searchform });
    const serachobj = {};
    // console.log(searchform);
    const startDate = new Date(searchform.startDate);
    startDate.setHours(0, 0, 0, 0);
    const endDate = new Date(searchform.endDate);
    endDate.setHours(23, 59, 59, 999);
    // console.log(startDate, endDate);
    serachobj['startDate'] = startDate;
    this.searchpublicforms.get('startDate').setValue(startDate);
    serachobj['endDate'] = endDate;
    this.searchpublicforms.get('endDate').setValue(endDate);
    this.formservice.getpublicsharedformsinfo(formid, shareid, pageSize, pageIndex, serachobj)
    .subscribe( res => {
      // console.log(res);
      const publicforms = JSON.parse(res);
      this.length = publicforms.totalRecords;
      this.pageIndex = publicforms.nextOffSet;
      this.dataSourcepublicforms = publicforms.data;
    });
  }
  pageevent(event) {
    // console.log(event);
    this.getpublicsharedformsinfo(this.formid, this.shareid,
      this.searchpublicforms.value, event.pageSize, event.pageIndex * event.pageSize);
  }
}
