import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams
} from '@angular/common/http';
import {Subject, BehaviorSubject, Observable } from 'rxjs';
import { UtilsService } from '../utils.service';
import { CustomEncoder } from '../shared/classes/customencoder';
// import { Subject} from 'rxjs';
import * as moment from 'moment';
import { utf8Encode } from '@angular/compiler/src/util';
import { encode } from 'querystring';
@Injectable({
  providedIn: 'root',

})
export class FormsService {
  public baseUrl: string;
  private UMP_URL: string;
  private UMP_URL_ATTACHMENT: string;

  public testJson;
  public companySettings: BehaviorSubject<any>;



  constructor(

    private http: HttpClient,
    private utilsservice: UtilsService
  ) {
    this.baseUrl = this.utilsservice.getUMPUrl();
    this.UMP_URL = `${this.baseUrl}/UMP/API/v2/applications/DIGITAL_FORMS/execute`;
    this.UMP_URL_ATTACHMENT = `${this.baseUrl}/UMP/API/v2/applications/DIGITAL_FORMS/attachments/`;
    this.companySettings = new BehaviorSubject({});

  }
  getforms(
    query,
    formFilter: object,
    pageNumber: number,
    pageSize: number,
    selectedforms: string[]
  ): Observable<any> {
    // console.log(formFilter);
    formFilter['selectedForm'] = selectedforms.toString();

    const inputParams = {
      limit: pageSize,
      offset: pageNumber,
      query: query,
      formFilter: formFilter,
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_FORMS`,
      {},
      httpOptions
    );
  }
  getformsets(
    query,
    pageNumber: number,
    pageSize: number,
    formid: string,
    teamid: string,
    formsets: any
  ): Observable<any> {
    // console.log(formFilter);
    const filter = {};
    filter['formId'] = formid;
    filter['teamId'] = teamid;
    filter['excludeFormset'] = formsets.toString();
    const inputParams = {
      limit: pageSize,
      offset: pageNumber,
      query: query,
      filter: filter,
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_FORMSET`,
      {},
      httpOptions
    );
  }
  getallforms(selectedCategory: string, formsearchquery?: string): Observable<any> {
    const inputParams = {
      category: selectedCategory,
      // searchTerm: formsearchquery,
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers, 
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_ALL_FORMS`,
      {},
      httpOptions
    );
  }
  createform(createdform, selectedicon, typedata): Observable<any> {
    let Create_API_To_Call = 'DIGITAL_FORMS_PA_CREATE_FORM';
    let copyformId = null;
    if (typedata.iscopyform) {
      copyformId = typedata.form.formId;
    }
    // console.log(typedata.form.formId);
    if (typedata.type === 'masterform') {
      Create_API_To_Call = 'DIGITAL_FORMS_PA_CREATE_RESOURCE';
    }
    console.log(createdform);
    
    const inputParams = {
      title: createdform.title,
      name: createdform.shortname,
      description: createdform.description,
      avatar: selectedicon,
      usage: createdform.usage,
      formtype: createdform.formtype,
      type: typedata.type,
      copySource: copyformId,
      tags: createdform.formCategory,
    };
    console.log(inputParams);
    
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/${Create_API_To_Call}`,
      {},
      httpOptions
    );
  }

  updateform(createdform, selectedicon, typedata): Observable<any> {

    const inputParams = {
      title: createdform.title,
      name: createdform.shortname,
      formId: typedata.form.formId,
      description: createdform.description,
      avatar: selectedicon,
      usage: createdform.usage,
      formtype: createdform.formtype,
      type: typedata.type,
      tags: createdform.formCategory,
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_UPDATE_FORM_INFO`,
      {},
      httpOptions
    );
  }
  getform(formid, input) {
    const inputParams = {
      formId: formid,
      input: input,
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_FORM`,
      {},
      httpOptions
    );
  }

  getformAttr(formid, type) {
    const inputParams = {
      formId: formid,
      input: 'attributes',
      attribType:  type
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_FORM`,
      {},
      httpOptions
    );
  }
  getformdata(formid, type) {
    // console.log(type);
    const inputParams = {
      formId: formid,
      type: type,
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_FORM_DATA`,
      {},
      httpOptions
    );
  }

  getformDataWithMasterData(formid, type, includeMasterData) {
    // console.log(type);
    const inputParams = {
      formId: formid,
      type: type,
      includeMasterData: includeMasterData,
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_FORM_DATA`,
      {},
      httpOptions
    );
  }
  getMasterData(searchquery) {
    const inputParams = {
      query: searchquery,
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_RESOURCES`,
      {},
      httpOptions
    );
  }
  getmasterformcomponents(formid, type) {
    const inputParams = {
      formId: formid,
      type: type,
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_RESOURCE_DATA`,
      {},
      httpOptions
    );
  }
  getresourceDetails(resourceformname, resourceType: string, pageSize: number, pageIndex: number) {
    const inputParams = {
      name: resourceformname,
      type: resourceType,
      limit: pageSize,
      offSet: pageIndex
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_SUBMISSION`,
      {},
      httpOptions
    );
  }
  getresourceformdata(resourceinfo) {
    const inputParams = resourceinfo;
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_SUBMISSION_DATA`,
      {},
      httpOptions
    );
  }
  createsubmissiondata(formid, formname, subdata) {
    const inputParams = {
      formid: formid,
      name: formname,
      submissionData: subdata,
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_CREATE_SUBMISSION`,
      {},
      httpOptions
    );
  }
  updatesubmissiondata(formname, type, subid, subdata) {
    const inputParams = {
      name: formname,
      type: type,
      submissionId: subid,
      submissionData: subdata,
    };
    const headers = this.utilsservice.setBasicHeaders('application/x-www-form-urlencoded');
    const body = this.utilsservice.setBody(inputParams);
    const httpOptions = {
      headers: headers
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_UPDATE_SUBMISSION`,
      body,
      httpOptions
    );
  }
  getallformsets() {
    const inputParams = {};
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_FORMSET`,
      {},
      httpOptions
    );
  }

  createformset(formsetform, selectedicon) {
    const inputParams = {
      name: formsetform.title,
      help: formsetform.description,
      avatar: selectedicon,
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_CREATE_FORM_SET`,
      {},
      httpOptions
    );
  }
  updateformset(formsetid, formsetform, icon) {
    // console.log(formsetid);
    const inputParams = {
      id: formsetid,
      name: formsetform.name,
      help: formsetform.description,
      avatar:icon
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_UPDATE_FORMSET`,
      {},
      httpOptions
    );
  }
  getformset(formsetid) {
    const inputParams = {
      formsetId: formsetid,
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_FORMSET_DETAILS`,
      {},
      httpOptions
    );
  }
  getteams_for_forms(formid, formsetid) {
    const inputParams = {
      formId: formid,
      formsetId: formsetid,
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_TEAMS_FOR_FORMS`,
      {},
      httpOptions
    );
  }
  getreviewers(id, type, isformorformset, formorformsetname, reviewertype) {
    const inputParams = {
      id: id,
      type: type,
      formOrFormsetNameGiven: isformorformset,
      formOrFormsetName: formorformsetname,
      reviewerType: reviewertype,
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_USER_AND_TEAMS_FOR_REVIEW`,
      {},
      httpOptions
    );
  }
  assignFormToformset(formnames, formsetnames) {
    // console.log(formsetid);
    const inputParams = {
      formId: formnames.toString(),
      formsetId: formsetnames.toString(),
      mandatory: '1',
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_ASSIGN_FORM_TO_FORMSET`,
      {},
      httpOptions
    );
  }
  assignFormToteam(
    formnames,
    teamid,
    formtoggleoptions,
    formsetnames,
    sharedusers,
    reviewuser,
    reviewteam
  ) {
    let formids, formsetids;
    if (formnames) {
      formids = formnames.toString();
    } else {
      formids = formnames;
    }
    if (formsetnames) {
      formsetids = formsetnames.toString();
    } else {
      formsetids = formsetnames;
    }
    const inputParams = {
      formId: formids,
      formsetId: formsetids,
      teamId: teamid,
      reviewReqd: formtoggleoptions.enableReview,
      shared: formtoggleoptions.enableShared,
      sharedusers: sharedusers,
      handoff: formtoggleoptions.enableShiftHandoff,
      reviewUser: reviewuser,
      reviewTeam: reviewteam,
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_ASSIGN_TEAM_TO_FORM`,
      {},
      httpOptions
    );
  }
  assignTeamsToform(
    formids,
    forms,
    formtoggleoptions,
    sharedusers,
    reviewuser,
    reviewteam
  ) {
    const formsstr = forms.toString();
    const inputParams = {
      formId: formids.formId,
      formsetId: formids.formsetId,
      teamId: formsstr,
      reviewReqd: formtoggleoptions.enableReview,
      shared: formtoggleoptions.enableShared,
      sharedusers: sharedusers,
      handoff: formtoggleoptions.enableShiftHandoff,
      reviewUser: reviewuser,
      reviewTeam: reviewteam,
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_ASSIGN_FORM_TO_TEAM`,
      {},
      httpOptions
    );
  }
  deleteform(formId) {
    const inputParams = {
      formId: formId,
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_ARCHIVE_FORM`,
      {},
      httpOptions
    );
  }
  deleteresource(formId) {
    const inputParams = {
      formId: formId,
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_ARCHIVE_RESOURCE`,
      {},
      httpOptions
    );
  }
  removeFormfromFormset(formId, formsetid) {
    const inputParams = {
      formId: formId,
      formsetId: formsetid,
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_REMOVE_FORM_FROM_FORMSET`,
      {},
      httpOptions
    );
  }
  updateFormofFormset(formId: string, formsetid: string, ismandatory: boolean) {
    const inputParams = {
      formId: formId,
      formsetId: formsetid,
      mandatory: ismandatory,
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_UPDATE_FORM_OF_FORMSET`,
      {},
      httpOptions
    );
  }
  deleteformset(formsetId) {
    const inputParams = {
      formsetId: formsetId,
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_DELETE_FORM_SET`,
      {},
      httpOptions
    );
  }
  createCategory(Category:any,associatedApps:any): Observable<any> {
    const inputParams = {
      categoryName: Category,
      associatedApps:associatedApps
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_CREATE_FORM_CATEGORY`,
      {},
      httpOptions
    );
  }
  /*
  public format(date: Date): string {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      return year + '-' + this._to2digit(month) + '-' + this._to2digit(day);
      // return this._to2digit(day) + '/' + this._to2digit(month) + '/' + year;
  }

  private _to2digit(n: number) {
    return ('00' + n).slice(-2);
  }
  */
  createschedule(
    formids,
    teams,
    scheduletype,
    enddate,
    scheduledata,
    taskpriority,
    duedays,
    selectedScheduletype,
    selectedWorkflow,
  ): Observable<any> {
    // console.log(enddate, Math.floor((new Date()).getTime() / 1000));
    if (enddate) {
      scheduledata.pickervalue = enddate.getTime();
      enddate = enddate.getTime();
    }
    const inputParams = {
      formId: formids.formid,
      taskpriority: taskpriority,
      duedays: duedays,
      formsetId: formids.formsetid,
      type: scheduletype,
      startDate: new Date().getTime(),
      endDate: enddate,
      teams: teams,
      scheduleData: scheduledata,
      scheduleMode : selectedScheduletype,
      workflowId: selectedWorkflow,
    };
    // console.log("form schedule",inputParams);
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_CREATE_SCHEDULE`,
      {},
      httpOptions
    );
  }

  createscheduleforreports(
    reportadata,
    teams,
    users,
    externalEmails,
    scheduletype,
    fileType,
    orientation,
    emailMessage,
    enddate,
    scheduledata,

    // taskpriority,
    duedays
  ): Observable<any> {
    // console.log(enddate, Math.floor((new Date()).getTime() / 1000));
    if (enddate) {
      scheduledata.pickervalue = enddate.getTime();
      enddate = enddate.getTime();
    }
    const inputparams = {
      reportId: reportadata.data.reportId,
      // taskpriority: taskpriority,
      duedays: duedays,
      type: scheduletype,
      users:users,
      externalEmails:externalEmails,
      startDate: new Date().getTime(),
      endDate: enddate,
      scheduleMessage:emailMessage,
      teams: teams,
      fileType:fileType,
      fileOrientation:orientation,
      scheduleData: scheduledata,
    };
    console.log("Sent  to server for schedule",inputparams);
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputparams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_CREATE_FORM_REPORT_SCHEDULE`,
      {},
      httpOptions
    );
  }


  updatescheduleforreport(
    reportadata,
    teams,
    users,
    externalEmails,
    scheduletype,
    fileType,
    orientation,
    emailMessage,
    enddate,
    scheduledata,

    // taskpriority,
    duedays
  ): Observable<any> {
    if (enddate) {
      scheduledata.pickervalue = enddate.getTime();
      enddate = enddate.getTime();
    }
    const inputparams = {
      id : reportadata.data.scheduleId,
      reportId: reportadata.data.reportId,
      // taskpriority: taskpriority,
      duedays: duedays,
      type: scheduletype,
      users:users,
      externalEmails:externalEmails,
      startDate: new Date().getTime(),
      endDate: enddate,
      scheduleMessage:emailMessage,
      teams: teams,
      fileType:fileType,
      fileOrientation:orientation,
      scheduleData: scheduledata,
    };
    console.log(inputparams);
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputparams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_UPDATE_FORM_REPORT_SCHEDULE`,
      {},
      httpOptions
    );
  }
  updateschedule(
    scheduleid,
    formids,
    teams,
    scheduletype,
    enddate,
    scheduledata,
    taskpriority,
    duedays,
    selectedScheduletype, 
    selectedWorkflow,
  ): Observable<any> {
    if (enddate) {
      scheduledata.pickervalue = enddate.getTime();
      enddate = enddate.getTime();
    }
    const inputParams = {
      id: scheduleid,
      taskpriority: taskpriority,
      duedays: duedays,
      formId: formids.formid,
      formsetId: formids.formsetid,
      type: scheduletype,
      startDate: new Date().getTime(),
      endDate: enddate,

      teams: teams,
      scheduleData: scheduledata,
      scheduleMode: selectedScheduletype,
      workflowId: selectedWorkflow,
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_UPDATE_SCHEDULE`,
      {},
      httpOptions
    );
  }
  getschedule(scheduleid) {
    const inputParams = {
      schdeuleId: scheduleid,
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_SCHEDULE`,
      {},
      httpOptions
    );
  }
  savetranslations(formid: string, translations: any) {
    const inputParams = {
      formId: formid,
      translations: translations,
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_SAVE_TRANSLATIONS`,
      {},
      httpOptions
    );
  }
  deteteschedule(scheduleids) {
    const inputParams = {
      schdeuleId: scheduleids,
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_DELETE_SCHEDULE`,
      {},
      httpOptions
    );
  }
  saveform(formId, formname, formdetails, form, formtype) {
    //  form=JSON.stringify(form);
    // form=encodeURI(form)
    console.log(form);
    
    const inputParams = {
      formId: formId,
      name: formname,
      description: formdetails.formdescription,
      avatar: formdetails.icon,
      content: form,
      type: formtype,
    };
    const headers = this.utilsservice.setBasicHeaders('application/x-www-form-urlencoded');
    const body = this.utilsservice.setBody(inputParams);

    const httpOptions = {
      headers: headers
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_UPDATE_FORM`,
      body,
      httpOptions
    );
  }
  masterdateupdate(formId, formname, formdescription, icon, formtype) {
    const inputParams = {
      formId: formId,
      name: formname,
      description: formdescription,
      avatar: icon,
      // content: ' ',
      type: formtype,
    };
    const headers = this.utilsservice.setBasicHeaders('application/x-www-form-urlencoded');
    const body = this.utilsservice.setBody(inputParams);
console.log(inputParams);

    const httpOptions = {
      headers: headers
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_UPDATE_FORM`,
      body,
      httpOptions
    );
  }
  publishform(formId, publishform) {
    const inputParams = {
      formId: formId,
      comments: publishform.comments,
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_PUBLISH_FORM`,
      {},
      httpOptions
    );
  }
  deteteformsetassignments(formsetid, assignmentids) {
    const assignments = assignmentids.toString();
    const inputParams = {
      formId: formsetid,
      teamId: assignments,
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_REMOVE_FORMSET_FROM_TEAM`,
      {},
      httpOptions
    );
  }
  deteteformassignments(formid, assignmentids) {
    const assignments = assignmentids.toString();
    const inputParams = {
      formId: formid,
      teamId: assignments,
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_REMOVE_FORM_FROM_TEAM`,
      {},
      httpOptions
    );
  }
  getassignmentdetails(assignmentId) {
    const inputParams = {
      assignmentId: assignmentId,
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_ASSIGNMENT_DETAILS`,
      {},
      httpOptions
    );
  }
  updateassignmentdetails(
    assignmentId,
    formtoggleoptions,
    sharedusers,
    reviewuser,
    reviewteam
  ) {
    const inputParams = {
      assignmentId: assignmentId,
      reviewReqd: formtoggleoptions.enableReview,
      sharedusers: sharedusers,
      shared: formtoggleoptions.enableShared,
      handoff: formtoggleoptions.enableShiftHandoff,
      reviewUser: reviewuser,
      reviewTeam: reviewteam,
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_UPDATE_ASSIGNMENT`,
      {},
      httpOptions
    );
  }
  updateschedulerunstatus(scheduleId, status) {
    const inputParams = {
      id: scheduleId,
      runStatus: status,
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_UPDATE_SCHEDULE`,
      {},
      httpOptions
    );
  }
  runtaskimmediately(scheduleId) {
    const inputParams = {
      schdeuleId: scheduleId,
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams,'false','externalReference');
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_RUN_INSTANT_SCHEDULE`,
      {},
      httpOptions
    );
  }
  saveattributes(formid: string, keyvalueform: any, attribType:string) {
    const inputParams = {
      formId: formid,
      attributes: keyvalueform.attributes,
      attribType:attribType
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_SAVE_ATTRIBUTES`,
      {},
      httpOptions
    );
    
  }
  getreports(searchsubmissionsform, pagesize, pageindex) {
    //   if (searchsubmissionsform.createdDate && typeof searchsubmissionsform.createdDate !== 'string') {
    //   searchsubmissionsform.createdDate = this.format(searchsubmissionsform.createdDate);
    // }
    //   if (searchsubmissionsform.submissionDate && typeof searchsubmissionsform.submissionDate !== 'string') {
    //   searchsubmissionsform.submissionDate = this.format(searchsubmissionsform.submissionDate);
    // }
    const inputParams = {
      createdDate: Date.parse(searchsubmissionsform.createdDate),
      submissionDate: Date.parse(searchsubmissionsform.submissionDate),
      formName: searchsubmissionsform.formName,
      teamId:searchsubmissionsform.sumbittedTeamName,
      submittedBy: searchsubmissionsform.submittedBy,
      taskStatus: searchsubmissionsform.taskStatus,
      delayedSubmission: searchsubmissionsform.delayedSubmission,
      // category:searchsubmissionsform.formCategory,
      formCategory:searchsubmissionsform.formCategory,
      runStatus: status,
      limit: pagesize,
      offset: pageindex,
      query: searchsubmissionsform.query
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_SUBMISSION_REPORT`,
      {},
      httpOptions
    );
  }

// Just for development
  getreportsTemporary(searchsubmissionsform, pagesize, pageindex) {

    // let newJSON = this.newMthod(searchsubmissionsform.queryJson);
    // console.log(newJSON);


    const inputparams = {
      // id: "",
      createdDate: 1596220200000,
      submissionDate: 1602008999000,
      name: searchsubmissionsform.name,
      desc: searchsubmissionsform.desc,
      query: searchsubmissionsform.query,
      public: searchsubmissionsform.public,
      favourite: searchsubmissionsform.favourite,
      edit: searchsubmissionsform.edit,
      formId: searchsubmissionsform.formId,
      reportId: searchsubmissionsform.reportId,
      queryJson: searchsubmissionsform.queryJson,
      selectedColumns: searchsubmissionsform.selectedColumns,
      preview : searchsubmissionsform.preview
    };
    console.log(inputparams);




    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputparams);

    const httpOptions = {
      headers: headers,
      params: params
    };

    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_RUN_SUBMISSION_REPORT`,
      {},
      httpOptions
    );
  }


  newMthod(JsonToChangeTimeFromMomentToString) {
    JsonToChangeTimeFromMomentToString.rules.forEach((element: any) => {
      if (element.rules) {
       return this.newMthod(element);
      } else if (element.field) {


        if (Array.isArray(element.value) && typeof element.value[0] !== 'number' ) {
          // console.log(element,  ' ==> its an array');

          element.value[0] = moment(element.value[0]).format('DD-MM-YYYY');
          element.value[1] = moment(element.value[1]).format('DD-MM-YYYY');
          // console.log(element.value);
        } else if ( (element.value instanceof moment)) {
          // console.log(element , '==>Its a moment Object');
          const momentObject = element.value;
          element.value = momentObject.format('DD-MM-YYYY');
          // console.log( element.value);
        }
      }
    });

    return JsonToChangeTimeFromMomentToString;
  }






  getformslisttoexport(formtype: string) {
    const inputParams = {
      type: formtype,
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_FORMS_TO_EXPORT`,
      {},
      httpOptions
    );
  }
  exportforms(formtype: string, selectedforms: any) {
    const inputParams = {
      type: formtype,
      selected: selectedforms.toString(),
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_EXPORT_DATA`,
      {},
      httpOptions
    );
  }
  importformsfromfile(
    importtype: string,
    formtype: string,
    autopublish: boolean,
    overWriteDraft: boolean,
    formsToBeImported: any
  ) {
    const inputParams = {
      importType: importtype,
      type: formtype,
      autopublish: autopublish,
      overWriteDraft: overWriteDraft,
      data: formsToBeImported,
    };
    const headers = this.utilsservice.setBasicHeaders('application/x-www-form-urlencoded');
    const body = this.utilsservice.setBody(inputParams);
    const httpOptions = {
      headers: headers
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_IMPORT_DATA`,
      body,
      httpOptions
    );
  }
  downloadremoteforms(
    importtype: string,
    formtype: string,
    importcredsform: any
  ) {
    const importcredsformstr = JSON.stringify(importcredsform);
    const inputParams = {
      importType: importtype,
      type: formtype,
      initDataDownload: true,
      remoteCreds: importcredsformstr,
    };
    const headers = this.utilsservice.setBasicHeaders('application/x-www-form-urlencoded');
    const body = this.utilsservice.setBody(inputParams);
    const httpOptions = {
      headers: headers
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_IMPORT_DATA`,
      body,
      httpOptions
    );
  }
  importremoteforms(
    importtype: string,
    formtype: string,
    importcredsform: string,
    selectlist: any,
    autopublish: boolean,
    overwritedraft: boolean
  ) {
    const importcredsformstr = JSON.stringify(importcredsform);
    const inputParams = {
      importType: importtype,
      type: formtype,
      initDataDownload: false,
      remoteCreds: importcredsformstr,
      data: selectlist.toString(),
      autopublish: autopublish,
      overWriteDraft: overwritedraft,
    };
    const headers = this.utilsservice.setBasicHeaders('application/x-www-form-urlencoded');
    const body = this.utilsservice.setBody(inputParams);
    const httpOptions = {
      headers: headers
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_IMPORT_DATA`,
      body,
      httpOptions
    );
  }
  getListOfWorkflows(offset: number, limit: number, WorkflowNameSpace: string) {
    const headers = this.utilsservice.setBasicHeaders('application/json');
    let params = new HttpParams({ encoder: new CustomEncoder() });
    params = params.append('offset', offset.toString());
    params = params.append('limit', limit.toString());
    params = params.append('wfNamespace', WorkflowNameSpace);
    const httpOptions = {
      headers: headers,
      params: params,
    };
    return this.http.get<any>(
      `${this.baseUrl}/UMP/API/v2/workflow`,
      httpOptions
    );
  }
  getListOfSystemProperties(
    offset: number,
    limit: number,
    WorkflowType: string
  ) {
    const headers = this.utilsservice.setBasicHeaders('application/json');
    let params = new HttpParams({ encoder: new CustomEncoder() });
    params = params.append('offset', offset.toString());
    params = params.append('limit', limit.toString());
    params = params.append('wfType', WorkflowType);
    const httpOptions = {
      headers: headers,
      params: params,
    };
    return this.http.get<any>(
      `${this.baseUrl}/UMP/API/v2/workflow`,
      httpOptions
    );
  }
  getWorkflowHelp(workflowname: string, WorkflowNameSpace: string) {
    let headers = new HttpHeaders();
    let params = new HttpParams({ encoder: new CustomEncoder() });
    params = params.append('wfName', workflowname);
    params = params.append('wfNamespace', WorkflowNameSpace);
    /** set request headers for http request */
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('accept', 'application/json');
    const httpOptions = {
      headers: headers,
      params: params,
    };
    return this.http.get<any>(
      `${this.baseUrl}/UMP/API/v2/workflow/${workflowname}/help`,
      httpOptions
    );
  }
  getWorkflowData(formid: string) {
    // const importcredsformstr = JSON.stringify(importcredsform);
    const inputParams = {
      formId: formid,
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_WORKFLOW_ALL_PROPS`,
      {},
      httpOptions
    );
  }
  getWorkflowOfForm(formid: string) {
    // const importcredsformstr = JSON.stringify(importcredsform);
    const inputParams = {
      formId: formid,
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_WORKFLOW_OF_A_FORM`,
      {},
      httpOptions
    );
  }
  saveWorkflowData(formid: string, Workflowdata: any) {
    const inputParams = {
      formId: formid,
      workflowdata: Workflowdata,
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_SAVE_ALL_WORFLOW_PROPS`,
      {},
      httpOptions
    );
  }
  savepdfSettingsData(formid: string, pdfSettings: any) {
    const inputParams = {
      formId: formid,
      pdfSettings: pdfSettings,
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_SAVE_FORM_PDF_SETTINGS`,
      {},
      httpOptions
    );
  }
  getformsharingdata(formid, limit, offset, query) {
    const inputParams = {
      formId: formid,
      limit: limit,
      offset: offset,
      query: query,
      includePublicShareIds: true,
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_FORM_SHARE_INFO`,
      {},
      httpOptions
    );
  }
  getpublicIp() {
    return this.http.get<any>(
      'https://cors-anywhere.herokuapp.com/https://api.ipify.org/?format=json'
    );
  }
  createformsharingdata(
    formid: string,
    createshareform: any,
    publicIp: string
  ) {
    const inputParams = {
      name: createshareform.name,
      formId: formid,
      permission: createshareform.permission,
      email: createshareform.email,
      private: createshareform.private,
      externalShareId:createshareform.externalShareId,
      expiry: createshareform.expiry.getTime(),
      ip: publicIp,
    };

    console.log(inputParams);
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GENERATE_SHARE_URL`,
      {},
      httpOptions
    );
  }
  getsharedformdata(shareId: string, submissionId: string) {
    const inputParams = {
      shareId: shareId,
      submissionId: submissionId,
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_FORM_SHARED_DATA`,
      {},
      httpOptions
    );
  }
  getpublicsharedformsinfo(
    formId: string,
    shareId: string,
    pagesize: number,
    pageindex: number,
    searchform: any
  ) {
    // console.log(searchform);
    const inputParams = {
      formId: formId,
      shareId: shareId,
      limit: pagesize,
      offset: pageindex,
      startDate: Date.parse(searchform.startDate),
      endDate: Date.parse(searchform.endDate),
      query: searchform.formName,
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_PUBLIC_SHARED_FORMS`,
      {},
      httpOptions
    );
  }
  revertformversion(formid: string, version: number) {
    const inputParams = {
      formId: formid,
      version: version,
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_REVERT_FORM_TO_VERSION`,
      {},
      httpOptions
    );
  }

  // Methods for Documents
  uploadDocument(documentFile) {
    const formData = new FormData();
    formData.append('file', documentFile, documentFile.name);
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'multipart/form-data');
    headers = headers.append('Accept', 'application/json');
    const attachmentid = this.utilsservice.generateRandomString(32);
    return this.http.post<any>(
      this.UMP_URL_ATTACHMENT + attachmentid,
      formData
    );
  }

  addDoctToServer(formId: string, createDocForm: any, attachId: string) {
    const inputParams = {
      docName: createDocForm.docName,
      formId: formId,
      docDesc: createDocForm.docDesc,
      attachId: attachId,
      docUrl: createDocForm.docUrl,
      urlType: createDocForm.urlType,
      autoDownload: createDocForm.autoDownload
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      this.UMP_URL + '/DIGITAL_FORMS_PA_ADD_DOCS_TO_FORM',
      {},
      httpOptions
    );
  }

  updateDocsInTheServer(document ) {
    // console.log(document);
    const inputParams = document;
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      this.UMP_URL + '/DIGITAL_FORMS_PA_ADD_DOCS_TO_FORM',
      {},
      httpOptions
    );
  }

  getAllDocument(form): Observable<any> {
    const inputParams = form;
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      this.UMP_URL + '/DIGITAL_FORMS_PA_GET_ALL_FORM_DOCUMENTS',
      {},
      httpOptions
    );
  }

  getDocumentDataAsObservable(formid, limit, offset, query) {
    const inputParams = {
      formId: formid,
      limit: limit,
      offset: offset,
      query: query,
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_FORM_DOCUMENTS`,
      {},
      httpOptions
    );
  }

  deleteDocument(formId, stringifiedDocId, deleteFlag): Observable<any> {
    const inputParams = {
      formId: formId,
      docId: stringifiedDocId,
      delete: deleteFlag,
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_DELETE_FORM_DOCUMENT`,
      {},
      httpOptions
    );
  }


  AssociateDocument(selectedDocs) {
    const inputParams = selectedDocs;
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_ADD_DOCS_TO_FORM`,
      {},
      httpOptions
    );
  }
  DownloadDocument(AttachId) {
    const attachmentid = AttachId;
    return this.http.get(this.UMP_URL_ATTACHMENT + attachmentid, {
      responseType: 'blob',
    });
  }

  // search matching form in reports
  searchforms(query): Observable<any> {
    const paginationparams = {
      query: query,
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(paginationparams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_FORMS`,
      {},
      httpOptions
    );
  }

  // search matching form in reports
  getformsForCategory(category): Observable<any> {
    const paginationparams = {
      formCategory: category,
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(paginationparams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_FORMS`,
      {},
      httpOptions
    );
  }


  // methods for reports page
  getFormFields(formId) {

    const inputparams = {
      formId: formId,
    };

    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputparams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_ALL_FORM_FIELDS`,
      {},
      httpOptions
    );

  }

  createQuery(queryobject) {
    const inputparams = {
      // id: "",
      name: queryobject.name,
      desc: queryobject.desc,
      query: queryobject.query,
      public: queryobject.public,
      favourite: queryobject.favourite,
      edit: queryobject.edit,
      formId: queryobject.formId,
      reportId: queryobject.reportId,
      queryJson: queryobject.queryJson,
      updateFavourite:  queryobject.updateFavourite,
      selectedColumns: queryobject.columns
    };
    console.log(inputparams);
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputparams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_SAVE_FORM_QUERIES`,
      {},
      httpOptions
    );
  }

  getAllQueries(criteria) {
    // const inputparamsjson = JSON.stringify(criteria);
    // console.log(inputparamsjson);
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(criteria);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_FORM_QUERIES`,
      {},
      httpOptions
    );
  }

  deleteQuery(deleteParam) {
    const inputparams = {
      reportId: deleteParam.id,
      delete: deleteParam.delete
    };

    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputparams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_SAVE_FORM_QUERIES`,
      {},
      httpOptions
    );
  }

  getQueryUsingId(queryId) {
    const inputparams = {
      reportId: queryId
    };
    const inputparamsjson = JSON.stringify(inputparams);
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputparams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_FORM_QUERY`,
      {},
      httpOptions
    );
  }

  createNewreportCopy(objForReport) {
    const inputparams = {
      copy: objForReport.copy,
      copySrc: objForReport.copySrc
    };


    const inputparamsjson = JSON.stringify(inputparams);
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputparams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_SAVE_FORM_QUERIES`,
      {},
      httpOptions
    );
  }

  saveQuerytemporrarilyintoService(Json) {
    this.testJson = Json;
    console.log(this.testJson);
  }

  getTestJsonFromService() {
    return this.testJson;
  }


  shareForReport(QUeryToBeShared) {
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(QUeryToBeShared);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_SAVE_FORM_QUERIES`,
      {},
      httpOptions
    );
  }

  deleteShareForReport(ShareToDelete) {
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(ShareToDelete);
    const httpOptions = {
      headers: headers,
      params: params
    };


    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_REMOVE_FORM_REPORT_SHARE`,
      {},
      httpOptions
    );
  }

  getScheduleById(scheduleId){
    const inputparams = {
      schdeuleId: scheduleId
    };

    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputparams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_FORM_REPORT_SCHEDULE`,
      {},
      httpOptions
    );

  }

  deleteSchedule(objToDelete) {
    const inputparams = {
      delete: objToDelete.delete,
      id:objToDelete.scheduleId
    };

    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputparams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_UPDATE_FORM_REPORT_SCHEDULE`,
      {},
      httpOptions
    );


  }


  createExternalUser(userObject) {
    const inputparams = {
      firstName: userObject.firstName,
      lastName:userObject.lastName,
      email:userObject.email,
      phoneNumber:userObject.phoneNumber,
      private:userObject.private,
      id:userObject.id
    };
    console.log("inputMessage in service", inputparams);
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputparams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_SAVE_EXTERNAL_USERS`,
      {},
      httpOptions
    );


  }


  editExternalUser(userObject,recievedId) {
    const inputparams = {
      firstName: userObject.firstName,
      lastName:userObject.lastName,
      email:userObject.email,
      phoneNumber:userObject.phoneNumber,
      private:userObject.private,
      id:recievedId

    };

    console.log(inputparams);
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputparams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_UPDATE_EXTERNAL_USER`,
      {},
      httpOptions
    );


  }


  deleteExternalUser(userObject,recievedId) {
    const inputparams = {
      firstName: userObject.firstName,
      lastName:userObject.lastName,
      email:userObject.email,
      phoneNumber:userObject.phoneNumber,
      private:userObject.private,
      id:recievedId,
      delete:true

    };

    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputparams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_UPDATE_EXTERNAL_USER`,
      {},
      httpOptions
    );


  }


  getExternalUsers(queryVal,exclude) {
    const inputparams = {
     query:queryVal,
     excludeusers:exclude

    };

    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputparams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_EXTERNAL_USERS`,
      {},
      httpOptions
    );
  }


  getNetsedFormsFromServer(query){


    const inputparams = {
      formId:query.formId,
      input:query.displayType
     };

     const headers = this.utilsservice.setBasicHeaders('application/json');
     const params = this.utilsservice.setParams(inputparams);
     const httpOptions = {
       headers: headers,
       params: params
     };
     return this.http.post<any>(
       `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_FORM`,
       {},
       httpOptions
     );

  }

  getExternaluserDEtails(userId: string){
    const inputparams = {
      id:userId
     };

     const headers = this.utilsservice.setBasicHeaders('application/json');
     const params = this.utilsservice.setParams(inputparams);
     const httpOptions = {
       headers: headers,
       params: params
     };
     return this.http.post<any>(
       `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_EXTERNAL_USER`,
       {},
       httpOptions
     );
  }


  updateNestedForms(recformId: string){
    const inputparams = {
      formId:recformId
     };

     const headers = this.utilsservice.setBasicHeaders('application/json');
     const params = this.utilsservice.setParams(inputparams,'false',this.utilsservice.generateRandomString(32),'true');
     const httpOptions = {
       headers: headers,
       params: params
     };
     return this.http.post<any>(
       `${this.UMP_URL}/DIGITAL_FORMS_PA_UPDATE_ALL_NESTED_FORM_VERSION`,
       {},
       httpOptions
     );
  }



    // GetDetails to be called first
    getWorkflowForMasterDataDetails(masterDatFormId: string){

      const inputParams = {
        masterDataId:masterDatFormId
      };
      const headers = this.utilsservice.setBasicHeaders('application/json');
      const params = this.utilsservice.setParams(inputParams);
      const httpOptions = {
        headers: headers,
        params: params
      };

      return this.http.post<any>(
        `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_MASTERDATA_WF_PROPS`,
        {},
        httpOptions
      );
    }


    getWorkflowForMasterData(masterDatFormId: string){

      const inputParams = {
        masterDataId:masterDatFormId
      };
      const headers = this.utilsservice.setBasicHeaders('application/json');
      const params = this.utilsservice.setParams(inputParams);
      const httpOptions = {
        headers: headers,
        params: params
      };

      return this.http.post<any>(
        `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_WORKFLOW_OF_A_MASTERDATA`,
        {},
        httpOptions
      );
    }
    createMasterDataSchedule(
      masterDataId: string,
      selectedWorkflowId: string,
      type: string,
      endDate: any,
      scheduleData: any
    ): Observable<any> {
      if (endDate) {
        scheduleData.pickervalue = endDate.getTime();
        endDate = endDate.getTime();
      }
      const inputParams = {
        masterDataId: masterDataId,
        selectedWorkflowId: selectedWorkflowId,
        type: type,
        startDate: new Date().getTime(),
        endDate: endDate,
        scheduleData: scheduleData
      };
      const headers = this.utilsservice.setBasicHeaders('application/json');
      const params = this.utilsservice.setParams(inputParams);
      const httpOptions = {
        headers: headers,
        params: params
      };
      return this.http.post<any>(
        `${this.UMP_URL}/DIGITAL_FORMS_PA_SAVE_MASTERDATA_WF_PROPS`,
        {},
        httpOptions
      );
    }
    updateScheduleRunStatus(masterDatFormId: string, scheduleRunStatus: string){
      const inputParams = {
        masterdata: true,
        masterdataId:masterDatFormId,
        scheduleRunStatus: scheduleRunStatus
      };
      const headers = this.utilsservice.setBasicHeaders('application/json');
      const params = this.utilsservice.setParams(inputParams);
      const httpOptions = {
        headers: headers,
        params: params
      };

      return this.http.post<any>(
        `${this.UMP_URL}/DIGITAL_FORMS_PA_UPDATE_SCHEDULE`,
        {},
        httpOptions
      );
    }

  // Get the company settings 
  getCompanySettings(): Observable<any> {
    return this.companySettings.asObservable();
  }

  getSmsSettings() {
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const httpOptions = {
      headers: headers,
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_SMS_SETTINGS`,
      {},
      httpOptions
    );
  }

  updateSmsSettings(data) {
    const inputParams = {
      "accountid": data.accountid,
      "authtoken": data.authtoken
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
      const httpOptions = {
        headers: headers,
        params: params
      };

    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_UPDATE_SMS_SETTINGS`,
      {},
      httpOptions
    );
  }

  getdefaultAttributes(type:string){
    const inputParams = {
      'attribType':  type
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);

    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_FORM_STANDARD_ATTRIBUTES`,
      {},
      httpOptions
    );
  }

  getListOfDashboards(isAdmin:boolean){
    const inputParams = {
      "adminOnly": isAdmin,
      "sortOnName": isAdmin ? false : true
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
      const httpOptions = {
        headers: headers,
        params: params
      };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_DASHBOARD_LIST`,
      {},
      httpOptions
    );
  }
  getDashboardsForImport(){
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const httpOptions = {
      headers: headers,
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_DASHBOARDS_FOR_IMPORT`,
      {},
      httpOptions
    );
  }

  
  importDashboard(data) {
    const inputParams = {
      "dashboards": data
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
      const httpOptions = {
        headers: headers,
        params: params
      };

    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_IMPORT_DASHBOARD`,
      {},
      httpOptions
    );
  }

  markDashboardFavorite(data:any) {
    const inputparams = {
     data
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(data);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_UPDATE_DASHBOARD_PROPS`,
      {},
      httpOptions
    );
  }

  discardformversion(formid: string) {
    const inputParams = {
      formId: formid,
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_DISCARD_FORM_DRAFT`,
      {},
      httpOptions
    );
  }
  getScheduleWorkflows(formid: string){
    
    const inputParams = {
      formId: formid,
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_SCHEDULE_WORKFLOWS`,
      {},
      httpOptions
    );
  }
  getFormFieldsForAttr(formid: string){
    const inputParams = {
      formId: formid,
      onlyHeaderFields:true
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_FORM_FIELDS`,
      {},
      httpOptions
    );
  }
  private sharedData: Subject<any> = new Subject<any>();
  sharedData$: Observable<any> = this.sharedData.asObservable();

  setData(updatedData) {
    this.sharedData.next(updatedData);  
  }

  updateFormCategory(Category:any,associatedApps:any){
    const formData={
      categoryName: Category,
      associatedApps:associatedApps
    } 
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(formData);
    const httpOptions = {
      headers: headers,
       params: params
    };
    return this.http.post<any>(`${this.UMP_URL}/DIGITAL_FORMS_PA_UPDATE_FORM_CATEGORY`,{},httpOptions);
  }
}
