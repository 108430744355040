import { CustomEncoder } from '../../shared/classes/customencoder';
import EditFormUtils from 'formiojs/components/_classes/component/editForm/utils';

export function NewSmartDataEditForm() {
  return {
    components: [
      {
        type: 'tabs',
        key: 'tabs',
        components: [
          {
            label: 'Data',
            key: 'data',
            weight: 0,
            components: [
              {
                weight: 0,
                type: 'textfield',
                input: true,
                key: 'label',
                label: 'Label',
                placeholder: 'Field Label',
                tooltip: 'The label for this field that will appear next to it.',
                validate: {
                  required: true
                }
              },
              {
                type: 'checkbox',
                key: 'hidden',
                defaultValue: true,
                label: 'Hidden',
                tooltip: 'A hidden field is still a part of the form, but is hidden from view.',
                input: true,
              }
            ]
          },
          {
            label: 'Trigger',
            key: 'trigger',
            weight: 10,
            components: [
              {
                type: 'panel',
                title: 'Execute fetch API',
                theme: 'default',
                collapsible: true,
                collapsed: false,
                key: 'trigger.prefetch',
                // weight: 150,
                components: [
                  {
                    type: 'checkbox',
                    input: false,
                    key: 'trigger.prefetch.init',
                    defaultValue: false,
                    label: 'Trigger on form init',
                    tooltip: 'When enabled the request will be made when the form is initialized.',
                    weight: 15
                  },
                  {
                    type: 'checkbox',
                    input: true,
                    key: 'trigger.prefetch.triggerbycomponent',
                    defaultValue: true,
                    label: 'Triggered by form component',
                    tooltip: 'The smartdata fetch will be triggered by another form component',
                    weight: 15
                  },
                  {
                    type: 'htmlelement',
                    tag: 'div',
                    content: (context) => {
                      // console.log(context);
                        return `SDC can be triggered by Barcode or by a button.  In the barcode or button add an action to trigger the SDC.  If the SDC has to be triggered by any other generic component, set the other component to fire an event with selected property name from the SDC API tab.`;
                    }
                  },
                  {
                    type: 'select',
                    input: true,
                    key: 'trigger.prefetch.refreshOn',
                    label: 'Trigger on Data change',
                    weight: 10,
                    tooltip: 'Refresh data when another field changes.',
                    dataSrc: 'custom',
                    valueProperty: 'value',
                    data: {
                      custom: function (e) {
                        // console.log('Edit form:', e);
                        const t = [];
                        return t.push({
                          label: 'Any Change',
                          value: 'data'
                        }), e.utils.eachComponent(e.instance.options.editForm.components,
                          (function (n, i) {
                            // console.log(n, i);
                            // tslint:disable-next-line: no-unused-expression
                            n.key !== e.data.key && t.push({
                              label: n.label || n.key,
                              value: i
                            });
                          })), t;
                      }
                    }
                  },
                ]
              },
              {
                type: 'panel',
                title: 'Post executing fetch API',
                theme: 'default',
                collapsible: true,
                collapsed: true,
                key: 'trigger.postfetch',
                // weight: 150,
                components: [
                  // this.logicVariablesTable(additionalParams),
                  {
                    type: 'textfield',
                    label: 'Fire Event',
                    key: 'trigger.postfetch.event',
                    input: true,
                    weight: 10,
                    tooltip: 'The event to fire after fetch data API has been executed.',
                    defaultValue: 'SmartDataEvent'
                    // description: 'When in a datagrid or editgrid, { { rowIndex } } is available for interpolation to target a specific row.'
                  },
                  {
                    type: 'panel',
                    title: 'JavaScript',
                    collapsible: true,
                    collapsed: false,
                    style: { 'margin-bottom': '10px' },
                    key: 'trigger.postfetch.panel',
                    // customConditional() {
                    //   return !Evaluator.noeval;
                    // },
                    components: [
                      {
                        type: 'textarea',
                        key: 'trigger.postfetch.panel.javascript',
                        rows: 5,
                        editor: 'ace',
                        hideLabel: true,
                        input: true
                      },
                      {
                        type: 'htmlelement',
                        tag: 'div',
                        content: `<p>Enter custom javascript code.</p>`
                      }
                    ]
                  }

                ]
              }
            ]
          },
          {
            label: 'API',
            key: 'fetchapi',
            weight: 20,
            components: [
              {
                weight: 0,
                type: 'textfield',
                input: true,
                key: 'key',
                label: 'Property Name',
                tooltip: 'The name of this field in the API endpoint.',
                validate: {
                  pattern: '(\\w|\\w[\\w-.]*\\w)',
                  patternMessage: 'The property name must only contain alphanumeric characters, underscores, dots and dashes and should not be ended by dash or dot.',
                  required: true
                }
              },
              {
                type: 'select',
                input: true,
                weight: 0,
                tooltip: 'The source to get the data data. You can fetch from a URL or use javascript to get the value.',
                key: 'dataSrc',
                defaultValue: 'workflow',
                label: 'Data Source Type',
                dataSrc: 'values',
                data: {
                  values: [
                    {
                      label: 'URL',
                      value: 'url'
                    },
                    {
                      label: 'Workflow',
                      value: 'workflow'
                    }
                  ]
                }
              }, {
                type: 'textfield',
                input: true,
                key: 'fetchapi.url',
                weight: 10,
                label: 'Data Source URL',
                placeholder: 'Data Source URL',
                tooltip: 'A URL that returns data. You can interpolate form data using curly bracket notation.',
                clearOnHide: true,
                conditional: {
                  json: {
                    '===': [{
                      var: 'data.dataSrc'
                    }, 'url']
                  }
                }
              }, {
                type: 'select',
                input: true,
                label: 'Method',
                key: 'fetchapi.method',
                clearOnHide: true,
                tooltip: 'The HTTP Request method to use when making the request.',
                defaultValue: 'get',
                weight: 11,
                template: '<span>{{ item.label }}</span>',
                dataSrc: 'values',
                data: {
                  values: [{
                    label: 'Get',
                    value: 'get'
                  }, {
                    label: 'Put',
                    value: 'put'
                  }, {
                    label: 'Post',
                    value: 'post'
                  }, {
                    label: 'Patch',
                    value: 'patch'
                  }, {
                    label: 'Delete',
                    value: 'delete'
                  }]
                },
                conditional: {
                  json: {
                    '===': [{
                      var: 'data.dataSrc'
                    }, 'url']
                  }
                }
              }, {
                type: 'datagrid',
                input: true,
                label: 'Request Headers',
                key: 'fetchapi.headers',
                tooltip: 'Set any headers that should be sent along with the request to the url. This is useful for authentication.',
                weight: 12,
                components: [{
                  label: 'Key',
                  key: 'key',
                  input: true,
                  type: 'textfield'
                }, {
                  label: 'Value',
                  key: 'value',
                  input: true,
                  type: 'textfield'
                }],
                clearOnHide: true,
                conditional: {
                  json: {
                    '===': [{
                      var: 'data.dataSrc'
                    }, 'url']
                  }
                }
              },
              {
                label: 'FormId',
                key: 'fetchapi.formId',
                input: true,
                type: 'textfield',
                hidden: true,
                customDefaultValue: (context) => {
                  // console.log('In Input select data', context);
                  const URLArray = window.location.pathname.split('/');
                  // console.log(pathArray);
                  const urlmatrixstrings = URLArray[3].split(';');
                  const formId = urlmatrixstrings[0];
                  // console.log(formId);
                  return formId;
                },
              },
              {
                type: 'select',
                label: 'Workflow Name',
                key: 'fetchapi.workflowname',
                customClass: "is-flipped",
                placeholder: 'Select workflow',
                dataSrc: 'url',
                data: {
                  url: `${localStorage.getItem('UMP_url')}/UMP/API/v2/applications/DIGITAL_FORMS/execute/DIGITAL_FORMS_PA_GET_ALL_WORKFLOWS`,
                  method: 'POST',
                  headers: [
                    { key: 'Authorization', value: 'Bearer ' + localStorage.getItem('token') },
                    { key: 'Content-Type', value: 'application/x-www-form-urlencoded' },
                    { key: 'accept', value: 'application/json' }
                  ]
                },
                valueProperty: 'wfName',
                lazyLoad: true,
                template: '<span>{{ item.wfTitle }}</span>',
                // refreshOn: 'make',
                clearOnRefresh: true,
                selectValues: 'workflows',
                validate: {
                  required: true
                },
                customDefaultValue: (context) => {
                  // console.log('In Input select data', context);
                  const URLArray = window.location.pathname.split('/');
                  // console.log(pathArray);
                  const urlmatrixstrings = URLArray[3].split(';');
                  const formId = urlmatrixstrings[0];
                  // console.log(formId);
                  const inputparams = {
                    formId: `${formId}`
                  };
                  const inputparamsjson = JSON.stringify(inputparams);
                  // console.log(inputparamsjson);
                  const encodedInput = new CustomEncoder().encodeValue(inputparamsjson);
                  const body = `queuedExecute=false&messageFormat=custom&inputMessage=${encodedInput}`;
                  context.component.data.body = body;
                },
                conditional: {
                  json: {
                    '===': [{
                      var: 'data.dataSrc'
                    }, 'workflow']
                  }
                }
              },
            ]
          },
        ]
      }
    ]
  }
}
