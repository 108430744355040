<mat-toolbar color="primary">
  <button  mat-button (click)="downloadLog()">
    <mat-icon>download</mat-icon>
  </button>

  <span>{{ imageData.originalName || imageData.name }}</span>
  <span class="fill-remaining-space"></span>
    <button mat-button (click)="closeoverlay(null)">
    <mat-icon>clear</mat-icon>
  </button>
</mat-toolbar>


<div class="dialog-container">
  <img [src]="imageData.url" id="{{imageData.compnentId}}" alt="{{imageData.originalName || imageData.name}}" style="width: 100%;height: 100%;">
    
</div>
