const TextFieldComponentForSmartId = Formio.Components.components.textfield;
import { SmartIdEditForm } from './smart-id.editForm';
class SmartIDComponent extends TextFieldComponentForSmartId {

  constructor(component, options, data) {
    super(component, options, data);
  }

  static schema(...extend) {
    return TextFieldComponentForSmartId.schema({
      type: 'smartId',
      label: 'Smart ID',
      input: true,
      key: 'smartId',
      inputType: 'text',
      inputFormat: 'plain',
      disabled: true,
    }, ...extend);
  }

  static get builderInfo() {
    return {
      title: 'Smart Id',
      group: 'premium',
      icon: 'id-card-o',
      weight: 10,
      documentation: '/userguide/#smartid',
      schema: SmartIDComponent.schema()
    };
  }

  static addTemplate(name, template) {
    Templates.templates[name] = template;
  }

  get defaultSchema() {
    return SmartIDComponent.schema();
  }

  render() {
    return super.render(
      this.renderTemplate('input', {
        input: this.inputInfo
      }),
    );
  }

  attach(element) {
    let today = new Date();
    let day = '';
    let month = '';
    if (Number(today.getDate()) <= 9 && this.component.addLeadingZeros) {
      day = '0' + today.getDate();
    } else {
      day = today.getDate();
    }
    if (Number(today.getMonth()) <= 9 && this.component.addLeadingZeros) {
      month = '0' + Number(today.getMonth() + 1);
    } else {
      month = Number(today.getMonth() + 1);
    }
    if (this.component.addLeadingZeros && this.component.startCounter != 'undefined') {
      if (this.component.startCounter == "undefined" || this.component.startCounter == undefined) {
        this.component.startCounter = 1;
      }

      if (this.component.digitsInConter) {
        this.component.startCounter = String(this.component.startCounter).padStart(this.component.digitsInConter, 0);
      }
    }
    if (this.component.pattern) {
      let patternInput = this.component.pattern;
      if (patternInput.includes("${counter}")) {
        patternInput = patternInput.replace("${day}", day);
        patternInput = patternInput.replace("${month}", month);
        patternInput = patternInput.replace("${year}", today.getFullYear());
        patternInput = patternInput.replace("${day}", day);
        patternInput = patternInput.replace("${counter}", this.component.startCounter);
        this.setValue(patternInput);

      } else {
        this.resetValue();
      }
    }
    return super.attach(element);
  }
}

// Register the component to the Formio.Components registry.
SmartIDComponent.editForm = SmartIdEditForm;
Formio.Components.addComponent("smartId", SmartIDComponent);
