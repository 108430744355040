import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable,  } from 'rxjs';
import { UtilsService } from '../utils.service';
/*
export function uploadProgress<T>(cb: (progress: number) => void) {
  return tap((event: HttpEvent<T>) => {
    if (event.type === HttpEventType.UploadProgress) {
      cb(Math.round((100 * event.loaded) / event.total));
    }
  });
}

export function toResponseBody<T>() {
  return pipe(
    filter((event: HttpEvent<T>) => event.type === HttpEventType.Response),
    map((res: HttpResponse<T>) => res.body)
  );
}
*/
@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  public baseUrl: string;
  private imageUploadUrl: string;
  private UMP_URL: string;
  public progress = 0;
  constructor(
    private http: HttpClient,
    private utilsservice: UtilsService
  ) {
    this.baseUrl = this.utilsservice.getUMPUrl();
    this.UMP_URL = `${this.baseUrl}/UMP/API/v2/applications/DIGITAL_FORMS/execute`;
    this.imageUploadUrl = `${this.baseUrl}/UMP/API/v2/applications/DIGITAL_FORMS/attachments`;
  }
  uploadimage(imagefile): Observable<any> {
    // console.log(imagefile);
    const body = new FormData();
    body.append('file', imagefile, imagefile.name);
    const attachmentid = this.utilsservice.generateRandomString(32);
    /*
    const headers = this.utilsservice.setBasicHeaders('multipart/form-data');
    const httpOptions = {
      headers: headers,
      //  reportProgress: true,
      //  observe: 'events'
    };
    */
    return this.http.post<any>(
      `${this.imageUploadUrl}/${attachmentid}`,
      body
    );
  }
  getgeneralsettings(): Observable<any> {
    const inputParams = {};
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_GENERAL_APP_SETTINGS`,
      {},
      httpOptions
    );
  }
  savecompanysettings(companyname, companyworkdays): Observable<any> {
    const attachmentId = localStorage.getItem('attachmentId');
    const inputParams = {
      companyName: companyname,
      companyWorkdays: companyworkdays,
      attachmentId: attachmentId
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_UPDATE_COMPANY_SETTINGS`,
      {},
      httpOptions
    );
  }
  updategeneralsettings(
    generalsettingsvalues: any,
    user: string,
    token: string,
    loginExpiry:string,
    passwordResetExpiry: string
  ): Observable<any> {
    const inputParams = {
      generalsettingsform: generalsettingsvalues,
      user: user,
      token: token,
      loginExpiry:loginExpiry,
      passwordResetExpiry: passwordResetExpiry
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_UPDATE_GENERAL_APP_SETTINGS`,
      {},
      httpOptions
    );
  }
  pingformio(): Observable<any>  {
    const inputParams = {};
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_PING_FORMIO`,
      {},
      httpOptions
    );
  }
  refreshtoken(loginform: any): Observable<any> {
    const inputParams = loginform;
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams,'true');
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_UPDATE_PASSWORD_AS_ROOT_USER`,
      {},
      httpOptions
    );
  }
  regeneratetoken(token: string): Observable<any> {
    const inputParams = {
      token: token
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_REGENERATE_AUTH_TOKEN`,
      {},
      httpOptions
    );
  }
  getemailsettings(): Observable<any> {
    const inputParams = {};
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_EMAIL_SETTINGS`,
      {},
      httpOptions
    );
  }
  updateemailsettings(emailsettingvalues: any) {
    // console.log(emailsettingvalues);
    const inputParams = {
      emailsettingsform: emailsettingvalues
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_UPDATE_EMAIL_SETTINGS`,
      {},
      httpOptions
    );
  }
  geteditorsettings(type: string): Observable<any> {
    const inputParams = {
      type: type
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_SCRIPT_SETTINGS`,
      {},
      httpOptions
    );
  }
  updateeditorsettings(type: string, data: any, addbootstrapclasses: boolean) {
    // console.log(addbootstrapclasses);
    const inputParams = {
      type: type,
      data: btoa(data),
      addbootstrapclasses: addbootstrapclasses
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_UPDATE_SCRIPT_SETTINGS`,
      {},
      httpOptions
    );
  }
  getpdfsettings(): Observable<any> {
    const inputParams = {};
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_PDF_SETTINGS`,
      {},
      httpOptions
    );
  }
  savepdfsettings(header: any, footer: any, pdfsettingsform: any) {
    // console.log(addbootstrapclasses);
    const inputParams = {
      header: btoa(header),
      footer: btoa(footer),
      pdfsettings: pdfsettingsform
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_UPDATE_PDF_SETTINGS`,
      {},
      httpOptions
    );
  }
  getcompanysettings(): Observable<any> {
    const inputParams = {};
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_COMPANY_SETTINGS`,
      {},
      httpOptions
    );
  }
  loadappversion() {
    return this.http.get('assets/appversion.json');
  }
  getAllEmailTemplates(): Observable<any> {
    const inputParams = {};
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_ALL_MAIL_TEMPLATES`,
      {},
      httpOptions
    );
  }
  loademailtemplate(selected_email_template_json): Observable<any> {
    const inputParams = {
      templateName: selected_email_template_json.selectedtemplate
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_MAIL_TEMPLATE`,
      {},
      httpOptions
    );
  }

  saveemailtemplate(
    selected_email_template_json: any,
    html_content: any
  ) {
    const inputParams = {
      name: selected_email_template_json.selectedtemplate,
      subject: selected_email_template_json.subject,
      description: selected_email_template_json.description,
      htmlContent: btoa(html_content)
    };
    const headers = this.utilsservice.setBasicHeaders('application/x-www-form-urlencoded');
    const body = this.utilsservice.setBody(inputParams);
    const httpOptions = {
      headers: headers
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_CREATE_MAIL_TEMPLATE`,
      body,
      httpOptions
    );
  }
  createEmailTemplate(
    templateForm: any,
  ) {
    const inputParams = { ... templateForm, create: true};
    const headers = this.utilsservice.setBasicHeaders('application/x-www-form-urlencoded');
    const body = this.utilsservice.setBody(inputParams);
    const httpOptions = {
      headers: headers
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_CREATE_MAIL_TEMPLATE`,
      body,
      httpOptions
    );
  }
  deleteEmailTemplate(selected_email_template_json): Observable<any> {
    const inputParams = {
      templateName: selected_email_template_json.selectedtemplate
    };
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_UPDATE_MAIL_TEMPLATE`,
      {},
      httpOptions
    );
  }
  updateDashboardSettings(data:any){
    const inputParams = data;
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_UPDATE_DASHBOARD_APP_SETTINGS`,
      {},
      httpOptions
    );
  }
  getDashboardSettings(){
    const inputParams = {};
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_DASHBOARD_APP_SETTINGS`,{},httpOptions
    );
  }
  getWebhooks(token:any,wfName:any){
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const httpOptions = {
      headers: headers
    };
    return this.http.get<any>(`${this.UMP_URL}/webhook/wf/${token}/${wfName}`,httpOptions);
  }
  updateWebhook(token:any,wfName:any){
    const inputParams = {};
    const headers = this.utilsservice.setBasicHeaders('application/json');
    const params = this.utilsservice.setParams(inputParams);
    const httpOptions = {
      headers: headers,
      params: params
    };
    return this.http.post<any>(`${this.UMP_URL}/webhook/wf/${token}/${wfName}`,{},httpOptions);
  }
}
