
<mat-toolbar color="primary">
  <mat-toolbar-row>
      <span> {{title}}</span>
      <span class="fill-remaining-space"></span>
      <button mat-button (click)="close()">
    <mat-icon>clear</mat-icon>
  </button>
  </mat-toolbar-row>
</mat-toolbar>
<br>
<div mat-dialog-content>
    <p>{{message}}</p>
</div>

<div mat-dialog-actions>
  <button mat-flat-button color="primary" (click)="onDismiss()">No</button>
  <button mat-flat-button color="warn" (click)="onConfirm()">Yes</button>
</div>
