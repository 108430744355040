
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  private appConfig: any;
  constructor(private http: HttpClient, private router: Router) { }

  loadAppConfig() {
    return this.http.get('assets/config.json?v=' + Date.now())
      .toPromise()
      .then(data => {
        this.appConfig = data;
      })
      .catch(error => {
        // console.error(error);
        const hosturl = window.location.hostname.toLowerCase();
        let configjson;
        // if (hosturl.includes('turboapps.io')) {
        const domainparts = window.location.hostname.split('.');
        // below line is Used to test for localhost
        // domainparts[0] = 'unvired';
        const discoveryurl = `https://discovery.turboapps.io?api=rtbbxtwfONWzs0V&domain=${domainparts[0]}`;
        // console.log(domainparts ,discoveryurl);
        return this.http.get(discoveryurl, { observe: 'response' })
          .toPromise()
          .then((configdata: any) => {
            try {
              if (configdata.status !== 200) {
                // Error Code: 2 - Discovery failed
                alert("TurboApps not configured properly. Contact your Administrator. Error Code: 2");

              } else if (configdata.status === 200) {
                if (configdata && configdata.body && configdata.body.data.length > 0) {
                  // for future ref
                  // if(hosturl.includes('sbox.turboapps.io')){
                  //   configjson = configdata.data.filter( element => element.ump_type === 'Q');
                  // } else if(hosturl.includes('turboapps.io')) {
                  //   configjson = configdata.data.filter( element => element.ump_type === 'P')
                  // } else {
                  //   configjson = configdata.data.filter( element => element.ump_type === 'D');
                  //   // console.log(configjson);
                  // }
                  // console.log(configjson);

                  configjson = configdata.body.data;
                  for (let i = 0; i < configjson.length; i++) {
                    if(configjson[i].admin_url.includes(window.location.host)){
                      this.appConfig = {
                        "apiBaseUrl": configjson[i].ump_url,
                        "domain": configjson[i].domain?.domain,
                        "app": configjson[i].app,
                        "login": {
                          "type": configjson[i].login_type,
                          "port": configjson[i].login_port,
                          "adsdomain": configjson[i].login_adsdomain
                        }
                      }
                    }
                  }
                  if (!this.appConfig){
                    // Error Code: 1 - Config.json has error in json
                    alert("TurboApps not configured properly. Contact your Administrator. Error Code: 1")
                  }

                } else {
                  // alert('The domain is not configured on our servers. Please check if it is typed correctly and try again.  If you think this is an error, you can write to Unvired Support to enable this domain.');
                  this.router.navigate(['/domainnotfound']);
                }
              }
            } catch (e) {
              // Error code 3 - Discovery returned invalid data
              alert("TurboApps not configured properly. Contact your Administrator. Error Code: 3")
              console.log('Error getting on config data:', e);
            }
          });
        // } else {
        //   // console.error('System misconfigured, contact your administrator');
        //   alert('Config Error: System misconfigured, contact your administrator');
        // }
      });
  }
  get apiBaseUrl() {
    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }
    return this.appConfig.apiBaseUrl;
  }
  get domain() {

    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }

    return this.appConfig.domain;
  }
  get login() {
    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }
    return this.appConfig.login;
  }
  get app() {
    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }
    return this.appConfig.app;
  }
}
