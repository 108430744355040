
<div class="split left">
  <div class="centered">
    <img height="180px" src="assets/images/product_logo.png" alt="Turbo Apps Logo">
  </div>
</div>
<div class="split right">
  <div class="centered">
  <div class="login-form-container">
    <form [formGroup]="loginform">
      <div >
        <div *ngIf="forgetpasswordloading" class="sk-cube-grid">
          <div class="sk-cube sk-cube1"></div>
          <div class="sk-cube sk-cube2"></div>
          <div class="sk-cube sk-cube3"></div>
          <div class="sk-cube sk-cube4"></div>
          <div class="sk-cube sk-cube5"></div>
          <div class="sk-cube sk-cube6"></div>
          <div class="sk-cube sk-cube7"></div>
          <div class="sk-cube sk-cube8"></div>
          <div class="sk-cube sk-cube9"></div>
        </div>
    </div>
    <div class="turbo-apps-logo-for-small-screens">
      <img height="100px" src="assets/images/product_logo_small.png" alt="Turbo Apps Logo">
    </div>
        <div *ngIf="!domainlocal" class="welcome-back-msg">Login</div>
        <div *ngIf="domainlocal">
            <span class="welcome-back-msg">Welcome back {{firstnamelocal}}!</span>
            <div class="logged-in-msg">You are logged in as:</div>
            <div class="returning-user-login-screen">
                <u>{{emaillocal}}</u>
            </div>
        </div>
        <div *ngIf="showdomainfield">
            <mat-form-field class="input-field-width" *ngIf="!domainlocal">
                <input matInput id='domain' #input formControlName="domain" style="text-transform: uppercase;"  class="login-buttons" type="text" placeholder="{{Domainplaceholder}}">
                <mat-error *ngIf="loginform.get('domain').hasError('whitespace') && !(loginform.get('domain').hasError('required'))">
                    Whitespaces are not allowed
                </mat-error>
            </mat-form-field>
        </div>
        <div *ngIf="showadsdomain">
            <mat-form-field class="input-field-width">
                <mat-label>Active Directory Domain</mat-label>
                <mat-select formControlName="adsdomainname">
                    <mat-option [value]="adsdomain" *ngFor="let adsdomain of adsdomains">{{adsdomain}}</mat-option>
                  </mat-select>
              </mat-form-field>
        </div>
        <div>
            <mat-form-field class="input-field-width" *ngIf="!domainlocal">
                <input matInput id='email' formControlName="email" class="login-buttons" type="text" placeholder="{{Emailplaceholder}}">
                <mat-error *ngIf="loginform.get('email').hasError('required')">
                    email or username is required
                </mat-error>
                <mat-error *ngIf="loginform.get('email').hasError('email')">
                    Please enter valid email id
                </mat-error>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field class="input-field-width">
                <input matInput id='password' formControlName="password" class="login-buttons" (keyup.enter)="login()" [type]=" showpassword ? 'text' : 'password'" placeholder="Password">
                <button type="button" *ngIf="!showpassword" matSuffix mat-icon-button aria-label="icon-button with a Eye off icon" (click)="showpassword=!showpassword;">
                    <mat-icon>visibility_off</mat-icon>
                </button>
                <button type="button" *ngIf="showpassword" matSuffix mat-icon-button aria-label="icon-button with a Eye icon" (click)="showpassword=!showpassword">
                    <mat-icon>visibility</mat-icon>
                </button>
                <mat-error *ngIf="loginform.get('password').hasError('required')">
                    password is required
                </mat-error>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field class="input-field-width">
                <mat-label>Preferred Language</mat-label>
                <mat-select [(value)]="currentLanguage" (selectionChange)="selectLanguage($event)">
                    <mat-option [value]="language.key" *ngFor="let language of languages">{{language?.display}}</mat-option>
                  </mat-select>
              </mat-form-field>
        </div>

        <div class="button-container">
          <button mat-flat-button type="button" (click)="login()"  color="primary" class="login-button mat-elevation-z4" [disabled]="!loginform.valid">Login</button>
          <button *ngIf="showssologin" mat-flat-button type="button" color="primary" (click)="openpopup()" class="login-button">Login via SSO</button>
        </div>
    </form>
    </div>
        <div class="login-options">
           <span class="other-login-options">
            <a mat-button *ngIf="domainlocal" [routerLink]="[]" (click)="removeemail()">Use a different account</a>
            <a mat-button *ngIf="!domainlocal" [routerLink]="[]" (click)="signuprequest()">Sign Up</a>
            </span>
            <span class="forget-password">
            <a mat-button (click)="forgetpassword()">Forgot Password?</a>
            </span>
        </div>
        <div class="unvired-logo">
          <!-- <h6>Powered By</h6> -->
          <img width="150px" src="assets/images/Unvired_logo_for_about.png">
        </div>
</div>
</div>
