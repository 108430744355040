import _ from 'lodash';
import EditFormUtils from 'formiojs/components/_classes/component/editForm/utils';
import getContextComponents from 'formiojs/utils/utils';
// import EditFormUtils from 'formiojs/components/_classes/component/editForm/utils';
import FileEditData from 'formiojs/components/file/editForm/File.edit.data';
import FileEditDisplay from 'formiojs/components/file/editForm/File.edit.display';
import { FormioUtils } from 'angular-formio';
export function fileEditForm() {
    return {
        //         components: [
        //     {
        //         type: 'select',
        //         input: true,
        //         key: 'storage',
        //         label: 'Storage',
        //         placeholder: 'Select your file storage provider',
        //         weight: 0,
        //         tooltip: 'Which storage to save the files in.',
        //         valueProperty: 'value',
        //         dataSrc: 'custom',
        //         data: {
        //             custom() {
        //                 return _.map(Formio.Providers.getProviders('storage'), (storage, key) => ({
        //                     label: storage.title,
        //                     value: key
        //                 }));
        //             }
        //         }
        //     },
        //     {
        //         type: 'textfield',
        //         input: true,
        //         key: 'url',
        //         label: 'Url',
        //         weight: 10,
        //         placeholder: 'Enter the url to post the files to.',
        //         tooltip: "See <a href='https://github.com/danialfarid/ng-file-upload#server-side' target='_blank'>https://github.com/danialfarid/ng-file-upload#server-side</a> for how to set up the server.",
        //         conditional: {
        //             json: { '===': [{ var: 'data.storage' }, 'url'] }
        //         }
        //     },
        //     {
        //         type: 'textfield',
        //         input: true,
        //         key: 'options.indexeddb',
        //         label: 'Database',
        //         weight: 10,
        //         placeholder: 'Enter the indexeddb database name',
        //         conditional: {
        //             json: {
        //                 in: [
        //                     {
        //                         var: 'data.storage'
        //                     },
        //                     ['indexeddb']
        //                 ],
        //             }
        //         }
        //     },
        //     {
        //         type: 'textfield',
        //         input: true,
        //         label: 'Table',
        //         key: 'options.indexeddbTable',
        //         weight: 10,
        //         placeholder: 'Enter the name for indexeddb table',
        //         conditional: {
        //             json: {
        //                 in: [
        //                     {
        //                         var: 'data.storage'
        //                     },
        //                     ['indexeddb']
        //                 ],
        //             }
        //         }
        //     },
        //     {
        //         type: 'textarea',
        //         key: 'options',
        //         label: 'Custom request options',
        //         tooltip: 'Pass your custom xhr options(optional)',
        //         rows: 5,
        //         editor: 'ace',
        //         input: true,
        //         weight: 15,
        //         placeholder: `{
        //   "withCredentials": true
        // }`,
        //         conditional: {
        //             json: {
        //                 '===': [{
        //                     var: 'data.storage'
        //                 }, 'url']
        //             }
        //         }
        //     },
        //     {
        //         type: 'textfield',
        //         input: true,
        //         key: 'fileKey',
        //         label: 'File form-data key',
        //         weight: 17,
        //         placeholder: 'Enter the key name of a file for form data.',
        //         tooltip: 'Key name that you would like to modify for the file while calling API request.',
        //         conditional: {
        //             json: {
        //                 '===': [{
        //                     var: 'data.storage'
        //                 }, 'url']
        //             }
        //         }
        //     },
        //     {
        //         type: 'textfield',
        //         input: true,
        //         key: 'dir',
        //         label: 'Directory',
        //         placeholder: '(optional) Enter a directory for the files',
        //         tooltip: 'This will place all the files uploaded in this field in the directory',
        //         weight: 20
        //     },
        //     {
        //         type: 'textfield',
        //         input: true,
        //         key: 'fileNameTemplate',
        //         label: 'File Name Template',
        //         placeholder: '(optional) {{{name}}-{{guid}}}}}',
        //         tooltip: 'Specify template for name of uploaded file(s). Regular template variables are available (`data`, `component`, `user`, `value`, `moment` etc.), also `fileName`, `guid` variables are available. `guid` part must be present, if not found in template, will be added at the end.',
        //         weight: 25
        //     },
        //     {
        //         type: 'checkbox',
        //         input: true,
        //         key: 'image',
        //         label: 'Display as image(s)',
        //         tooltip: 'Instead of a list of linked files, images will be rendered in the view.',
        //         weight: 30
        //     },
        //     {
        //         type: 'checkbox',
        //         input: true,
        //         key: 'uploadOnly',
        //         label: 'Upload Only',
        //         tooltip: 'When this is checked, will only allow you to upload file(s) and consequently the download, in this component, will be unavailable.',
        //         weight: 33,
        //     },
        //     {
        //         type: 'checkbox',
        //         input: true,
        //         key: 'privateDownload',
        //         label: 'Private Download',
        //         tooltip: 'When this is checked, the file download will send a POST request to the download URL with the x-jwt-token header. This will allow your endpoint to create a Private download system.',
        //         weight: 31,
        //         conditional: {
        //             json: { '===': [{ var: 'data.storage' }, 'url'] }
        //         }
        //     },
        //     {
        //         type: 'textfield',
        //         input: true,
        //         key: 'imageSize',
        //         label: 'Image Size',
        //         placeholder: '100',
        //         tooltip: 'The image size for previewing images.',
        //         weight: 40,
        //         conditional: {
        //             json: { '==': [{ var: 'data.image' }, true] }
        //         }
        //     },
        //     {
        //         type: 'checkbox',
        //         input: true,
        //         key: 'webcam',
        //         label: 'Enable web camera',
        //         tooltip: 'This will allow using an attached camera to directly take a picture instead of uploading an existing file.',
        //         weight: 32
        //     },
        //     {
        //         type: 'textfield',
        //         input: true,
        //         key: 'webcamSize',
        //         label: 'Webcam Width',
        //         placeholder: '320',
        //         tooltip: 'The webcam size for taking pictures.',
        //         weight: 38,
        //         conditional: {
        //             json: { '==': [{ var: 'data.webcam' }, true] }
        //         }
        //     },
        //     {
        //         type: 'checkbox',
        //         input: true,
        //         key: 'setcompression',
        //         label: 'Set Compression values',
        //         tooltip: 'This will allow to enter image compression values',
        //         weight: 32
        //     },
        //     {
        //         type: 'textfield',
        //         input: true,
        //         key: 'fileCompressionSize',
        //         label: 'File Compression size',
        //         placeholder: '',
        //         tooltip: 'File Compression size.',
        //         weight: 38,
        //         conditional: {
        //             json: { '==': [{ var: 'data.setcompression' }, true] }
        //         }
        //     },
        //     {
        //         type: 'textfield',
        //         input: true,
        //         key: 'MaximumCompressionHeight',
        //         label: 'Maximum Height',
        //         placeholder: '',
        //         tooltip: 'Maximum Compression Height.',
        //         weight: 38,
        //         conditional: {
        //             json: { '==': [{ var: 'data.setcompression' }, true] }
        //         }
        //     },
        //     {
        //         type: 'textfield',
        //         input: true,
        //         key: 'MaximumCompressionWidth',
        //         label: 'Maximum Width',
        //         placeholder: '',
        //         tooltip: 'Maximum Compression Width.',
        //         weight: 38,
        //         conditional: {
        //             json: { '==': [{ var: 'data.setcompression' }, true] }
        //         }
        //     },
        //     {
        //         type: 'datagrid',
        //         input: true,
        //         label: 'File Types',
        //         key: 'fileTypes',
        //         tooltip: 'Specify file types to classify the uploads. This is useful if you allow multiple types of uploads but want to allow the user to specify which type of file each is.',
        //         weight: 11,
        //         components: [
        //             {
        //                 label: 'Label',
        //                 key: 'label',
        //                 input: true,
        //                 type: 'textfield'
        //             },
        //             {
        //                 label: 'Value',
        //                 key: 'value',
        //                 input: true,
        //                 type: 'textfield'
        //             }
        //         ]
        //     },
        //     {
        //         type: 'textfield',
        //         input: true,
        //         key: 'filePattern',
        //         label: 'File Pattern',
        //         placeholder: '.jpg,video/*,application/pdf',
        //         tooltip: 'See <a href=\'https://github.com/danialfarid/ng-file-upload#full-reference\' target=\'_blank\'>https://github.com/danialfarid/ng-file-upload#full-reference</a> for how to specify file patterns.',
        //         weight: 50
        //     },
        //     {
        //         type: 'textfield',
        //         input: true,
        //         key: 'fileMinSize',
        //         label: 'File Minimum Size',
        //         placeholder: '1MB',
        //         tooltip: 'See <a href=\'https://github.com/danialfarid/ng-file-upload#full-reference\' target=\'_blank\'>https://github.com/danialfarid/ng-file-upload#full-reference</a> for how to specify file sizes.',
        //         weight: 60
        //     },
        //     {
        //         type: 'textfield',
        //         input: true,
        //         key: 'fileMaxSize',
        //         label: 'File Maximum Size',
        //         placeholder: '10MB',
        //         tooltip: 'See <a href=\'https://github.com/danialfarid/ng-file-upload#full-reference\' target=\'_blank\'>https://github.com/danialfarid/ng-file-upload#full-reference</a> for how to specify file sizes.',
        //         weight: 70
        //     },
        // ]};




        components: [
            {
                type: 'tabs',
                key: 'tabs',
                components: [
                    {
                        label: 'Display',
                        key: 'display',
                        weight: 0,
                        // components: FileEditDisplay
                        // components: [
                        //     {
                        //         weight: 0,
                        //         type: 'textfield',
                        //         input: true,
                        //         key: 'label',
                        //         label: 'Label',
                        //         placeholder: 'Field Label',
                        //         tooltip: 'The label for this field that will appear next to it.',
                        //         validate: {
                        //             required: true
                        //         }
                        //     },
                        //     {
                        //         weight: 20,
                        //         type: 'checkbox',
                        //         label: 'Hidden',
                        //         tooltip: 'A hidden field is still a part of the form, but is hidden from view.',
                        //         key: 'hidden',
                        //         input: true
                        //     },

                        //     {
                        //         weight: 40,
                        //         type: 'checkbox',
                        //         label: 'Clear Value When Hidden',
                        //         key: 'clearOnHide',
                        //         defaultValue: true,
                        //         tooltip: 'When a field is hidden, clear the value.',
                        //         input: true
                        //     },

                        // ]
                        components: [
                            {
                                weight: 0,
                                type: 'textfield',
                                input: true,
                                key: 'label',
                                label: 'Label',
                                placeholder: 'Field Label',
                                tooltip: 'The label for this field that will appear next to it.',
                                validate: {
                                    required: true
                                }
                            },
                            {
                                type: 'select',
                                input: true,
                                key: 'labelPosition',
                                label: 'Label Position',
                                tooltip: 'Position for the label for this field.',
                                weight: 20,
                                defaultValue: 'top',
                                dataSrc: 'values',
                                data: {
                                    values: [
                                        { label: 'Top', value: 'top' },
                                        { label: 'Left (Left-aligned)', value: 'left-left' },
                                        { label: 'Left (Right-aligned)', value: 'left-right' },
                                        { label: 'Right (Left-aligned)', value: 'right-left' },
                                        { label: 'Right (Right-aligned)', value: 'right-right' },
                                        { label: 'Bottom', value: 'bottom' }
                                    ]
                                }
                            },
                            {
                                type: 'number',
                                input: true,
                                key: 'labelWidth',
                                label: 'Label Width',
                                tooltip: 'The width of label on line in percentages.',
                                clearOnHide: false,
                                weight: 30,
                                placeholder: '30',
                                suffix: '%',
                                validate: {
                                    min: 0,
                                    max: 100
                                },
                                conditional: {
                                    json: {
                                        and: [
                                            { '!==': [{ var: 'data.labelPosition' }, 'top'] },
                                            { '!==': [{ var: 'data.labelPosition' }, 'bottom'] },
                                        ]
                                    }
                                }
                            },
                            {
                                type: 'number',
                                input: true,
                                key: 'labelMargin',
                                label: 'Label Margin',
                                tooltip: 'The width of label margin on line in percentages.',
                                clearOnHide: false,
                                weight: 30,
                                placeholder: '3',
                                suffix: '%',
                                validate: {
                                    min: 0,
                                    max: 100
                                },
                                conditional: {
                                    json: {
                                        and: [
                                            { '!==': [{ var: 'data.labelPosition' }, 'top'] },
                                            { '!==': [{ var: 'data.labelPosition' }, 'bottom'] },
                                        ]
                                    }
                                }
                            },
                            // {
                            //     weight: 100,
                            //     type: 'textfield',
                            //     input: true,
                            //     key: 'placeholder',
                            //     label: 'Placeholder',
                            //     placeholder: 'Placeholder',
                            //     tooltip: 'The placeholder text that will appear when this field is empty.'
                            // },
                            {
                                weight: 200,
                                type: 'textarea',
                                input: true,
                                key: 'description',
                                label: 'Description',
                                placeholder: 'Description for this field.',
                                tooltip: 'The description is text that will appear below the input field.',
                                editor: 'ace',
                                as: 'html',
                                wysiwyg: {
                                    minLines: 3,
                                    isUseWorkerDisabled: true,
                                },
                            },
                            {
                                weight: 300,
                                type: 'textarea',
                                input: true,
                                key: 'tooltip',
                                label: 'Tooltip',
                                placeholder: 'To add a tooltip to this field, enter text here.',
                                tooltip: 'Adds a tooltip to the side of this field.',
                                editor: 'ace',
                                as: 'html',
                                wysiwyg: {
                                    minLines: 3,
                                    isUseWorkerDisabled: true,
                                },
                            },
                            {
                                weight: 500,
                                type: 'textfield',
                                input: true,
                                key: 'customClass',
                                label: 'Custom CSS Class',
                                placeholder: 'Custom CSS Class',
                                tooltip: 'Custom CSS class to add to this component.'
                            },
                            {
                                weight: 600,
                                type: 'textfield',
                                input: true,
                                key: 'tabindex',
                                label: 'Tab Index',
                                placeholder: '0',
                                tooltip: 'Sets the tabindex attribute of this component to override the tab order of the form. See the <a href=\'https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/tabindex\'>MDN documentation</a> on tabindex for more information.'
                            },
                            {
                                weight: 1100,
                                type: 'checkbox',
                                label: 'Hidden1',
                                tooltip: 'A hidden field is still a part of the form, but is hidden from view.',
                                key: 'hidden',
                                input: true
                            },
                            {
                                weight: 1200,
                                type: 'checkbox',
                                label: 'Hide Label',
                                tooltip: 'Hide the label (title, if no label) of this component. This allows you to show the label in the form builder, but not when it is rendered.',
                                key: 'hideLabel',
                                input: true
                            },
                            {
                                weight: 1350,
                                type: 'checkbox',
                                label: 'Initial Focus',
                                tooltip: 'Make this field the initially focused element on this form.',
                                key: 'autofocus',
                                input: true
                            },
                            {
                                weight: 1370,
                                type: 'checkbox',
                                label: 'Show Label in DataGrid',
                                tooltip: 'Show the label when in a Datagrid.',
                                key: 'dataGridLabel',
                                input: true,
                                customConditional(context) {
                                    // return context.instance.options?.flags?.inDataGrid;
                                }
                            },
                            {
                                weight: 1400,
                                type: 'checkbox',
                                label: 'Disabled',
                                tooltip: 'Disable the form input.',
                                key: 'disabled',
                                input: true
                            },
                            {
                                weight: 1500,
                                type: 'checkbox',
                                label: 'Table View',
                                tooltip: 'Shows this value within the table view of the submissions.',
                                key: 'tableView',
                                input: true
                            },
                            {
                                weight: 1600,
                                type: 'checkbox',
                                label: 'Modal Edit',
                                tooltip: 'Opens up a modal to edit the value of this component.',
                                key: 'modalEdit',
                                input: true
                            }
                        ]
                    },
                    {
                        label: 'File',
                        key: 'file',
                        weight: 0,
                        components: [
                            {
                                type: 'select',
                                input: true,
                                key: 'storage',
                                label: 'Storage',
                                placeholder: 'Select your file storage provider',
                                weight: 0,
                                tooltip: 'Which storage to save the files in.',
                                data: {
                                values: [
                                  { label: 'Base64', value: 'base64' },
                                  { label: 'Smart Storage', value: 'SmartStorage' }
                                ]
                            }
                            },
                            // {
                            //     type: 'select',
                            //     input: true,
                            //     key: 'storage',
                            //     label: 'Storage',
                            //     placeholder: 'Select your file storage provider',
                            //     weight: 0,
                            //     tooltip: 'Which storage to save the files in.',
                            //     valueProperty: 'value',
                            //     dataSrc: 'custom',
                            //     data: {
                            //         custom() {
                            //             return _.map(Formio.Providers.getProviders('storage'), (storage, key) => ({
                            //                 label: storage.title,
                            //                 value: key
                            //             }));
                            //         }
                            //     }
                            // },
                            {
                                type: 'textfield',
                                input: true,
                                key: 'url',
                                label: 'Url',
                                weight: 10,
                                placeholder: 'Enter the url to post the files to.',
                                tooltip: "See <a href='https://github.com/danialfarid/ng-file-upload#server-side' target='_blank'>https://github.com/danialfarid/ng-file-upload#server-side</a> for how to set up the server.",
                                conditional: {
                                    json: { '===': [{ var: 'data.storage' }, 'url'] }
                                }
                            },
                            {
                                type: 'textfield',
                                input: true,
                                key: 'options.indexeddb',
                                label: 'Database',
                                weight: 10,
                                placeholder: 'Enter the indexeddb database name',
                                conditional: {
                                    json: {
                                        in: [
                                            {
                                                var: 'data.storage'
                                            },
                                            ['indexeddb']
                                        ],
                                    }
                                }
                            },
                            {
                                type: 'textfield',
                                input: true,
                                label: 'Table',
                                key: 'options.indexeddbTable',
                                weight: 10,
                                placeholder: 'Enter the name for indexeddb table',
                                conditional: {
                                    json: {
                                        in: [
                                            {
                                                var: 'data.storage'
                                            },
                                            ['indexeddb']
                                        ],
                                    }
                                }
                            },
                            {
                                type: 'textarea',
                                key: 'options',
                                label: 'Custom request options',
                                tooltip: 'Pass your custom xhr options(optional)',
                                rows: 5,
                                editor: 'ace',
                                input: true,
                                weight: 15,
                                placeholder: `{
          "withCredentials": true
        }`,
                                conditional: {
                                    json: {
                                        '===': [{
                                            var: 'data.storage'
                                        }, 'url']
                                    }
                                }
                            },
                            {
                                type: 'textfield',
                                input: true,
                                key: 'fileKey',
                                label: 'File form-data key',
                                weight: 17,
                                placeholder: 'Enter the key name of a file for form data.',
                                tooltip: 'Key name that you would like to modify for the file while calling API request.',
                                conditional: {
                                    json: {
                                        '===': [{
                                            var: 'data.storage'
                                        }, 'url']
                                    }
                                }
                            },
                            {
                                type: 'textfield',
                                input: true,
                                key: 'dir',
                                label: 'Directory',
                                placeholder: '(optional) Enter a directory for the files',
                                tooltip: 'This will place all the files uploaded in this field in the directory',
                                weight: 20
                            },
                            {
                                type: 'textfield',
                                input: true,
                                key: 'fileNameTemplate',
                                label: 'File Name Template',
                                placeholder: '(optional) {{{name}}-{{guid}}}}}',
                                tooltip: 'Specify template for name of uploaded file(s). Regular template variables are available (`data`, `component`, `user`, `value`, `moment` etc.), also `fileName`, `guid` variables are available. `guid` part must be present, if not found in template, will be added at the end.',
                                weight: 25
                            },
                            {
                                type: 'checkbox',
                                input: true,
                                key: 'image',
                                label: 'Display as image(s)',
                                tooltip: 'Instead of a list of linked files, images will be rendered in the view.',
                                weight: 30
                            },
                            {
                                type: 'checkbox',
                                input: true,
                                key: 'uploadOnly',
                                label: 'Upload Only',
                                tooltip: 'When this is checked, will only allow you to upload file(s) and consequently the download, in this component, will be unavailable.',
                                weight: 33,
                            },
                            {
                                type: 'checkbox',
                                input: true,
                                key: 'privateDownload',
                                label: 'Private Download',
                                tooltip: 'When this is checked, the file download will send a POST request to the download URL with the x-jwt-token header. This will allow your endpoint to create a Private download system.',
                                weight: 31,
                                conditional: {
                                    json: { '===': [{ var: 'data.storage' }, 'url'] }
                                }
                            },
                            {
                                type: 'textfield',
                                input: true,
                                key: 'imageSize',
                                label: 'Image Size',
                                placeholder: '100',
                                tooltip: 'The image size for previewing images.',
                                weight: 40,
                                conditional: {
                                    json: { '==': [{ var: 'data.image' }, true] }
                                }
                            },
                            {
                                type: 'checkbox',
                                input: true,
                                key: 'webcam',
                                label: 'Enable Web Camera',
                                tooltip: 'This will allow using an attached camera to directly take a picture instead of uploading an existing file.',
                                weight: 32
                            },
                            {
                                type: 'textfield',
                                input: true,
                                key: 'webcamSize',
                                label: 'Webcam Width',
                                placeholder: '320',
                                tooltip: 'The webcam size for taking pictures.',
                                weight: 38,
                                conditional: {
                                    json: { '==': [{ var: 'data.webcam' }, true] }
                                }
                            },
                            {
                                type: 'checkbox',
                                input: true,
                                key: 'changeDefaultCompression',
                                label: 'Change Default Compression',
                                tooltip: 'Customize the default image compression parameters',
                                weight: 32
                            },
                            {
                                type: 'number',
                                input: true,
                                key: 'fileCompressionSize',
                                label: 'Compression Size',
                                placeholder: '2',
                                suffix: 'MB',
                                tooltip: 'File compression size in MB.',
                                weight: 38,
                                conditional: {
                                    json: { '==': [{ var: 'data.changeDefaultCompression' }, true] }
                                }
                            },
                            {
                                type: 'number',
                                input: true,
                                key: 'MaximumCompressionHeightOrWidth',
                                label: 'Maximum Height or Width (pixels)',
                                placeholder: '2048',
                                tooltip: 'Maximum height or width in pixels.',
                                weight: 38,
                                conditional: {
                                    json: { '==': [{ var: 'data.changeDefaultCompression' }, true] }
                                }
                            },
                            {
                                type: 'datagrid',
                                input: true,
                                label: 'File Types',
                                key: 'fileTypes',
                                tooltip: 'Specify file types to classify the uploads. This is useful if you allow multiple types of uploads but want to allow the user to specify which type of file each is.',
                                weight: 11,
                                components: [
                                    {
                                        label: 'Label',
                                        key: 'label',
                                        input: true,
                                        type: 'textfield'
                                    },
                                    {
                                        label: 'Value',
                                        key: 'value',
                                        input: true,
                                        type: 'textfield'
                                    }
                                ]
                            },
                            {
                                type: 'textfield',
                                input: true,
                                key: 'filePattern',
                                label: 'File Pattern',
                                placeholder: '.jpg,video/*,application/pdf',
                                tooltip: 'See <a href=\'https://github.com/danialfarid/ng-file-upload#full-reference\' target=\'_blank\'>https://github.com/danialfarid/ng-file-upload#full-reference</a> for how to specify file patterns.',
                                weight: 50
                            },
                            {
                                type: 'textfield',
                                input: true,
                                key: 'fileMinSize',
                                label: 'File Minimum Size',
                                placeholder: '1MB',
                                tooltip: 'See <a href=\'https://github.com/danialfarid/ng-file-upload#full-reference\' target=\'_blank\'>https://github.com/danialfarid/ng-file-upload#full-reference</a> for how to specify file sizes.',
                                weight: 60
                            },
                            {
                                type: 'textfield',
                                input: true,
                                key: 'fileMaxSize',
                                label: 'File Maximum Size',
                                placeholder: '10MB',
                                tooltip: 'See <a href=\'https://github.com/danialfarid/ng-file-upload#full-reference\' target=\'_blank\'>https://github.com/danialfarid/ng-file-upload#full-reference</a> for how to specify file sizes.',
                                weight: 70
                            },
                        ]
                    },
                    {
                        label: 'Data',
                        key: 'data',
                        weight: 0,
                        components: [

                            {
                                weight: 0,
                                type: 'checkbox',
                                label: 'Multiple Values',
                                tooltip: 'Allows multiple values to be entered for this field.',
                                key: 'multiple',
                                input: true
                            },

                            {
                                weight: 30,
                                type: 'radio',
                                label: 'Persistent',
                                tooltip: 'A persistent field will be stored in database when the form is submitted.',
                                key: 'persistent',
                                input: true,
                                inline: true,
                                defaultValue: true,
                                values: [
                                    { label: 'None', value: false },
                                    { label: 'Server', value: true },
                                    { label: 'Client', value: 'client-only' },
                                ]
                            },
                            {
                                weight: 150,
                                type: 'checkbox',
                                label: 'Protected',
                                tooltip: 'A protected field will not be returned when queried via API.',
                                key: 'protected',
                                input: true
                            },
                            {
                                type: 'checkbox',
                                input: true,
                                weight: 200,
                                key: 'dbIndex',
                                label: 'Database Index',
                                tooltip: 'Set this field as an index within the database. Increases performance for submission queries.'
                            },
                            {
                                weight: 400,
                                type: 'checkbox',
                                label: 'Encrypted (Enterprise Only)',
                                tooltip: 'Encrypt this field on the server. This is two way encryption which is not suitable for passwords.',
                                key: 'encrypted',
                                input: true
                            },
                            {
                                type: 'select',
                                input: true,
                                key: 'redrawOn',
                                label: 'Redraw On',
                                weight: 600,
                                tooltip: 'Redraw this component if another component changes. This is useful if interpolating parts of the component like the label.',
                                dataSrc: 'custom',
                                valueProperty: 'value',
                                data: {
                                    custom(context) {
                                        var values = [];
                                        values.push({ label: 'Any Change', value: 'data' });
                                        context.utils.eachComponent(context.instance.options.editForm.components, function (component, path) {
                                            if (component.key !== context.data.key) {
                                                values.push({
                                                    label: component.label || component.key,
                                                    value: path
                                                });
                                            }
                                        });
                                        return values;
                                    }
                                },
                                conditional: {
                                    json: { '!': [{ var: 'data.dataSrc' }] },
                                },
                            },
                            {
                                weight: 700,
                                type: 'checkbox',
                                label: 'Clear Value When Hidden',
                                key: 'clearOnHide',
                                defaultValue: true,
                                tooltip: 'When a field is hidden, clear the value.',
                                input: true
                            },
                            EditFormUtils.javaScriptValue('Custom Default Value', 'customDefaultValue', 'customDefaultValue', 1000,
                                '<p><h4>Example:</h4><pre>value = data.firstName + " " + data.lastName;</pre></p>',
                                '<p><h4>Example:</h4><pre>{"cat": [{"var": "data.firstName"}, " ", {"var": "data.lastName"}]}</pre>'
                            ),
                            EditFormUtils.javaScriptValue('Calculated Value', 'calculateValue', 'calculateValue', 1100,
                                '<p><h4>Example:</h4><pre>value = data.a + data.b + data.c;</pre></p>',
                                '<p><h4>Example:</h4><pre>{"+": [{"var": "data.a"}, {"var": "data.b"}, {"var": "data.c"}]}</pre><p><a target="_blank" href="http://formio.github.io/formio.js/app/examples/calculated.html">Click here for an example</a></p>',
                                '<tr><th>token</th><td>The decoded JWT token for the authenticated user.</td></tr>'
                            ),
                            {
                                type: 'checkbox',
                                input: true,
                                weight: 1100,
                                key: 'calculateServer',
                                label: 'Calculate Value on server',
                                tooltip: 'Checking this will run the calculation on the server. This is useful if you wish to override the values submitted with the calculations performed on the server.'
                            },
                            {
                                type: 'checkbox',
                                input: true,
                                weight: 1200,
                                key: 'allowCalculateOverride',
                                label: 'Allow Manual Override of Calculated Value',
                                tooltip: 'When checked, this will allow the user to manually override the calculated value.'
                            },

                        ]
                    },
                    {
                        label: 'Validation',
                        key: 'validation',
                        weight: 10,
                        components: [
                            {
                                weight: 10,
                                type: 'checkbox',
                                label: 'Required',
                                tooltip: 'A required field must be filled in before the form can be submitted.',
                                key: 'validate.required',
                                input: true
                            },
                            {
                                weight: 190,
                                type: 'textfield',
                                input: true,
                                key: 'errorLabel',
                                label: 'Error Label',
                                placeholder: 'Error Label',
                                tooltip: 'The label for this field when an error occurs.'
                            },
                            {
                                weight: 200,
                                key: 'validate.customMessage',
                                label: 'Custom Error Message',
                                placeholder: 'Custom Error Message',
                                type: 'textfield',
                                tooltip: 'Error message displayed if any error occurred.',
                                input: true
                            },
                            {
                                type: 'panel',
                                title: 'Custom Validation',
                                collapsible: true,
                                collapsed: true,
                                style: { 'margin-bottom': '10px' },
                                key: 'custom-validation-js',
                                weight: 300,
                                customConditional() {
                                    return !FormioUtils.Evaluator.noeval || FormioUtils.Evaluator.protectedEval;
                                },
                                components: [
                                    EditFormUtils.logicVariablesTable('<tr><th>input</th><td>The value that was input into this component</td></tr>'),
                                    {
                                        type: 'textarea',
                                        key: 'validate.custom',
                                        rows: 5,
                                        editor: 'ace',
                                        hideLabel: true,
                                        as: 'javascript',
                                        input: true
                                    },
                                    {
                                        type: 'htmlelement',
                                        tag: 'div',
                                        content: `
          <small>
            <p>Enter custom validation code.</p>
            <p>You must assign the <strong>valid</strong> variable as either <strong>true</strong> or an error message if validation fails.</p>
            <h5>Example:</h5>
            <pre>valid = (input === 'Joe') ? true : 'Your name must be "Joe"';</pre>
          </small>`
                                    },
                                    {
                                        type: 'well',
                                        components: [
                                            {
                                                weight: 100,
                                                type: 'checkbox',
                                                label: 'Secret Validation',
                                                tooltip: 'Check this if you wish to perform the validation ONLY on the server side. This keeps your validation logic private and secret.',
                                                description: 'Check this if you wish to perform the validation ONLY on the server side. This keeps your validation logic private and secret.',
                                                key: 'validate.customPrivate',
                                                input: true
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                type: 'panel',
                                title: 'JSONLogic Validation',
                                collapsible: true,
                                collapsed: true,
                                key: 'json-validation-json',
                                weight: 400,
                                components: [
                                    {
                                        type: 'htmlelement',
                                        tag: 'div',
                                        /* eslint-disable prefer-template */
                                        content: '<p>Execute custom logic using <a href="http://jsonlogic.com/" target="_blank">JSONLogic</a>.</p>' +
                                            '<h5>Example:</h5>' +
                                            '<pre>' + JSON.stringify({
                                                "if": [
                                                    { "===": [{ "var": "input" }, "Bob"] },
                                                    true,
                                                    "Your name must be 'Bob'!"
                                                ]
                                            }, null, 2) + '</pre>'
                                        /* eslint-enable prefer-template */
                                    },
                                    {
                                        type: 'textarea',
                                        key: 'validate.json',
                                        hideLabel: true,
                                        rows: 5,
                                        editor: 'ace',
                                        as: 'json',
                                        input: true
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        label: 'API',
                        key: 'api',
                        weight: 20,
                        components: [
                            {
                                weight: 0,
                                type: 'textfield',
                                input: true,
                                key: 'key',
                                label: 'Property Name',
                                tooltip: 'The name of this field in the API endpoint.',
                                validate: {
                                    pattern: '(\\w|\\w[\\w-.]*\\w)',
                                    patternMessage: 'The property name must only contain alphanumeric characters, underscores, dots and dashes and should not be ended by dash or dot.',
                                    required: true
                                }
                            },
                            {
                                weight: 100,
                                type: 'tags',
                                input: true,
                                label: 'Field Tags',
                                storeas: 'array',
                                tooltip: 'Tag the field for use in custom logic.',
                                key: 'tags'
                            },
                            {
                                weight: 200,
                                type: 'datamap',
                                label: 'Custom Properties',
                                tooltip: 'This allows you to configure any custom properties for this component.',
                                key: 'properties',
                                valueComponent: {
                                    type: 'textfield',
                                    key: 'value',
                                    label: 'Value',
                                    placeholder: 'Value',
                                    input: true
                                }
                            },
                        ]
                    },
                    {
                        label: 'Conditional',
                        key: 'conditional',
                        weight: 20,
                        components: [
                            {
                                type: 'panel',
                                title: 'Simple',
                                key: 'simple-conditional',
                                theme: 'default',
                                components: [
                                    {
                                        type: 'select',
                                        input: true,
                                        label: 'This component should Display:',
                                        key: 'conditional.show',
                                        dataSrc: 'values',
                                        data: {
                                            values: [
                                                { label: 'True', value: 'true' },
                                                { label: 'False', value: 'false' }
                                            ]
                                        }
                                    },
                                    {
                                        type: 'select',
                                        input: true,
                                        label: 'When the form component:',
                                        key: 'conditional.when',
                                        dataSrc: 'custom',
                                        valueProperty: 'value',

                                        data: {
                                            custom(context) {
                                               return FormioUtils.getContextComponents(context)
                                            }
                                        }
                                    },
                                    {
                                        type: 'textfield',
                                        input: true,
                                        label: 'Has the value:',
                                        key: 'conditional.eq'
                                    }
                                ]
                            },
                            EditFormUtils.javaScriptValue('Advanced Conditions', 'customConditional', 'conditional.json', 110,
                                '<p>You must assign the <strong>show</strong> variable a boolean result.</p>' +
                                '<p><strong>Note: Advanced Conditional logic will override the results of the Simple Conditional logic.</strong></p>' +
                                '<h5>Example</h5><pre>show = !!data.showMe;</pre>',
                                '<p><a href="http://formio.github.io/formio.js/app/examples/conditions.html" target="_blank">Click here for an example</a></p>'
                            )
                        ]
                    },
                    {
                        label: 'Logic',
                        key: 'logic',
                        weight: 20,
                        components: [
                            {
                                weight: 0,
                                input: true,
                                label: 'Advanced Logic',
                                key: 'logic',
                                templates: {
                                    header: '<div class="row"> \n  <div class="col-sm-6">\n    <strong>{{ value.length }} {{ "Advanced Logic Configured" }}</strong>\n  </div>\n</div>',
                                    row: '<div class="row"> \n  <div class="col-sm-6">\n    <div>{{ row.name }} </div>\n  </div>\n  <div class="col-sm-2"> \n    <div class="btn-group pull-right"> \n      <div class="btn btn-default editRow">{{ "Edit" }}</div> \n      <div class="btn btn-danger removeRow">{{ "Delete" }}</div> \n    </div> \n  </div> \n</div>',
                                    footer: '',
                                },
                                type: 'editgrid',
                                addAnother: 'Add Logic',
                                saveRow: 'Save Logic',
                                components: [
                                    {
                                        weight: 0,
                                        input: true,
                                        inputType: 'text',
                                        label: 'Logic Name',
                                        key: 'name',
                                        validate: {
                                            required: true,
                                        },
                                        type: 'textfield',
                                    },
                                    {
                                        weight: 10,
                                        key: 'triggerPanel',
                                        input: false,
                                        title: 'Trigger',
                                        tableView: false,
                                        components: [
                                            {
                                                weight: 0,
                                                input: true,
                                                tableView: false,
                                                components: [
                                                    {
                                                        weight: 0,
                                                        input: true,
                                                        label: 'Type',
                                                        key: 'type',
                                                        tableView: false,
                                                        data: {
                                                            values: [
                                                                {
                                                                    value: 'simple',
                                                                    label: 'Simple',
                                                                },
                                                                {
                                                                    value: 'javascript',
                                                                    label: 'Javascript',
                                                                },
                                                                {
                                                                    value: 'json',
                                                                    label: 'JSON Logic',
                                                                },
                                                                {
                                                                    value: 'event',
                                                                    label: 'Event',
                                                                },
                                                            ],
                                                        },
                                                        dataSrc: 'values',
                                                        template: '<span>{{ item.label }}</span>',
                                                        type: 'select',
                                                    },
                                                    {
                                                        weight: 10,
                                                        label: '',
                                                        key: 'simple',
                                                        type: 'container',
                                                        tableView: false,
                                                        customConditional({ row }) {
                                                            return row.type === 'simple';
                                                        },
                                                        components: [
                                                            {
                                                                input: true,
                                                                key: 'show',
                                                                label: 'Show',
                                                                type: 'hidden',
                                                                tableView: false,
                                                                calculateValue() {
                                                                    return true;
                                                                },
                                                            },
                                                            {
                                                                type: 'select',
                                                                input: true,
                                                                label: 'When the form component:',
                                                                key: 'when',
                                                                dataSrc: 'custom',
                                                                valueProperty: 'value',
                                                                tableView: false,
                                                                data: {
                                                                    custom(context) {
                                                                        return FormioUtils.getContextComponents(context)
                                                                    },
                                                                },
                                                            },
                                                            {
                                                                type: 'textfield',
                                                                input: true,
                                                                label: 'Has the value:',
                                                                key: 'eq',
                                                                tableView: false,
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        weight: 10,
                                                        type: 'textarea',
                                                        key: 'javascript',
                                                        rows: 5,
                                                        editor: 'ace',
                                                        as: 'javascript',
                                                        input: true,
                                                        tableView: false,
                                                        placeholder: `result = (data['mykey'] > 1);`,
                                                        description: '"row", "data", and "component" variables are available. Return "result".',
                                                        customConditional({ row }) {
                                                            return row.type === 'javascript';
                                                        },
                                                    },
                                                    {
                                                        weight: 10,
                                                        type: 'textarea',
                                                        key: 'json',
                                                        rows: 5,
                                                        editor: 'ace',
                                                        label: 'JSON Logic',
                                                        as: 'json',
                                                        input: true,
                                                        tableView: false,
                                                        placeholder: `{ ... }`,
                                                        description: '"row", "data", "component" and "_" variables are available. Return the result to be passed to the action if truthy.',
                                                        customConditional({ row }) {
                                                            return row.type === 'json';
                                                        },
                                                    },
                                                    {
                                                        weight: 10,
                                                        type: 'textfield',
                                                        key: 'event',
                                                        label: 'Event Name',
                                                        placeholder: 'event',
                                                        description: 'The event that will trigger this logic. You can trigger events externally or via a button.',
                                                        tableView: false,
                                                        customConditional({ row }) {
                                                            return row.type === 'event';
                                                        },
                                                    },
                                                ],
                                                key: 'trigger',
                                                type: 'container',
                                            },
                                        ],
                                        type: 'panel',
                                    },
                                    {
                                        weight: 20,
                                        input: true,
                                        label: 'Actions',
                                        key: 'actions',
                                        tableView: false,
                                        templates: {
                                            header: '<div class="row"> \n  <div class="col-sm-6"><strong>{{ value.length }} {{"actions" }}</strong></div>\n</div>',
                                            row: '<div class="row"> \n  <div class="col-sm-6">\n    <div>{{ row.name }} </div>\n  </div>\n  <div class="col-sm-2"> \n    <div class="btn-group pull-right"> \n      <div class="btn btn-default editRow">{{ "Edit" }}</div> \n      <div class="btn btn-danger removeRow">{{ "Delete" }}</div> \n    </div> \n  </div> \n</div>',
                                            footer: '',
                                        },
                                        type: 'editgrid',
                                        addAnother: 'Add Action',
                                        saveRow: 'Save Action',
                                        components: [
                                            {
                                                weight: 0,
                                                title: 'Action',
                                                input: false,
                                                key: 'actionPanel',
                                                type: 'panel',
                                                components: [
                                                    {
                                                        weight: 0,
                                                        input: true,
                                                        inputType: 'text',
                                                        label: 'Action Name',
                                                        key: 'name',
                                                        validate: {
                                                            required: true,
                                                        },
                                                        type: 'textfield',
                                                    },
                                                    {
                                                        weight: 10,
                                                        input: true,
                                                        label: 'Type',
                                                        key: 'type',
                                                        data: {
                                                            values: [
                                                                {
                                                                    value: 'property',
                                                                    label: 'Property',
                                                                },
                                                                {
                                                                    value: 'value',
                                                                    label: 'Value',
                                                                },
                                                                {
                                                                    label: 'Merge Component Schema',
                                                                    value: 'mergeComponentSchema',
                                                                },
                                                                {
                                                                    label: 'Custom Action',
                                                                    value: 'customAction',
                                                                },
                                                            ],
                                                        },
                                                        dataSrc: 'values',
                                                        template: '<span>{{ item.label }}</span>',
                                                        type: 'select',
                                                    },
                                                    {
                                                        weight: 20,
                                                        type: 'select',
                                                        template: '<span>{{ item.label }}</span>',
                                                        dataSrc: 'json',
                                                        tableView: false,
                                                        data: {
                                                            json: [
                                                                {
                                                                    label: 'Hidden',
                                                                    value: 'hidden',
                                                                    type: 'boolean',
                                                                },
                                                                {
                                                                    label: 'Required',
                                                                    value: 'validate.required',
                                                                    type: 'boolean',
                                                                },
                                                                {
                                                                    label: 'Disabled',
                                                                    value: 'disabled',
                                                                    type: 'boolean',
                                                                },
                                                                {
                                                                    label: 'Label',
                                                                    value: 'label',
                                                                    type: 'string',
                                                                },
                                                                {
                                                                    label: 'Title',
                                                                    value: 'title',
                                                                    type: 'string',
                                                                },
                                                                {
                                                                    label: 'Prefix',
                                                                    value: 'prefix',
                                                                    type: 'string',
                                                                },
                                                                {
                                                                    label: 'Suffix',
                                                                    value: 'suffix',
                                                                    type: 'string',
                                                                },
                                                                {
                                                                    label: 'Tooltip',
                                                                    value: 'tooltip',
                                                                    type: 'string',
                                                                },
                                                                {
                                                                    label: 'Description',
                                                                    value: 'description',
                                                                    type: 'string',
                                                                },
                                                                {
                                                                    label: 'Placeholder',
                                                                    value: 'placeholder',
                                                                    type: 'string',
                                                                },
                                                                {
                                                                    label: 'Input Mask',
                                                                    value: 'inputMask',
                                                                    type: 'string',
                                                                },
                                                                {
                                                                    label: 'CSS Class',
                                                                    value: 'className',
                                                                    type: 'string',
                                                                },
                                                                {
                                                                    label: 'Container Custom Class',
                                                                    value: 'customClass',
                                                                    type: 'string',
                                                                },
                                                            ],
                                                        },
                                                        key: 'property',
                                                        label: 'Component Property',
                                                        input: true,
                                                        customConditional({ row }) {
                                                            return row.type === 'property';
                                                        },
                                                    },
                                                    {
                                                        weight: 30,
                                                        input: true,
                                                        label: 'Set State',
                                                        key: 'state',
                                                        tableView: false,
                                                        data: {
                                                            values: [
                                                                {
                                                                    label: 'True',
                                                                    value: 'true',
                                                                },
                                                                {
                                                                    label: 'False',
                                                                    value: 'false',
                                                                },
                                                            ],
                                                        },
                                                        dataSrc: 'values',
                                                        template: '<span>{{ item.label }}</span>',
                                                        type: 'select',
                                                        customConditional({ row }) {
                                                            return row.type === 'property' &&
                                                                row.hasOwnProperty('property') &&
                                                                row.property.type === 'boolean';
                                                        },
                                                    },
                                                    {
                                                        weight: 30,
                                                        type: 'textfield',
                                                        key: 'text',
                                                        label: 'Text',
                                                        inputType: 'text',
                                                        input: true,
                                                        tableView: false,
                                                        description: 'Can use templating with {{ data.myfield }}. "data", "row", "component" and "result" variables are available.',
                                                        customConditional({ row }) {
                                                            return row.type === 'property' &&
                                                                row.hasOwnProperty('property') &&
                                                                row.property.type === 'string' &&
                                                                !row.property.component;
                                                        },
                                                    },
                                                    {
                                                        weight: 20,
                                                        input: true,
                                                        label: 'Value (Javascript)',
                                                        key: 'value',
                                                        editor: 'ace',
                                                        as: 'javascript',
                                                        rows: 5,
                                                        placeholder: `value = data.myfield;`,
                                                        type: 'textarea',
                                                        tableView: false,
                                                        description: '"row", "data", "component", and "result" variables are available. Return the value.',
                                                        customConditional({ row }) {
                                                            return row.type === 'value';
                                                        },
                                                    },
                                                    {
                                                        weight: 20,
                                                        input: true,
                                                        label: 'Schema Defenition',
                                                        key: 'schemaDefinition',
                                                        editor: 'ace',
                                                        as: 'javascript',
                                                        rows: 5,
                                                        placeholder: `schema = { label: 'Updated' };`,
                                                        type: 'textarea',
                                                        tableView: false,
                                                        description: '"row", "data", "component", and "result" variables are available. Return the schema.',
                                                        customConditional({ row }) {
                                                            return row.type === 'mergeComponentSchema';
                                                        },
                                                    },
                                                    Object.assign(EditFormUtils.logicVariablesTable('<tr><th>input</th><td>The value that was input into this component</td></tr>'),
                                                        {
                                                            customConditional({ row }) {
                                                                return row.type === 'customAction';
                                                            }
                                                        }
                                                    ),
                                                    {
                                                        weight: 20,
                                                        input: true,
                                                        label: 'Custom Action (Javascript)',
                                                        key: 'customAction',
                                                        editor: 'ace',
                                                        rows: 5,
                                                        placeholder: `value = data.myfield;`,
                                                        type: 'textarea',
                                                        tableView: false,
                                                        customConditional({ row }) {
                                                            return row.type === 'customAction';
                                                        },
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                ],
                            }
                        ]
                    },
                    {
                        label: 'Layout',
                        key: 'layout',
                        weight: 20,
                        components: [
                            {
                                label: 'HTML Attributes',
                                type: 'datamap',
                                input: true,
                                key: 'attributes',
                                keyLabel: 'Attribute Name',
                                valueComponent: {
                                    type: 'textfield',
                                    key: 'value',
                                    label: 'Attribute Value',
                                    input: true
                                },
                                tooltip: 'Provide a map of HTML attributes for component\'s input element (attributes provided by other component settings or other attributes generated by form.io take precedence over attributes in this grid)',
                                addAnother: 'Add Attribute',
                            },
                            {
                                type: 'panel',
                                legend: 'PDF Overlay',
                                title: 'PDF Overlay',
                                key: 'overlay',
                                tooltip: 'The settings inside apply only to the PDF forms.',
                                weight: 2000,
                                collapsible: true,
                                collapsed: true,
                                components: [
                                    {
                                        type: 'textfield',
                                        input: true,
                                        key: 'overlay.style',
                                        label: 'Style',
                                        placeholder: '',
                                        tooltip: 'Custom styles that should be applied to this component when rendered in PDF.'
                                    },
                                    {
                                        type: 'textfield',
                                        input: true,
                                        key: 'overlay.page',
                                        label: 'Page',
                                        placeholder: '',
                                        tooltip: 'The PDF page to place this component.'
                                    },
                                    {
                                        type: 'textfield',
                                        input: true,
                                        key: 'overlay.left',
                                        label: 'Left',
                                        placeholder: '',
                                        tooltip: 'The left margin within a page to place this component.'
                                    },
                                    {
                                        type: 'textfield',
                                        input: true,
                                        key: 'overlay.top',
                                        label: 'Top',
                                        placeholder: '',
                                        tooltip: 'The top margin within a page to place this component.'
                                    },
                                    {
                                        type: 'textfield',
                                        input: true,
                                        key: 'overlay.width',
                                        label: 'Width',
                                        placeholder: '',
                                        tooltip: 'The width of the component (in pixels).'
                                    },
                                    {
                                        type: 'textfield',
                                        input: true,
                                        key: 'overlay.height',
                                        label: 'Height',
                                        placeholder: '',
                                        tooltip: 'The height of the component (in pixels).'
                                    },

                                ]
                            }
                        ]
                    }




                    // tabs ended
                ]
            }
        ],
    };
}
