import { Component, OnInit, AfterViewInit, OnDestroy, ChangeDetectorRef, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../auth/authentication.service';
import { whiteSpaceValidator } from '../shared/directives/whitespacevalidator.directive';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { catchError, finalize, map } from 'rxjs/operators';
import { Observable, BehaviorSubject, of as observableOf, merge } from 'rxjs';
import { AppConfigService } from '../app.config';
import { PopupService } from '../auth/popup.service';
import { TranslateService } from '@ngx-translate/core';
import { HotToastService } from '@ngneat/hot-toast';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {
  // private issubmitted: boolean;
  public forgotpasserrmsg: string;
  public forgotpassautherrmsg: string;
  public forgotpassauthsuccessmsg: string;
  public forgetpasswordloading: boolean = false;
  public showpassword = false;
  public showdomainfield: boolean;
  public showssologin: boolean;
  public showadsdomain: boolean;
  public adsdomains = [];
  public isoncloud: boolean;
  public isonPremise: boolean;
  public Domainplaceholder: string = 'Domain';
  public Emailplaceholder: string = 'Email';
  // private inputype = '';
  public basedomain: string;
  public logininfo: any;
  public domainlocal: string;
  public emaillocal: string;
  public firstnamelocal: string;
  public errmsg: string;
  public loginform: FormGroup;
  public languages = [
    {
      key:'en',
      display: 'English'
    },
    {
      key:'fr',
      display: 'Français'
    },
    {
      key:'es',
      display: 'Español'
    }
  ];
  public currentLanguage: any;
  public loggedIn = new BehaviorSubject<boolean>(false);
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );
      public setDomain:any;
  constructor(private appConfigService: AppConfigService,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private authservice: AuthenticationService,
    private popupservice: PopupService,
    public translate: TranslateService,
    private toastService: HotToastService,
    private breakpointObserver: BreakpointObserver) {
    const selectedlang = localStorage.getItem('language');
    if (selectedlang) {
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('en');
    this.currentLanguage = selectedlang;
    this.translate.use(this.currentLanguage);
    }
  }
  ngOnInit() {
    var url_string = window.location.href; 
    var url = new URL(url_string);
    var c = url.searchParams.get("domain");
     this.setDomain=c;
     this.domainlocal = localStorage.getItem('domain');
     if(this.setDomain !== null){
      this.setDomain=c;
      console.log("URL domain",this.setDomain);
     }else{
      this.setDomain='';
      console.log("local storage domain", this.domainlocal);
      if(this.domainlocal !== null){
        this.setDomain=this.domainlocal;
      }else{
        this.setDomain='';
      }
     }
    // console.log('Device Info', this.getDevice());
    // this.setDomain=this.domainlocal;
    this.emaillocal = localStorage.getItem('email');
    // const usr = decode(localStorage.getItem('token'));
    this.firstnamelocal = localStorage.getItem('name');
    // console.log(this.domainlocal, this.emaillocal);
    this.loginform = this.fb.group({
      domain: [`${this.setDomain}`, [Validators.required, whiteSpaceValidator()]],
      email: [this.emaillocal, [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
    this.route.queryParams.subscribe(params => {
      if (!params['error']) {
        localStorage.removeItem('token');
        this.loggedIn.next(false);
        this.router.navigate(['login']);
      } else {
        this.errmsg = params['error'];
        this.toastService.error(
          `${this.errmsg}`,
          {
            autoClose: false,
            id: "auth-error",
            dismissible: true,
            position: "bottom-right",
            style: {
              border: "1px solid var(--error-color)",
              padding: "16px",
              color: "var(--error-color)",
            },
          }
        );
      }
    });
  }
  ngAfterViewInit() {
    /** Logic to find if the app is on-premise or running on cloud */
    // Prio is:  config first, followed by cliud url and then custom
    this.logininfo = this.appConfigService.login;
                if(this.logininfo) {
                  this.checklogintype();
                }
    this.basedomain = this.appConfigService.domain;
    //  const hosturl = window.location
    //  console.log(hosturl);
    // If base domain not defined, check if the url is the cloud digitalform.io url to extract domain
    // if (!this.basedomain && hosturl.includes('turboapps.io')) {
    //   const domainparts = window.location.hostname.split('.');
    //   this.isoncloud = true;
    //   // console.log(domainparts);
    //   if (hosturl !== 'sbox.turboapps.io' && (domainparts.length === 3 || domainparts.length === 4)) {
    //     this.removedomainandpatchvalue(domainparts[0]);
    //   } else {
    //     this.showdomainfield = true;
    //   }
    // } else {
      /** running on premise,
      * if domainname is set in config.json use that value otherwise display domain field */
      if (this.basedomain) {
        this.removedomainandpatchvalue(this.basedomain);
      } else {
        this.showdomainfield = true;
      }
    // }
    this.cdr.detectChanges();
    // this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#D3D3D3';
  }
  checklogintype() {

    switch (this.logininfo.type) {
      case 'saml':
        this.showssologin = true;
        break;
      case 'ads':
        this.showssologin = false;
        // this.loginform.get('email').setValue(null);
        this.loginform.get('email').setValidators([Validators.required]);
        this.loginform.get('email').updateValueAndValidity();
        this.Domainplaceholder = 'Company';
        this.Emailplaceholder = 'Active Directory User Name';
        if(this.logininfo.adsdomain.length === 1){
          this.showadsdomain = false;
          this.loginform.addControl('adsdomainname', new FormControl(this.logininfo.adsdomain[0], Validators.required));
        }
        else {
          this.showadsdomain = true;
          this.loginform.addControl('adsdomainname', new FormControl('', Validators.required));
          this.adsdomains = this.logininfo.adsdomain;
        }
        break;
      case 'sap':
        this.showssologin = false;
        this.loginform.get('email').setValidators([Validators.required]);
        this.loginform.get('email').updateValueAndValidity();
        this.Emailplaceholder = 'SAP User Name';
        break;
      case 'email':
        this.showssologin = false;
        this.Emailplaceholder = 'Email';
        break;
      default:
        break;
    }
  }
  removeemail() {
    localStorage.removeItem('domain');
    this.domainlocal = localStorage.getItem('domain');
    localStorage.removeItem('email');
    this.emaillocal = localStorage.getItem('email');
    localStorage.removeItem('name');
    localStorage.removeItem('login_type');
    // this.firstnamelocal = localStorage.getItem('name');
    localStorage.removeItem('companysettings');
    this.router.navigate(['login']);
  }
  removedomainandpatchvalue(domainname: string) {
    this.showdomainfield = false;
    this.loginform.patchValue({
      domain: domainname
    });
  }
  forgetpassword() {
    this.forgetpasswordloading = true;
    if (this.loginform.get('domain').hasError('required') && this.loginform.get('email').hasError('required')) {
      this.forgetpasswordloading = false;
      this.forgotpasserrmsg = 'Doamin and Email are required to reset password';
      this.toastService.error(
        `${this.forgotpasserrmsg}`,
        {
          autoClose: false,
          id: "auth-error",
          dismissible: true,
          position: "bottom-right",
          style: {
            border: "1px solid var(--error-color)",
            padding: "16px",
            color: "var(--error-color)",
          },
        }
      );
    } else if (!this.loginform.get('domain').hasError('required') && this.loginform.get('email').hasError('required')) {
      this.forgetpasswordloading = false;
      this.forgotpasserrmsg = 'Email is required to reset password';
      this.toastService.error(
        `${this.forgotpasserrmsg}`,
        {
          autoClose: false,
          id: "auth-error",
          dismissible: true,
          position: "bottom-right",
          style: {
            border: "1px solid var(--error-color)",
            padding: "16px",
            color: "var(--error-color)",
          },
        }
      );
    } else if (!this.loginform.get('email').hasError('required') && this.loginform.get('domain').hasError('required')) {
      this.forgetpasswordloading = false;
      this.forgotpasserrmsg = 'Domain is required to reset password';
      this.toastService.error(
        `${this.forgotpasserrmsg}`,
        {
          autoClose: false,
          id: "auth-error",
          dismissible: true,
          position: "bottom-right",
          style: {
            border: "1px solid var(--error-color)",
            padding: "16px",
            color: "var(--error-color)",
          },
        }
      );
    } else {

      this.authservice.forgotpassword(this.loginform.value)
        .subscribe(
          (res: any) => {
            if (res.status === 204) {
              // this.router.navigate(['resetpassword']);
              this.forgetpasswordloading = false;
              this.forgotpassauthsuccessmsg = 'The reset password link has been sent to your email, please check your mailbox.';
              this.toastService.success(`${this.forgotpassauthsuccessmsg}`, {
                style: {
                  border: "1px solid var(--success-color)",
                  padding: "16px",
                  color: "var(--success-color)",
                },
                duration: 4000,
                position: "top-right",
              });
            } else {
              this.forgetpasswordloading = false;
              this.forgotpassautherrmsg = 'Email is not configured.You may wanna create account first.';
              this.toastService.error(
                `${this.forgotpassautherrmsg}`,
                {
                  autoClose: false,
                  id: "auth-error",
                  dismissible: true,
                  position: "bottom-right",
                  style: {
                    border: "1px solid var(--error-color)",
                    padding: "16px",
                    color: "var(--error-color)",
                  },
                }
              );
            }
            // this.router.navigate(['dashboard']);
          },
          (error) => {
            this.forgetpasswordloading = false;
            this.errmsg = error.error.error;
            this.toastService.error(
              `${this.errmsg}`,
              {
                autoClose: false,
                id: "auth-error",
                dismissible: true,
                position: "bottom-right",
                style: {
                  border: "1px solid var(--error-color)",
                  padding: "16px",
                  color: "var(--error-color)",
                },
              }
            );
            // console.log(error.error.error);
          }
        );
      // console.log('email entered');
    }
  }
  signuprequest() {
    if (this.loginform.get('domain').hasError('required') && this.loginform.get('email').hasError('required')) {
      this.forgotpasserrmsg = 'Please enter doamin and email to sign up';
      this.toastService.error(
        `${this.forgotpasserrmsg}`,
        {
          autoClose: false,
          id: "auth-error",
          dismissible: true,
          position: "bottom-right",
          style: {
            border: "1px solid var(--error-color)",
            padding: "16px",
            color: "var(--error-color)",
          },
        }
      );
    } else if (!this.loginform.get('domain').hasError('required') && this.loginform.get('email').hasError('required')) {
      this.forgotpasserrmsg = 'Email is required for sign up';
      this.toastService.error(
        `${this.forgotpasserrmsg}`,
        {
          autoClose: false,
          id: "auth-error",
          dismissible: true,
          position: "bottom-right",
          style: {
            border: "1px solid var(--error-color)",
            padding: "16px",
            color: "var(--error-color)",
          },
        }
      );
    } else if (!this.loginform.get('email').hasError('required') && this.loginform.get('domain').hasError('required')) {
      this.forgotpasserrmsg = 'Domain is required for sign up';
      this.toastService.error(
        `${this.forgotpasserrmsg}`,
        {
          autoClose: false,
          id: "auth-error",
          dismissible: true,
          position: "bottom-right",
          style: {
            border: "1px solid var(--error-color)",
            padding: "16px",
            color: "var(--error-color)",
          },
        }
      );
    } else {
      this.authservice.signup(this.loginform.value)
        .subscribe(
          (res: any) => {
            // console.log(res);
            if (res.status === 204) {
              // this.router.navigate(['resetpassword']);
              this.forgotpassauthsuccessmsg = 'The signup link has been sent to your email, please check your mailbox.';
              this.toastService.success(`${this.forgotpassauthsuccessmsg}`, {
                style: {
                  border: "1px solid var(--success-color)",
                  padding: "16px",
                  color: "var(--success-color)",
                },
                duration: 4000,
                position: "top-right",
              });
            } else {
              this.forgotpassautherrmsg = 'Error signing up, please try after some time.';
              this.toastService.error(
                `${this.forgotpassautherrmsg}`,
                {
                  autoClose: false,
                  id: "auth-error",
                  dismissible: true,
                  position: "bottom-right",
                  style: {
                    border: "1px solid var(--error-color)",
                    padding: "16px",
                    color: "var(--error-color)",
                  },
                }
              );
            }
          },
          (error) => {
            // console.log(error.error);
            this.errmsg = error.error.error;
            this.toastService.error(
              `${this.errmsg}`,
              {
                autoClose: false,
                id: "auth-error",
                dismissible: true,
                position: "bottom-right",
                style: {
                  border: "1px solid var(--error-color)",
                  padding: "16px",
                  color: "var(--error-color)",
                },
              }
            );
          }
        );
      // console.log('email entered');
    }
  }
  login() {
    // console.log(this.loginform.value);
    if (this.loginform.valid) {
      this.authservice.authenticateUser(this.loginform.value)
        .subscribe(
          (res) => {
            // console.log(res);
            this.router.navigate(['dashboard']);
          },
          (error) => {
            this.errmsg = error.error.error;
            this.toastService.error(
              `${this.errmsg}`,
              {
                autoClose: false,
                id: "auth-error",
                dismissible: true,
                position: "bottom-right",
                style: {
                  border: "1px solid var(--error-color)",
                  padding: "16px",
                  color: "var(--error-color)",
                },
              }
            );
            // console.log(error.error.error);
          }
        );
    }
  }
  openpopup() {
    // const request_type = 'login';
    this.popupservice.openPopup('login');
  }
  selectLanguage(event: any) {
    const language = event.value;
    this.currentLanguage = language;
    if (this.currentLanguage) {
      localStorage.setItem('language', this.currentLanguage);
      this.translate.use(this.currentLanguage)
    }
  }
  ngOnDestroy() {
    this.toastService.close('auth-error');
  }
}
function typeOf(url_string: string): any {
  throw new Error('Function not implemented.');
}

