
<div class="split left">
  <div class="centered">
    <img height="180px" src="assets/images/product_logo.png" alt="Turbo Apps Logo">
  </div>
</div>
<div class="split right">
  <div class="centered">
    <div class="login-form-container">
      <form [formGroup]="signupform">
        <div class="turbo-apps-logo-for-small-screens">
          <img height="100px" src="assets/images/product_logo_small.png" alt="Turbo Apps Logo">
        </div>
          <div class="welcome-back-msg">Signup</div>
          <mat-chip-list>
              <mat-basic-chip class="chip">{{email}}</mat-basic-chip>
          </mat-chip-list>
          <div>
              <mat-form-field class="input-field-width">
                  <input matInput formControlName="firstname" placeholder="First name">
                  <mat-error *ngIf="signupform.get('firstname').hasError('whitespace') && !(signupform.get('firstname').hasError('required'))">
                      Whitespaces are not allowed
                  </mat-error>
              </mat-form-field>
          </div>
          <div>
              <mat-form-field class="input-field-width">
                  <input matInput formControlName="lastname" placeholder="Last name">
                  <mat-error *ngIf="signupform.get('lastname').hasError('whitespace') && !(signupform.get('lastname').hasError('required'))">
                      Whitespaces are not allowed
                  </mat-error>
              </mat-form-field>
          </div>
          <div>
              <mat-form-field class="input-field-width">
                  <input matInput formControlName="password" [type]=" showpassword ? 'text' : 'password'" placeholder="Password">
                  <button type="button" *ngIf="!showpassword" matSuffix mat-icon-button aria-label="icon-button with a Eye off icon" (click)="showpassword=!showpassword;">
                      <mat-icon>visibility_off</mat-icon>
                  </button>
                  <button type="button" *ngIf="showpassword" matSuffix mat-icon-button aria-label="icon-button with a Eye icon" (click)="showpassword=!showpassword">
                      <mat-icon>visibility</mat-icon>
                  </button>
                  <mat-error *ngIf="signupform.get('password').hasError('required')">
                      password is required
                  </mat-error>
              </mat-form-field>
          </div>
          <div>
              <mat-form-field class="input-field-width">
                  <input matInput formControlName="confirmpassword" [type]=" showpassword ? 'text' : 'password'" placeholder="Confirm Password">
                  <button type="button" *ngIf="!showpassword" matSuffix mat-icon-button aria-label="icon-button with a Eye off icon" (click)="showpassword=!showpassword;">
                      <mat-icon>visibility_off</mat-icon>
                  </button>
                  <button type="button" *ngIf="showpassword" matSuffix mat-icon-button aria-label="icon-button with a Eye icon" (click)="showpassword=!showpassword">
                      <mat-icon>visibility</mat-icon>
                  </button>
                  <mat-error *ngIf="signupform.get('confirmpassword').hasError('mustMatch') && !(signupform.get('confirmpassword').hasError('required'))">
                      passwords do not match
                  </mat-error>
              </mat-form-field>
          </div>

          <div class="button-container">
              <button mat-flat-button type="button" color="primary" (click)="signup()" class="login-button mat-elevation-z4" [disabled]="!signupform.valid">Signup</button>
          </div>
      </form>
  </div>
  <div class="unvired-logo">
    <!-- <h6>Powered By</h6> -->
    <img width="150px" src="assets/images/Unvired_logo_for_about.png">
  </div>
  </div>
  </div>
