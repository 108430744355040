import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { ReportDataService } from 'src/app/report-builder/report-data.service';

@Component({
  selector: 'app-form-users',
  templateUrl: './form-users.component.html',
  styleUrls: ['./form-users.component.css']
})
export class FormUsersComponent implements OnInit {
  @HostListener('window:keyup.esc') onKeyUp() {
    this.closeoverlay();
}
  title: string;
  wfdata:any;
  errmsg: string;
  public dispData:boolean;
  constructor(public dialogRef: MatDialogRef<FormUsersComponent>, private reportData: ReportDataService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    dialogRef.disableClose = true;
    // Update view with given values
    this.title = data.title;
    this.wfdata = data.wfdata;
  }
  closeoverlay() {
    this.dialogRef.close();
  }
  ngOnInit() {
  //   this.dialogRef.keydownEvents().subscribe(event => {
  //     console.log(event.key);
      
  //     if (event.key === "Escape") {
  //         this.close();
  //     }
  // });
// if(this.data.wfdata){
//   this.dispData=false;
// }
console.log(this.data);

  }

  close() {
    this.dialogRef.close(this.wfdata);
  }
}
export class ConfirmDialogModel {

  constructor(public title: string, public wfdata:any) {
    
  }
  
}
