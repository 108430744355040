const TextFieldComponent = Formio.Components.components.textfield;
import { BarcodeEditForm } from './barcode.editForm.ts';
export default class BarcodeComponent extends TextFieldComponent {

    constructor(component, options, data) {
        super(component, options, data);
        // console.log(options);
    }

    static schema(...extend) {
        return TextFieldComponent.schema({
            type: 'barcode',
            label: 'Barcode',
            input: true,
            key: 'textfield',
            inputType: 'text',
            inputFormat: 'plain',
            barcodeDataField: true

        }, ...extend);
    }

    static get builderInfo() {
        return {
            title: 'Barcode',
            group: 'premium',
            icon: 'barcode',
            weight: 20,
            documentation: '/userguide/#barcode',
            schema: BarcodeComponent.schema()
        };
    }

    static addTemplate(name, template) {
        Templates.templates[name] = template;
    }

    get defaultSchema() {
        return BarcodeComponent.schema();
    }

    get inputInfo() {
        const info = super.inputInfo;
        info.type = 'input';
        if (this.component.hasOwnProperty('spellcheck')) {
            info.attr.spellcheck = this.component.spellcheck;
        }

        if (this.component.mask) {
            info.attr.type = 'password';
        } else {
            info.attr.type = (this.component.inputType === 'password') ? 'password' : 'text';
        }
        info.changeEvent = 'input';
        return info;
    }

    get prefix() {
        if (this.component.type && this.component.type === 'barcode') {
            const barcodeIcon = this.renderTemplate('icon', {
                ref: 'icon',
                className: this.iconClass('barcode'),
                styles: '',
                content: ''
            }).trim();
            return barcodeIcon;
        }
    }

    get emptyValue() {
        return '';
    }

    maskValue(value, flags = {}) {
        if (!value || (typeof value !== 'object')) {
            value = {
                value,
                maskName: this.component.inputMasks[0].label
            };
        }

        // If no value is provided, then set the defaultValue.
        if (!value.value) {
            const defaultValue = flags.noDefault ? this.emptyValue : this.defaultValue;
            value.value = Array.isArray(defaultValue) ? defaultValue[0] : defaultValue;
        }

        return value;
    }

    normalizeValue(value, flags = {}) {
        if (!this.isMultipleMasksField) {
            return super.normalizeValue(value);
        }
        if (Array.isArray(value)) {
            return super.normalizeValue(value.map((val) => this.maskValue(val, flags)));
        }
        return super.normalizeValue(this.maskValue(value, flags));
    }

    setValueAt(index, value, flags = {}) {
        if (!this.isMultipleMasksField) {
            return super.setValueAt(index, value, flags);
        }
        value = this.maskValue(value, flags);
        const textValue = value.value || '';
        const textInput = this.refs.mask ? this.refs.mask[index] : null;
        const maskInput = this.refs.select ? this.refs.select[index] : null;
        const mask = this.getMaskPattern(value.maskName);
        if (textInput && maskInput && mask) {
            textInput.value = conformToMask(textValue, FormioUtils.getInputMask(mask)).conformedValue;
            maskInput.value = value.maskName;
        } else {
            return super.setValueAt(index, textValue, flags);
        }
    }

    getValueAt(index) {
        if (!this.isMultipleMasksField) {
            return super.getValueAt(index);
        }
        const textInput = this.refs.mask ? this.refs.mask[index] : null;
        const maskInput = this.refs.select ? this.refs.select[index] : null;
        return {
            value: textInput ? textInput.value : undefined,
            maskName: maskInput ? maskInput.value : undefined
        };
    }

    isEmpty(value = this.dataValue) {
        if (!this.isMultipleMasksField) {
            return super.isEmpty((value || '').toString().trim());
        }
        return super.isEmpty(value) || (this.component.multiple ? value.length === 0 : (!value.maskName || !value.value));
    }

    render() {
        return super.render(
            this.renderTemplate('input', {
                prefix: this.prefix,
                input: this.inputInfo,
            })
        );
    }

    attach(element) {
        this.loadRefs(element, {
            prefix: 'icon'
        });
        this.input = this.refs.prefix[0];
        let componentObj = this;
        if (this.input) {
            this.addEventListener(this.input, 'click', (e) => {
                setTimeout(() => {
                    let eventCustom = new CustomEvent('customScan', {
                        detail: componentObj
                    });
                    document.dispatchEvent(eventCustom);
                }, 200);
            });
        }

        document.addEventListener('scannedData', (e) => {
            if (e.detail) {
                // console.log(this.component);
                this.setValue(e.detail) ;
                // componentObj.emit('customEvent', {
                //     type: componentObj.component.eventName,
                //     component: componentObj.component,
                //     data: e.detail
                // });
                componentObj.emit(componentObj.component.eventName,{detail: e.detail});
                // let eventCustom = new CustomEvent(componentObj.component.eventName, {detail:e.detail});
                // document.dispatchEvent(eventCustom);
            }
        }, false);


        this.addShortcut(this.input);
        return super.attach(element);

    }
}
BarcodeComponent.editForm = BarcodeEditForm;
// Register the component to the Formio.Components registry.
Formio.Components.addComponent("barcode", BarcodeComponent);
