import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot,
  CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private authservice: AuthenticationService,
    private router: Router
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.authservice.isLoggedIn.pipe(
      take(1),
      map((isuserLoggedIn: boolean) => {
        // console.log(isuserLoggedIn);
        if (isuserLoggedIn) {
          return true;
        }
          this.router.navigate(['/login']);
         // console.log('accessing admin routes without logging in');
          return false;
      })
    );
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
     return this.canActivate(next, state);
  }
}

