import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { AppConfigService } from '../app.config';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import decode from 'jwt-decode';
import { FormsService } from '../formio-core/forms-and-formsets.service';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  /** Based on the screen size, switch from standard to one column per row */
  public cards;
  public externalapps = [];
  public dashboardUrlExist: boolean = true;
  public dashboardUrl = "";
  public loadingCtrl: boolean = true;
  public dashboardTitle = "TurboForms Dashboard";
  public token = "";
  public dashboardSlug = "";
  public companysettingsDashboard = "";

  dashboardlistForm: FormGroup;
  public listOfDashboards = [];
  private subscription: Subscription;

  constructor(private breakpointObserver: BreakpointObserver, 
    private appConfigService: AppConfigService,
    private formsservice: FormsService,
    private fb: FormBuilder
    ) { }
  ngOnInit() {
    this.loadingCtrl = true;
    this.dashboardlistForm = this.fb.group({
      dashboardType: [''],
    });

  
    const usr = decode(localStorage.getItem('token'));
    this.subscription = this.formsservice.getCompanySettings().subscribe((companysettings: any) => {
       if(Object.keys(companysettings).length > 0){
        if (companysettings && companysettings.dashboard) {
          this.companysettingsDashboard = companysettings.dashboard
          this.dashboardUrlExist = true;
          this.token = localStorage.getItem('token');
          this.formsservice
          .getListOfDashboards(true)
          .subscribe((response: any) =>{
            console.log("response = " + response)
            let responseobj = JSON.parse(response);
            if(responseobj.status === "Success"){
            if(responseobj && responseobj.dashboards && responseobj.dashboards.length > 0){
              for(let i=0; i< responseobj.dashboards.length; i++){
                if(responseobj.dashboards[i].default){
                this.dashboardTitle = responseobj.dashboards[i].name;
                this.dashboardSlug = responseobj.dashboards[i].slug;
                break;
                }else{
                  this.dashboardTitle = 'Turbo Forms';
                  this.dashboardSlug = 'turboforms';
                }
              } 
              this.dashboardUrl = this.companysettingsDashboard + `/login/?apitoken=${this.token}&redirect=${this.dashboardSlug}&standalone=2&expand_filters=0`;
              this.listOfDashboards = responseobj.dashboards;
              this.listOfDashboards.forEach(item => {
                if (item.slug === this.dashboardSlug) {
                  item.selected = 'true';
                }else{
                  item.selected = 'false';
                }
              });
            }
          }
          });
        }else{
          this.dashboardUrlExist = false;
        }
        this.loadingCtrl = false;
      }
  });

      

    if(this.appConfigService.app && this.appConfigService.app.length > 0) {
      let cols = 1, rows = 1;
      this.appConfigService.app.forEach(element => {
        let appobj = {
          title:element.name,
          icon: element.icon,
          iconcolor: element.icon || '#000',
          desc: element.description || 'App Description',
          cols: cols,
          rows: rows,
          url: element.url,
          target: element.target
        };
        this.externalapps.push(appobj);
      });

    }
    this.cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
      map(({ matches }) => {
        if (matches) {
          if (usr.ROLE === 'Admin') {
          return [
            { title: 'Users', route: 'users', icon: 'person', iconcolor: '#9acd32', desc: 'Add or manage users', cols: 3, rows: 1 },
            { title: 'Teams', route: 'teams', icon: 'group', iconcolor: '#FF3366', desc: 'Create & add users to teams', cols: 3, rows: 1 },
            { title: 'Forms', route: 'forms',  icon: 'description', iconcolor: '#009688', desc: 'Create & Publish forms', cols: 3, rows: 1 },
            { title: 'Formsets', route: 'formsets',  icon: 'folder', iconcolor: '#006b96', desc: 'Create and manage formsets', cols: 3, rows: 1 },
            { title: 'MasterData', route: 'masterdata',  icon: 'widgets', iconcolor: '#ff3d02', desc: 'Resources for forms', cols: 3, rows: 1 },
            { title: 'Report Builder', route: 'reports/builder',  icon: 'assessment', iconcolor: '#961b00', desc: 'Build custom reports', cols: 3, rows: 1 },
            { title: 'Submission Report', route: 'reports/submission',  icon: 'summarize', iconcolor: '#961b00', desc: 'View form activity', cols: 1, rows: 1 },
            { title: 'Workflows', route: 'workflows', icon: 'engineering', iconcolor: '#332c2c', desc: 'Design Workflows', cols: 3, rows: 1 },
            { title: 'Settings', route: 'settings',  icon: 'settings', iconcolor: '#332c2c', desc: 'Manage Settings', cols: 3, rows: 1 }
          ];
        } else {
          return [
            { title: 'MasterData', route: 'masterdata',  icon: 'widgets', iconcolor: '#ff3d02', desc: 'Resources for forms', cols: 3, rows: 1 },
            { title: 'Report Builder', route: 'reports/builder',  icon: 'assessment', iconcolor: '#961b00', desc: 'Build custom reports', cols: 3, rows: 1 },
            { title: 'Submission Report', route: 'reports/submission',  icon: 'summarize', iconcolor: '#961b00', desc: 'View form activity', cols: 1, rows: 1 }
          ];
        }
        }
        if (usr.ROLE === 'Admin') {
        return [
          { title: 'Users', route: 'users',  icon: 'person', iconcolor: '#9acd32', desc: 'Add or manage users', cols: 1, rows: 1 },
          { title: 'Teams', route: 'teams',  icon: 'group', iconcolor: '#FF3366', desc: 'Create & add users to teams', cols: 1, rows: 1 },
          { title: 'Forms', route: 'forms',  icon: 'description', iconcolor: '#009688', desc: 'Create & publish forms', cols: 1, rows: 1 },
          { title: 'Formsets', route: 'formsets',  icon: 'folder', iconcolor: '#006b96', desc: 'Create and manage formsets', cols: 1, rows: 1 },
          { title: 'MasterData', route: 'masterdata',  icon: 'widgets', iconcolor: '#ff3d02', desc: 'Resources for forms', cols: 1, rows: 1 },
          { title: 'Report Builder', route: 'reports/builder',  icon: 'assessment', iconcolor: '#961b00', desc: 'Build custom reports', cols: 1, rows: 1 },
          { title: 'Submission Report', route: 'reports/submission',  icon: 'summarize', iconcolor: '#961b00', desc: 'View form activity', cols: 1, rows: 1 },
          { title: 'Workflows', route: 'workflows', icon: 'engineering', iconcolor: '#332c2c', desc: 'Design Workflows', cols: 1, rows: 1 },
          { title: 'Settings', route: 'settings',  icon: 'settings', iconcolor: '#332c2c', desc: 'Manage Settings', cols: 1, rows: 1 }
        ];
      } else {
          return [
          { title: 'MasterData', route: 'masterdata',  icon: 'widgets', iconcolor: '#ff3d02', desc: 'Resources for forms', cols: 1, rows: 1 },
          { title: 'Report Builder', route: 'reports/builder',  icon: 'assessment', iconcolor: '#961b00', desc: 'Build custom reports', cols: 1, rows: 1 },
          { title: 'Submission Report', route: 'reports/submission',  icon: 'summarize', iconcolor: '#961b00', desc: 'View form activity', cols: 1, rows: 1 }
        ];
        }
      })
    );
  }
  openapp(url: string, target: string) {
    window.open(url, target);
  }

  loadSelectedDashboard(list){
    console.log("loadSelectedDashboard() called");
    this.dashboardTitle = list.name;
    this.dashboardSlug = list.slug;
    this.dashboardUrl = this.companysettingsDashboard + `/login/?apitoken=${this.token}&redirect=${this.dashboardSlug}&standalone=2&expand_filters=0`;
    this.listOfDashboards.forEach(item => {
      if (item.slug === this.dashboardSlug) {
        item.selected = 'true';
      } else {
        item.selected = 'false';
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
 
}
