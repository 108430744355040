<!-- <div class="login-background-container">
    <div class="container">
        <div class="top-left">
            <img src="assets/images/forms_logo.png" height="130px">
        </div>
        <div class="top-right">
            <div class="header">Digital Forms</div>
            <div class="meta-header">Digital Delivered</div>
        </div>
        <div class="error-msg" *ngIf="errmsg">
            <span>{{errmsg}}</span>
        </div>
        <div class="login-form-container">
            <form [formGroup]="resetpasswordform">
                <div class="form-header">Reset Password</div>
                <div>
                    <mat-form-field class="input-field-width">
                        <input matInput formControlName="password" [type]=" showpassword ? 'text' : 'password'" placeholder="Password">
                        <button type="button" *ngIf="!showpassword" matSuffix mat-icon-button aria-label="icon-button with a Eye off icon" (click)="showpassword=!showpassword;">
                            <mat-icon>visibility_off</mat-icon>
                        </button>
                        <button type="button" *ngIf="showpassword" matSuffix mat-icon-button aria-label="icon-button with a Eye icon" (click)="showpassword=!showpassword">
                            <mat-icon>visibility</mat-icon>
                        </button>
                        <mat-error *ngIf="resetpasswordform.get('password').hasError('whitespace') && !(resetpasswordform.get('password').hasError('required'))">
                            Whitespaces are not allowed
                        </mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="input-field-width">
                        <input matInput formControlName="confirmpassword" [type]=" showpassword ? 'text' : 'password'" placeholder="Confirm Password">
                        <button type="button" *ngIf="!showpassword" matSuffix mat-icon-button aria-label="icon-button with a Eye off icon" (click)="showpassword=!showpassword;">
                            <mat-icon>visibility_off</mat-icon>
                        </button>
                        <button type="button" *ngIf="showpassword" matSuffix mat-icon-button aria-label="icon-button with a Eye icon" (click)="showpassword=!showpassword">
                            <mat-icon>visibility</mat-icon>
                        </button>
                        <mat-error *ngIf="resetpasswordform.get('confirmpassword').hasError('mustMatch') && !(resetpasswordform.get('confirmpassword').hasError('required'))">
                            passwords do not match
                        </mat-error>
                    </mat-form-field>
                </div>
                <div *ngIf="!displaymsg" class="button-container">
                    <button mat-flat-button type="button" color="primary" (click)="ressetpassword()" class="login-button" [disabled]="!resetpasswordform.valid">Reset</button>
                </div>
            </form>
        </div>
    </div>
</div>   -->
    <div class="split left">
      <div class="centered">
        <img height="180px" src="assets/images/product_logo.png" alt="Turbo Apps Logo">
      </div>
    </div>
    <div class="split right">
      <div class="centered">
        <div class="error-msg" *ngIf="errmsg">
          <span>{{errmsg}}</span>
        </div>
        <div class="login-form-container">
          <form [formGroup]="resetpasswordform">
            <div class="turbo-apps-logo-for-small-screens">
              <img height="100px" src="assets/images/product_logo_small.png" alt="Turbo Apps Logo">
            </div>
              <div class="form-header">Reset Password</div>
              <div>
                  <mat-form-field class="input-field-width">
                      <input matInput formControlName="password" [type]=" showpassword ? 'text' : 'password'" placeholder="Password">
                      <button type="button" *ngIf="!showpassword" matSuffix mat-icon-button aria-label="icon-button with a Eye off icon" (click)="showpassword=!showpassword;">
                          <mat-icon>visibility_off</mat-icon>
                      </button>
                      <button type="button" *ngIf="showpassword" matSuffix mat-icon-button aria-label="icon-button with a Eye icon" (click)="showpassword=!showpassword">
                          <mat-icon>visibility</mat-icon>
                      </button>
                      <mat-error *ngIf="resetpasswordform.get('password').hasError('whitespace') && !(resetpasswordform.get('password').hasError('required'))">
                          Whitespaces are not allowed
                      </mat-error>
                  </mat-form-field>
              </div>
              <div>
                  <mat-form-field class="input-field-width">
                      <input matInput formControlName="confirmpassword" [type]=" showpassword ? 'text' : 'password'" placeholder="Confirm Password">
                      <button type="button" *ngIf="!showpassword" matSuffix mat-icon-button aria-label="icon-button with a Eye off icon" (click)="showpassword=!showpassword;">
                          <mat-icon>visibility_off</mat-icon>
                      </button>
                      <button type="button" *ngIf="showpassword" matSuffix mat-icon-button aria-label="icon-button with a Eye icon" (click)="showpassword=!showpassword">
                          <mat-icon>visibility</mat-icon>
                      </button>
                      <mat-error *ngIf="resetpasswordform.get('confirmpassword').hasError('mustMatch') && !(resetpasswordform.get('confirmpassword').hasError('required'))">
                          passwords do not match
                      </mat-error>
                  </mat-form-field>
              </div>
              <div *ngIf="!displaymsg" class="button-container">
                  <button mat-flat-button type="button" color="primary" (click)="ressetpassword()" class="login-button mat-elevation-z4" [disabled]="!resetpasswordform.valid">Reset</button>
              </div>
          </form>
        </div>
        <div class="unvired-logo">
          <!-- <h6>Powered By</h6> -->
          <img width="150px" src="assets/images/Unvired_logo_for_about.png">
        </div>
      </div>
    </div>
