import { Component, OnInit, AfterViewInit, HostBinding } from '@angular/core';
import { Router, UrlTree, UrlSegment, UrlSegmentGroup, PRIMARY_OUTLET
       , ActivatedRoute, Event, NavigationEnd, NavigationError, NavigationCancel } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { SettingsService } from '../settings/settings.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../auth/authentication.service';
import decode from 'jwt-decode';
import { DomSanitizer } from '@angular/platform-browser';
import { OverlayContainer } from '@angular/cdk/overlay';
import { PopupService } from '../auth/popup.service';
import { AppConfigService } from '../app.config';
import { FormsService } from '../formio-core/forms-and-formsets.service';
import {Location} from '@angular/common';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit, AfterViewInit {
  @HostBinding('class') componentCssClass;
  @HostBinding('style.font-size.px') font: number;
  selectedtheme: string;
  public params:any;
  public role: string;
  public companysettings: any;
  public base64comapnyimage: any;
  public isExpanded: boolean;
  public help_url = 'https://docs.unvired.com/builder/';
  public help_page_name = '';
  public logintype: string;
  public externalapps = [];
  public dashboardUrlExist:boolean = false;
   isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
     .pipe(
       map(result => result.matches)
     );
  constructor(
              private authservice: AuthenticationService,
              private breakpointObserver: BreakpointObserver,
              private router: Router,
              private sanitizer: DomSanitizer,
              private settingservice: SettingsService,
              private popupservice: PopupService,
              private overlayContainer: OverlayContainer,
              private appConfigService: AppConfigService,
              private formsservice: FormsService,
              private location: Location,
              private route:ActivatedRoute,
              // private OverlayContainer: OverlayContainer
             ) {
              
           
    router.events.subscribe((routerEvent: Event) => {
      if (routerEvent instanceof NavigationEnd ||
        routerEvent instanceof NavigationCancel ||
        routerEvent instanceof NavigationError) {
        // console.log(routerEvent);
        const tree: UrlTree = this.router.parseUrl(routerEvent.url);
        const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
        const s: UrlSegment[] = g.segments;
        // console.log(s);
        // console.log(s[0].path);
        // console.log(s[0].parameters);
        if (s[0].path === 'dashboard') {
          this.help_page_name = '';
         } else if (s[0].path === 'users') {
           this.help_page_name = 'users/#managing-users';
         } else if (s[0].path === 'teams') {
           this.help_page_name = 'users/#managing-teams';
         } else if (s[0].path === 'forms') {
           this.help_page_name = 'forms';
         } else if (s[0].path === 'formsets') {
          this.help_page_name = 'forms/#formsets';
         } else if (s[0].path === 'formio') {
            if(s[1]){
              if(s[1].path === 'formbuilder') {
                this.help_page_name = 'forms/#design-forms';
              } else if(s[1].path === 'formrenderer') {
                this.help_page_name = 'reports/#submission-report';
              }
            }
         } else if (s[0].path === 'masterdata') {
             this.help_page_name = 'masterdata';
         } else if (s[0].path === 'reports') {
           if(s[1]){
             if(s[1].path === 'submission') {
             this.help_page_name = 'reports/#submission-report';
           } else if(s[1].path === 'builder') {
             this.help_page_name = 'reports/#report-builder';
           }
         }  else {
           this.help_page_name = 'reports';
          }
         } else if (s[0].path === 'settings') {
           this.help_page_name = 'settings';
         } else if (s[0].path === 'workflows') {
           this.help_page_name = 'workflows/#the-workflow-builder';
        } else if (s[0].path === 'dashboard-list'){
          this.help_page_name = 'dashboards';

         }
        
      }
    });
    }
  
      handleActiveClass(){
        let relativePath: string = this.location.path();
         return (relativePath === '/reports/submission');
        }
  ngOnInit() {
    // this.font= 18;

    this.route.queryParams.subscribe(params => {
      this.params=params;
  });

    // this.isLoggedIn$ = this.authservice.isLoggedIn;
     const theme = localStorage.getItem('selectedTheme');
    this.logintype = localStorage.getItem('login_type');
     this.onSetTheme(theme);
    const usr = decode(localStorage.getItem('token'));
    if(this.appConfigService.app && this.appConfigService.app.length > 0) {
      let cols = 1, rows = 1;
      this.appConfigService.app.forEach(element => {
        let appobj = {
          title:element.name,
          icon: element.icon,
          iconcolor: element.icon || '#000',
          desc: element.description || 'App Description',
          cols: cols,
          rows: rows,
          url: element.url,
          target: element.target
        };
        this.externalapps.push(appobj);
      });

    }
    this.role = usr.ROLE;
    let res = localStorage.getItem('companysettings');
    if (res) {
      this.useComapnySettingsData(res);

    } else {
      this.settingservice.getcompanysettings()
        .subscribe(
          res => {
            localStorage.setItem('companysettings', res);
            this.useComapnySettingsData(res);
          }
        );
    }
    
  }
  useComapnySettingsData(res){
    this.companysettings = JSON.parse(res).company;
    let companysettingsData = JSON.parse(res)
    if (companysettingsData && companysettingsData.dashboard) {
      this.dashboardUrlExist = true;
    } else {
      this.dashboardUrlExist = false;
    }
    this.formsservice.companySettings.next(JSON.parse(res));
    this.base64comapnyimage = JSON.parse(res).company.attachmentId;
  }
  ngAfterViewInit() {
  }
  onSetTheme(theme) {
    localStorage.setItem('selectedTheme', theme);
    if (this.componentCssClass) {
      this.overlayContainer.getContainerElement().classList.remove(this.componentCssClass);
      this.componentCssClass = null;
      this.overlayContainer.getContainerElement().classList.add(theme);
      this.componentCssClass = theme;
    } else {
      this.componentCssClass = theme;
      this.overlayContainer.getContainerElement().classList.add(theme);
    }
   
  }

  transform() {
    return this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpeg;base64,' + this.base64comapnyimage);
  }
  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
}
  onLogout() {
    if (this.logintype === 'SSO') {
    this.popupservice.openPopup('logout');
  } else {
    this.authservice.removeTokenAndlogOut();
  }
  }
  openapp(url: string, target: string) {
    window.open(url, target);
  }
}
