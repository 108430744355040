import EditFormUtils from 'formiojs/components/_classes/component/editForm/utils';

import ComponentEditConditional from 'formiojs/components/_classes/component/editForm/Component.edit.conditional';
import ComponentEditLogic from 'formiojs/components/_classes/component/editForm/Component.edit.logic';
import ComponentEditValidation from 'formiojs/components/_classes/component/editForm/Component.edit.validation';
import ComponentEditLayout from 'formiojs/components/_classes/component/editForm/Component.edit.layout';
import { Formio, FormioUtils } from 'angular-formio';
let masterdataObj;
let selId;

export function SmartSelectEditForm() {
  return {
    components: [{
      key: 'type',
      type: 'hidden'
    },
    {
      type: 'tabs',
      key: 'tabs',
      components: [{
        label: 'Display',
        key: 'display',
        weight: 0,
        components: [{
          weight: 0,
          type: 'textfield',
          input: true,
          key: 'label',
          label: 'Label',
          placeholder: 'Field Label',
          tooltip: 'The label for this field that will appear next to it.',
          validate: {
            required: true
          }
        },
        {
          type: 'select',
          input: true,
          key: 'labelPosition',
          label: 'Label Position',
          tooltip: 'Position for the label for this field.',
          weight: 20,
          defaultValue: 'top',
          dataSrc: 'values',
          data: {
            values: [{
              label: 'Top',
              value: 'top'
            },
            {
              label: 'Left (Left-aligned)',
              value: 'left-left'
            },
            {
              label: 'Left (Right-aligned)',
              value: 'left-right'
            },
            {
              label: 'Right (Left-aligned)',
              value: 'right-left'
            },
            {
              label: 'Right (Right-aligned)',
              value: 'right-right'
            },
            {
              label: 'Bottom',
              value: 'bottom'
            }
            ]
          }
        },
        {
          type: 'number',
          input: true,
          key: 'labelWidth',
          label: 'Label Width',
          tooltip: 'The width of label on line in percentages.',
          clearOnHide: false,
          weight: 30,
          placeholder: '30',
          suffix: '%',
          validate: {
            min: 0,
            max: 100
          },
          conditional: {
            json: {
              and: [{
                '!==': [{
                  var: 'data.labelPosition'
                }, 'top']
              },
              {
                '!==': [{
                  var: 'data.labelPosition'
                }, 'bottom']
              },
              ]
            }
          }
        },
        {
          type: 'number',
          input: true,
          key: 'labelMargin',
          label: 'Label Margin',
          tooltip: 'The width of label margin on line in percentages.',
          clearOnHide: false,
          weight: 30,
          placeholder: '3',
          suffix: '%',
          validate: {
            min: 0,
            max: 100
          },
          conditional: {
            json: {
              and: [{
                '!==': [{
                  var: 'data.labelPosition'
                }, 'top']
              },
              {
                '!==': [{
                  var: 'data.labelPosition'
                }, 'bottom']
              },
              ]
            }
          }
        },
        {
          weight: 100,
          type: 'textfield',
          input: true,
          key: 'placeholder',
          label: 'Placeholder',
          placeholder: 'Placeholder',
          tooltip: 'The placeholder text that will appear when this field is empty.'
        },
        {
          weight: 200,
          type: 'textarea',
          input: true,
          key: 'description',
          label: 'Description',
          placeholder: 'Description for this field.',
          tooltip: 'The description is text that will appear below the input field.',
          editor: 'ace',
          as: 'html',
          wysiwyg: {
            minLines: 3,
            isUseWorkerDisabled: true,
          },
        },
        {
          weight: 300,
          type: 'textarea',
          input: true,
          key: 'tooltip',
          label: 'Tooltip',
          placeholder: 'To add a tooltip to this field, enter text here.',
          tooltip: 'Adds a tooltip to the side of this field.',
          editor: 'ace',
          as: 'html',
          wysiwyg: {
            minLines: 3,
            isUseWorkerDisabled: true,
          },
        },
        {
          weight: 500,
          type: 'textfield',
          input: true,
          key: 'customClass',
          label: 'Custom CSS Class',
          placeholder: 'Custom CSS Class',
          tooltip: 'Custom CSS class to add to this component.'
        },
        {
          weight: 600,
          type: 'textfield',
          input: true,
          key: 'tabindex',
          label: 'Tab Index',
          placeholder: '0',
          tooltip: 'Sets the tabindex attribute of this component to override the tab order of the form. See the <a href=\'https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/tabindex\'>MDN documentation</a> on tabindex for more information.'
        },
        {
          weight: 1100,
          type: 'checkbox',
          label: 'Hidden',
          tooltip: 'A hidden field is still a part of the form, but is hidden from view.',
          key: 'hidden',
          input: true
        },
        {
          weight: 1200,
          type: 'checkbox',
          label: 'Hide Label',
          tooltip: 'Hide the label (title, if no label) of this component. This allows you to show the label in the form builder, but not when it is rendered.',
          key: 'hideLabel',
          input: true
        },
        {
          weight: 1350,
          type: 'checkbox',
          label: 'Initial Focus',
          tooltip: 'Make this field the initially focused element on this form.',
          key: 'autofocus',
          input: true
        },
        {
          weight: 1400,
          type: 'checkbox',
          label: 'Disabled',
          tooltip: 'Disable the form input.',
          key: 'disabled',
          input: true
        },
        {
          weight: 1500,
          type: 'checkbox',
          label: 'Table View',
          tooltip: 'Shows this value within the table view of the submissions.',
          key: 'tableView',
          input: true
        },
        {
          weight: 1600,
          type: 'checkbox',
          label: 'Modal Edit',
          tooltip: 'Opens up a modal to edit the value of this component.',
          key: 'modalEdit',
          input: true
        },
        ]

      },
      {
        label: 'Data',
        key: 'data',
        weight: 0,
        components: [{
          weight: 0,
          type: 'checkbox',
          label: 'Multiple Values',
          tooltip: 'Allows multiple values to be entered for this field.',
          key: 'multiple',
          input: true
        },
        {
          type: 'select',
          input: true,
          weight: 0,
          tooltip: 'The source to use for the select data. Values lets you provide your own values and labels. JSON lets you provide raw JSON data. URL lets you provide a URL to retrieve the JSON data from.',
          key: 'dataSrc',
          defaultValue: 'values',
          label: 'Data Source Type',
          dataSrc: 'values',
          data: {
            values: [{
              label: 'Values',
              value: 'values'
            },
            {
              label: 'URL',
              value: 'url'
            },
            {
              label: 'Master Data',
              value: 'masterdata'
            },
            {
              label: 'Custom',
              value: 'custom'
            },
            {
              label: 'Raw JSON',
              value: 'json'
            },
            {
              label: 'IndexedDB',
              value: 'indexeddb'
            },
            ],
          },
        },
        {
          type: 'textfield',
          weight: 10,
          input: true,
          key: 'indexeddb.database',
          label: 'Database name',
          tooltip: 'The name of the indexeddb database.',
          conditional: {
            json: {
              '===': [{
                var: 'data.dataSrc'
              }, 'indexeddb']
            },
          },
        },
        {
          type: 'textfield',
          input: true,
          key: 'indexeddb.table',
          label: 'Table name',
          weight: 16,
          tooltip: 'The name of table in the indexeddb database.',
          conditional: {
            json: {
              '===': [{
                var: 'data.dataSrc'
              }, 'indexeddb']
            },
          }
        },
        {
          type: 'textarea',
          as: 'json',
          editor: 'ace',
          weight: 18,
          input: true,
          key: 'indexeddb.filter',
          label: 'Row Filter',
          tooltip: 'Filter table items that match the object.',
          defaultValue: {},
          conditional: {
            json: {
              '===': [{
                var: 'data.dataSrc'
              }, 'indexeddb']
            },
          },
        },
        {
          type: 'textarea',
          as: 'json',
          editor: 'ace',
          weight: 10,
          input: true,
          key: 'data.json',
          label: 'Data Source Raw JSON',
          tooltip: 'A raw JSON array to use as a data source.',
          conditional: {
            json: {
              '===': [{
                var: 'data.dataSrc'
              }, 'json']
            },
          },
        },
        {
          type: 'textfield',
          input: true,
          key: 'data.url',
          weight: 10,
          label: 'Data Source URL',
          placeholder: 'Data Source URL',
          tooltip: 'A URL that returns a JSON array to use as the data source.',
          conditional: {
            json: {
              '===': [{
                var: 'data.dataSrc'
              }, 'url']
            },
          },
        },
        {
          type: 'checkbox',
          input: true,
          label: 'Lazy Load Data',
          key: 'lazyLoad',
          tooltip: 'When set, this will not fire off the request to the URL until this control is within focus. This can improve performance if you have many Select dropdowns on your form where the API\'s will only fire when the control is activated.',
          weight: 11,
          conditional: {
            json: {
              and: [{
                in: [{
                  var: 'data.dataSrc'
                },
                [
                  'masterdata',
                  'url',
                ],
                ],
              },
              {
                '!==': [{
                  var: 'data.widget'
                },
                  'html5'
                ]
              }
              ]
            },
          },
        },
        {
          type: 'datagrid',
          input: true,
          label: 'Request Headers',
          key: 'data.headers',
          tooltip: 'Set any headers that should be sent along with the request to the url. This is useful for authentication.',
          weight: 11,
          components: [{
            label: 'Key',
            key: 'key',
            input: true,
            type: 'textfield',
          },
          {
            label: 'Value',
            key: 'value',
            input: true,
            type: 'textfield',
          },
          ],
          conditional: {
            json: {
              '===': [{
                var: 'data.dataSrc'
              }, 'url']
            },
          },
        },
        {
          type: 'textfield',
          label: 'Default Value',
          key: 'defaultValue',
          weight: 5,
          placeholder: 'Default Value',
          tooltip: 'The will be the value for this field, before user interaction. Having a default value will override the placeholder text.',
          input: true
        },
        {
          type: 'datagrid',
          input: true,
          label: 'Data Source Values',
          key: 'data.values',
          tooltip: 'Values to use as the data source. Labels are shown in the select field. Values are the corresponding values saved with the submission.',
          weight: 10,
          reorder: true,
          defaultValue: true,
          components: [{
            label: 'Label',
            key: 'label',
            input: true,
            type: 'textfield',
          },
          {
            label: 'Value',
            key: 'value',
            input: true,
            type: 'textfield',
            allowCalculateOverride: true,
            calculateValue: {
              _camelCase: [{
                var: 'row.label'
              }]
            },
          },
          ],
          conditional: {
            json: {
              '===': [{
                var: 'data.dataSrc'
              }, 'values']
            },
          },
        },
        {
          type: 'select',
          label: 'Masterdata',
          key: 'data.masterdata',
          tooltip: 'The masterdata entity to be used with this field.',
          placeholder: 'Select masterdata',
          dataSrc: 'url',
          data: {
            url: `${localStorage.getItem('UMP_url')}/UMP/API/v2/applications/DIGITAL_FORMS/execute/DIGITAL_FORMS_PA_GET_MASTERDATA_AND_FIELDS`,
            method: 'POST',
            headers: [
              { key: 'Authorization', value: 'Bearer ' + localStorage.getItem('token') },
              { key: 'Content-Type', value: 'application/x-www-form-urlencoded' },
              { key: 'accept', value: 'application/json' }
            ]
          },
          masterdataIdValue: 'masterdataId',
          valueProperty: 'masterdataName',
          lazyLoad: true,
          template: '<span>{{ item.masterdataName }}</span>',
          clearOnRefresh: true,
          selectValues: 'masterdata',
          validate: {
            required: true
          },
          onSetItems(component, form) {
            masterdataObj = form.masterdata;
          },
          onChange(context) {
            if (context && context.flags && context.flags.modified) {
              if (context.instance && context.instance.data && context.instance.data.data && context.instance.data.data.masterdata) {
                const selMasterdataName = context.instance.data.data.masterdata;
                for (let i = 0; i < masterdataObj.length; i++) {
                  if (selMasterdataName && selMasterdataName === masterdataObj[i].masterdataName) {
                    selId = masterdataObj[i].masterdataId;
                  }
                }
                context.instance.root.getComponent('selMasterdataId').setValue(selId);
                context.instance.root.getComponent('valueProperty').resetValue();
                context.instance.root.getComponent('labelProperty').resetValue();
                context.instance.root.getComponent('searchField').resetValue();

              }
            }
          },
          conditional: {
            json: {
              '===': [{
                var: 'data.dataSrc'
              }, 'masterdata']
            }
          }
        },
        {
          type: 'textfield',
          input: true,
          label: 'Data Path',
          key: 'selectValues',
          weight: 12,
          description: 'The object path to the iterable items.',
          tooltip: 'The property within the source data, where iterable items reside. For example: results.items or results[0].items',
          conditional: {
            json: {
              '===': [{
                var: 'data.dataSrc'
              }, 'url']
            },
          },
        },
        {
          type: 'textfield',
          input: true,
          label: 'sel Master data Id',
          key: 'selMasterdataId',
          skipMerge: true,
          clearOnHide: false,
          refreshOn: 'data.masterdata',
          hidden: true,
        },
        {
          type: 'select',
          input: true,
          label: 'Label Property',
          key: 'labelProperty',
          skipMerge: true,
          clearOnHide: true,
          tooltip: 'The field to use as the label.',
          weight: 11,
          refreshOn: 'data.masterdata',
          template: '<span>{{ item.label }}</span>',
          valueProperty: 'key',
          dataSrc: 'url',
          lazyLoad: false,
          validate: {
            required: true
          },
          onSetItems(component, form) {
            const newItems = form.type === 'masterdata' ? [{
              label: '{Entire Object}',
              key: 'data',
            }] : [];
            FormioUtils.eachComponent(form.components, (component, path) => {
              if (component.input) {
                newItems.push({
                  label: component.label || component.key,
                  key: `data.${path}`
                });
              }
            });
            return newItems;
          },
          onChange(context) {
            if (context && context.flags && context.flags.modified) {
              const valueProp = context.instance.data.labelProperty;
              const templateProp = valueProp ? valueProp : 'data';
              const template = `<span>{{ item.${templateProp} }}</span>`;
              const searchField = valueProp ? `${valueProp}__regex` : '';
              context.instance.root.getComponent('template').setValue(template);
              context.instance.root.getComponent('searchField').setValue(searchField);
            }
          },
          data: {
            url: `${localStorage.getItem('UMP_url')}/UMP/API/v2/applications/DIGITAL_FORMS/execute/DIGITAL_FORMS_PA_GET_MASTERDATA_AND_FIELDS?queuedExecute=false&messageFormat=custom&inputMessage=%7B%22formId%22%3A%22{{data.selMasterdataId}}%22%7D`,
            method: 'POST',
            headers: [
              { key: 'Authorization', value: 'Bearer ' + localStorage.getItem('token') },
              { key: 'Content-Type', value: 'application/x-www-form-urlencoded' },
              { key: 'accept', value: 'application/json' }
            ]
          },
          conditional: {
            json: {
              and: [{
                '===': [{
                  var: 'data.dataSrc'
                }, 'masterdata']
              },
              {
                '!==': [{
                  var: 'data.reference'
                }, true]
              },
              {
                var: 'data.data.masterdata'
              },
              ],
            },
          },
        },
        {
          type: 'select',
          input: true,
          label: 'Value Property',
          key: 'valueProperty',
          skipMerge: true,
          clearOnHide: true,
          tooltip: 'The field to use as the value.',
          weight: 11,
          refreshOn: 'data.masterdata',
          template: '<span>{{ item.label }}</span>',
          valueProperty: 'key',
          dataSrc: 'url',
          lazyLoad: false,
          validate: {
            required: true
          },
          onSetItems(component, form) {
            const newItems = form.type === 'masterdata' ? [{
              label: '{Entire Object}',
              key: 'data',
            }] : [];
            FormioUtils.eachComponent(form.components, (component, path) => {
              if (component.input) {
                newItems.push({
                  label: component.label || component.key,
                  key: `data.${path}`
                });
              }
            });
            return newItems;
          },
          onChange(context) {
            if (context && context.flags && context.flags.modified) {
              // const valueProp = context.instance.data.valueProperty;
              // const templateProp = valueProp ? valueProp : 'data';
              // const template = `<span>{{ item.${templateProp} }}</span>`;
              // const searchField = valueProp ? `${valueProp}__regex` : '';
              // context.instance.root.getComponent('template').setValue(template);
              // context.instance.root.getComponent('searchField').setValue(searchField);
            }
          },
          data: {
            url: `${localStorage.getItem('UMP_url')}/UMP/API/v2/applications/DIGITAL_FORMS/execute/DIGITAL_FORMS_PA_GET_MASTERDATA_AND_FIELDS?queuedExecute=false&messageFormat=custom&inputMessage=%7B%22formId%22%3A%22{{data.selMasterdataId}}%22%7D`,
            method: 'POST',
            headers: [
              { key: 'Authorization', value: 'Bearer ' + localStorage.getItem('token') },
              { key: 'Content-Type', value: 'application/x-www-form-urlencoded' },
              { key: 'accept', value: 'application/json' }
            ]
          },
          conditional: {
            json: {
              and: [{
                '===': [{
                  var: 'data.dataSrc'
                }, 'masterdata']
              },
              {
                '!==': [{
                  var: 'data.reference'
                }, true]
              },
              {
                var: 'data.data.masterdata'
              },
              ],
            },
          },
        },
        {
          type: 'select',
          input: true,
          label: 'Storage Type',
          key: 'dataType',
          clearOnHide: true,
          tooltip: 'The type to store the data. If you select something other than autotype, it will force it to that type.',
          weight: 12,
          template: '<span>{{ item.label }}</span>',
          dataSrc: 'values',
          data: {
            values: [{
              label: 'Autotype',
              value: 'auto'
            },
            {
              label: 'String',
              value: 'string'
            },
            {
              label: 'Number',
              value: 'number'
            },
            {
              label: 'Boolean',
              value: 'boolean'
            },
            {
              label: 'Object',
              value: 'object'
            },
            ],
          },
        },
        {
          type: 'textfield',
          input: true,
          key: 'idPath',
          weight: 12,
          label: 'ID Path',
          placeholder: 'id',
          tooltip: 'Path to the select option id.'
        },
        {
          type: 'textfield',
          input: true,
          label: 'Value Property',
          key: 'valueProperty',
          skipMerge: true,
          clearOnHide: false,
          weight: 13,
          description: "The selected item's property to save.",
          tooltip: 'The property of each item in the data source to use as the select value. If not specified, the item itself will be used.',
          conditional: {
            json: {
              in: [{
                var: 'data.dataSrc'
              },
              [
                'json',
                'url',
                'custom'
              ],
              ],
            },
          },
        },
        {
          type: 'textfield',
          input: true,
          label: 'Select Fields',
          key: 'selectFields',
          tooltip: 'The properties on the resource to return as part of the options. Separate property names by commas. If left blank, all properties will be returned.',
          placeholder: 'Comma separated list of fields to select.',
          weight: 14,
          conditional: {
            json: {
              and: [{
                '===': [{
                  var: 'data.dataSrc'
                }, 'masterdata']
              },
              {
                '===': [{
                  var: 'data.valueProperty'
                }, '']
              },
              ],
            },
          },
        },
        {
          type: 'checkbox',
          input: true,
          key: 'disableLimit',
          label: 'Disable limiting response',
          tooltip: 'When enabled the request will not include the limit and skip options in the query string',
          weight: 15,
          conditional: {
            json: {
              '===': [{
                var: 'data.dataSrc'
              }, 'url']
            },
          },
        },
        {
          type: 'textfield',
          input: true,
          key: 'searchField',
          label: 'Search Query Name',
          weight: 16,
          description: 'Name of URL query parameter',
          tooltip: 'The name of the search querystring parameter used when sending a request to filter results with. The server at the URL must handle this query parameter.',
          conditional: {
            json: {
              in: [{
                var: 'data.dataSrc'
              },
              [
                'url',
                'masterdata',
              ],
              ],
            },
          },
        },
        {
          type: 'number',
          input: true,
          key: 'minSearch',
          weight: 17,
          label: 'Minimum Search Length',
          tooltip: 'The minimum amount of characters they must type before a search is made.',
          defaultValue: 0,
          conditional: {
            json: {
              and: [{
                '===': [{
                  var: 'data.dataSrc'
                }, 'url']
              },
              {
                '!=': [{
                  var: 'data.searchField'
                }, '']
              },
              ],
            },
          },
        },
        {
          type: 'textfield',
          input: true,
          key: 'filter',
          label: 'Filter Query',
          weight: 18,
          description: 'The filter query for results.',
          tooltip: 'Use this to provide additional filtering using query parameters.',
          conditional: {
            json: {
              in: [{
                var: 'data.dataSrc'
              },
              [
                'url',
                'masterdata',
              ],
              ],
            },
          },
        },
        {
          type: 'number',
          input: true,
          key: 'limit',
          label: 'Limit',
          weight: 18,
          description: 'Maximum number of items to view per page of results.',
          tooltip: 'Use this to limit the number of items to request or view.',
          clearOnHide: false,
          defaultValue: 10,
          conditional: {
            json: {
              and: [{
                in: [{
                  var: 'data.dataSrc'
                },
                [
                  'url',
                  'masterdata'
                ],
                ]
              },
              {
                '!==': [{
                  var: 'data.disableLimit'
                }, true]
              }
              ]
            },
          },
        },
        {
          type: 'textarea',
          input: true,
          key: 'data.custom',
          label: 'Custom Values',
          editor: 'ace',
          rows: 10,
          weight: 14,
          placeholder: "values = data['mykey'];",
          tooltip: 'Write custom code to return the value options. The form data object is available.',
          conditional: {
            json: {
              '===': [{
                var: 'data.dataSrc'
              }, 'custom']
            },
          },
        },
        {
          type: 'textarea',
          input: true,
          key: 'template',
          label: 'Item Template',
          editor: 'ace',
          as: 'html',
          rows: 3,
          weight: 18,
          tooltip: 'The HTML template for the result data items.',
          allowCalculateOverride: true,
          calculateValue: (context) => {
            if (!context.data.template) {
              if (context.instance && context.instance._currentForm.options.editComponent) {
                return context.instance._currentForm.options.editComponent.template;
              }
            }

            return context.data.template;
          }
        },
        {
          type: 'select',
          input: true,
          key: 'refreshOn',
          label: 'Refresh Options On',
          weight: 19,
          tooltip: 'Refresh data when another field changes.',
          dataSrc: 'custom',
          valueProperty: 'value',
          data: {
            custom(context) {
              var values = [];
              values.push({
                label: 'Any Change',
                value: 'data'
              });
              context.utils.eachComponent(context.instance.options.editForm.components, function (component, path) {
                if (component.key !== context.data.key) {
                  values.push({
                    label: component.label || component.key,
                    value: path
                  });
                }
              });
              return values;
            }
          },
          conditional: {
            json: {
              in: [{
                var: 'data.dataSrc'
              },
              [
                'url',
                'masterdata',
                'values'
              ],
              ],
            },
          },
        },
        {
          type: 'select',
          input: true,
          key: 'refreshOnBlur',
          label: 'Refresh Options On Blur',
          weight: 19,
          tooltip: 'Refresh data when another field is blured.',
          dataSrc: 'custom',
          valueProperty: 'value',
          data: {
            custom(context) {
              var values = [];
              values.push({
                label: 'Any Change',
                value: 'data'
              });
              context.utils.eachComponent(context.instance.options.editForm.components, function (component, path) {
                if (component.key !== context.data.key) {
                  values.push({
                    label: component.label || component.key,
                    value: path
                  });
                }
              });
              return values;
            }
          },
          conditional: {
            json: {
              in: [{
                var: 'data.dataSrc'
              },
              [
                'url',
                'masterdata',
                'values'
              ],
              ],
            },
          },
        },
        {
          type: 'checkbox',
          input: true,
          weight: 20,
          key: 'clearOnRefresh',
          label: 'Clear Value On Refresh Options',
          defaultValue: false,
          tooltip: 'When the Refresh On field is changed, clear this components value.',
          conditional: {
            json: {
              in: [{
                var: 'data.dataSrc'
              },
              [
                'url',
                'masterdata',
                'values'
              ],
              ],
            },
          },
        },
        {
          type: 'checkbox',
          input: true,
          weight: 21,
          key: 'searchEnabled',
          label: 'Enable Static Search',
          defaultValue: true,
          tooltip: 'When checked, the select dropdown will allow for searching within the static list of items provided.',
        },
        {
          label: 'Search Threshold',
          mask: false,
          tableView: true,
          alwaysEnabled: false,
          type: 'number',
          input: true,
          key: 'selectThreshold',
          validate: {
            min: 0,
            customMessage: '',
            json: '',
            max: 1,
          },
          delimiter: false,
          requireDecimal: false,
          encrypted: false,
          defaultValue: 0.3,
          weight: 22,
          tooltip: 'At what point does the match algorithm give up. A threshold of 0.0 requires a perfect match, a threshold of 1.0 would match anything.',
        },
        {
          type: 'checkbox',
          input: true,
          weight: 23,
          key: 'addResource',
          label: 'Add Resource',
          tooltip: 'Allows to create a new resource while entering a submission.',
          conditional: {
            json: {
              '===': [{
                var: 'data.dataSrc'
              }, 'masterdata']
            },
          },
        },
        {
          type: 'textfield',
          label: 'Add Resource Label',
          key: 'addResourceLabel',
          tooltip: 'Set the text of the Add Resource button.',
          placeholder: 'Add Resource',
          weight: 24,
          input: true,
          conditional: {
            json: {
              and: [{
                '===': [{
                  var: 'data.dataSrc'
                }, 'masterdata']
              },
              {
                '!!': {
                  var: 'data.addResource'
                }
              },
              ],
            },
          },
        },
        {
          type: 'checkbox',
          input: true,
          weight: 25,
          key: 'reference',
          label: 'Save as reference',
          tooltip: 'Using this option will save this field as a reference and link its value to the value of the origin record.',
          conditional: {
            json: {
              '===': [{
                var: 'data.dataSrc'
              }, 'masterdata']
            },
          },
        },
        {
          type: 'checkbox',
          input: true,
          weight: 26,
          key: 'authenticate',
          label: 'Formio Authenticate',
          tooltip: 'Check this if you would like to use Formio Authentication with the request.',
          conditional: {
            json: {
              '===': [{
                var: 'data.dataSrc'
              }, 'url']
            },
          },
        },
        {
          type: 'checkbox',
          input: true,
          weight: 27,
          key: 'readOnlyValue',
          label: 'Read Only Value',
          tooltip: 'Check this if you would like to show just the value when in Read Only mode.',
        },
        {
          type: 'textarea',
          as: 'json',
          editor: 'ace',
          weight: 28,
          input: true,
          key: 'customOptions',
          label: 'Choices.js options',
          tooltip: 'A raw JSON object to use as options for the Select component (Choices JS).',
          defaultValue: {},
        },
        {
          type: 'checkbox',
          input: true,
          weight: 29,
          key: 'useExactSearch',
          label: 'Use exact search',
          tooltip: 'Disables search algorithm threshold.',
        },
        {
          type: 'checkbox',
          input: true,
          weight: 29,
          key: 'ignoreCache',
          label: 'Disables Storing Request Result in the Cache',
          tooltip: 'Check it if you don\'t want the requests and its results to be stored in the cache. By default, it is stored and if the Select tries to make the request to the same URL with the same paremetrs, the cached data will be returned. It allows to increase performance, but if the remote source\'s data is changing quite often and you always need to keep it up-to-date, uncheck this option.',
          conditional: {
            json: {
              'or': [{
                '===': [{
                  var: 'data.dataSrc'
                }, 'url']
              },
              {
                '===': [{
                  var: 'data.dataSrc'
                }, 'masterdata']
              },
              ]
            },
          },
        },
        {
          weight: 30,
          type: 'radio',
          label: 'Persistent',
          tooltip: 'A persistent field will be stored in database when the form is submitted.',
          key: 'persistent',
          input: true,
          inline: true,
          defaultValue: true,
          values: [{
            label: 'None',
            value: false
          },
          {
            label: 'Server',
            value: true
          },
          {
            label: 'Client',
            value: 'client-only'
          },
          ]
        },
        {
          weight: 150,
          type: 'checkbox',
          label: 'Protected',
          tooltip: 'A protected field will not be returned when queried via API.',
          key: 'protected',
          input: true
        },
        {
          type: 'checkbox',
          input: true,
          weight: 200,
          key: 'dbIndex',
          label: 'Database Index',
          tooltip: 'Set this field as an index within the database. Increases performance for submission queries.'
        },
        {
          weight: 400,
          type: 'checkbox',
          label: 'Encrypted (Enterprise Only)',
          tooltip: 'Encrypt this field on the server. This is two way encryption which is not suitable for passwords.',
          key: 'encrypted',
          input: true
        },
        {
          weight: 700,
          type: 'checkbox',
          label: 'Clear Value When Hidden',
          key: 'clearOnHide',
          defaultValue: true,
          tooltip: 'When a field is hidden, clear the value.',
          input: true
        },
        EditFormUtils.javaScriptValue('Custom Default Value', 'customDefaultValue', 'customDefaultValue', 1000,
          '<p><h4>Example:</h4><pre>value = data.firstName + " " + data.lastName;</pre></p>',
          '<p><h4>Example:</h4><pre>{"cat": [{"var": "data.firstName"}, " ", {"var": "data.lastName"}]}</pre>'
        ),
        EditFormUtils.javaScriptValue('Calculated Value', 'calculateValue', 'calculateValue', 1100,
          '<p><h4>Example:</h4><pre>value = data.a + data.b + data.c;</pre></p>',
          '<p><h4>Example:</h4><pre>{"+": [{"var": "data.a"}, {"var": "data.b"}, {"var": "data.c"}]}</pre><p><a target="_blank" href="http://formio.github.io/formio.js/app/examples/calculated.html">Click here for an example</a></p>',
          '<tr><th>token</th><td>The decoded JWT token for the authenticated user.</td></tr>'
        ),
        {
          type: 'checkbox',
          input: true,
          weight: 1100,
          key: 'calculateServer',
          label: 'Calculate Value on server',
          tooltip: 'Checking this will run the calculation on the server. This is useful if you wish to override the values submitted with the calculations performed on the server.'
        },
        {
          type: 'checkbox',
          input: true,
          weight: 1200,
          key: 'allowCalculateOverride',
          label: 'Allow Manual Override of Calculated Value',
          tooltip: 'When checked, this will allow the user to manually override the calculated value.'
        }
        ]
      },
      {
        label: 'Validation',
        key: 'validation',
        weight: 20,
        components: ComponentEditValidation
      },
      {
        label: 'API',
        key: 'api',
        weight: 30,
        components: [{
          weight: 0,
          type: 'textfield',
          input: true,
          key: 'key',
          label: 'Property Name',
          tooltip: 'The name of this field in the API endpoint.',
          validate: {
            pattern: '(\\w|\\w[\\w-.]*\\w)',
            patternMessage: 'The property name must only contain alphanumeric characters, underscores, dots and dashes and should not be ended by dash or dot.'
          }
        }, {
          weight: 100,
          type: 'tags',
          input: true,
          label: 'Field Tags',
          storeas: 'array',
          tooltip: 'Tag the field for use in custom logic.',
          key: 'tags'
        }, {
          weight: 200,
          type: 'datamap',
          label: 'Custom Properties',
          tooltip: 'This allows you to configure any custom properties for this component.',
          key: 'properties',
          valueComponent: {
            type: 'textfield',
            key: 'value',
            label: 'Value',
            placeholder: 'Value',
            input: true
          }
        },
        {
          weight: 400,
          type: 'textfield',
          input: true,
          placeholder: 'Event Name',
          tooltip: '',
          key: 'eventName',
          clearOnHide: true,
          validate: {
            required: true
          },
          conditional: {
            json: {
              '===': [{
                var: 'data.publishEvents'
              }, true]
            }
          }
        }
        ]
      },
      {
        label: 'Conditional',
        key: 'conditional',
        weight: 20,
        components: ComponentEditConditional
      },
      {
        label: 'Logic',
        key: 'logic',
        weight: 50,
        components: ComponentEditLogic
      },
      {
        label: 'Layout',
        key: 'layout',
        weight: 60,
        components: ComponentEditLayout
      }
      ]
    }
    ]
  };
}
