export function SmartIdEditForm() {
  return {
    components: [
      { key: 'type', type: 'hidden' },
      {
        type: 'tabs',
        key: 'tabs',
        components: [
          {
            label: 'Display',
            key: 'display',
            weight: 0,
            components: [
              {
                weight: 0,
                type: 'textfield',
                input: true,
                key: 'label',
                label: 'Label',
                placeholder: 'Field Label',
                tooltip: 'The label for this field that will appear next to it.',
                validate: {
                  required: true
                }
              },
              {
                type: 'select',
                input: true,
                key: 'labelPosition',
                label: 'Label Position',
                tooltip: 'Position for the label for this field.',
                weight: 20,
                defaultValue: 'top',
                dataSrc: 'values',
                data: {
                  values: [
                    { label: 'Top', value: 'top' },
                    { label: 'Left (Left-aligned)', value: 'left-left' },
                    { label: 'Left (Right-aligned)', value: 'left-right' },
                    { label: 'Right (Left-aligned)', value: 'right-left' },
                    { label: 'Right (Right-aligned)', value: 'right-right' },
                    { label: 'Bottom', value: 'bottom' }
                  ]
                }
              },
              {
                type: 'number',
                input: true,
                key: 'labelWidth',
                label: 'Label Width',
                tooltip: 'The width of label on line in percentages.',
                clearOnHide: false,
                weight: 30,
                placeholder: '30',
                suffix: '%',
                validate: {
                  min: 0,
                  max: 100
                },
                conditional: {
                  json: {
                    and: [
                      { '!==': [{ var: 'data.labelPosition' }, 'top'] },
                      { '!==': [{ var: 'data.labelPosition' }, 'bottom'] },
                    ]
                  }
                }
              },
              {
                type: 'number',
                input: true,
                key: 'labelMargin',
                label: 'Label Margin',
                tooltip: 'The width of label margin on line in percentages.',
                clearOnHide: false,
                weight: 40,
                placeholder: '3',
                suffix: '%',
                validate: {
                  min: 0,
                  max: 100
                },
                conditional: {
                  json: {
                    and: [
                      { '!==': [{ var: 'data.labelPosition' }, 'top'] },
                      { '!==': [{ var: 'data.labelPosition' }, 'bottom'] },
                    ]
                  }
                }
              },
              {
                weight: 100,
                type: 'textfield',
                input: true,
                key: 'placeholder',
                label: 'Placeholder',
                placeholder: 'Placeholder',
                tooltip: 'The placeholder text that will appear when this field is empty.'
              },
              {
                weight: 200,
                type: 'textarea',
                input: true,
                key: 'description',
                label: 'Description',
                placeholder: 'Description for this field.',
                tooltip: 'The description is text that will appear below the input field.',
                editor: 'ace',
                as: 'html',
                wysiwyg: {
                  minLines: 3,
                },
              },
              {
                weight: 300,
                type: 'textarea',
                input: true,
                key: 'tooltip',
                label: 'Tooltip',
                placeholder: 'To add a tooltip to this field, enter text here.',
                tooltip: 'Adds a tooltip to the side of this field.',
                editor: 'ace',
                as: 'html',
                wysiwyg: {
                  minLines: 3,
                },
              },
              {
                weight: 320,
                type: 'textfield',
                input: true,
                key: 'prefix',
                label: 'Prefix',
                prefix: '<i class=\"fa fa-id-card-o\" aria-hidden=\"true\"></i>'
              },
              {
                weight: 330,
                type: 'textfield',
                input: true,
                key: 'suffix',
                label: 'Suffix'
              },
            ]
          },
          {
            label: 'Data',
            key: 'data',
            weight: 0,
            components: [
              {
                weight: 0,
                type: 'textfield',
                input: true,
                key: 'pattern',
                label: 'Pattern',
                placeholder: 'Pattern for generating the ID',
                tooltip: 'Create custom numbers by specifying a pattern. \n Available variables:\n${counter} - Number \n ${day} - 01 to 31 \n ${month} - 01 to 12 \n ${year} - 2021... \n For example, ${year} - ${counter} would be converted to 2021-00001 if zero prefix is true.',
                validate: {
                  custom: 'valid = (input.includes(\'${counter}\')) ? true : \'Pattern should contain ${counter}\'',
                  pattern: '^[^\\s]*$',
                  customMessage: 'space is not allowed or Pattern should contain ${counter}'
                }
              },
              {
                label: 'Start Counter',
                requireDecimal: false,
                inputFormat: 'plain',
                defaultValue: 1,
                key: 'startCounter',
                type: 'number',
                input: true,
                tooltip: 'Counter will start at this number.',
              },
              {
                weight: 1700,
                type: 'checkbox',
                label: 'Add Leading Zeros',
                tooltip: 'Enabling will generating id like for e.g. UN-00001 instead of UN-1',
                key: 'addLeadingZeros',
                input: true,
              },
              {
                type: 'select',
                input: true,
                key: 'digitsInConter',
                label: 'Number of digits in Counter',
                tooltip: 'Generates Id for e.g. as UN-00001 for a 5 digit counter. Once max is reached IDs will be generated without zero prefix like UN-999999.',
                weight: 20,
                conditional: {
                  json: {
                    '===': [{
                      var: 'data.addLeadingZeros'
                    }, true]
                  }
                },
                defaultValue: '5',
                dataSrc: 'values',
                data: {
                  values: [
                    { label: '5', value: '5' },
                    { label: '6', value: '6' },
                    { label: '7', value: '7' },
                    { label: '8', value: '8' },
                    { label: '9', value: '9' },
                    { label: '10', value: '10' },
                    { label: '11', value: '11' },
                    { label: '12', value: '12' },
                    { label: '13', value: '13' },
                    { label: '14', value: '14' },
                    { label: '15', value: '15' },
                    { label: '16', value: '16' },
                    { label: '17', value: '17' },
                    { label: '18', value: '18' },
                    { label: '19', value: '19' },
                    { label: '20', value: '20' }
                  ]
                }
              },
            ]
          },
          {
            label: 'API',
            key: 'api',
            weight: 30,
            components: [
              {
                weight: 0,
                type: 'textfield',
                input: true,
                key: 'key',
                label: 'Property Name',
                tooltip: 'The name of this field in the API endpoint.',
                validate: {
                  pattern: '(\\w|\\w[\\w-.]*\\w)',
                  patternMessage: 'The property name must only contain alphanumeric characters, underscores, dots and dashes and should not be ended by dash or dot.'
                }
              },
            ]
          },
        ],
      }
    ]
  };
}
