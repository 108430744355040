import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { FormsService } from '../formio-core/forms-and-formsets.service';
import { CreateFormComponent } from '../shared/dialogs/create-form/create-form.component';
import { SelectListComponent } from '../shared/dialogs/select-list/select-list.component';
import { ImportFormsComponent } from '../shared/dialogs/import-forms/import-forms.component';
import { ConfirmDialogModel, ConfirmDialogComponent } from '../shared/components/confirm-dialog/confirm-dialog.component';
// import { FormsModule } from './forms';
import { TableColumn } from '../shared/components/table/TableColumn';
import { Sort } from "@angular/material/sort";
import { Observable, Subject } from 'rxjs';
import { SelectDashboardComponent } from '../shared/dialogs/select-dashboard/select-dashboard.component';
import * as dayjs from 'dayjs';
import * as relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);
import decode from 'jwt-decode';
import {DashboardlistDialogComponent} from '../shared/components/dashboardlist-dialog/dashboardlist-dialog.component';
@Component({
  selector: 'app-dashboard-list',
  templateUrl: './dashboard-list.component.html',
  styleUrls: ['./dashboard-list.component.css']
})
export class DashboardListComponent implements OnInit {

  public isClicked = false;
  public filled = false;
  public categories: any;
  public categoryforms: any;
  public errmsg: string;
  public searchterm: string;
  public activelinkcolor: boolean;
  public iscategoryformsempty: boolean;
  public usr;

  listactive = true;
  forms: [];
  listOfDashboards = []

  formsTableColumns: TableColumn[];
  rowActions = [
    {
      label: 'Export',
      key: 'Export',
      icon: 'import_export'
    },
    {
      label: 'Update',
      key: 'update',
      icon: 'edit'
    },
    {
      label: 'Delete',
      key: 'delete',
      icon: 'delete'
    },
  ];
  @ViewChild('Categoryinput') Categoryinput: ElementRef<HTMLInputElement>;
  constructor(
    private route: ActivatedRoute,
    private formservice: FormsService,
    private matDialog: MatDialog,
    private router: Router
  ) { }
  ngOnInit() {
    this.usr = decode(localStorage.getItem('token'));
    console.log("usr = " + JSON.stringify(this.usr));
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.getDashboardList();
    });
    this.initializeColumns();
  }

  getDashboardList() {
    this.formservice
      .getListOfDashboards(false)
      .subscribe((response: any) => {
        // console.log("responseobj = " + response)
        let responseobj = JSON.parse(response);
        console.log(responseobj);
        
        if (responseobj.status === "Success") {
          if (responseobj && responseobj.dashboards && responseobj.dashboards.length > 0) {
            for (let i = 0; i < responseobj.dashboards.length; i++) {
              responseobj.dashboards[i].avatar = "insights";
              // Get the relative time from UMP timestamp
              const timepassed = dayjs(responseobj.dashboards[i].modifiedAt).from(dayjs());
              responseobj.dashboards[i]['modifiedAt'] = timepassed;

              if (responseobj.dashboards[i].default) {

              }
            }
            this.listOfDashboards = responseobj.dashboards;
          }
        }
      });
  }
  initializeColumns(): void {
    this.formsTableColumns = [
      {
        name: 'Icon',
        dataKey: 'avatar',
        position: 'right',
        isSortable: false,
        type: 'icon',
      },
      {
        name: 'Name',
        dataKey: 'name',
        position: 'left',
        isSortable: false,
        type: 'string'
      },
      {
        name: 'User Name',
        dataKey: 'userName',
        position: 'left',
        isSortable: false,
        type: 'string'
      },
      {
        name: 'Role',
        dataKey: 'accessRole',
        position: 'left',
        isSortable: false,
        type: 'dashboard_role_icons'
      },
      {
        name: 'Last Updated',
        dataKey: 'modifiedAt',
        position: 'left',
        isSortable: false,
        type: 'date'
      },
      {
        name: 'slug',
        dataKey: 'slug',
        position: 'left',
        isSortable: false,
        type: 'string'
      },
      {
        name: 'Actions',
        dataKey: 'default',
        position: 'left',
        isSortable: false,
        type: 'dashboard'
      }
    ];
    
    // if (this.usr.ROLE === 'Admin') {
    //   this.formsTableColumns.splice(3, 0, { name: 'Role', dataKey: 'accessRole', position: 'left', isSortable: true, type: 'dashboard_role_icons' });
    // }
  }

  rowAction(eventobj: any) {
    const action = eventobj.actionType;
    const row = eventobj.row;
    switch (action) {
      case 'copy':
        // console.log('copy', row);
        //  this.copyform(row);
        break;
      case 'update':
         this.update(row);
        break;
      case 'delete':
         this.delete(row.id);
        break;
      case 'favorite':
        let data = { "id": row.id, "userId": row.userId, "favourite": !row.favorite, "default": row.default, "updateDefault": false }
        this.updateDashboardProps(data);
        break;
      case 'default':
        let obj = { "id": row.id, "userId": row.userId, "favourite": row.favorite, "default": !row.default, "updateDefault": true }
        this.updateDashboardProps(obj);
        break;
      default:
        break;
    }
  }

  public openSelectlistOverlay() {
    // Returns a handle to the open overlay
    const dialogRef = this.matDialog.open(SelectDashboardComponent, {
      minWidth: '40%',
      panelClass: 'custom-dialog',
      data: {
        type: 'form'
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        console.log("dialog closed")
        this.getDashboardList();
      }
    });
  }
  public openImportFormsOverlay(type: string) {
    // Returns a handle to the open overlay
    const dialogRef = this.matDialog.open(ImportFormsComponent, {
      minWidth: '40%',
      panelClass: 'custom-dialog',
      data: {
        importtype: type,
        formtype: 'form'
      }
    });
  }

  updateDashboardProps(data) {
    this.formservice.markDashboardFavorite(data)
      .subscribe((res) => {
        const response = JSON.parse(res);
        if (response.error === '') {
          if (response.status === 'Success') {
            this.errmsg = "";
            this.getDashboardList();
          }
        } else {
          this.errmsg = response.error;
        }
      });

  }
  update(data:any){
    console.log(data);
    const dialogRef = this.matDialog.open(DashboardlistDialogComponent, {
      minWidth: '40%',
      panelClass: 'custom-dialog',
      data: {
       update:data,
       isupdate:true
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
         console.log(res);
         
      }
    });
  }
  delete(data:any){
    
    const dialogData = new ConfirmDialogModel('Confirm Action', 'Are you sure you want to detete ?');
    const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData,
      panelClass: 'custom-dialog'
    });
    dialogRef.afterClosed().subscribe(confirmationres => {
      if (confirmationres) {
        console.log(data);
        
      //   this.formservice.deleteform(data)
      //   .subscribe((res: any) => {
      //     const response = JSON.parse(res);
      //     if (response.error === '') {
      //       if (response.status === 'Success') {
      //         console.log(response);
      //     }
      //   } else {
      //     this.errmsg = response.error;
      //      console.log(this.errmsg);
      //   }
      // });
      console.log(confirmationres);
      }
    });
  }
}
