
import baseEditForm from 'formiojs/components/_classes/component/Component.form';
// const FieldComponent = Components.editForm;
export function AutoCompleteFieldEditForm(...extend) {
  return baseEditForm([
    {
      key: 'display',
      components: [
        {
          weight: 700,
          type: 'textfield',
          input: true,
          key: 'autocomplete',
          label: 'Autocomplete',
          placeholder: 'on',
          tooltip: 'Indicates whether input elements can by default have their values automatically completed by the browser. See the <a href=\'https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete\'>MDN documentation</a> on autocomplete for more information.'
        }
      ]
    },
  //    {
  //      key: 'data',
  //      components: [
  //     {
  //       type: 'datagrid',
  //       input: true,
  //       label: 'Auto SelectValues Values',
  //       key: 'data.autoselectvalues',
  //       tooltip: 'Values to use as the data source. Labels are shown in the select field. Values are the corresponding values saved with the submission.',
  //       weight: 10,
  //       reorder: true,
  //       components: [
  //         {
  //           label: 'Label',
  //           key: 'label',
  //           input: true,
  //           type: 'textfield',
  //         },
  //         {
  //           label: 'Value',
  //           key: 'value',
  //           input: true,
  //           type: 'textfield',
  //           allowCalculateOverride: true,
  //           calculateValue: { _camelCase: [{ var: 'row.label' }] },
  //         },
  //       ],
  //     },
  //   ]
  //  }
  ], ...extend);
}
