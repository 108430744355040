<mat-toolbar color="primary" class="mat-elevation-z8">
  <div class="company-title">
    <button type="button" mat-icon-button aria-label="Side nav toggle icon" (click)="isExpanded = !isExpanded">
      <mat-icon>menu</mat-icon>
    </button>
    <a routerLink="/dashboard" *ngIf="companysettings">
      {{ companysettings.companyName }} - Turbo Apps Builder
    </a>
  </div>
  <div class="company-logo">
    <button mat-button routerLink="/dashboard" *ngIf="companysettings">
      <img class="company-image" *ngIf="base64comapnyimage" [src]="transform()" />
    </button>
  </div>
  <div class="logout">
    <mat-menu #appMenu="matMenu">
      <button mat-menu-item (click)="onSetTheme('default-theme')">
        Default
      </button>
     <!-- <button mat-menu-item (click)="onSetTheme('dark-theme')">Blue grey & Amber</button>
       <button mat-menu-item (click)="onSetTheme('red-dark-theme')">Red & Orange</button>  -->
      <button mat-menu-item (click)="onSetTheme('light-theme')">
        Blue & Light blue
      </button>
      <button mat-menu-item (click)="onSetTheme('dark-theme')">
      black
      </button>
      <button mat-menu-item (click)="onSetTheme('red-dark-theme')">
        red-dark-theme
        </button>
        <button mat-menu-item (click)="onSetTheme('red-light-theme')">
          red-light-theme
          </button>
        
        <button mat-menu-item (click)="onSetTheme('green-dark-theme')">
          green-dark-theme
          </button>
          <button mat-menu-item (click)="onSetTheme('green-light-theme')">
            green-light-theme 
            </button>
          
    </mat-menu>

    <!-- <button mat-icon-button [matMenuTriggerFor]="appMenu">
      <mat-icon>format_paint</mat-icon>
    </button> -->
    <a mat-button target="_blank" [href]="sanitize(help_url + help_page_name)">
      <mat-icon>help</mat-icon>
    </a>
    <button mat-button (click)="onLogout()">
      <mat-icon>exit_to_app</mat-icon>
    </button>
  </div>
</mat-toolbar>
<mat-sidenav-container class="sidenav-container" autosize [hasBackdrop]="false">
  <mat-sidenav #drawer [fixedInViewport]="true" [disableClose]="true" [fixedTopGap]="65"
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'side' : 'side'"
    [opened]="true">
    <!-- <mat-toolbar>Menu
             </mat-toolbar> -->
    <mat-nav-list>
      <a mat-list-item [routerLink]="['/dashboard']" matTooltip="{{ 'Home' | translate }}"
        routerLinkActive="active-list-item">
        <mat-icon>home</mat-icon>
        <span [translate]="'Home'" *ngIf="isExpanded" class="menu-icon-spacing">Home</span>
      </a>

      <a mat-list-item [routerLink]="['/users']" matTooltip="{{ 'Users' | translate }}" *ngIf="role === 'Admin'"
        routerLinkActive="active-list-item">
        <mat-icon>person</mat-icon>
        <span [translate]="'Users'" *ngIf="isExpanded" class="menu-icon-spacing">Users</span>
      </a>
      
      <a mat-list-item [routerLink]="['/teams']" matTooltip="{{ 'Teams' | translate }}" *ngIf="role === 'Admin'"
        routerLinkActive="active-list-item">
        <mat-icon>group</mat-icon>
        <span [translate]="'Teams'" *ngIf="isExpanded" class="menu-icon-spacing">Teams</span>
      </a>
      <a mat-list-item [routerLink]="['/forms']" matTooltip="{{ 'Forms' | translate }}" *ngIf="role === 'Admin'"
        routerLinkActive="active-list-item">
        <mat-icon>description</mat-icon>
        <span [translate]="'Forms'" *ngIf="isExpanded" class="menu-icon-spacing">Forms</span>
      </a>
      <a mat-list-item [routerLink]="['/formsets']" matTooltip="{{ 'Formsets' | translate }}" *ngIf="role === 'Admin'"
        routerLinkActive="active-list-item">
        <mat-icon>folder</mat-icon>
        <span [translate]="'Formsets'" *ngIf="isExpanded" class="menu-icon-spacing">Formsets</span>
      </a>
      <a mat-list-item [routerLink]="['/masterdata']" matTooltip="{{ 'MasterData' | translate }}"
        routerLinkActive="active-list-item">
        <mat-icon>widgets</mat-icon>
        <span [translate]="'MasterData'" *ngIf="isExpanded" class="menu-icon-spacing">Master Data</span>
      </a>
       <a mat-list-item *ngIf="dashboardUrlExist" [routerLink]="['/dashboard-list']" matTooltip="{{ 'Dashboard' | translate }}"
      routerLinkActive="active-list-item">
        <mat-icon>data_exploration</mat-icon>
        <span [translate]="'Dashboard'" *ngIf="isExpanded" class="menu-icon-spacing">Dashboard</span>
      </a>
      <a mat-list-item [routerLink]="['/reports']" matTooltip="{{ 'Report Builder' | translate }}"
      routerLinkActive="active-list-item" [ngClass]="{'inactive-list-item': handleActiveClass()}"><!--[routerLinkActiveOptions]="{exact: true}" [ngClass]="{'active-list-item': handleActiveClass()}" -->
        <mat-icon>assessment</mat-icon>
        <span [translate]="'Reports Builder'" *ngIf="isExpanded" class="menu-icon-spacing">Report Builder</span>
      </a>
      <a mat-list-item [routerLink]="['/reports/submission']" matTooltip="{{ 'Submission Report' | translate }}"
      routerLinkActive="active-list-item"  >
      <mat-icon>summarize</mat-icon>
      <span [translate]="'Submission Report'" *ngIf="isExpanded" class="menu-icon-spacing">Submission Report</span>
      </a>
      <a mat-list-item [routerLink]="['/workflows']" matTooltip="{{ 'Workflows' | translate }}" *ngIf="role === 'Admin'"
        routerLinkActive="active-list-item">
        <mat-icon>engineering</mat-icon>
        <span [translate]="'Workflows'" *ngIf="isExpanded" class="menu-icon-spacing">Workflows</span>
      </a>
      <a mat-list-item [routerLink]="['/settings']" matTooltip="{{ 'Settings' | translate }}" *ngIf="role === 'Admin'"
        routerLinkActive="active-list-item">
        <mat-icon>settings</mat-icon>
        <span [translate]="'Settings'" *ngIf="isExpanded" class="menu-icon-spacing">Settings</span>
      </a>
      <div *ngIf="role === 'Admin' && externalapps.length > 0">
        <a mat-list-item *ngFor="let app of externalapps" (click)="openapp(app.url, app.target)"
          matTooltip="{{ app.title | translate }}" routerLinkActive="active-list-item">
          <mat-icon>{{ app.icon }}</mat-icon>
          <span [translate]="app.title" *ngIf="isExpanded" class="menu-icon-spacing">{{ app.title }}</span>
        </a>
      </div>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <!-- App content root routes are rendered here -->
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
