import ComponentEditConditional from 'formiojs/components/_classes/component/editForm/Component.edit.conditional';
import ComponentEditAPI from 'formiojs/components/_classes/component/editForm/Component.edit.api';
import ComponentEditLogic from 'formiojs/components/_classes/component/editForm/Component.edit.logic';
import ComponentEditValidation from 'formiojs/components/_classes/component/editForm/Component.edit.validation';
import ComponentEditLayout from 'formiojs/components/_classes/component/editForm/Component.edit.layout';
import EditFormUtils from 'formiojs/components/_classes/component/editForm/utils';

export function SmartTableEditForm() {
  return {
    components: [{
      key: 'type',
      type: 'hidden'
    },
    {
      type: 'tabs',
      key: 'tabs',
      components: [
        {
          label: 'Display',
          key: 'display',
          weight: 0,
          components:
            [
              {
                weight: 0,
                type: 'textfield',
                input: true,
                key: 'label',
                label: 'Label',
                placeholder: 'Field Label',
                tooltip: 'The label for this field that will appear next to it.',
                validate: {
                  required: true
                }
              },
              {
                weight: 706,
                type: 'select',
                label: 'Columns Layout Mode',
                key: 'columnsLayoutMode',
                input: true,
                tooltip: 'Columns Layout Mode of the table.',
                dataSrc: 'values',
                defaultValue: 'fitDataFill',
                data: {
                  values: [{
                    label: 'Fit Data',
                    value: 'fitData'
                  }, {
                    label: 'Fit Data Fill',
                    value: 'fitDataFill'
                  }, {
                    label: 'Fit Data Stretch',
                    value: 'fitDataStretch'
                  },
                  {
                    label: 'Fit Columns',
                    value: 'fitColumns'
                  }]
                }
              }, {
                weight: 707,
                type: 'select',
                label: 'Responsive Columns Layout ',
                key: 'responsiveLayout',
                input: true,
                tooltip: 'Responsive Columns Layout of the table.',
                dataSrc: 'values',
                data: {
                  values: [{
                    label: 'None',
                    value: 'none'
                  }, {
                    label: 'Hide',
                    value: 'hide'
                  }, {
                    label: 'Collapse',
                    value: 'collapse'
                  }]
                }, defaultValue: 'collapse',
              }, {
                weight: 708,
                type: 'select',
                label: 'Column Grouping',
                key: 'columnHeaderVertAlign',
                input: true,
                tooltip: 'Column Grouping of the table.',
                dataSrc: 'values',
                data: {
                  values: [{
                    label: 'None',
                    value: 'none'
                  }, {
                    label: 'Top',
                    value: 'top'
                  }, {
                    label: 'Middle',
                    value: 'middle'
                  }, {
                    label: 'Bottom',
                    value: 'bottom'
                  }]
                }, defaultValue: 'middle',
              },
              {
                weight: 709,
                type: 'checkbox',
                label: 'Frozen Columns',
                key: 'frozen',
                input: true,
                tooltip: 'This will freeze the position of columns on the left and right of the table.',
              },
              {
                weight: 712,
                type: 'checkbox',
                label: 'Filter Header',
                key: 'filterHeader',
                input: true,
                tooltip: 'This will allows to filter table header with table data.',
              }, {
                weight: 713,
                type: 'checkbox',
                label: 'Movable Rows',
                key: 'movableRows',
                input: true,
                tooltip: 'This will allows to move rows up and down the table.',
              },
              {
                weight: 713,
                type: 'checkbox',
                label: 'MultiSelect',
                key: 'multiSelect',
                input: true,
                defaultValue: true,
                tooltip: 'This will allows to select multiple rows in the table.',
              },
              {
                weight: 713,
                type: 'checkbox',
                label: 'Add Action Button to Each row',
                key: 'actionButton',
                input: true,
                defaultValue: true,
                tooltip: 'This will Add Action Button to each row in the table.',
              },
              {
                weight: 713,
                type: 'checkbox',
                label: 'Allow to add rows',
                key: 'allowAddRows',
                input: true,
                defaultValue: true,
                tooltip: 'This will Allow to add rows in the table.',
              },
              {
                weight: 713,
                type: 'checkbox',
                label: 'Allow to delete rows',
                key: 'allowDeleteRows',
                input: true,
                defaultValue: true,
                tooltip: 'This will Allow to delete rows in the table.',
              },
              {
                type: 'textfield',
                label: 'Fontawesome icon tag',
                key: 'fontawesomeIconTag',
                weight: 112,
                tooltip: 'This icon will be used for Action Button. Ex: fa-exchange',
                placeholder: 'Enter a Fontawesome icon tag',
                input: true,
                defaultValue: 'fa-exchange',
                conditional: {
                  json: {
                    '===': [{
                      var: 'data.actionButton'
                    }, true]
                  }
                },
              },
              {
                weight: 713,
                type: 'checkbox',
                label: 'Column Selection Menu',
                key: 'columnSelectionMenu',
                input: true,
                defaultValue: true,
                tooltip: 'This will Add menu button to Column header.',
              }
            ]
        },
        {
          label: 'Data',
          key: 'data',
          weight: 10,
          // components: ComponentEditData
          components:
            [
              {
                type: 'textfield',
                label: 'Default Value',
                key: 'defaultValue',
                weight: 5,
                placeholder: 'Default Value',
                tooltip: 'The will be the value for this field, before user interaction. Having a default value will override the placeholder text.',
                input: true
              },
              {
                weight: 30,
                type: 'radio',
                label: 'Persistent',
                tooltip: 'A persistent field will be stored in database when the form is submitted.',
                key: 'persistent',
                input: true,
                inline: true,
                defaultValue: true,
                values: [
                  { label: 'None', value: false },
                  { label: 'Server', value: true },
                  { label: 'Client', value: 'client-only' },
                ]
              },
              {
                weight: 150,
                type: 'checkbox',
                label: 'Protected',
                tooltip: 'A protected field will not be returned when queried via API.',
                key: 'protected',
                input: true
              },
              {
                type: 'checkbox',
                input: true,
                weight: 200,
                key: 'dbIndex',
                label: 'Database Index',
                tooltip: 'Set this field as an index within the database. Increases performance for submission queries.'
              },
              {
                weight: 400,
                type: 'checkbox',
                label: 'Encrypted (Enterprise Only)',
                tooltip: 'Encrypt this field on the server. This is two way encryption which is not suitable for passwords.',
                key: 'encrypted',
                input: true
              },
              {
                type: 'select',
                input: true,
                key: 'redrawOn',
                label: 'Redraw On',
                weight: 600,
                tooltip: 'Redraw this component if another component changes. This is useful if interpolating parts of the component like the label.',
                dataSrc: 'custom',
                valueProperty: 'value',
                data: {
                  custom(context) {
                    // tslint:disable-next-line:prefer-const
                    var values = [];
                    values.push({ label: 'Any Change', value: 'data' });
                    context.utils.eachComponent(context.instance.options.editForm.components, function (component, path) {
                      if (component.key !== context.data.key) {
                        values.push({
                          label: component.label || component.key,
                          value: path
                        });
                      }
                    });
                    return values;
                  }
                },
                conditional: {
                  json: { '!': [{ var: 'data.dataSrc' }] },
                },
              },
              {
                weight: 700,
                type: 'checkbox',
                label: 'Clear Value When Hidden',
                key: 'clearOnHide',
                defaultValue: true,
                tooltip: 'When a field is hidden, clear the value.',
                input: true
              },
              EditFormUtils.javaScriptValue('Custom Default Value', 'customDefaultValue', 'customDefaultValue', 1000,
                '<p><h4>Example:</h4><pre>value = data.firstName + " " + data.lastName;</pre></p>',
                '<p><h4>Example:</h4><pre>{"cat": [{"var": "data.firstName"}, " ", {"var": "data.lastName"}]}</pre>'
              ),
              EditFormUtils.javaScriptValue('Calculated Value', 'calculateValue', 'calculateValue', 1100,
                '<p><h4>Example:</h4><pre>value = data.a + data.b + data.c;</pre></p>',
                '<p><h4>Example:</h4><pre>{"+": [{"var": "data.a"}, {"var": "data.b"}, {"var": "data.c"}]}</pre><p><a target="_blank" href="http://formio.github.io/formio.js/app/examples/calculated.html">Click here for an example</a></p>',
                '<tr><th>token</th><td>The decoded JWT token for the authenticated user.</td></tr>'
              ),
              {
                type: 'checkbox',
                input: true,
                weight: 1100,
                key: 'calculateServer',
                label: 'Calculate Value on server',
                tooltip: 'Checking this will run the calculation on the server. This is useful if you wish to override the values submitted with the calculations performed on the server.'
              },
              {
                type: 'checkbox',
                input: true,
                weight: 1200,
                key: 'allowCalculateOverride',
                label: 'Allow Manual Override of Calculated Value',
                tooltip: 'When checked, this will allow the user to manually override the calculated value.'
              },
            ]
        },
        {
          label: 'Validation',
          key: 'validation',
          weight: 20,
          components: ComponentEditValidation
        },
        {
          label: 'API',
          key: 'api',
          weight: 30,
          components: ComponentEditAPI
        },
        {
          label: 'Conditional',
          key: 'conditional',
          weight: 40,
          components: ComponentEditConditional
        },
        {
          label: 'Logic',
          key: 'logic',
          weight: 50,
          components: ComponentEditLogic
        },
        {
          label: 'Layout',
          key: 'layout',
          weight: 60,
          components: ComponentEditLayout
        },
        {
          label: 'Columns',
          key: 'columns',
          weight: 70,

          components: [
            {
              type: 'datagrid',
              input: true,
              label: 'Column Header',
              key: 'columnHeaderNames',
              tooltip: 'This will create the header name of a table. Names separated by comma will be considered under table`s column groups.',
              weight: 0,
              reorder: true,
              defaultValue: [{ label: '', value: '', readOnly: true }],
              components: [
                {
                  label: 'Label',
                  key: 'label',
                  input: true,
                  type: 'textfield',
                },
                {
                  label: 'Value',
                  key: 'value',
                  input: true,
                  type: 'textfield',
                  allowCalculateOverride: true
                },
                {
                  type: 'checkbox',
                  label: 'ReadOnly',
                  key: 'readOnly',
                  input: true,
                  weight: 1,
                  defaultValue: true
                },
              ],
            },
            {
              weight: 713,
              type: 'checkbox',
              label: 'Set Item Headers',
              key: 'setItemHeaders',
              input: true,
              defaultValue: false,
              tooltip: 'This will allow to set item headers for the table.',
            },
            {
              type: 'datagrid',
              input: true,
              label: 'Item Header',
              key: 'itemHeaderNames',
              tooltip: 'This will create the header name of a nested table. Names separated by comma will be considered under table`s column groups.',
              weight: 0,
              reorder: true,
              defaultValue: [{ label: '', value: '', readOnly: true }],
              conditional: {
                json: {
                  '===': [{
                    var: 'data.setItemHeaders'
                  }, true]
                }
              },
              components: [
                {
                  label: 'Label',
                  key: 'label',
                  input: true,
                  type: 'textfield',
                },
                {
                  label: 'Value',
                  key: 'value',
                  input: true,
                  type: 'textfield',
                  allowCalculateOverride: true
                },
                {
                  type: 'checkbox',
                  label: 'ReadOnly',
                  key: 'readOnly',
                  input: true,
                  weight: 1,
                  defaultValue: true
                },
              ],
            },
          ]
        }
      ]
    }
    ]
  };
}
