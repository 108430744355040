import { Components } from 'angular-formio';
import _ from 'lodash';
import { AutoCompleteFieldEditForm } from './autocompletefield.editForm';
import { Formio } from 'angular-formio';
import FormioUtils from 'formiojs/utils';
const InputComponent = Components.components.input;
export default class AutocompleteField extends InputComponent {
  constructor(component, options, data) {
    super(component, options, data);
    // console.log(component);
  }
  static schema(...extend) {
    return InputComponent.schema({
      label: "Autocomplete Input",
      key: "autocomplete",
      type: "autocomplete",
      persistent: "client-only"
    },
      ...extend
    );
  }

  init() {
    super.init();
  }
  render() {
    // console.log(this.builderMode);
    // if (this.component.autocomplete) {
    //    console.log(this.component.autocomplete);
    // }
    return super.render();
  }
  attach(element) {
    this.loadRefs(element,{
      element:'single'
    });
    // console.log(this.refs.element);
    return super.attach(element);
  }
  attachElement(element) {
    // console.log(element);
    // console.log(this.component.data.autoselectvalues);
    let _self = this;
    this.refs.element.classList.add("autocomplete-field");
    let currentFocus;
    let arr = this.data.inputName.choice;
    // console.log(this.data.inputName.choice);
    this.addEventListener(element,'input', function(e) {
      _self.setValue(element.value);
      element.setAttribute('autocomplete','off');
      // console.log(e.data);
      if(e.data === '$' || e.data === '{') {
      let a, b, i, val = element.value;

      closeAllLists();
      if (!val) { return false;}
      currentFocus = -1;
      /*create a DIV element that will contain the items (values):*/
      a = document.createElement("div");
      a.setAttribute("id", element.id + "autocomplete-list");
      a.setAttribute("class", "autocomplete-items");
      /*append the DIV element as a child of the autocomplete container:*/
      element.parentNode.appendChild(a);
      /*for each item in the array...*/
      for (i = 0; i < arr.length; i++) {
        /*check if the item starts with the same letters as the text field value:*/
        // if (arr[i].substr(0, val.length).toUpperCase() == val.toUpperCase()) {
          /*create a DIV element for each matching element:*/
          b = document.createElement("div");
          b.classList.add("auto-items-area");
          /*make the matching letters bold:*/
          b.innerHTML = `<span class='material-icons'>${arr[i].icon}</span>`;
          b.innerHTML += `<span>${arr[i].label}</span>`;
          /*insert a input field that will hold the current array item's value:*/
          b.innerHTML += "<input type='hidden' value='" +  arr[i].value  + "'>";
          /*execute a function when someone clicks on the item value (DIV element):*/
              b.addEventListener("click", function(e) {
              /*insert the value for the autocomplete text field:*/
              // console.log(element.value.substr(element.value.length - 1));
              if(element.value.substr(element.value.length - 1) === '{') {
                element.value = element.value.slice(0,-2) + this.getElementsByTagName("input")[0].value;
              } else {
                element.value = element.value.slice(0,-1) + this.getElementsByTagName("input")[0].value;
              }
              // console.log(element.value);
              _self.setValue(element.value);
              /*close the list of autocompleted values,
              (or any other open lists of autocompleted values:*/
              closeAllLists();
          });
          a.appendChild(b);
        // }
      }} else {
        closeAllLists();
      }
  });
  /*execute a function presses a key on the keyboard:*/
  this.addEventListener(element,"keydown", function(e) {
    var x = document.getElementById(element.id + "autocomplete-list");
    if (x) x = x.getElementsByTagName("div");
    if (e.keyCode == 40) {
      /*If the arrow DOWN key is pressed,
      increase the currentFocus variable:*/
      currentFocus++;
      /*and and make the current item more visible:*/
      addActive(x);
    } else if (e.keyCode == 38) { //up
      /*If the arrow UP key is pressed,
      decrease the currentFocus variable:*/
      currentFocus--;
      /*and and make the current item more visible:*/
      addActive(x);
    } else if (e.keyCode == 13) {
      /*If the ENTER key is pressed, prevent the form from being submitted,*/
      e.preventDefault();
      if (currentFocus > -1) {
        /*and simulate a click on the "active" item:*/
        if (x) x[currentFocus].click();
      }
    }
});
function addActive(x) {
  /*a function to classify an item as "active":*/
  if (!x) return false;
  /*start by removing the "active" class on all items:*/
  removeActive(x);
  if (currentFocus >= x.length) currentFocus = 0;
  if (currentFocus < 0) currentFocus = (x.length - 1);
  /*add class "autocomplete-active":*/
  x[currentFocus].classList.add("autocomplete-active");
}
function removeActive(x) {
  /*a function to remove the "active" class from all autocomplete items:*/
  for (var i = 0; i < x.length; i++) {
    x[i].classList.remove("autocomplete-active");
  }
}
function closeAllLists(elmnt) {
  /*close all autocomplete lists in the document,
  except the one passed as an argument:*/
  var x = document.getElementsByClassName("autocomplete-items");
  for (var i = 0; i < x.length; i++) {
    if (elmnt != x[i] && elmnt != element) {
    x[i].parentNode.removeChild(x[i]);
  }
}}

}
  detach() {
    // Called on redraw or rebuild. The opposite of attach.
  }

  destroy() {
    // Called on rebuild. The opposite of init.
  }
}

// AutocompleteField.builderInfo = {
//   title: "Autocomplete Field",
//   icon: "terminal",
//   group: "basic",
//   // documentation: "http://help.form.io/userguide/#datasource",
//   weight: 30,
//   schema: AutocompleteField.schema()
// };

AutocompleteField.editForm = AutoCompleteFieldEditForm;
// Register the component to the Formio.Components registry.
Components.addComponent('autocomplete', AutocompleteField);
