import {
  Injectable,
  Inject,
  OnInit,
  Injector,
  ComponentRef
} from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { IconpickerComponent } from './iconpicker.component';
import { iconPickerOverlayRef } from './iconpickerref';
import { ICON_PICKER_DATA } from './iconpicker.tokens';
// Each property can be overridden by the consumer
interface iconPickerModalConfig {
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
  data?:any
}
const DEFAULT_CONFIG: iconPickerModalConfig = {
  hasBackdrop: true,
  backdropClass: 'light-backdrop',
  panelClass: 'iconpicker-panel',
  data:null
};


@Injectable({
  providedIn: 'root'
})
export class IconpickerService {
  constructor(private injector: Injector, private overlay: Overlay) {}

  open(config: iconPickerModalConfig = {}) {
    // Override default configuration
    const dialogConfig = { ...DEFAULT_CONFIG, ...config };
    // Returns an OverlayRef which is a PortalHost
    const overlayRef = this.createOverlay(dialogConfig);
    // Instantiate remote control
    const dialogRef = new iconPickerOverlayRef(overlayRef);
    const overlayComponent = this.attachDialogContainer(
      overlayRef,
      dialogConfig,
      dialogRef
    );
    // Create ComponentPortal that can be attached to a PortalHost
    // const createUserPortal = new ComponentPortal(CreateUserDialogComponent);
    // overlayRef.attach(createUserPortal);
    overlayRef.backdropClick().subscribe(_ => dialogRef.close());
    dialogRef.componentInstance = overlayComponent;
    // Return remote control
    return dialogRef;
  }
  private attachDialogContainer(
    overlayRef: OverlayRef,
    config: iconPickerModalConfig,
    dialogRef: iconPickerOverlayRef
  ) {
    const injector = this.createInjector(config, dialogRef);

    const containerPortal = new ComponentPortal(
      IconpickerComponent,
      null,
      injector
    );
    const containerRef: ComponentRef<IconpickerComponent> = overlayRef.attach(
      containerPortal
    );

    return containerRef.instance;
  }
  private createInjector(
    config: iconPickerModalConfig,
    dialogRef: iconPickerOverlayRef
  ): PortalInjector {
    const injectionTokens = new WeakMap();

    injectionTokens.set(iconPickerOverlayRef, dialogRef);
    injectionTokens.set(ICON_PICKER_DATA, config.data);

    return new PortalInjector(this.injector, injectionTokens);
  }
  private createOverlay(config: iconPickerModalConfig) {
    const overlayConfig = this.getOverlayConfig(config);
    return this.overlay.create(overlayConfig);
  }
  private getOverlayConfig(config: iconPickerModalConfig): OverlayConfig {
    const positionStrategy = this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();

    const overlayConfig = new OverlayConfig({
      width: '250px',
      height: '200px',
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy
    });

    return overlayConfig;
  }
}
