<div class="overlay-container">
    <mat-toolbar color="primary">
    <span>Update Dashboard List</span> 
      <span class="fill-remaining-space"></span>
      <button mat-button (click)="closeoverlay(null)" class="clear-border-radius">
        <mat-icon>clear</mat-icon>
      </button>
    </mat-toolbar>
    <form class="dialog-container" [formGroup]="update" autocomplete="off"  novalidate>
        <mat-form-field>
            <input  matInput placeholder="Name" formControlName="name"/>
            <mat-error> Name is required</mat-error>
          </mat-form-field>
          <mat-form-field>
            <input  matInput placeholder="User Name" formControlName="userName"/>
             <mat-error> User Name is required </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input  matInput placeholder="Description" formControlName="description"/>
            <mat-error>  Description is required</mat-error>
          </mat-form-field>
          <mat-form-field>
            <input  matInput placeholder="Slug" formControlName="slug"/>
            <mat-error> Slug is required </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input  matInput placeholder="Access Role" formControlName="accessRole"/>
            <mat-error> Access Role is required </mat-error>
          </mat-form-field>
          <mat-form-field appearance="legacy">
            <input matInput placeholder="Pick Icon" formControlName="icon" />
            <mat-icon>{{ picked_icon }}</mat-icon>
            <button mat-icon-button (click)="openiconpicker()" matSuffix>
              <mat-icon>description</mat-icon>
            </button>
          </mat-form-field>
        <div class="aligned-center-btn">
         <button mat-flat-button color="primary" [disabled]="!update.valid" (click)="updateDashboardList()" >Update</button>
        </div>
    </form>
</div>
