import { Injectable } from '@angular/core';
import {
  CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
// import { map, take } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {

  constructor(private authservice: AuthenticationService,
              private router: Router) { }
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const user = this.authservice.decode();
    // console.log(user);
    if (user.ROLE === next.data.role) {
      return true;
    }

    // navigate to not found page
    this.router.navigate(['/reports']);
    return false;
  }
}
