<mat-toolbar color="primary">
  <span>Import Forms</span>
  <span class="fill-remaining-space"></span>
  <button mat-button (click)="closeoverlay()">
    <mat-icon>clear</mat-icon>
  </button>
</mat-toolbar>
<div *ngIf="errmsg" class="error-msg">
  <span>{{ errmsg }}</span>
</div>
<div class="dialog-container" *ngIf="data.importtype === 'file'">
  <div>
    <mat-checkbox
      class="checkbox-label-margin"
      labelPosition="before"
      (change)="autopublish = !autopublish"
      >Publish after import</mat-checkbox
    >
    <div>
      <mat-checkbox
        class="checkbox-label-margin"
        labelPosition="before"
        (change)="overwritedraft = !overwritedraft"
        >Overwrite existing drafts during import</mat-checkbox
      >
    </div>
  </div>
  <div class="file-drop-area">
    <button mat-flat-button color="primary">Choose file</button>
    <span class="file-msg">{{
      file ? file.name : "or drag and drop file here"
    }}</span>
    <input
      class="file-input"
      #fileInput
      accept="application/json"
      type="file"
      (change)="processFile(fileInput)"
    />
  </div>
  <div class="aligned-center-btn">
    <button
      mat-flat-button
      color="primary"
      (click)="importfromfile()"
      [disabled]="!FileJsonObj"
    >
      Import
    </button>
    <!-- <button mat-flat-button color="primary" *ngIf="data.importtype === 'remote'" [disabled]="!importform.valid">Import</button> -->
  </div>
</div>
<div *ngIf="data.importtype === 'remote' && formsselectlist.length === 0">
  <form class="dialog-container" [formGroup]="importform" novalidate>
    <mat-form-field>
      <input
        matInput
        placeholder="URL"
        type="url"
        formControlName="url"
        autocomplete="off"
      />
      <mat-error *ngIf="importform.get('url').hasError('required')">
        url is required
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <input
        matInput
        placeholder="User"
        formControlName="email"
        autocomplete="off"
      />
      <mat-error *ngIf="importform.get('email').hasError('required')">
        user is required
      </mat-error>
      <mat-error *ngIf="importform.get('email').hasError('email')">
        Please enter valid email id
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <input
        formControlName="password"
        matInput
        placeholder="Password"
        [type]="showpassword ? 'text' : 'password'"
      />
      <button
        type="button"
        *ngIf="!showpassword"
        matSuffix
        mat-icon-button
        aria-label="icon-button with a Eye off icon"
        (click)="showpassword = !showpassword"
      >
        <mat-icon>visibility_off</mat-icon>
      </button>
      <button
        type="button"
        *ngIf="showpassword"
        matSuffix
        mat-icon-button
        aria-label="icon-button with a Eye icon"
        (click)="showpassword = !showpassword"
      >
        <mat-icon>visibility</mat-icon>
      </button>
      <mat-error *ngIf="importform.get('password').hasError('whitespace')">
        Whitespaces are not allowed
      </mat-error>
    </mat-form-field>
  </form>
  <div class="aligned-center-btn">
    <button
      mat-flat-button
      color="primary"
      *ngIf="data.importtype === 'remote'"
      (click)="downloadforms()"
      [disabled]="!importform.valid"
    >
      Import
    </button>
    <!-- <button mat-flat-button color="primary" *ngIf="data.importtype === 'remote'" [disabled]="!importform.valid">Import</button> -->
  </div>
</div>
<div
  [ngStyle]="{ visibility: formsselectlist.length > 0 ? 'visible' : 'hidden' }"
  class="select-list-container"
>
  <!--
    <pre *ngIf="formsselectlist">
      {{formsselectlist | json}}
    </pre>
  -->
  <div>
    <mat-checkbox
      class="checkbox-label-margin"
      labelPosition="before"
      (change)="autopublish = !autopublish"
      >Publish after import</mat-checkbox
    >
    <div>
      <mat-checkbox
        class="checkbox-label-margin"
        labelPosition="before"
        (change)="overwritedraft = !overwritedraft"
        >Overwrite existing drafts during import</mat-checkbox
      >
    </div>
    <button
      mat-flat-button
      class="export-btn"
      (click)="importselectedforms()"
      color="primary"
    >
      Import
    </button>
  </div>
  <mat-checkbox
    class="checkbox-label-margin"
    labelPosition="before"
    (change)="$event ? masterToggle() : null"
    [checked]="selectedformitems.selectedOptions.hasValue() && isAllSelected()"
    [indeterminate]="
      selectedformitems.selectedOptions.hasValue() && !isAllSelected()
    "
  >
    Select All
  </mat-checkbox>
  <mat-selection-list #selectedformitems>
    <mat-list-option
      *ngFor="let formitem of formsselectlist"
      [value]="formitem.id"
    >
      {{ formitem.name }}
    </mat-list-option>
  </mat-selection-list>
</div>
